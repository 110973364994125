import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import LoadingView from "../app/LoadingView";

const QuantityPicker = ({
  quantity,
  onAddition,
  onSubtraction,
  maxBottles,
  currentBottles,
  limitedStock,
  isShippingFee,
}: {
  quantity: number;
  onAddition: () => void;
  onSubtraction: () => void;
  maxBottles: number;
  currentBottles: number;
  limitedStock?: boolean;
  isShippingFee?: boolean;
}) => {
  const [preventAdd, setPreventAdd] = useState(false);
  const [preventSub, setPreventSub] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);

  const disableSub = quantity <= 1;
  // FIXME - quantity >= maxQuantity

  useEffect(() => {
    if (currentBottles + quantity === maxBottles || limitedStock === true) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
  }, [quantity]);

  useEffect(() => {
    setPreventAdd(false);
    setPreventSub(false);
  }, [quantity]);

  const doAdd = () => {
    setPreventAdd(true);
    onAddition();
  };
  const doSub = () => {
    setPreventSub(true);
    onSubtraction();
  };

  return (
    <div className="quantity-picker-component">
      <div className="button-container">
        <Button
          className="sub-button"
          onClick={doSub}
          disabled={preventSub || preventAdd || disableSub || isShippingFee}
        >
          {preventSub ? <LoadingView minHeight="0" /> : <span>-</span>}
        </Button>
      </div>
      <div className="quantity">{quantity}</div>
      <div className="button-container">
        <Button
          className="add-button"
          onClick={doAdd}
          disabled={preventAdd || preventSub || disableAdd || isShippingFee}
        >
          {preventAdd ? <LoadingView minHeight="0" /> : <span>+</span>}
        </Button>
      </div>
    </div>
  );
};

export default QuantityPicker;
