const IMAGE_HOST = "http://localhost:3000";

const IMAGE_FALLBACKS = {
  distilleryCard: require("./assets/fallbacks/distillery-premises.png"),
  distilleryPageEnviron: require("./assets/fallbacks/distillery-environ.png"),
  distilleryPageInterior: require("./assets/fallbacks/distillery-interior.png"),
  distilleryPagePremises: require("./assets/fallbacks/distillery-premises.png"),
  caskImageCaskBottom: require("./assets/fallbacks/cask-bottom.png"),
  caskImageCaskBottles: require("./assets/fallbacks/cask-bottles.png"),
  defaultWhiskyProduct: require("./assets/fallbacks/bottle-Fallback.png"),
  defaultPlaceholder: require("./assets/fallbacks/tiny.jpg"),
  glenrothesPlaceholder: require("./assets/fallbacks/glenrothesPlaceholder.png"),
  defaultBottle: require("./assets/home-page/.v3/db-lg.png"),
};

export { IMAGE_HOST, IMAGE_FALLBACKS };
