import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../state/rootReducer";

export function RequireAuth({ children }: any) {
  const loggedInUser = useSelector(
    (state: RootState) => state.user.credentials?.accessToken,
  );
  if (!loggedInUser) {
    window.sessionStorage.setItem("location", window.location.pathname);
  }

  return (
    loggedInUser ? (
      children
    ) : (
      <>
        {/* {console.log(location)} */}
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      </>
    )
  )
}
