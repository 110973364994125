import { MdDataExploration } from "react-icons/md";
import { store } from "../../../state/store";
import { formatCurrency } from "../../../utils/currency";

const shippingMarket = (market: string) => {
  switch (market) {
    case "GB":
      return "*UK only";
    case "US":
      return "*US only";
    case "EU":
      return "*EU only (see T&Cs for available destinations)";
    default:
      return "*UK only";
  }
};

export const collectiveBarrel = () => {
  const barrel = [
    {
      id: 0,
      url: require("../../../assets/collective-page/iconBarrel.svg"),
      title: "save every month",
      text: `Add between  ${formatCurrency(
        store.getState().shop.currency,
        0,
      ).format(5)} - ${formatCurrency(store.getState().shop.currency, 0).format(
        200,
      )} / month to your BarrelBank to use on Caskshares and ready bottled shop.`,
    },
    {
      id: 1,
      url: require("../../../assets/collective-page/iconDiscount.svg"),
      title: "earn discounts",
      text: "Earn discounts on your Caskshare purchases and keep track of Caskshare savings and discounts.",
    },
  ];
  return barrel;
};

export const collectiveCards = () => {
  const cards = [
    {
      id: 0,
      url: require("../../../assets/collective-page/cardExclusive.jpg"),
      title: "SAVE WITH BARRELBANK",
      text: `For every ${formatCurrency(
        store.getState().shop.currency,
        0
      ).format(100)} you deposit, receive ${formatCurrency(
        store.getState().shop.currency,
        0
      ).format(10)} from us.`,
      markets: ["GB"],
    },
    {
      id: 1,
      url: require("../../../assets/collective-page/cardExclusive.jpg"),
      title: "MEMBER PRICING",
      text: "Save with Exclusive Collective Member Discounts.",
      markets: ["US", "EU"],
    },
    {
      id: 2,
      url: require("../../../assets/collective-page/cardShipping.jpg"),
      title: "FREE SHIPPING",
      text: `Free shipping on all products.<br>${shippingMarket(
        store.getState().shop.market
      )}`,
    },
    {
      id: 3,
      url: require("../../../assets/collective-page/cardEvents.jpg"),
      title: "EVENTS",
      text: "Monthly events at our private bar and virtually.",
    },
  ];
  return cards;
};

export const collectiveInfo = () => {
  const data = [
    {
      id: 0,
      url: require("../../../assets/collective-page/iconPortfolio.svg"),
      title: "Caskshare",
      title2: "Portfolio",
      text: "Build your whisky portfolio of unique -cask whisky.",
    },
    {
      id: 1,
      url: require("../../../assets/collective-page/iconShipping.svg"),
      title: "free",
      title2: "shipping",
      text: `Free shipping on all purchases.`,
    },
    {
      id: 2,
      url: require("../../../assets/collective-page/iconBarrel.svg"),
      title: "Save with",
      title2: "BarrelBank",
      text: `For every ${formatCurrency(
        store.getState().shop.currency,
        0,
      ).format(100)} you deposit, receive ${formatCurrency(
        store.getState().shop.currency,
        0,
      ).format(10)} from us.`,
      // text2: "You can save your BarrelBank funds up to buy anything on our site"
      markets: ["GB"],
    },
    {
      id: 3,
      url: require("../../../assets/collective-page/iconBarrel.svg"),
      title: "Save with",
      title2: "Member Pricing",
      text: "Enjoy Exclusive Collective Member Discounts.",
      markets: ["US", "EU"],
    },
    {
      id: 4,
      url: require("../../../assets/collective-page/iconPortfolio.svg"),
      title: "Caskshare",
      title2: "Events",
      text: "Book your seat and buy your pack, all in one place.",
    },
  ];
  return data;
};

export const largeCardCollectiveInfo = () => {
  const data = [
    {
      id: 0,
      title: "exclusive events",
      body: "Whether you’re joining us online or in person, you can book your seat and buy your pack, all in one place. Not only that, but you can catch up on past events too. Simply order your pack linked to the video you’d like to see and enjoy at your leisure.",
      url: require("../../../caskshare-v3/assets/collective-page/collective_events.jpg"),
    },
    {
      id: 1,
      title: "BarrelBank",
      url: require("../../../caskshare-v3/assets/collective-page/collective_barrelbank.jpg"),
      body: `For every ${formatCurrency(
        store.getState().shop.currency,
        0,
      ).format(100)} 
      spent from your Barrelbank you get ${formatCurrency(
        store.getState().shop.currency,
        0,
      ).format(10)}
       credit towards your purchase. This means you can save money each month, spread the cost of your Caskshare purchase and earn discounts along the way.`,
      markets: ["GB"],
    },
    {
      id: 1,
      title: "Member Pricing",
      url: require("../../../caskshare-v3/assets/collective-page/collective_barrelbank.jpg"),
      body: `Benefit from exclusive Collective Member pricing. Log in to your Collective Member account to receive discounts on our bottles. Make your
      money go further. Get notified first to ensure you get preferential access to our latest releases.`,
      markets: ["US", "EU"],
    },
  ];
  return data;
};
