import { useEffect, useRef, useState, useMemo, MutableRefObject } from "react";

export default function useInViewport<T extends Element>(
  ref: MutableRefObject<T>,
  rootMargin: string = "0px",
  threshold: number = 0,
): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry?.isIntersecting);
        },
        {
          rootMargin,
          threshold,
        },
      ),
    [],
  );

  useEffect(() => {
    ref?.current && observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref?.current, observer]);

  return isIntersecting;
}
