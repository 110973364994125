import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LineItem } from "../../graphql/LineItem";
import { Product } from "../../graphql/Products";
import { Cask } from "../../graphql/Casks";
import client from "../configure-client";
import { store } from "../store";
import { CheckoutInput, CHECKOUT_UPDATE } from "../../graphql/Checkout";
import ActivateAccountPage from "../../pages/ActivateAccountPage";
import { iteratorSymbol } from "immer/dist/internal";
import { StringLiteralLike } from "typescript";
import { batch } from "react-redux";
import { title } from "process";
interface CheckoutReducer {
  id: number;
  email: String;
  redeemedFunds: Partial<{
    amount: number;
    deposited: number;
    interest: number;
  }>;
  amountTotal: number;
  amountTax: number;
  amountUndiscounted: number;
  amountUntaxed: number;
  amountShipping: number;
  amountPayable: number;
  status: String;
  giftCardLine: Partial<{
    id: number;
    priceTax: number;
    priceTotal: number;
    quantity: number;
    title: String;
  }>;
  lineItems: LineItem[];
  shippingAddress: Partial<{
    id: string;
    address1: string;
    address2: string;
    city: string;
    company: string;
    countryCode: string;
    firstName: string;
    lastName: string;
    email: String;
    phone: string;
    stateCode: string;
    postalCode: string;
    deliveryInstruction: string;
  }>;
  billingAddress: Partial<{
    id: string;
    address1: string;
    address2: string;
    city: string;
    company: string;
    countryCode: string;
    firstName: string;
    lastName: string;
    email: String;
    phone: string;
    stateCode: string;
    postalCode: string;
  }>;
  shippingLine: Partial<{
    id: string;
    title: string;
    priceTotal: number;
    priceTax: number;
  }>;
  stockIssues: Partial<
    [
      {
        id: string;
        remaining: number;
      },
    ]
  >;
  validationCounter: number;
  // promoCode: string | null;
  promoCode: Partial<{
    code: string;
    description: string;
    provider: string;
    valid: boolean;
  }>;
}

const initialState: CheckoutReducer = {
  id: 0,
  email: "",
  amountTotal: -1,
  amountTax: -1,
  amountUndiscounted: -1,
  amountUntaxed: -1,
  amountShipping: -1,
  amountPayable: -1,
  status: "",
  shippingAddress: {},
  billingAddress: {},
  giftCardLine: {},
  lineItems: [],
  shippingLine: {},
  redeemedFunds: {},
  stockIssues: [],
  validationCounter: 0,
  promoCode: {},
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckoutId(state, action: PayloadAction<any>) {
      state.id = action.payload;
    },
    setShippingAddress(state, action: PayloadAction<any>) {
      state.shippingAddress = action.payload;
    },
    setBillingAddress(state, action: PayloadAction<any>) {
      state.billingAddress = action.payload;
    },
    setEmail(state, action: PayloadAction<any>) {
      state.email = action.payload;
    },
    setLineItems(state, action: PayloadAction<[LineItem]>) {
      state.lineItems = action.payload;
    },
    setShippingLine(state, action: PayloadAction<any>) {
      const {
        payload: { priceTotal, id, title },
      } = action;
      state.shippingLine.priceTotal = priceTotal;
      state.shippingLine.id = id;
      state.shippingLine.title = title;
    },
    setRedeemedFunds(state, action: PayloadAction<any>) {
      const {
        payload: { amount, deposited, interest },
      } = action;
      if (amount) {
        state.redeemedFunds.amount = amount ?? 0;
        state.redeemedFunds.deposited = deposited ?? "";
        state.redeemedFunds.interest = interest ?? "";
      } else {
        state.redeemedFunds = {};
      }
    },
    setGiftCardLine(state, action: PayloadAction<any>) {
      const {
        payload: { id, priceTax, priceTotal, quantity, title },
      } = action;
      if (id) {
        state.giftCardLine.id = id;
        state.giftCardLine.priceTax = priceTax;
        state.giftCardLine.priceTotal = priceTotal;
        state.giftCardLine.quantity = quantity;
        state.giftCardLine.title = title;
      } else {
        state.giftCardLine = {};
      }
    },
    setTotals(state, action: PayloadAction<any>) {
      const {
        payload: {
          amountTotal,
          amountTax,
          amountUndiscounted,
          amountUntaxed,
          amountShipping,
          amountPayable,
        },
      } = action;
      state.amountTotal = amountTotal;
      state.amountTax = amountTax;
      state.amountUndiscounted = amountUndiscounted;
      state.amountUntaxed = amountUntaxed;
      state.amountShipping = amountShipping;
      state.amountPayable = amountPayable;
    },
    resetCheckout(state) {
      state.id = 0;
      state.shippingAddress = {};
      state.billingAddress = {};
      state.shippingLine = {};
      state.lineItems = [];
      state.status = "";
      state.giftCardLine = {};
      state.redeemedFunds = {};
      state.validationCounter = 0;
      state.promoCode = {};
    },
    setCheckoutStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    setStockIssues(state, action: PayloadAction<[any]>) {
      state.stockIssues = action.payload;
    },
    incrementValidationCounter(state) {
      state.validationCounter += 1;
    },
    setPromoCode(state, action: PayloadAction<any>) {
      const {
        payload: { code, description, provider, valid },
      } = action;
      if (code) {
        state.promoCode.code = code;
        state.promoCode.description = description;
        state.promoCode.provider = provider;
        state.promoCode.valid = valid;
      } else {
        state.promoCode = {};
      }
    },
  },
});

export const {
  resetCheckout,
  setCheckoutId,
  setCheckoutStatus,
  setStockIssues,
} = checkoutSlice.actions;

const {
  setShippingAddress,
  setBillingAddress,
  setLineItems,
  setRedeemedFunds,
  setGiftCardLine,
  setTotals,
  setShippingLine,
  setEmail,
  incrementValidationCounter,
  setPromoCode,
} = checkoutSlice.actions;

const sanitizeLineItems = (inLineItems: LineItem[]) => {
  let outLineItems = inLineItems.map((item: LineItem) => ({
    id: item.id,
    inventoryId: item.inventoryId,
    quantity: item.quantity,
    type: item.type,
    gift: item.gift,
    giftData: item.giftData,
    reservationId: item.reservationId,
  }));
  return outLineItems;
};
export { sanitizeLineItems };

const sanitizeAddress = (inAddress: any) => {
  let outAddress = {
    id: inAddress.id,
    firstName: inAddress.firstName,
    lastName: inAddress.lastName,
    email: inAddress.email,
    address1: inAddress.address1,
    address2: inAddress.address2,
    phone: inAddress.phone,
    city: inAddress.city,
    postalCode: inAddress.postalCode,
    stateCode: inAddress.stateCode,
    countryCode: inAddress.countryCode,
    deliveryInstruction: inAddress.deliveryInstruction,
  };
  return outAddress;
};
export { sanitizeAddress };

// const sanitizePromoCode = (promo: any) => {
//   let inPromo = {
//     code: promo.code,
//     description: promo.description,
//     provider: promo.provider,
//     valid: promo.valid,
//   };
//   return inPromo;
// };
// export { sanitizePromoCode };

const writeToCheckout = async (lineItems: LineItem[], dispatch: any) => {
  const shopId = store.getState().shop.id;
  const variables = {
    shop: shopId,
    input: {
      lineItems: sanitizeLineItems(lineItems),
      currency: store.getState().shop.currency,
    },
  };

  // Use existing checkout if it exists
  const checkoutId = store.getState().checkout.id;
  if (checkoutId) {
    // @ts-ignore
    variables.input.id = checkoutId;
  }

  const shippingAddress = sanitizeAddress(
    store.getState().checkout.shippingAddress,
  );

  const billingAddress = store.getState().checkout.billingAddress
    ? sanitizeAddress(store.getState().checkout.billingAddress)
    : null;

  const userEmail = store.getState().user.email;
  if (userEmail) {
    // @ts-ignore
    variables.input.email = userEmail;
  }

  const guestUserEmail = store.getState().checkout.shippingAddress.email;
  if (guestUserEmail) {
    // @ts-ignore
    variables.input.email = guestUserEmail;
  }

  // const shippingAddress = store.getState().checkout.shippingAddress;

  if (shippingAddress.countryCode) {
    // @ts-ignore
    variables.input.shippingAddress = shippingAddress;
  }

  if (billingAddress && billingAddress.countryCode) {
    // @ts-ignore
    variables.input.billingAddress = billingAddress;
  }

  // const promoCode = sanitizePromoCode(store.getState().checkout.promoCode);
  const promoCode = store.getState().checkout.promoCode;
  if (promoCode && promoCode.code) {
    // @ts-ignore
    variables.input.promoCode = promoCode.code;
  }

  const { data, errors } = await client.mutate<any, { input: CheckoutInput }>({
    mutation: CHECKOUT_UPDATE,
    // @ts-ignore
    variables,
  });

  if (data.checkoutUpdate.checkout) {
    batch(() => {
      dispatch(setCheckoutId(data.checkoutUpdate.checkout.id));
      dispatch(setEmail(userEmail));
      !userEmail && dispatch(setEmail(guestUserEmail));
      dispatch(setLineItems(data.checkoutUpdate.checkout.lineItems));
      dispatch(
        setRedeemedFunds(data.checkoutUpdate.checkout.redeemedFunds ?? {}),
      );
      dispatch(
        setGiftCardLine(data.checkoutUpdate.checkout.giftCardLine ?? {}),
      );
      dispatch(
        setShippingLine(data.checkoutUpdate.checkout.shippingLine ?? {}),
      );
      dispatch(setTotals(data.checkoutUpdate.checkout));
      dispatch(setStockIssues(data.checkoutUpdate.stockIssues));
      dispatch(incrementValidationCounter());
    });
  } else {
    dispatch(resetCheckout());
  }
};

export const addValidationCounter = () => async (dispatch: any) => {
  dispatch(incrementValidationCounter());
  await writeToCheckout(store.getState().checkout.lineItems, dispatch);
};

export const addAddressesToCheckout =
  (shippingAddress: any, billingAddress: any | null) =>
  async (dispatch: any) => {
    if (store.getState().checkout.status === "completed") {
      dispatch(resetCheckout());
    }
    dispatch(setShippingAddress(shippingAddress));
    dispatch(setBillingAddress(billingAddress));
    dispatch(incrementValidationCounter());
    await writeToCheckout(store.getState().checkout.lineItems, dispatch);
  };

export const addAddressToCheckout = (address: any) => async (dispatch: any) => {
  if (store.getState().checkout.status === "completed") {
    dispatch(resetCheckout());
  }

  dispatch(setShippingAddress(address));
  dispatch(incrementValidationCounter());
  await writeToCheckout(store.getState().checkout.lineItems, dispatch);
};

export const deleteFromCheckout =
  (lineItem: LineItem) => async (dispatch: any) => {
    if (store.getState().checkout.status === "completed") {
      dispatch(resetCheckout());
    }

    const checkout = store.getState().checkout;
    const { lineItems } = checkout;

    const newLineItems = lineItems.filter((i: any) => i.id !== lineItem.id);
    await writeToCheckout(newLineItems, dispatch);
  };

export const addPromoCodeToCheckout =
  (promoCode: any) => async (dispatch: any) => {
    dispatch(setPromoCode(promoCode));
    await writeToCheckout(store.getState().checkout.lineItems, dispatch);
  };

export const removePromoCodeFromCheckout = () => async (dispatch: any) => {
  dispatch(setPromoCode({}));
  await writeToCheckout(store.getState().checkout.lineItems, dispatch);
};

export const increaseCheckoutQuantity =
  ({
    product,
    giftData,
    giftQuantity,
    reservationId,
  }: {
    product: Product | Cask | any;
    giftData?: any;
    giftQuantity?: number;
    reservationId?: string;
  }) =>
  async (dispatch: any) => {
    if (store.getState().checkout.status === "completed") {
      dispatch(resetCheckout());
    }
    const productId = product.defaultVariant.id;
    const giftingData = giftData;
    const quantity = giftQuantity;

    let { lineItems } = store.getState().checkout;

    let newLineItems;
    // Already in checkout, update quantity
    let existingProductInCheckout = lineItems.find(
      (i: any) => i.variant.id === productId && !i.bundleChild,
    );
    let existingBundlesInCheckout = lineItems.find(
      (i: LineItem) => i.isABundle && product?.isABundle,
    );
    if (
      existingProductInCheckout &&
      !giftData?.recipientEmail &&
      !reservationId
    ) {
      newLineItems = lineItems.map((i: any) => {
        if (
          existingProductInCheckout &&
          existingProductInCheckout.type === "product" &&
          existingProductInCheckout.id === i.id &&
          existingProductInCheckout.product.onePerCustomer === true
        ) {
          return i;
        } else if (
          existingProductInCheckout &&
          existingProductInCheckout.id === i.id
        ) {
          return {
            id: existingProductInCheckout.id,
            inventoryId: existingProductInCheckout.inventoryId,
            quantity: existingProductInCheckout.quantity + 1,
            type: existingProductInCheckout.type,
            giftData: existingProductInCheckout.giftData,
            gift: existingProductInCheckout.gift,
            reservationId: existingProductInCheckout.reservationId,
          };
        }
        return i;
      });
    } else if (
      existingBundlesInCheckout ||
      (existingProductInCheckout && giftData?.recipientEmail)
    ) {
      newLineItems = lineItems.map((i: LineItem) => {
        if (
          existingProductInCheckout &&
          existingProductInCheckout.id === i.id
          // && (existingBundlesInCheckout || !existingBundlesInCheckout)
        ) {
          return {
            id: existingProductInCheckout.id,
            inventoryId: existingProductInCheckout.inventoryId,
            quantity: existingProductInCheckout.quantity,
            type: existingProductInCheckout.type,
            giftData: !existingProductInCheckout.giftData
              ? giftingData
              : existingProductInCheckout.giftData,
            gift: !existingProductInCheckout.gift
              ? giftingData?.recipientEmail
                ? true
                : false
              : existingProductInCheckout.gift,
            reservationId: existingProductInCheckout.reservationId,
          };
        }
        return i;
      });
    } else if (existingProductInCheckout && reservationId) {
      newLineItems = lineItems.map((i: any) => {
        if (
          existingProductInCheckout &&
          existingProductInCheckout.id === i.id
        ) {
          return {
            id: existingProductInCheckout.id,
            inventoryId: existingProductInCheckout.inventoryId,
            quantity: existingProductInCheckout.quantity,
            type: existingProductInCheckout.type,
            giftData: !existingProductInCheckout.giftData
              ? giftingData
              : existingProductInCheckout.giftData,
            gift: !existingProductInCheckout.gift
              ? giftingData?.recipientEmail
                ? true
                : false
              : existingProductInCheckout.gift,
            reservationId: reservationId,
          };
        }
        return i;
      });
    } else {
      newLineItems = [
        ...lineItems,
        {
          type: product.type,
          quantity: !quantity ? 1 : quantity,
          inventoryId: product.defaultVariant.id,
          giftData: giftingData,
          gift: giftingData?.recipientEmail ? true : false,
          reservationId: reservationId,
        },
      ];
    }
    await writeToCheckout(newLineItems, dispatch);
  };

export const reduceCheckoutQuantity =
  (product: Product | Cask, giftData?: any) => async (dispatch: any) => {
    if (store.getState().checkout.status === "completed") {
      dispatch(resetCheckout());
    }

    const productId = product.defaultVariant.id;
    const giftingData = giftData;

    let { lineItems } = store.getState().checkout;

    let newLineItems;
    // Already in checkout, update quantity
    let existingProductInCheckout = lineItems.find(
      (i: any) => i.variant.id === productId && !i.bundleChild,
    );
    if (existingProductInCheckout && !giftData) {
      newLineItems = lineItems.map((i: any) => {
        if (
          existingProductInCheckout &&
          existingProductInCheckout.id === i.id
        ) {
          return {
            id: existingProductInCheckout.id,
            inventoryId: existingProductInCheckout.inventoryId,
            quantity: existingProductInCheckout.quantity - 1,
            type: existingProductInCheckout.type,
            giftData: existingProductInCheckout.giftData,
            gift: existingProductInCheckout.gift,
          };
        }
        return i;
      });
    } else if (existingProductInCheckout && giftData) {
      newLineItems = lineItems.map((i: any) => {
        if (
          existingProductInCheckout &&
          existingProductInCheckout.id === i.id
        ) {
          return {
            id: existingProductInCheckout.id,
            inventoryId: existingProductInCheckout.inventoryId,
            quantity: existingProductInCheckout.quantity,
            type: existingProductInCheckout.type,
            giftData: giftingData,
            gift: giftingData?.recipientEmail ? true : false,
          };
        }
        return i;
      });
    } else {
      newLineItems = [
        ...lineItems,
        {
          type: product.type,
          quantity: 1,
          inventoryId: product.defaultVariant.id,
          gift: false,
        },
      ];
      addValidationCounter();
    }
    await writeToCheckout(newLineItems, dispatch);
  };

export default checkoutSlice.reducer;
