import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useMutation } from "react-apollo";
import { FiArrowRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { gsap } from "gsap";

import { SEND_REFERRAL } from "../../graphql/User";
import { RootState } from "../../state/rootReducer";
import PaymentFailureModal from "../../components/checkout/PaymentFailureModal";

import styles from "./Account.module.scss";

const AccountReferFriend = ({
  setModalOpen,
  modalOpen,
}: {
  setModalOpen?: any;
  modalOpen: boolean;
}) => {
  const shopId = useSelector((state: RootState) => state.shop.id);
  const loggedInUser = useSelector((state: RootState) => state.user);
  const history = useHistory();
  // @ts-ignore

  const referModal = useRef(null);
  const [validated, setValidated] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [referralData, setReferralData] = useState({
    recipientEmail: "",
    referralNote: "Hey, check this out!",
  });

  const [sendReferral, { data: sendReferralData }] = useMutation(SEND_REFERRAL);

  useEffect(() => {
    if (loggedInUser.collectiveActive === true) {
      return;
    } else {
      // history.push("/the-collective");
    }
  }, [loggedInUser.collectiveActive]);

  useEffect(() => {
    gsap.to(referModal.current, { visibility: "visible", opacity: "1" });
  });

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "initial";
    };
  }, [modalOpen]);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleSubmit = async (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (form.checkValidity() === true) {
      e.preventDefault();
      e.stopPropagation();

      const { data } = await sendReferral({
        variables: {
          referralData: referralData,
        },
      });
      if (data?.sendReferral.success === false) {
        console.warn("transferStart() failed");
        setPaymentError(
          "There was a problem referring your friend. Please, try again later.",
        );
      } else {
        setConfirmationModalOpen(true);
      }
    }

    setValidated(true);
  };

  const closePaymentFailureModal = () => {
    setPaymentError("");
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  return (
    <>
      <div ref={referModal} className={styles.referModal}>
        <div className={styles.inner}>
          <Row className={styles.referFriendWrap}>
            <Col className={styles.inner} xs={12}>
              <div className={styles.benefits}>
                <h5>
                  Refer a friend to <span>The Collective</span>
                </h5>
                <p>
                  Receive £10 credit when you refer a friend to the best whisky
                  club in town and what’s more, they’ll receive a welcome bottle
                  of whisky from our vault.
                </p>
                <p>
                  You can share your whisky and your membership over and over
                  again, there’s no cap on how many people you can refer.
                </p>
              </div>

              <div className={styles.form}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className={styles.formRow}>
                    <Form.Group className={styles.formInput}>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Enter email address"
                        onChange={(e) =>
                          setReferralData({
                            ...referralData,
                            recipientEmail: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className={styles.formRow}>
                    <Form.Group className={styles.formInput}>
                      <Form.Control
                        type="text"
                        placeholder="Add a message (optional)"
                        defaultValue={referralData.referralNote}
                        as="textarea"
                        rows={3}
                        onChange={(e) =>
                          setReferralData({
                            ...referralData,
                            referralNote: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className={`${styles.formRow} ${styles.buttonWrap}`}>
                    <Button className={styles.btnCoralOutline} type="submit">
                      Invite <FiArrowRight />
                    </Button>
                    <Button
                      onClick={(e: any) => {
                        e.stopPropagation();
                        console.log("clicked");
                        setModalOpen && setModalOpen(false);
                      }}
                      className={styles.btnBlackOutline}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <PaymentFailureModal
        message={paymentError}
        doClose={closePaymentFailureModal}
        show={Boolean(paymentError)}
      />
      {confirmationModalOpen && (
        <div className={styles.confirmationModalWrap}>
          <div className={styles.inner}>
            <h4>You're all set!</h4>
            <p>
              We will email <span>{referralData?.recipientEmail}</span> with
              their unique referral code.
            </p>
            <p>Thanks for using Caskshare.</p>
            <p>The Caskshare Team.</p>
            <Button
              onClick={() => {
                setConfirmationModalOpen(false);
                setModalOpen(false);
                setReferralData({ ...referralData, recipientEmail: "" });
              }}
              className={styles.btnPrimary}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountReferFriend;
