import React from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router";
import { SelectWrapper } from "./SelectWrapper";
import styles from "./SelectWrapper.module.scss";
import { IMAGE_FALLBACKS } from "../../../../config";
import ProgressiveImg from "../../../.v2/general/ProgressiveImg";
import { Events } from "../../../../graphql/User";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";

type StateData = {
  image: string;
  tastingPacks: number;
  seats: number;
  event?: Events;
  showConfirmation: boolean;
  addExtraPack?: boolean;
  setAddExtraPack?: any;
};

type SelectProps = StateData & {
  updateState: (state: Partial<StateData>) => void;
};

export function SelectExtraPack({
  tastingPacks,
  seats,
  event,
  image,
  showConfirmation,
  addExtraPack,
  setAddExtraPack,
}: SelectProps) {
  return (
    <SelectWrapper
      title={
        !showConfirmation
          ? `Add ${event?.upsellProduct?.title}`
          : showConfirmation && seats === 0
          ? "You are all set!"
          : "Your seat has been booked!"
      }
      imageComp={
        !showConfirmation && (
          <ProgressiveImg
            src={image}
            placeholderSrc={IMAGE_FALLBACKS.glenrothesPlaceholder}
            props={{ alt: "upsellProduct" }}
          />
        )
      }
      show={!showConfirmation && true}
    >
      {!showConfirmation ? (
        <>
          {event?.upsellProduct?.description && (
            <p>{event?.upsellProduct?.description}</p>
          )}
          <div className={styles.qtyPicker}>
            <button
              type="button"
              className={styles.btnPrimary}
              onClick={() => setAddExtraPack((prev: boolean) => !prev)}
            >
              {!addExtraPack ? (
                <>
                  <FiPlusCircle /> Add sample
                </>
              ) : (
                <>
                  <FiMinusCircle /> Remove sample
                </>
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          {(tastingPacks > 0 && addExtraPack) ||
          tastingPacks > 0 ||
          addExtraPack ? (
            <p style={{ border: "none" }}>
              Go to your basket to complete this purchase.
            </p>
          ) : null}
        </>
      )}
    </SelectWrapper>
  );
}
