import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styles from "./StartCollection.module.scss";

const StartCollection = () => {
  return (
    <Row className={styles.startCollection}>
      <Col xs={12} className={styles.inner}>
        <span />
        <h3>unique, timeless, accessible.</h3>
        <p>
          The uncompromised experience of your own maturing cask whisky, by the
          bottle - and world's finest distilleries at your fingertips.
        </p>
        <Button as={Link} to="/caskshares" className={styles.btnDark}>
          Start your collection
        </Button>
      </Col>
    </Row>
  );
};

export default StartCollection;
