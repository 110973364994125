import React, { useState, useEffect } from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useQuery, useMutation } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { matchPath } from "react-router";
import { useApolloClient } from "@apollo/react-hooks";
import { persistStore } from "redux-persist";
import { FaPhoneAlt } from "react-icons/fa";
import { batch } from "react-redux";

import { SHOPS, Shop } from "../../../graphql/Shops";
import { store } from "../../../state/store";
import { setShop } from "../../../state/slices/shopSlice";
import { RootState } from "../../../state/rootReducer";
import { resetUser } from "../../../state/slices/userSlice";
import { resetCheckout } from "../../../state/slices/checkoutSlice";
import { LOGOUT } from "../../../graphql/Auth";
import CurrencyDropdown from "../../../components/app/CurrencyDropdown";
import Socials from "./Socials";
import styles from "./TopNav.module.scss";

const StoreOptions = ({
  setMobileMenu,
  lightOrDark = "dark",
  showStoreOptions,
  mobileMenu,
}: {
  setMobileMenu?: (show: boolean) => void;
  lightOrDark?: "light" | "dark";
  showStoreOptions?: boolean;
  mobileMenu?: boolean;
}) => {
  const client = useApolloClient();
  const { data } = useQuery(SHOPS);
  const [userStore, setUserStore] = useState(null);
  const [isShopSelectionOpen, setIsShopSelectionOpen] = useState(false);
  const [urlLocale, setUrlLocale] = useState('');
  const loggedInUser = useSelector((state: RootState) => state.user);
  const currentStore = useSelector((state: RootState) => state.shop.currency);
  const currentLocation = useSelector(
    (state: RootState) => state.shop.isCurrentUserLocation,
  );
  const featuresInState = useSelector(
    (state: RootState) => state.shop.features,
  );
  const shippingMonthIncrementInState = useSelector(
    (state: RootState) => state.shop.shippingMonthIncrement,
  );

  const shopId = useSelector((state: RootState) => state.shop.id);

  const [customerLogout] = useMutation(LOGOUT);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const match = matchPath(
    location.pathname, {
      path: "/:locale(gb|us|eu)(\/|\z)?",
      strict: true,
    }
  );

  const ballotMatch = matchPath(
    location.pathname, {
      path: "/:ballot(ballot)(\/|\z)?",
      strict: true,
  }
  );

  const onLogout = async () => {
    client.clearStore(); // added to clear user data after logging out
    persistStore(store).purge();

    await customerLogout({
      variables: {
        shop: shopId,
      },
    });

    batch(() => {
      dispatch(resetUser());
      dispatch(resetCheckout());
    });

    if (setMobileMenu) {
      setMobileMenu(false);
    }

    if (!urlLocale) {
      history.push("/");
    }
  };

  const onLogin = () => {
    if (setMobileMenu) {
      setMobileMenu(false);
    }
    history.push({
      pathname: "/login",
      state: {
        from: {
          pathname: location.pathname,
        },
      },
    });
  };

  useEffect(() => {
    // @ts-ignore
    setUrlLocale(match?.params?.locale || '');
  }, [match]);

  useEffect(() => {
    // @ts-ignore
    if (ballotMatch && currentStore !== 'gbp') {
      setUrlLocale('gb');
    }
  }, [ballotMatch]);

  // Pre-select user store
  useEffect(() => {
    if (data && data?.shops) {
      const matchedStore = data.shops.find(
        (s: Shop) => s.isCurrentUserLocation === true,
      );

      let localeStore = null;
      if (urlLocale) {
        localeStore = data.shops.find(
          (s: Shop) => s.market.localeCompare(urlLocale, undefined, {sensitivity: 'accent'}) === 0,
        );
      }

      if (localeStore) {
        setUserStore(localeStore.currency);

        // remove the locale prefix now that we have dealt with it
        history.replace(
          location.pathname.replace(/\/gb|\/us|\/eu/, '')
        );

      } else if (!currentStore && matchedStore) {
        dispatch(setShop(matchedStore));
      } else if (!currentStore) {
        dispatch(setShop(data.shops[0]));
      }
      // if (!currentStore && data?.shops) {
      //   dispatch(setShop(data.shops[0]));
      // }
    }
  }, [data, currentStore, urlLocale]);

  // Open Store selection if store not matched
  useEffect(() => {
    if (!currentStore || !currentLocation) {
      setIsShopSelectionOpen(true);
    }
    return () => {
      setIsShopSelectionOpen(false);
    };
  }, [currentStore]);

  useEffect(() => {
    if (currentStore && data?.shops) {
      const dataStore = data?.shops.filter(
        (a: Shop) => a.currency === currentStore,
      );
      // console.log(dataStore[0].features);
      // console.log(featuresInState);
      if (
        dataStore[0]?.features &&
        JSON.stringify(featuresInState) !==
          JSON.stringify(dataStore[0]?.features)
      ) {
        dispatch(setShop(dataStore[0]));
      }
      if (
        (dataStore[0]?.shippingMonthIncrement &&
          shippingMonthIncrementInState === undefined) ||
        dataStore[0]?.shippingMonthIncrement !== shippingMonthIncrementInState
      ) {
        dispatch(setShop(dataStore[0]));
      } else {
        return;
      }
    }
    return () => {};
  }, [currentStore, data?.shops]);

  // Set store selected by user
  useEffect(() => {
    if (data) {
      const shop = data.shops.find((s: Shop) => s.currency === userStore);
      if (!shop) {
        return;
      }
      setIsShopSelectionOpen(false);
      dispatch(setShop(shop));
      setUserStore(null);
      onLogout();
    } else {
      return;
    }
  }, [userStore]);

  return (
    <>
      <Container
        id="storeOptions"
        className={`${showStoreOptions ? styles.showStoreOptions : null}  ${
          styles.storeOptionsComponent
        }`}
      >
        <Row className={styles.storeOptionsWrap}>
          {mobileMenu ? null : (
            <div className={styles.phoneNumber}>
              <FaPhoneAlt /> <a href="tel:00441313812275">+44-131-381-2275</a>
            </div>
          )}

          <CurrencyDropdown setUserStore={setUserStore} />

          {loggedInUser.email ? (
            <Button variant="secondary" onClick={onLogout}>
              Logout
            </Button>
          ) : (
            <Button variant="secondary" onClick={onLogin}>
              Login
            </Button>
          )}
          {!loggedInUser.collectiveActive ? (
            <Button
              as={Link}
              to="/my-account"
              variant="secondary"
              onClick={() => setMobileMenu && setMobileMenu(false)}
              className={styles.memberAccount}
            >
              My Account
            </Button>
          ) : (
            <Button
              as={Link}
              to="/my-account/collective"
              variant="secondary"
              onClick={() => setMobileMenu && setMobileMenu(false)}
              className={`${styles.memberAccount} ${styles.collectiveActive}`}
            >
              My <span>Collective</span>
            </Button>
          )}

          {/* {lightOrDark === "light" || mobileMenu ? null : (
            <div className="socialWrap">
              <Socials variant="dark" />
            </div>
          )} */}
        </Row>
      </Container>

      <div
        className={`
          ${
            isShopSelectionOpen && !mobileMenu
              ? styles.shopSelectionOverlay
              : styles.default
          } ${showStoreOptions ? null : styles.addSpace}
        `}
      >
        <div className={styles.inner}>
          <div className={styles.text}>
            <p>
              Choose another country or region to see content specific to your
              location and shop online.
            </p>
          </div>
          <div className={styles.dropdown}>
            <CurrencyDropdown setUserStore={setUserStore} />
            <Button
              onClick={() => setIsShopSelectionOpen(false)}
              className={styles.currencyButton}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreOptions;
