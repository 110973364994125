import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Cask } from "../../../graphql/Casks";
import CaskCard from "../casks/caskCard/CaskCard";

import styles from "./FeaturedBottlesSlider.module.scss";

const FeaturedBottlesSlider = ({
  casks,
  customer,
}: {
  casks?: any;
  customer?: any;
}) => {
  const history = useHistory();
  const caskRef = useRef(null);

  useEffect(() => {
    if (caskRef.current && casks) {
      // @ts-ignore
      caskRef.current?.slickGoTo(0);
    }
  }, [caskRef, casks]);

  const onReserveChoiceClick = (c: Cask) => {
    const newCaskId = c.defaultVariant.slug
      ? c.defaultVariant.slug
      : c.defaultVariant.id.split("/")[4];
    history.push(`/caskshares/${newCaskId}`);
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: casks && casks?.length >= 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: casks && casks?.length >= 3 ? true : false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: casks && casks?.length >= 2 ? true : false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: casks && casks?.length >= 2 ? true : false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 688,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          infinite: false,
          autoplay: false,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          infinite: false,
          autoplay: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Col xs={12} className={styles.featuredBottlesSliderWrap}>
      {casks ? (
        <Slider ref={caskRef} {...settings}>
          {casks.map((cask: Cask) => (
            <CaskCard
              key={cask.variants[0].id}
              cask={cask}
              customer={customer}
              disableOwnedCask={true}
              onClick={onReserveChoiceClick}
            />
          ))}
        </Slider>
      ) : null}
    </Col>
  );
};
export default FeaturedBottlesSlider;
