import React from "react";
import { Col, InputGroup, FormControl } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const CasksharesSearch = ({
  searchTerm,
  setSearchTerm,
  onRequestSearch,
}: {
  searchTerm: string;
  setSearchTerm: (arg0: any) => void;
  onRequestSearch?: (arg0: any) => void;
}) => {
  // const { t } = useTranslation();

  return (
    <Col xs={12} style={{ padding: "0" }}>
      <InputGroup className="mb-3">
        <FormControl
          onChange={(e: any) => setSearchTerm(e.target.value)}
          aria-label="Search Caskshares"
          placeholder="Search caskshares"
          value={searchTerm}
        />
        <InputGroup.Append>
          <InputGroup.Text onClick={onRequestSearch}>
            <i
              style={{ width: "30px" }}
              className="fa fa-search fa-fw"
              aria-hidden="true"
            />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Col>
  );
};

export default CasksharesSearch;
