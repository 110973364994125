import React from "react";

const caskshareStatus = ({
  status,
  showNumber = false,
}: {
  status: string;
  showNumber: boolean;
}) => {
  switch (status) {
    case "shipping_now":
      return showNumber ? "3. bottled" : "bottled";
      break;
    case "shipping_three_months":
      return showNumber ? "2. bottling soon" : "bottling soon";
      break;
    case "shipping_one_year":
      return showNumber ? "2. maturing" : "maturing";
      break;
    case "maturing":
      return showNumber ? "1. maturing" : "maturing";
      break;
    default:
      return "";
  }
  return;
};

export default caskshareStatus;
