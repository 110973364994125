import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Button, Form, InputGroup, Toast } from "react-bootstrap";

const SignUpForNewForm = ({
  lightOrDark = "dark",
}: {
  lightOrDark?: string;
}) => {
  const [email, setEmail] = useState("");
  const [showToast, setShowToast] = useState(false);

  // const submitSignUpForm = async (e: any) => {
  //   e.preventDefault();

  //   // @ts-ignore
  //   window._klOnsite = window._klOnsite || [];
  //   // @ts-ignore
  //   window._klOnsite.push(["openForm", "UAfE6f"]);
  //   setShowToast(true);
  // };

  const handleOpenNewsLetter = () => {
    // @ts-ignore
    window._klOnsite = window._klOnsite || [];
    // @ts-ignore
    window._klOnsite.push(["openForm", "VTZnPC"]);
  };

  return (
    <Button variant="light" onClick={() => handleOpenNewsLetter()}>
      Sign me up
    </Button>
    // <Form
    //   onSubmit={submitSignUpForm}
    //   className="sign-up-for-new-form-component"
    // >
    //   <InputGroup>
    //     <Form.Control
    //       size="lg"
    //       type="text"
    //       placeholder="Email address"
    //       required
    //       value={email}
    //       onChange={(e) => setEmail(e.target.value)}
    //     />
    //     <InputGroup.Append>
    //       <Button
    //         type="submit"
    //         variant={lightOrDark === "dark" ? "outline-dark" : "outline-light"}
    //         style={{ zIndex: "unset" }}
    //       >
    //         Sign me up
    //       </Button>
    //     </InputGroup.Append>
    //   </InputGroup>

    //   <Toast
    //     delay={3000}
    //     autohide
    //     animation
    //     onClose={() => setShowToast(false)}
    //     style={{ marginTop: "30px" }}
    //     show={showToast}
    //   >
    //     <Toast.Header closeButton={false}>
    //       Your have been added to our mailing list!
    //     </Toast.Header>
    //   </Toast>
    // </Form>
  );
};

export default SignUpForNewForm;
