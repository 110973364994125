import React, {
  useState,
  useEffect,
  useRef,
  lazy,
  Suspense,
  ReactElement,
} from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { useSSR, useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import moment from "moment";

import styles from "./InsideCollective.module.scss";
import { GET_CUSTOMER, EVENTS, Events } from "../../../graphql/User";
import { Cask } from "../../../graphql/Casks";
import { RootState } from "../../../state/rootReducer";
import { getMetaText, getCopyText } from "../../../AppText";
import LoadingView from "../../../components/app/LoadingView";
import ScrollToSection from "../../../components/app/ScrollToSection";
import CollectiveItemsSlider from "./components/CollectiveItemsSlider";
import MyCollectionSlider from "./components/MyCollectionSlider";
import { formatCurrency } from "../../../utils/currency";
import LoadingLogo from "../../app/LoadingLogo";

import Joyride, { ACTIONS, EVENTS as JoyEvents, STATUS } from "react-joyride";
import TheCollective from "../TheCollective";
import AccountReferFriend from "../AccountReferFriend";
import { renderReferFriend } from "../renderReferFriend";

export type CustomerEvents = {
  slug: string;
  seats: number;
};
// collectiveDashboardPageWrap
const InsideCollective = () => {
  const CUSTOMER_EVENT_INIT: CustomerEvents = {
    slug: "",
    seats: 0,
  };

  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const user = useSelector((state: RootState) => state.user);
  const [activeEvent, setActiveEvent] = useState<any>({});
  const [upcomingEvents, setUpcomingEvents] = useState<any>([]);
  const [customerEvents, setCustomerEvents] = useState([CUSTOMER_EVENT_INIT]);
  const [customerCollection, setCustomerCollection] = useState<Cask[]>([]);
  const [hideCover, setHideCover] = useState(false);
  const [sortVid, setSortVid] = useState("all-events");
  const [scroll, setScroll] = useState("");
  const [referModalOpen, setReferModalOpen] = useState(false);
  const collectiveDashboard = useRef(null);

  const [{ run, steps }, setJoyride] = useState({
    run: true,
    steps: [
      {
        content: <h4>Welcome to your Collective Page.</h4>,
        locale: { skip: <strong>SKIP</strong> },
        target: "#my-collective",
        // target: document.body,
        // disableBeacon: true,
      },
      {
        title: "My Account",
        content: <p>Manage your personal details, payments and more.</p>,
        target: "#first",
      },
      {
        title: "Refer a Friend",
        content: <p>Refer a friend get £10 and give £10.</p>,
        target: "#second",
      },
      {
        title: "BarrelBank",
        content: (
          <p>
            Add money every month and we’ll add 10% interest to your balance.
          </p>
        ),
        target: "#third",
      },
      {
        title: "Events",
        content: (
          <p>
            See what events we’re hosting, book a place or watch our content.
          </p>
        ),
        target: "#fourth",
      },
      {
        title: "My Collection",
        content: (
          <p>
            {" "}
            See all of your purchases here and follow the journey of your
            whisky.
          </p>
        ),
        target: "#fifth",
      },
      {
        title: "Your benefits",
        content: (
          <p>
            Thank you for being with us! Here are some of the benefits of being
            the Collective member.
          </p>
        ),
        target: "#sixth",
      },
    ],
  });

  useEffect(() => {
    const findCookie = new Object(
      document.cookie
        .split(";")
        .map((c) => c.split("="))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(value),
          }),
          {},
        ),
    );

    // @ts-ignore
    if (findCookie?.collective_tour && findCookie?.collective_tour === "true") {
      setJoyride({ steps, run: false });
    } else {
      setJoyride({ steps, run: true });
    }
    return () => {
      setJoyride({ steps, run: true });
    };
  }, [document.cookie, run]);

  const handleJoyrideCallback = (data: any) => {
    const { status } = data;
    const topNav = document?.getElementById("topNavContainer");
    const storeOpt = document.getElementById("storeOptions");

    if (status === "running" && topNav && storeOpt) {
      topNav.style.marginBottom = "0";
      topNav.style.transform = "translateY(0px)";
      storeOpt.style.height = "auto";
      storeOpt.style.transform = "translateY(0px)";
    } else if (status !== "running" && topNav && storeOpt) {
      topNav.removeAttribute("style");
      storeOpt.removeAttribute("style");
    }

    // if (["finished", "skipped"].includes(status)) {
    if (["finished"].includes(status)) {
      console.log("tour status: ", status);
      // Need to set our running state to false, so we can restart if we click start again.
      let expDate = new Date();
      expDate.setFullYear(expDate.getFullYear() + 1);
      document.cookie = `collective_tour=true; expires=${expDate}`;
      setJoyride({ steps, run: false });
    }
  };

  // joyride

  const { data: eventData, loading: eventLoading } = useQuery(EVENTS, {
    variables: {
      shop: shop.id,
    },
  });

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER,
    {
      variables: {
        shop: shop.id,
      },
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (!user.collectiveActive) {
      history.push("/the-collective");
    }
  }, [user.collectiveActive]);

  useEffect(() => {
    if (customerData?.customer && customerData?.customer?.customerEvents)
      setCustomerEvents(customerData?.customer?.customerEvents);
    return () => {
      setCustomerEvents([CUSTOMER_EVENT_INIT]);
    };
  }, [customerData?.customer?.customerEvents]);

  useEffect(() => {
    if (customerData?.customer?.reservations) {
      setCustomerCollection(customerData?.customer?.reservations?.slice(0, 6));
    }
    return () => {
      setCustomerCollection([]);
    };
  }, [customerData?.customer?.reservations]);

  useEffect(() => {
    if (eventData && eventData?.events) {
      let activeEvent = eventData.events.filter(
        (a: Events) => a.featured === true,
      );
      setActiveEvent(activeEvent[0] || eventData.events[0]);
      setUpcomingEvents(eventData.events);
    }
    return () => {
      setActiveEvent({});
      setUpcomingEvents([]);
    };
  }, [eventData?.events]);

  if (eventLoading && customerLoading) {
    return <LoadingLogo />;
  }

  const formatDate = (d: Date) =>
    new Date(d).toLocaleString("default", {
      month: "long",
      year: "numeric",
    });

  const formatFullDate = (d: Date) =>
    new Date(d).toLocaleString("default", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  const mainVidMarkup = (url?: any) => {
    return {
      __html: `${
        url
          ? url
          : `<h3>
            Coming </br> <span>soon</span>
          </h3>`
      }`,
    };
  };

  const yourBenefits = [
    {
      id: 0,
      title: "Up to 20% discount",
      paragraph: "Enjoy discounts on maturing and ready to ship whiskies.",
      url: "/my-account",
      img: require("../../../assets/account-icons/discount.svg"),
    },
    // {
    //   id: 1,
    //   title: "Private sale access",
    //   paragraph: "Get access to exclusive whisky before everyone else.",
    //   url: "/my-account/barrel-bank",
    //   img: require("../../../assets/account-icons/vip.svg"),
    // },
    {
      id: 2,
      title: "BarrelBank",
      paragraph: `Get ${formatCurrency(shop?.currency, 0).format(
        10,
      )}  additional to spend for every ${formatCurrency(
        shop?.currency,
        0,
      ).format(100)} you deposit (or part thereof).`,
      url: "/my-account/my-payments",
      img: require("../../../assets/account-icons/BB.svg"),
    },
    {
      id: 3,
      title: "Priority access to Caskshare Ballots",
      paragraph:
        "Enjoy priority and early access to Caskshare Ballots for high-demand releases.",
      url: "/my-account/my-orders",
      img: require("../../../assets/account-icons/bottle.svg"),
    },
    {
      id: 4,
      title: `FREE ${shop.market} shipping`,
      paragraph: `On everything (${shop.market} only).`,
      url: "/my-account/my-gifts",
      img: require("../../../assets/account-icons/shipping.svg"),
    },
    {
      id: 5,
      title: "Access to exclusive events",
      paragraph: "Join meet the maker events, exclusive tastings and more.",
      url: "/my-account/details",
      img: require("../../../assets/account-icons/events.svg"),
    },
  ];

  return (
    <>
      <ScrollToSection scroll={scroll} setScroll={setScroll} />
      <Helmet>
        <title>{getMetaText("MY_COLLECTIVE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("MY_COLLECTIVE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("MY_COLLECTIVE", "description")}
        />
        <meta name="author" content={getMetaText("MY_COLLECTIVE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("MY_COLLECTIVE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("MY_COLLECTIVE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("MY_COLLECTIVE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("MY_COLLECTIVE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      {/* Joyride Start */}
      <Joyride
        steps={steps}
        callback={handleJoyrideCallback}
        disableOverlayClose
        continuous
        hideCloseButton
        scrollToFirstStep
        scrollOffset={150}
        showSkipButton
        run={run}
        showProgress
        styles={{
          options: {
            primaryColor: "#e76852",
          },
          buttonNext: {
            backgroundColor: "transparent",
            border: "#e76852 2px solid",
            outlineColor: "#e76852",
            color: "#e76852",
          },
          buttonBack: {
            backgroundColor: "transparent",
            border: "black 2px solid",
            outlineColor: "black",
            borderRadius: "4px",
            color: "black",
          },
          // options: {},
        }}
      />
      {/* Joyride End */}
      <Container
        fluid
        className={styles.collectiveDashboardPageWrap}
        ref={collectiveDashboard}
      >
        {/* Hero */}
        <Container className={styles.heroSection}>
          <Row className={styles.heroRow}>
            <Col xs={12} className={styles.contentCol}>
              <div className={styles.title}>
                {" "}
                <h3 id="my-collective">My Collective</h3>
              </div>
              <div className={styles.content}>
                <p>
                  Welcome to Your Collective page. Here you’ll have access to
                  all of the exclusive events, tasting packs and priority access
                  to newly released products.
                </p>
              </div>
              {shop.market === 'GB' ? (
                <div className={styles.barrelbankFeature}>
                  {customerData?.customer && (
                    <div id="#first" className={styles.barrelbankWrap}>
                      <img
                        src={require("../../../assets/account-icons/BB.svg")}
                        alt="BarrelBank icon"
                      />
                      <div className={styles.cta}>
                        <h4>
                          {formatCurrency(shop?.currency, 0).format(
                            customerData?.customer?.balance +
                              customerData?.customer?.interest,
                          )}
                        </h4>
                        {customerData?.customer?.barrelBankActive ? (
                          <Link to="/caskshares">Spend</Link>
                        ) : (
                          <a onClick={() => setScroll("barrelbank")}>Manage</a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              <div className={styles.accountLink}>
                <Button
                  id="first"
                  as={Link}
                  to="/my-account"
                  className={styles.btnBlackOutline}
                >
                  My Account
                </Button>
              </div>
            </Col>
            <Col xs={12} className={styles.navLinks}>
              {shop.market === 'GB' ? (
                <a
                  onClick={() => setScroll("barrelbank")}
                  className={styles.link}
                >
                  BarrelBank
                  <FiChevronDown />
                </a>
              ) : null}
              <a onClick={() => setScroll("events")} className={styles.link}>
                Events
                <FiChevronDown />
              </a>
              <a onClick={() => setScroll("products")} className={styles.link}>
                My collection
                <FiChevronDown />
              </a>
              {/* <a onClick={() => setScroll("podcasts")} className={styles.link}>
                Podcasts
                <FiChevronDown />
              </a> */}
              <a onClick={() => setScroll("benefits")} className={styles.link}>
                Benefits
                <FiChevronDown />
              </a>
            </Col>
          </Row>
        </Container>
        {/* Events */}
        <div id="refer-friend" className={styles.referOuterWrap}>
          <Container className={styles.referInnerWrap}>
            <Row className={styles.referRow}>
              <Col xs={12} className={styles.referCol}>
                <h2>
                  Ultra unique one-of-a kind <br /> single cask whisky
                </h2>
                <Button
                  type="button"
                  id="second"
                  onClick={() => setReferModalOpen((prev: boolean) => !prev)}
                  className={styles.btnPrimary}
                >
                  Refer a Friend
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        {shop.market === 'GB' ? (
          <div id="barrelbank" className={styles.bbOuterWrap}>
            <Container className={styles.bbInnerWrap}>
              <h4 style={{ textAlign: "center" }} id="third">
                BarrelBank
              </h4>

              <TheCollective data={customerData?.customer} />
            </Container>
          </div>
        ) : null}
        <div id="events" className={styles.eventsOuterWrap}>
          <Container className={styles.eventsInnerWrap}>
            {!activeEvent || !upcomingEvents ? (
              <LoadingView minHeight="550px" dark={false} />
            ) : (
              <>
                <Row className={styles.mainVidRow}>
                  <Col xs={12} md={3} className={styles.titleCol}>
                    <h4 id="fourth">Events</h4>
                    <p>
                      Whether you’re joining us online or in person, you can
                      book your seat and buy your pack, all in one place. Not
                      only that, but you can catch up on past events too. Simply
                      order your pack linked to the video you’d like to see and
                      enjoy at your leisure.
                    </p>
                  </Col>
                  <Col xs={12} md={9} className={styles.contentCol}>
                    <div className={styles.fullVid}>
                      <div
                        className={styles.mainVidMarkup}
                        dangerouslySetInnerHTML={mainVidMarkup(
                          activeEvent?.url,
                        )}
                      />{" "}
                      <div
                        className={`${styles.fullVidCover} ${
                          hideCover && styles.hideCover
                        }`}
                      >
                        <small>{formatDate(activeEvent?.date)}</small>
                        <h5>{activeEvent?.title}</h5>
                        <p
                          onClick={() => setHideCover((prev: boolean) => !prev)}
                        >
                          Watch now
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={styles.cardsRow}>
                  <Col xs={0} md={3}></Col>
                  <Col xs={12} md={9} className={styles.cardsCol}>
                    <div className={styles.vidSort}>
                      <label htmlFor="vidSort">Show me</label>
                      <select
                        id="vidSort"
                        onChange={(e) => setSortVid(e.target.value)}
                      >
                        <option value="all-events">All events</option>
                        <option value="upcoming-events">Upcoming events</option>
                      </select>
                    </div>

                    <CollectiveItemsSlider
                      events={upcomingEvents}
                      sort={sortVid}
                      customerEvents={customerEvents}
                      activeEvent={activeEvent}
                      setActiveEvent={setActiveEvent}
                      setHideCover={setHideCover}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
        {/* Exclusive Products */}
        <Container id="products" className={styles.exclusiveProducts}>
          <Row className={styles.innerRow}>
            <Col xs={12} sm={5} className={styles.titleCol}>
              <h4 id="fifth">My Collection</h4>
            </Col>
            <Col xs={12} sm={7} className={styles.buttonsCol}>
              <div className={styles.buttonsWrap}>
                <Button
                  as={Link}
                  to="/caskshares"
                  className={styles.btnPrimary}
                >
                  Shop caskshares
                </Button>
                <Button as={Link} to="/my-account" className={styles.btnLight}>
                  View full collection
                </Button>
              </div>
            </Col>
            <Col xs={12} className={styles.productsCol}>
              {customerLoading ? (
                <LoadingLogo minHeight={"550px"} />
              ) : customerCollection.length ? (
                <MyCollectionSlider customerCollection={customerCollection} />
              ) : (
                <div className={styles.caskUpsell}>
                  <h3>Start your collection</h3>
                  <p>
                    Reserve a bottle and build up your single cask whisky
                    collection.{" "}
                  </p>
                  <Button
                    as={Link}
                    to="/caskshares"
                    className={styles.btnPrimary}
                  >
                    Shop Now
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        {/* The Collective Podcasts */}
        {/* <Container id="podcasts" className={styles.collectivePodcasts}>
          <Row className={styles.innerRow}>
            <Col md={12} className={styles.titleCol}>
              <h4>The Collective Podcasts</h4>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Asperiores rerum quaerat doloribus fuga quam nulla!
              </p>
            </Col>
            <Col xs={12} className={styles.sorterCol}>
              <div className={styles.sorterWrap}>
                <small>Year</small>
                <p>
                  2022 <FiChevronDown />
                </p>
              </div>
            </Col>
            <Col xs={12} className={styles.podcastsCol}>
              <CaskSlider casks={caskData?.casks} />
              <div className={styles.sampleCard}></div>
              <div className={styles.sampleCard}></div>
              <div className={styles.sampleCard}></div>
              <div className={styles.sampleCard}></div>
            </Col>
          </Row>
        </Container> */}
        {/* Your Benefits */}
        <Container id="benefits" className={styles.benefitsWrap}>
          <Row className={styles.innerRow}>
            <Col xs={12} className={styles.titleCol}>
              <h4 id="sixth">Your benefits</h4>
              <p>
                Being part of the community has its perks. Joining The
                Collective means you have access to plenty of exclusive benefits
                including savings on bottles and free shipping.
              </p>
            </Col>
            <Col xs={12} className={styles.benefitsGrid}>
              {yourBenefits.filter((benefit: any) => {
                if (shop.market !== 'GB' && benefit.id === 2) {
                  return false;
                }
                return true;
              }).map((benefit: any) => (
                <div className={styles.gridItem} key={benefit.id}>
                  <div className={styles.imgWrap}>
                    <img src={benefit.img} alt={benefit.title} />
                  </div>
                  <h5>{benefit.title}</h5>
                  <small>{benefit.paragraph}</small>
                </div>
              ))}
            </Col>
            <Col className={styles.reviewButton}>
              <Button as={Link} to="/my-account" className={styles.btnPrimary}>
                My Account
              </Button>
            </Col>
          </Row>
          <Row className={styles.innerRow}>
            <Col className={styles.renewalDate}>
              <p>
                Your Caskshare Collective membership is valid until{" "}
                <span>
                  {formatFullDate(
                    customerData?.customer?.collectiveRenewalDate,
                  )}
                </span>
                . We will email you to remind you before this date. <br /> To
                cancel your automatic renewal please Contact Us.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      {referModalOpen &&
        renderReferFriend({
          setModalOpen: setReferModalOpen,
          modalOpen: referModalOpen,
        })}
    </>
  );
};

export default InsideCollective;
