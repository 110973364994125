import React from "react";
import { Col } from "react-bootstrap";
import ProductCard from "./.v2/ProductCard";

const ProductItem = ({
  data,
  onClick,
}: {
  data: any;
  onClick: (bottle: any) => void;
}) => {
  return (
    <>
      {data?.map((bottle: any) => (
        <Col className="whisky-card" key={bottle.id} xs={12} sm={6} xl={4}>
          <ProductCard bottle={bottle} onClick={() => onClick(bottle)} />
        </Col>
      ))}
    </>
  );
};

export default ProductItem;
