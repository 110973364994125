import React, { useEffect } from "react";

const ScrollToSection = ({
  scroll,
  setScroll,
}: {
  scroll?: any;
  setScroll?: any;
}) => {
  useEffect(() => {
    const el = document.getElementById(scroll);
    if (el) {
      window.scrollTo({
        top: 0,
      });
      setTimeout(
        () =>
          window.scrollTo({
            // -70 as navbar's height is 70 when not at the top.
            top: Number(el.getBoundingClientRect().top - 100),
            behavior: "smooth",
          }),
        300,
      );
      setScroll("");
    }
    return () => {
      setScroll("");
    };
  }, [scroll]);

  return null;
};

export default ScrollToSection;
