import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../state/rootReducer";

export function PrivateRoute({ children, ...rest }: any) {
  const loggedInUser = useSelector(
    (state: RootState) => state.user.credentials?.accessToken,
  );
  if (!loggedInUser) {
    window.sessionStorage.setItem("location", rest.location.pathname);
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedInUser ? (
          children
        ) : (
          <>
            {/* {console.log(location)} */}
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          </>
        )
      }
    />
  );
}
