import React from 'react'
import { persistor } from '../../state/store'
import Error from '../../components/app/Error'

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void
type ErrorHandlingComponent<Props> = (props: Props, error?: Error) => React.ReactNode

type ErrorState = { error?: Error }

export default function Catch<Props extends {}>(
  component: ErrorHandlingComponent<Props>,
  errorHandler?: ErrorHandler
): React.ComponentType<Props> {
  return class extends React.Component<Props, ErrorState> {
    state: ErrorState = {
      error: undefined
    }

    static getDerivedStateFromError(error: Error) {
      return { error }
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info)
      }
    }

    render() {
      return component(this.props, this.state.error)
    }
  }
}

type Props = {
  children: React.ReactNode
}

export const ErrorBoundary = Catch((props: Props, error?: Error) => {
  if (error) {
    persistor.purge()
    return (
      <Error message={error.message} />
    )
  }
  return <>{props.children}</>
})
