import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import styles from "./Social.module.scss";
import Socials from "../../components/app/Socials";

import social2 from "../../assets/images/social/social2.jpg";
import social3 from "../../assets/images/social/social3.jpg";
import social4 from "../../assets/images/social/social4.jpg";
import social5 from "../../assets/images/social/social5.jpg";
import social6 from "../../assets/images/social/social6.jpg";
import social7 from "../../assets/images/social/social7.jpg";

const Social = () => {
  return (
    <>
      <div className={`${styles.item} ${styles.item1}`}>
        <div className={styles.inner}>
          <h3>
            Share <br /> the love
          </h3>
          <div className={styles.socialLink}>
            <small>
              <span className={styles.bold}>@caskshare</span>
            </small>
            <small>#caskshare</small>
          </div>
        </div>
      </div>
      <div className={`${styles.item} ${styles.item2}`}>
        <div className={styles.inner}>
          <img src={social2} alt="social-image" />
        </div>
      </div>
      <div className={`${styles.item} ${styles.item3}`}>
        <div className={styles.inner}>
          <img src={social3} alt="social-image" />
        </div>
      </div>
      <div className={`${styles.item} ${styles.item4}`}>
        <div className={styles.inner}>
          <img src={social4} alt="social-image" />
        </div>
      </div>
      <div className={`${styles.item} ${styles.item5}`}>
        <div className={`${styles.inner} ${styles.innerS}`}>
          <img src={social5} alt="social-image" />
        </div>
      </div>
      <div className={`${styles.item} ${styles.item6}`}>
        <div className={styles.inner}>
          <img src={social6} alt="social-image" />
        </div>
      </div>
      <div className={`${styles.item} ${styles.item7}`}>
        <div className={styles.inner}>
          <img src={social7} alt="social-image" />
        </div>
      </div>
      <div className={`${styles.item} ${styles.item8}`}>
        <div className={styles.inner}>
          <div className={styles.socialLinks}>
            <div>
              <small>follow caskshare</small>
              <Socials />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Social;
