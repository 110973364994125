import React, { useState, useEffect } from "react";
import { idText } from "typescript";
import { BsChevronDown } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Range } from "rc-slider";

import "rc-slider/assets/index.css";
import CaskAgeSlider from "../../CaskAgeSlider";
import styles from "./CasksharesFilter.module.scss";
import { RootState } from "../../../../state/rootReducer";
import { CASK_SUMMARY, Cask } from "../../../../graphql/Casks";
import { getSpiritStyle, getProductionMethod } from "../../../../AppText";
import { useHistory } from "react-router-dom";

const CasksharesFilter = ({
  filter,
  setFilter,
  setSearchSort,
  refetch,
  width,
  breakpoint,
  clearVisible,
}: {
  filter: any;
  setFilter: (arg0: any) => void;
  setSearchSort: (arg0: any) => void;
  refetch: () => void;
  width?: any;
  breakpoint?: any;
  clearVisible?: boolean;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [distilleries, setDistilleries] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [pricingBrackets] = useState([
    { name: "£0 - £200", value: { min: 0, max: 201 } },
    { name: "£200 - £500", value: { min: 199, max: 501 } },
    { name: "£500 - £1000", value: { min: 499, max: 1001 } },
    { name: "£1000+", value: { min: 999, max: 20000 } },
  ]);
  const [agingBrackets] = useState([
    { name: "3y - 7y", value: { min: 0, max: 7 } },
    { name: "7y - 14y", value: { min: 7, max: 14 } },
    { name: "14y - 25y", value: { min: 14, max: 25 } },
    { name: "25y +", value: { min: 25, max: 75 } },
  ]);
  const [isCollapsed, setIsCollapsed] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });

  const shopId = useSelector((state: RootState) => state.shop.id);
  let { data, loading } = useQuery(CASK_SUMMARY, {
    variables: {
      shop: shopId,
    },
  });

  const caskSummary = data?.caskSummary;

  useEffect(() => {
    if (caskSummary?.distilleries && !viewMore && filter?.statuses?.length) {
      setDistilleries(
        caskSummary.distilleries
          .filter((a: any) => a.isAvailable === true)
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          .sort((distillery: any) =>
            filter.distilleries.includes(distillery.name) ? -1 : 1,
          )
          .slice(0, 5),
      );
    } else if (
      caskSummary?.distilleries &&
      !viewMore &&
      !filter?.statuses?.length
    ) {
      setDistilleries(
        caskSummary.distilleries
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          .sort((distillery: any) =>
            filter.distilleries.includes(distillery.name) ? -1 : 1,
          )
          .slice(0, 5),
      );
    } else if (
      caskSummary?.distilleries &&
      viewMore &&
      !filter?.statuses?.length
    ) {
      setDistilleries(
        caskSummary.distilleries
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          .sort((distillery: any) =>
            filter.distilleries.includes(distillery.name) ? -1 : 1,
          ),
      );
    } else if (
      caskSummary?.distilleries &&
      viewMore &&
      filter?.statuses?.length
    ) {
      setDistilleries(
        caskSummary.distilleries
          .filter((a: any) => a.isAvailable === true)
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          .sort((distillery: any) =>
            filter.distilleries.includes(distillery.name) ? -1 : 1,
          ),
      );
    }
    return () => {
      setDistilleries([]);
    };
  }, [
    caskSummary?.distilleries,
    viewMore,
    filter?.statuses,
    filter?.distilleries,
  ]);

  if (loading || !data?.caskSummary) {
    return null;
  }

  // check if exists in array
  const handleFilterSelection = (selectedItem: any, filterName: any) => {
    const newList = filter[filterName];

    const itemIndex = newList.findIndex((item: any) => {
      if (typeof item === "object") {
        return item.min === selectedItem.min && item.max === selectedItem.max;
      } else {
        return item === selectedItem;
      }
    });

    if (itemIndex > -1) {
      newList.splice(itemIndex, 1);
    } else {
      newList.push(selectedItem);
    }

    setFilter({
      ...filter,
      [filterName]: newList,
    });
    refetch();

    return () => {
      setFilter({});
    };
  };

  // const checkMin = (filterName: any) => {
  //   if (Math.min(...caskSummary[filterName]?.map((a: any) => a.name)) >= 0) {
  //     return Math.min(...caskSummary[filterName]?.map((a: any) => a.name));
  //   } else {
  //     return 0;
  //   }
  // };
  // const checkMax = (filterName: any) => {
  //   return Math.max(...caskSummary[filterName]?.map((a: any) => a.name));
  // };

  const timeName = (e: any) => {
    switch (e) {
      case "maturing":
        return t("maturing");
        break;
      case "shipping_three_months":
        return t("shipping in 3 months");
        break;
      case "shipping_now":
        return t("shipping now");
        break;
      case "shipping_one_year":
        return t("shipping in 1 year");
        break;
    }
  };

  const flavourName = (e: any) => {
    switch (e) {
      case "complexity":
        return t("complex");
        break;
      case "fruitiness":
        return t("floral");
        break;
      case "smokiness":
        return t("smokey / peaty");
        break;
      case "spice":
        return t("spicy");
      case "sweetness":
        return t("sweet / fruity");
        break;
    }
  };

  const clearFilters = () => {
    setFilter({
      ...filter,
      locations: [],
      distilleries: [],
      shippingTimeframe: [],
      spiritStyles: [],
      flavours: [],
      priceBrackets: [],
      bottlingAgeBrackets: [],
      tag: "",
    });
    setSearchSort({
      term: "featured",
      order: "asc",
    });
    history.replace("/caskshares");
  };

  return (
    <div className={styles.filersWrap}>
      {clearVisible && width > breakpoint ? (
        <div className={styles.clearFilters} onClick={clearFilters}>
          <p>Clear</p>
          <IoIosRefresh />
        </div>
      ) : null}
      {/* -------------------------------Location--------------------------------- */}
      <div
        data-testid="filter-location"
        className={`${styles.innerFilterWrap} ${
          isCollapsed[0] && styles.collapsed
        }`}
      >
        <div
          className={`${styles.title} ${
            isCollapsed[0] && styles.rotateChevron
          }`}
          onClick={() =>
            setIsCollapsed({
              ...isCollapsed,
              0: !isCollapsed[0],
            })
          }
        >
          <p>Location</p>
          <BsChevronDown />
        </div>
        {caskSummary.countries.map((country: any) => (
          <div key={country.name} className={styles.filterItem}>
            <input
              type="checkbox"
              id={country.name}
              className={styles.filterInput}
              checked={
                filter.locations?.find((a: any) => a === country.name)
                  ? true
                  : false
              }
              onChange={() => {
                handleFilterSelection(country.name, "locations");
              }}
            />
            <label className={styles.filterLabel} htmlFor={country.name}>
              {t(country.name)}
            </label>
          </div>
        ))}
      </div>
      {/* -------------------------------Distillery--------------------------------- */}
      <div
        data-testid="filter-distillery"
        className={`${styles.innerFilterWrap} ${
          isCollapsed[1] && styles.collapsed
        }`}
      >
        <div
          className={`${styles.title} ${
            isCollapsed[1] && styles.rotateChevron
          }`}
          onClick={() =>
            setIsCollapsed({
              ...isCollapsed,
              1: !isCollapsed[1],
            })
          }
        >
          <p>Distillery</p>
          <BsChevronDown />
        </div>
        {distilleries &&
          distilleries.map((distillery: any) => (
            <div key={distillery.name} className={styles.filterItem}>
              <input
                type="checkbox"
                id={distillery.name}
                className={styles.filterInput}
                checked={
                  filter.distilleries?.find((a: any) => a === distillery.name)
                    ? true
                    : false
                }
                onChange={() => {
                  handleFilterSelection(distillery.name, "distilleries");
                }}
              />
              <label className={styles.filterLabel} htmlFor={distillery.name}>
                {t(distillery.name)}
              </label>
            </div>
          ))}
        {!isCollapsed[1] && (
          <small onClick={() => setViewMore(!viewMore)}>
            {!viewMore ? "view more" : "view less"}
          </small>
        )}
      </div>
      {/* -------------------------------Shipping status--------------------------------- */}
      <div
        data-testid="filter-shipping-status"
        className={`${styles.innerFilterWrap} ${
          isCollapsed[2] && styles.collapsed
        }`}
      >
        <div
          className={`${styles.title} ${
            isCollapsed[2] && styles.rotateChevron
          }`}
          onClick={() =>
            setIsCollapsed({
              ...isCollapsed,
              2: !isCollapsed[2],
            })
          }
        >
          <p>Shipping status</p>
          <BsChevronDown />
        </div>
        {caskSummary.shippingTimeframe
          .sort((a: any) => (a.name === "maturing" ? -1 : 1))
          .sort((a: any) => (a.name === "shipping_one_year" ? -1 : 1))
          .sort((a: any) => (a.name === "shipping_three_months" ? -1 : 1))
          .sort((a: any) => (a.name === "shipping_now" ? -1 : 1))
          .map((timeframe: any) => (
            <div key={timeframe.name} className={styles.filterItem}>
              <input
                type="checkbox"
                id={timeframe.name}
                className={styles.filterInput}
                checked={
                  filter.shippingTimeframe?.find(
                    (a: any) => a === timeframe.name,
                  )
                    ? true
                    : false
                }
                onChange={() => {
                  handleFilterSelection(timeframe.name, "shippingTimeframe");
                }}
              />
              <label className={styles.filterLabel} htmlFor={timeframe.name}>
                {timeName(timeframe.name)}
              </label>
            </div>
          ))}
      </div>
      {/* -------------------------------Spirit Style--------------------------------- */}
      <div
        data-testid="filter-spiritStyles"
        className={`${styles.innerFilterWrap} ${
          isCollapsed[3] && styles.collapsed
        }`}
      >
        <div
          className={`${styles.title} ${
            isCollapsed[3] && styles.rotateChevron
          }`}
          onClick={() =>
            setIsCollapsed({
              ...isCollapsed,
              3: !isCollapsed[3],
            })
          }
        >
          <p>Spirit style</p>
          <BsChevronDown />
        </div>
        {caskSummary.spiritStyles
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          .map((spiritStyle: any) => (
            <div key={spiritStyle.name} className={styles.filterItem}>
              <input
                type="checkbox"
                id={spiritStyle.name}
                className={styles.filterInput}
                checked={
                  filter.spiritStyles?.find((a: any) => a === spiritStyle.name)
                    ? true
                    : false
                }
                onChange={() => {
                  handleFilterSelection(spiritStyle.name, "spiritStyles");
                }}
              />
              <label className={styles.filterLabel} htmlFor={spiritStyle.name}>
                {t(spiritStyle.name)}
              </label>
            </div>
          ))}
      </div>
      {/* -------------------------------Flavours--------------------------------- */}
      <div
        data-testid="filter-flavours"
        className={`${styles.innerFilterWrap} ${
          isCollapsed[4] && styles.collapsed
        }`}
      >
        <div
          className={`${styles.title} ${
            isCollapsed[4] && styles.rotateChevron
          }`}
          onClick={() =>
            setIsCollapsed({
              ...isCollapsed,
              4: !isCollapsed[4],
            })
          }
        >
          <p>Flavour</p>
          <BsChevronDown />
        </div>
        {caskSummary.flavours.map((flavour: any) => (
          <div key={flavour} className={styles.filterItem}>
            <input
              type="checkbox"
              id={flavour}
              className={styles.filterInput}
              checked={
                filter.flavours?.find((a: any) => a === flavour) ? true : false
              }
              onChange={() => {
                handleFilterSelection(flavour, "flavours");
              }}
            />
            <label className={styles.filterLabel} htmlFor={flavour}>
              {flavourName(flavour)}
            </label>
          </div>
        ))}
      </div>

      {/*-------------------------------Price Brackets---------------------------------*/}
      <div
        data-testid="filter-prices"
        className={`${styles.innerFilterWrap} ${
          isCollapsed[5] && styles.collapsed
        }`}
      >
        <div
          className={`${styles.title} ${
            isCollapsed[5] && styles.rotateChevron
          }`}
          onClick={() =>
            setIsCollapsed({
              ...isCollapsed,
              5: !isCollapsed[5],
            })
          }
        >
          <p>Price</p>
          <BsChevronDown />
        </div>
        {pricingBrackets?.map((price: any) => (
          <div key={price.name} className={styles.filterItem}>
            <input
              type="checkbox"
              id={price.name}
              className={styles.filterInput}
              checked={
                filter.priceBrackets?.find(
                  (a: any) => a.min === price.value.min,
                )
                  ? true
                  : false
              }
              onChange={() => {
                handleFilterSelection(price.value, "priceBrackets");
              }}
            />
            <label className={styles.filterLabel} htmlFor={price.name}>
              {t(price.name)}
            </label>
          </div>
        ))}
      </div>

      {/*-------------------------------Bottling Age---------------------------------*/}
      <div
        data-testid="filter-bottling-ages"
        className={`${styles.innerFilterWrap} ${
          isCollapsed[6] && styles.collapsed
        }`}
      >
        <div
          className={`${styles.title} ${
            isCollapsed[6] && styles.rotateChevron
          }`}
          onClick={() =>
            setIsCollapsed({
              ...isCollapsed,
              6: !isCollapsed[6],
            })
          }
        >
          <p>Bottling Age</p>
          <BsChevronDown />
        </div>
        {agingBrackets?.map((bottlingAge: any) => (
          <div key={bottlingAge.name} className={styles.filterItem}>
            <input
              type="checkbox"
              id={bottlingAge.name}
              className={styles.filterInput}
              checked={
                filter.bottlingAgeBrackets?.find(
                  (a: any) => a.min === bottlingAge.value.min,
                )
                  ? true
                  : false
              }
              onChange={() => {
                handleFilterSelection(bottlingAge.value, "bottlingAgeBrackets");
              }}
            />
            <label className={styles.filterLabel} htmlFor={bottlingAge.name}>
              {t(bottlingAge.name)}
            </label>
          </div>
        ))}
      </div>
      {/*-------------------------------END---------------------------------*/}
    </div>
  );
};

export default CasksharesFilter;
