import React, { CSSProperties, useState, useRef, useEffect } from "react";
import { Col, Row, Button, Dropdown } from "react-bootstrap";
import { setShop } from "../../state/slices/shopSlice";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { RootState } from "../../state/rootReducer";
import { SHOPS, Shop } from "../../graphql/Shops";
import styles from "./CurrencyDropdown.module.scss";

const currencyCodeToInfoLookup = {
  GBP: "£ - UK",
  USD: "$ - USA",
  EUR: "€ - EUR",
};
const currencyCodeToInfo = (code: string) => {
  // @ts-ignore
  return currencyCodeToInfoLookup[code.toUpperCase()] || code;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const CurrencyItem = ({ currency }: { currency: string }) => {
  return <span>{currencyCodeToInfo(currency)}</span>;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const CurrencyDropdown = ({
  userStore,
  setUserStore,
}: {
  userStore?: string;
  setUserStore?: any;
}) => {
  const { data } = useQuery(SHOPS);
  const currentShop = useSelector((state: RootState) => state.shop);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);

  const _selectedCurrency = userStore || currentShop?.currency;

  return (
    <>
      <Dropdown as="div" className={styles.currencyDropdown}>
        <Dropdown.Toggle
          variant="secondary"
          id="currency-dropdown"
          className={styles.currencyDropdownToggle}
        >
          <CurrencyItem currency={_selectedCurrency} />
        </Dropdown.Toggle>

        <Dropdown.Menu
          show={showCurrencyDropdown}
          className={styles.currencyDropdownMenu}
        >
          {data?.shops?.map((shop: Shop, index: number) => (
            <Dropdown.Item
              key={shop.currency}
              eventKey={String(index + 2)}
              onSelect={() => {
                setUserStore(shop.currency);
              }}
            >
              <CurrencyItem currency={shop.currency} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CurrencyDropdown;
