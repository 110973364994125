import React from "react";
import { Col } from "react-bootstrap";

import { Product } from "../../graphql/Products";
import { IMAGE_FALLBACKS } from "../../config";

const ProductImage = ({
  product,
  onClick,
}: {
  product: Product;
  onClick?: (product: Product) => void;
}) => {
  return (
    <Col
      className="product-image"
      xs={12}
      style={{ padding: "0" }}
      onClick={() => (onClick ? onClick(product) : null)}
    >
      <img
        style={{ width: "100%", margin: "0 auto" }}
        alt="Bottles"
        className="bottle-image"
        src={
          product.image ? product.image : IMAGE_FALLBACKS.defaultWhiskyProduct
        }
      />
    </Col>
  );
};

export default ProductImage;
