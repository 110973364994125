import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import { useQuery } from "react-apollo";
import pluralize from "pluralize";
import { hotjar } from "react-hotjar";
import { GrClose } from "react-icons/gr";

import { RootState } from "../state/rootReducer";
import { PRODUCT, RECOMMENDED_PRODUCTS, Product } from "../graphql/Products";
import LoadingView from "../components/app/LoadingView";
import { formatCurrency } from "../utils/currency";
import { IMAGE_FALLBACKS } from "../config";
import { increaseCheckoutQuantity } from "../state/slices/checkoutSlice";
import SignUpForNew from "../components/app/SignUpForNew/SignUpForNew";
import RecommendedProduct from "../components/products/RecommendedProduct";
import styles from "./ProductDetailsPage.module.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import YouMightLikeSlider from "../components/checkout/YouMightLikeSlider";

import TagManager from "react-gtm-module";
import LoadingLogo from "../components/app/LoadingLogo";
import ProgressiveImg from "../components/.v2/general/ProgressiveImg";

// defer the "Plan ahead" upsell modal for now
const SHOW_PLAN_AHEAD_MODAL = false;

const ProductDetailsPage = () => {
  hotjar.initialize(2882523, 6);
  const [charityRaffle, setCharityRaffle] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [price, setPrice] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);
  const [collectiveNote, setCollectiveNote] = useState("");
  const [imgModalOpen, setImgModalOpen] = useState(false);

  const shop = useSelector((state: RootState) => state.shop);
  const currency = useSelector((state: RootState) => state.shop.currency);
  const checkout = useSelector((state: RootState) => state.checkout);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );
  const history = useHistory();
  // @ts-ignore
  const { productId } = useParams();
  const { data, loading } = useQuery(PRODUCT, {
    variables: {
      shop: shop.id,
      id: productId,
    },
  });

  const recommendedVariables = (() => {
    if (productId.indexOf("-")) {
      return { shop: shop.id, slug: productId, first: 4 };
    } else {
      return {
        shop: shop.id,
        product: `gid://shopify/Product/${productId}`,
        first: 4,
      };
    }
  })();
  const { data: recommendedData, loading: recommendedLoading } = useQuery(
    RECOMMENDED_PRODUCTS,
    { variables: recommendedVariables },
  );

  useEffect(() => {
    let raffleTicket;
    if (data && data.product) {
      raffleTicket = data.product.title.includes("Charity Raffle");
      setCharityRaffle(raffleTicket);
    }
  }, [data]);

  useEffect(() => {
    let collectiveDiscount = data?.product?.percentageDiscount;
    if (data && !collectiveActive) {
      setPrice(data?.product?.defaultVariant?.price?.amount);
      setCollectiveUser(false);
      collectiveDiscount > "0" &&
        setCollectiveNote(
          `${Number(collectiveDiscount * 100).toFixed(
            0,
          )}% off for Collective members`,
        );
    } else if (loggedInUser && data?.product && collectiveActive) {
      setPrice(data?.product?.defaultVariant?.memberPrice);
      setCollectiveUser(true);
      collectiveDiscount > "0" &&
        setCollectiveNote(
          `${Number(collectiveDiscount * 100).toFixed(
            0,
          )}% Collective discount applied`,
        );
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("");
    };
  }, [loggedInUser, data, data?.product, collectiveActive]);

  if (loading || !data) {
    return <LoadingLogo />;
  }

  const product: Product = data?.product;

  // filter recommended casks to eliminate those that are already in the basket
  //     or are the same as the cask being purchased
  const lineItems = checkout?.lineItems || [];
  const lineItemIds = [
    ...lineItems.map((i: any) => i.variant.id),
    product.defaultVariant.id,
  ];
  const inCheckout = (p: Product) =>
    lineItemIds.find((i: any) => i === p.defaultVariant.id);
  const allSimilarProducts: Product[] = recommendedData?.similarProducts;
  const similarProducts = allSimilarProducts?.filter((p) => !inCheckout(p));

  // Google Analytics 4
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  var tagManagerArgs = {
    dataLayer: {
      event: "view_item",
      ecommerce: {
        currency: currency,
        items: [
          {
            item_id: `${product.seller.title}-${product.sku}`,
            item_brand: product.vendor,
            item_name: product.title,
            price: price,
            item_category: "product",
          },
        ],
      },
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  const addProductToBasket = () => {
    dispatch(increaseCheckoutQuantity({ product: product }));
    if (SHOW_PLAN_AHEAD_MODAL) {
      setShowModal(true);
    } else {
      history.push("/basket");
    }
  };

  const productDescriptionMarkup = (description?: string) => {
    return {
      __html: `
    ${
      description
        ? `<p class="preserve-new-lines">${description}</p>`
        : `<p style="display: none"></p>`
    }
    `,
    };
  };

  const productDetails = async (product: Product) => {
    // Note on casks we split on the defaultVariant Id, but on products it's just ID
    const productId = product.slug;
    history.push(`/products/${productId}`);
  };

  return (
    <>
      <Container className="relative">
        <Row className="back-to-browse-caskshares" as="section">
          <Col>
            <Link
              to={{
                pathname: "/caskshares",
              }}
              style={{
                color: "black",
                display: "flex",
                fontSize: ".9rem",
                alignItems: "center",
                lineHeight: "1",
              }}
            >
              <i
                style={{ width: "30px" }}
                className="fa fa-arrow-left fa-fw"
                aria-hidden="true"
              />
              Browse All
            </Link>
          </Col>
        </Row>
        <Row className="product-detail-wrap">
          <Col className="imgCol" sm={4} lg={2}>
            <div className="image-wrap">
              {product.image ? (
                <ProgressiveImg
                  onClick={() => setImgModalOpen(true)}
                  src={product.image}
                  placeholderSrc={IMAGE_FALLBACKS.defaultBottle}
                  props={{ alt: product.title, cursor: "pointer" }}
                />
              ) : (
                <img
                  src={IMAGE_FALLBACKS.defaultBottle}
                  alt="default-whisky-bottle-image"
                />
              )}
            </div>
            {/* <Slider
              dots={true}
              infinite={false}
              responsive={[
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
              slidesToShow={1}
              slidesToScroll={1}
              swipeToSlide={true}
              adaptiveHeight={true}
            >
              {product.image ? (
                <div className="image-wrap">
                  <img src={product.image} alt={product.title} />
                </div>
              ) : (
                <div className="image-wrap">
                  <img
                    src={IMAGE_FALLBACKS.defaultWhiskyProduct}
                    alt="default-whisky-bottle-image"
                  />
                </div>
              )}
            </Slider> */}
          </Col>
          <Col sm={8} lg={5}>
            {product.quantity && product.quantity >= 10 ? null : (
              <div className="qty-wrap">
                <h5>{`${pluralize(
                  "bottle",
                  Math.max(product.quantity, 0),
                  true,
                )} left`}</h5>
              </div>
            )}
            <h4 style={{ marginBottom: "1rem" }}>{product.title}</h4>
            {product.description && (
              <div
                className="product-desc"
                dangerouslySetInnerHTML={productDescriptionMarkup(
                  product.description,
                )}
              />
            )}
          </Col>
          <Col xs={12} lg={4} className="d-flex align-items-center info-col">
            <Row xs={12} className="info-row-wrap">
              <Col sm={6} lg={12}>
                <Row className="rmv-padding mb-3">
                  <Col className="small-title-wrap" style={{ flex: "2" }}>
                    <p className="small-title">seller</p>
                    {product.seller.title || "Unknown"}
                  </Col>
                  <Col className="small-title-wrap">
                    <p className="small-title">country</p>
                    {product.country}
                  </Col>
                </Row>
                <Row className="rmv-padding mb-3">
                  {product.volume !== "0.0" && (
                    <Col className="small-title-wrap" style={{ flex: "2" }}>
                      <p className="small-title">size</p>
                      {product.volume}cl
                    </Col>
                  )}

                  {product.abv !== "0.0" && (
                    <Col className="small-title-wrap">
                      <p className="small-title">abv</p>
                      {product.abv}%
                    </Col>
                  )}
                </Row>
              </Col>

              <Col sm={6} lg={12} className="basket-wrap">
                <Row className={styles.priceRow}>
                  <Col className={styles.priceWrap}>
                    <small>Price per bottle</small>
                    <p
                      className={
                        collectiveUser
                          ? styles.collectivePrice
                          : styles.nonCollective
                      }
                    >
                      {price && formatCurrency(shop?.currency, 2).format(price)}
                    </p>
                  </Col>
                  <Col className={styles.buttonWrap}>
                    {product.collectiveOnly === false ? (
                      <>
                        <Button
                          className={styles.btnPrimary}
                          onClick={addProductToBasket}
                          disabled={product.quantity <= 0}
                        >
                          {product.quantity >= 1 ? "Add to basket" : "Sold Out"}
                        </Button>
                        {charityRaffle && (
                          <Button
                            className={styles.btnLight}
                            onClick={() => history.push(`/raffle/${productId}`)}
                          >
                            View Detail
                          </Button>
                        )}
                      </>
                    ) : null}
                    {product.collectiveOnly === true && collectiveActive ? (
                      <Button
                        className={styles.btnPrimary}
                        onClick={addProductToBasket}
                        disabled={product.quantity <= 0}
                      >
                        {product.quantity >= 1 ? "Add to basket" : "Sold Out"}
                      </Button>
                    ) : null}
                    {product.collectiveOnly === true && !collectiveActive ? (
                      <>
                        <Button
                          className={styles.btnPrimary}
                          onClick={addProductToBasket}
                          disabled={product.quantity <= 0 || !collectiveActive}
                        >
                          {product.quantity >= 1 || !collectiveActive
                            ? "Collective Only"
                            : "Sold Out"}
                        </Button>
                        <Button
                          className={styles.btnLight}
                          onClick={() => history.push("/the-collective")}
                        >
                          Join The Collective
                        </Button>
                      </>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    className={`${styles.banner} ${
                      collectiveUser
                        ? styles.collectivePrice
                        : styles.nonCollective
                    }`}
                  >
                    {collectiveNote ? (
                      <p>{collectiveNote}</p>
                    ) : !collectiveNote && collectiveActive ? (
                      <Link to="/my-account/refer-a-friend">
                        Refer a Friend to The Collective
                      </Link>
                    ) : (
                      <Link to="/the-collective">Join the Collective</Link>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {similarProducts?.length ? (
          <Row className="recommended-product" as="section">
            <Col xs={12} className="you-might-also-like">
              <h4>You might also like</h4>
              <div className="browse-all">
                <Link to="/caskshares">
                  Browse All
                  {/* {getCopyText("ABOUT_PAGE", "in-the-press")} */}
                  <i className="fa fa-long-arrow-right fa-fw i-xs" />
                </Link>
              </div>
            </Col>
            <Col xs={12} className="recommended-slider">
              <YouMightLikeSlider
                items={similarProducts}
                loading={recommendedLoading}
              />
            </Col>
          </Row>
        ) : null}
      </Container>
      <div>
        <SignUpForNew />
      </div>
      {imgModalOpen && (
        <div
          onClick={() => setImgModalOpen(false)}
          className={styles.imageModal}
        >
          <div className={styles.imgWrap}>
            <img src={product.image} alt={product.title} />
          </div>
          <div onClick={() => setImgModalOpen(false)} className={styles.close}>
            <GrClose />
          </div>
        </div>
      )}
      <Modal
        show={showModal}
        size="lg"
        centered
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Container className="caskshare-page">
          <Modal.Header closeButton>
            <Row>
              <Col>
                <h4>Plan ahead</h4>
              </Col>
              <Col>
                <p
                  style={{
                    fontSize: "1.25rem",
                  }}
                >
                  A premium whisky collection is priceless and timeless. Here
                  are some suggestions to grow your collection based on your
                  current selection.
                </p>
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {/* {similarCasks.slice(0, 3).map((c) => (
								<Col sm={4} key={c.defaultVariant.id}>
									<CaskUpsell cask={c} />
								</Col>
							))} */}
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Link className="float-left" to="/basket">
              Skip step
            </Link>
            <div className="d-flex" />
            <Button as={Link} to="/basket">
              Continue to checkout
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </>
  );
};

export default ProductDetailsPage;
