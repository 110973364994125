import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { RootState } from "../state/rootReducer";
import { Helmet } from "react-helmet";

import { getMetaText, getCopyText } from "../AppText";
import { DISTILLERIES, Distillery } from "../graphql/Distillery";
import LoadingView from "../components/app/LoadingView";
import DistilleryCard from "../components/distillery/DistilleryCard";
import StartCollection from "./.v2/GeneralComps/StartCollection";
import LoadingLogo from "../components/app/LoadingLogo";

const DistilleriesPage = () => {
  const history = useHistory();
  const shopId = useSelector((state: RootState) => state.shop.id);
  const { data, loading } = useQuery(DISTILLERIES, {
    fetchPolicy: "network-only",
    variables: {
      shop: shopId,
    },
  });

  if (loading) {
    return <LoadingLogo />;
  }

  const onDistilleryClick = (d: Distillery) => {
    history.push(`/distillery/${d.slug}`);
  };

  let distilleries: Array<Distillery> = data?.distilleries || [];
  let searchResults = distilleries;

  return (
    <>
      <Container>
        <Helmet>
          <title>{getMetaText("DISTILLERIES_PAGE", "title")}</title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="keywords"
            content={getMetaText("DISTILLERIES_PAGE", "keywords")}
          />
          <meta
            name="description"
            content={getMetaText("DISTILLERIES_PAGE", "description")}
          />
          <meta
            name="author"
            content={getMetaText("DISTILLERIES_PAGE", "author")}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content={getMetaText("DISTILLERIES_PAGE", "title")}
          />
          <meta
            property="og:description"
            content={getMetaText("DISTILLERIES_PAGE", "description")}
          />
          <meta
            property="og:image"
            content={
              window.location.origin + require("../assets/home-page/splash.jpg")
            }
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@thecaskshare" />
          <meta
            name="twitter:title"
            content={getMetaText("DISTILLERIES_PAGE", "title")}
          />
          <meta
            name="twitter:description"
            content={getMetaText("DISTILLERIES_PAGE", "description")}
          />
          <meta
            name="twitter:image"
            content={
              window.location.origin + require("../assets/home-page/splash.jpg")
            }
          />
        </Helmet>

        <Row className="the-worlds-finest-distilleries" as="section">
          <Col>
            <Row>
              <Col sm={6}>
                <h3>The world’s finest distilleries</h3>
              </Col>
              <Col sm={6}>
                <p>
                  {getCopyText(
                    "DISTILLERIES_PAGE",
                    "the-worlds-finest-distilleries",
                  )}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <section className="all-distilleries">
          <Row>
            {!searchResults.length ? (
              <Col style={{ paddingTop: "2rem", marginBottom: "1rem" }}>
                <h3>No distilleries found</h3>
              </Col>
            ) : (
              <>
                {searchResults.map((distillery: Distillery) => (
                  <DistilleryCard
                    distillery={distillery}
                    onClick={onDistilleryClick}
                    key={distillery.id}
                  />
                ))}
              </>
            )}
          </Row>
        </section>

        <Row className="a-common-language" style={{}}>
          <Col
            sm={4}
            className="distilleries-piles-of-casks-image"
            style={{
              backgroundImage: `url(${require("../assets/distilleries-page/piles-of-casks.png")})`,
            }}
          >
            <img
              className="distilleries-stills-and-pipes-image d-none d-sm-block"
              alt="A common passion"
              src={require("../assets/distilleries-page/stills-and-pipes.png")}
            />
          </Col>
          <Col sm={8} className="mt-4 mt-sm-0">
            <Row>
              <Col sm={{ span: 8, offset: 1 }}>
                <h3>A common language - a different voice</h3>
              </Col>
              <Col xs={{ span: 8, offset: 4 }} style={{ marginTop: "1rem" }}>
                <p>
                  {getCopyText("DISTILLERIES_PAGE", "a-common-language-para-1")}
                </p>
                <p>
                  {getCopyText("DISTILLERIES_PAGE", "a-common-language-para-2")}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <StartCollection />
      </Container>
    </>
  );
};

export default DistilleriesPage;
