import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { getMetaText, getCopyText } from "../AppText";
import LoadingView from "../components/app/LoadingView";
import { useQuery } from "react-apollo";
import { GET_TERMS } from "../graphql/Terms";
import { useSelector } from "react-redux";
import { RootState } from "../state/rootReducer";
import LoadingLogo from "../components/app/LoadingLogo";

const TermsPage = () => {
  // @ts-ignore
  const { slug } = useParams();
  const shopId = useSelector((state: RootState) => state.shop.id);

  const role = (() => {
    switch (slug) {
      case "faq":
        return "faq";
      case "privacy-policy":
        return "cookie";
      case "terms-of-use":
        return "use";
      case "terms-and-conditions":
        return "tandc";
      case "ballot":
        return "ballot";
      default:
        return "term";
    }
  })();

  const { data, loading } = useQuery(GET_TERMS, {
    variables: {
      shop: shopId,
      role: role,
    },
  });
  if (loading) {
    return <LoadingLogo />;
  }

  const pageName = (() => {
    switch (role) {
      case "faq":
        return "FAQ_PAGE";
      case "cookie":
        return "COOKIES_PAGE";
      case "use":
        return "TERMS_OF_USE";
      case "tandc":
        return "TERMS_AND_CONDITIONS";
      case "ballot":
        return "TERMS_AND_CONDITIONS_OF_BALLOT";
      default:
        return "TERMS_PAGE";
    }
  })();

  const createTermMarkup = (title?: string, body?: string) => {
    return {
      __html: `
    ${
      title
        ? `<h5 style="padding:.5rem 0;">${title}</h5>`
        : `<h5 style="display: none"></h5>`
    }
    ${
      body
        ? `<p class="preserve-new-lines">${body}</p>`
        : `<p style="display: none"></p>`
    }
    `,
    };
  };

  return (
    <Container
      style={{ paddingTop: "50px", minHeight: "70vh", paddingBottom: "50px" }}
    >
      <Helmet>
        <title>{getMetaText(pageName, "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText(pageName, "keywords")} />
        <meta
          name="description"
          content={getMetaText(pageName, "description")}
        />
        <meta name="author" content={getMetaText(pageName, "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={getMetaText(pageName, "title")} />
        <meta
          property="og:description"
          content={getMetaText(pageName, "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta name="twitter:title" content={getMetaText(pageName, "title")} />
        <meta
          name="twitter:description"
          content={getMetaText(pageName, "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      <Row>
        <Col className="mb-3" xs={12} md={9}>
          <h3>{getMetaText(pageName, "title")?.split("|")[0]}</h3>
        </Col>
      </Row>
      <Row>
        <Col className="col-xs-hidden" md={3}></Col>
        <Col xs={12} md={9}>
          <Row>
            <Col xs={12}>
              {data?.terms?.map((term: any) => (
                <div
                  key={term.id}
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,.2)",
                    paddingBottom: "1rem",
                    marginBottom: "1rem",
                  }}
                  dangerouslySetInnerHTML={createTermMarkup(
                    term.title,
                    term.body,
                  )}
                />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsPage;
