import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Cask } from "../../graphql/Casks";
import { IMAGE_FALLBACKS } from "../../config";
import { BiPurchaseTag } from "react-icons/bi";
import Tooltip from "../app/Tooltip";
import pluralize from "pluralize";

const CaskThumbnail = ({
  cask,
  onClick,
  caskCodeHidden,
  customName,
}: {
  cask: Cask;
  onClick?: (cask: Cask) => void;
  caskCodeHidden?: any;
  customName?: string;
}) => {
  const [caskCode, setCaskCode] = useState<any>(null);
  const [distilleryName, setDistilleryName] = useState<any>(null);
  const [caskYear, setCaskYear] = useState<any>(null);

  useEffect(() => {
    if ((cask && cask?.code) || cask?.distillery || cask?.fillingDate) {
      setCaskCode(cask.code);
      setDistilleryName(cask.distillery.name + " " + "Distillery");
      setCaskYear(new Date(cask.fillingDate).getFullYear());
    }
  }, [cask]);

  const getCaskBottomImage = (cask: Cask) => {
    let images = cask.distillery.attachments.filter(
      (a) => a.role === "cask-bottom",
    );
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.caskImageCaskBottom;
    return image;
  };
  const getCaskBottlesImage = (cask: Cask) => {
    let caskImages = cask.seller.attachments?.filter(
      (a) => a.role === "cask-bottles",
    );
    let caskImage = caskImages?.length
      ? caskImages[0].url
      : IMAGE_FALLBACKS.caskImageCaskBottles;
    return caskImage;
  };

  return (
    <Col
      xs={12}
      className={`cask-image-component thumbnail ${onClick ? "clickable" : ""}`}
      onClick={() => (onClick ? onClick(cask) : null)}
    >
      <div className="cask-bottom-area">
        <div className="clipper">
          <img
            alt="Cask"
            className="cask-bottom-image"
            src={getCaskBottomImage(cask)}
          />
          <div className="thumbnail-content-wrap">
            {/* <div className="cask-code">53.7%</div>
            <div className="distillery-name">Macallan</div>
            <div className="cask-year">19XX</div> */}
            <div className="cask-code">{!caskCodeHidden && caskCode}</div>
            <div className="distillery-name">
              {!customName ? distilleryName : customName}
            </div>
            <div className="cask-year">{caskYear}</div>
          </div>
          <img
            alt="Cask-Mask"
            className="cask-mask"
            src={require("../../assets/images/cask-end-overlay.png")}
          />
        </div>
        <img
          alt="Cask-Shadow"
          className="cask-shadow-mask"
          src={require("../../assets/images/cask-shadow-masked.png")}
          aria-hidden="true"
        />
        <img
          alt="Bottles"
          className="bottle-image"
          src={getCaskBottlesImage(cask)}
        />
      </div>
    </Col>
  );
};

export default CaskThumbnail;
