import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./RangeSlider.module.scss";

const RangeSlider = ({
  depositAmount,
  setDepositAmount,
  onSubmit,
  updateSubmit,
  isBarrelBankActive,
}: {
  depositAmount: number;
  setDepositAmount: any;
  onSubmit?: any;
  updateSubmit?: any;
  isBarrelBankActive?: boolean;
}) => {
  const [active, setActive] = useState(false);
  const [range, setRange] = useState({
    0: 0,
    1: 20,
    2: 40,
    3: 60,
    4: 80,
    5: 100,
    6: 120,
    7: 140,
    8: 160,
    9: 180,
    10: 200,
  });

  useEffect(() => {
    let span = document.getElementById("span");
    if (active) {
      let value = depositAmount;
      if (span) {
        span.style.left = Math.round(value / 2) + "%";
      }
    }
  }, [active, depositAmount]);

  const handleChange = (e: any) => setDepositAmount(e.target.value);

  let ranges = Object.values(range);
  return (
    <form onSubmit={isBarrelBankActive ? updateSubmit : onSubmit}>
      <div className={styles.range}>
        <div className={styles.sliderValue}>
          <span id="span" className={`${styles.span} ${active && styles.show}`}>
            {depositAmount}
          </span>
        </div>
        <div className={styles.field}>
          <div className={`${styles.value} ${styles.left}`}>£0</div>
          <input
            id="input"
            type="range"
            list="tickmarks"
            value={depositAmount}
            min={0}
            max={200}
            step={5}
            onChange={handleChange}
            className={styles.slider}
            onInput={() => setActive(true)}
            onBlur={() => setActive(false)}
            onTouchEnd={() => setActive(false)}
          />
          <div className={`${styles.value} ${styles.right}`}>£200</div>
          <div className={styles.dataList}>
            {ranges.map((v: any) => (
              <span key={v} aria-valuenow={v}></span>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.submit}>
        <input
          type="number"
          min={0}
          max="200"
          step={5}
          value={depositAmount}
          onChange={handleChange}
          className={styles.inputNumber}
          placeholder="0"
        />
        <Button type="submit">
          {isBarrelBankActive ? "Update" : "Set Deposit Amount"}
        </Button>
      </div>
    </form>
  );
};

export default RangeSlider;
