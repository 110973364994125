import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, NavLink, Switch, Route, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useQuery } from "react-apollo";
import { GET_CUSTOMER } from "../graphql/User";
import LoadingView from "../components/app/LoadingView";
import { getMetaText, getCopyText } from "../AppText";
import { RootState } from "../state/rootReducer";
import LoadingLogo from "../components/app/LoadingLogo";
import { lazyWithRetry } from "../utils/lazyWithRetry";

import Joyride, {
  CallBackProps,
  ACTIONS,
  EVENTS as JoyEvents,
  STATUS,
} from "react-joyride";
import { formatCurrency } from "../utils/currency";

// import CommunityResources from "../components/account/collective/CommunityResources";

// const AccountReferFriend = lazyWithRetry(
//   () => import("../components/account/AccountReferFriend"),
// );
const AccountCollection = lazyWithRetry(
  () => import("../components/account/AccountCollection"),
);
// const AccountBarrelBank = lazyWithRetry(
//   () => import("../components/account/AccountBarrelBank"),
// );
const AccountPayments = lazyWithRetry(
  () => import("../components/account/AccountPayments"),
);
const AccountDetails = lazyWithRetry(
  () => import("../components/account/AccountDetails"),
);
const AccountOrders = lazyWithRetry(
  () => import("../components/account/AccountOrders"),
);
const AccountGifts = lazyWithRetry(
  () => import("../components/account/AccountGifts"),
);

export interface JoyrideState {
  run: boolean;
  steps: any[];
  stepIndex: number;
  tourActive: boolean;
}

const AccountOverviewPage = () => {
  const history = useHistory();
  const [user, setUser] = useState<any>({});
  const [active, setActive] = useState(false);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(true);
  const [leftPosition, setLeftPosition] = useState(0);
  const [titles, setTitles] = useState<any>([]);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  const [beaconVisible, setBeaconVisible] = useState(false);
  const [joyrideState, setJoyrideState] = useState<JoyrideState>({
    run: false,
    stepIndex: 0,
    tourActive: false,
    steps: [
      {
        content: (
          <>
            <h5>Welcome to your Account Page.</h5>
            <br />
            <p>
              See all your maturing whisky reservations here and follow the
              journey of your whisky.
            </p>
          </>
        ),
        locale: { skip: <strong>SKIP</strong> },
        target: "#first-slide",
        disableBeacon: true,
      },
      {
        title: "My Payments",
        content: <p>View all previous transactions on Caskshare.</p>,
        target: "#second-slide",
      },
      {
        title: "My Orders",
        content: <p>See all bottles that you’ve purchased.</p>,
        target: "#third-slide",
      },
      {
        title: "MY VOUCHERS",
        content: <p>Claim your gift voucher.</p>,
        target: "#fourth-slide",
      },
      {
        title: "My Details",
        content: <p>Update your shipping and billing addresses.</p>,
        target: "#fifth-slide",
      },
      {
        title: "My Details",
        content: (
          <p>
            You will be redirected to Stripe where you can update your payment
            card.
          </p>
        ),
        target: "#sixth-slide",
      },
    ],
  });

  const handleJoyride = async () => {
    // Call the setJoyrideState function
    await history.replace("/my-account");
    setJoyrideState({
      ...joyrideState,
      stepIndex: 0,
      run: true,
      tourActive: true,
    });
  };

  useEffect(() => {
    const findCookie = new Object(
      document.cookie
        .split(";")
        .map((c) => c.split("="))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(value),
          }),
          {},
        ),
    );

    // @ts-ignore
    if (findCookie?.account_tour && findCookie?.account_tour === "true") {
      setBeaconVisible(false);
    } else {
      setBeaconVisible(true);
    }
    return () => {
      setBeaconVisible(false);
    };
  }, [document.cookie]);

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type, status, lifecycle } = data;

    const topNav = document?.getElementById("topNavContainer");
    const storeOpt = document.getElementById("storeOptions");

    if (status === "running" && topNav && storeOpt) {
      topNav.style.marginBottom = "0";
      topNav.style.transform = "translateY(0px)";
      storeOpt.style.height = "auto";
      storeOpt.style.transform = "translateY(0px)";
    } else if (status !== "running" && topNav && storeOpt) {
      topNav.removeAttribute("style");
      storeOpt.removeAttribute("style");
    }

    if (action === "close" || action === "skip") {
      history.replace("/my-account");
      setJoyrideState({
        ...joyrideState,
        stepIndex: 0,
        run: false,
        tourActive: false,
      });
    }

    if (action !== "stop") {
      setBeaconVisible(false);
    }

    if (lifecycle === "complete" && index === 5) {
      let expDate = new Date();
      expDate.setFullYear(expDate.getFullYear() + 1);
      document.cookie = `account_tour=true; expires=${expDate}`;
      setBeaconVisible(false);
    }

    if (type === "step:after" && index === 0) {
      if (action === "next") {
        setJoyrideState({ ...joyrideState, run: false });
        history.replace("/my-account/my-payments");
      } else {
        history.replace("/my-account");
        setJoyrideState({ ...joyrideState, stepIndex: 0, run: false });
      }
    } else if (type === "step:after" && index === 1) {
      if (action === "next") {
        setJoyrideState({ ...joyrideState, run: false });
        history.replace("/my-account/my-orders");
      } else {
        history.replace("/my-account");
        setJoyrideState({ ...joyrideState, stepIndex: 0, run: true });
      }
    } else if (type === "step:after" && index === 2) {
      if (action === "next") {
        setJoyrideState({ ...joyrideState, run: false });
        history.replace("/my-account/my-gifts");
      } else {
        history.replace("/my-account/my-payments");
        setJoyrideState({ ...joyrideState, stepIndex: 1, run: true });
      }
    } else if (type === "step:after" && index === 3) {
      if (action === "next") {
        setJoyrideState({ ...joyrideState, run: false });
        history.replace("/my-account/details");
      } else {
        history.replace("/my-account/my-orders");
        setJoyrideState({ ...joyrideState, stepIndex: 2, run: true });
      }
    } else if (type === "step:after" && index === 4) {
      if (action === "next") {
        setJoyrideState({ ...joyrideState, stepIndex: 5, run: true });
      } else {
        history.replace("/my-account/my-gifts");
        setJoyrideState({ ...joyrideState, stepIndex: 3, run: true });
      }
    } else if (type === "step:after" && index === 5) {
      if (action === "prev") {
        setJoyrideState({ ...joyrideState, stepIndex: 4, run: true });
      } else if (type === "step:after" && index === 5) {
        setJoyrideState({
          ...joyrideState,
          tourActive: false,
          // stepIndex: 0,
          run: false,
        });
        history.replace("/my-account");
      }
    }
  };
  // joyride end

  const shop = useSelector((state: RootState) => state.shop);

  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      shop: shop.id,
    },
    fetchPolicy: "network-only",
  });
  const textSubstitutions = {
    "Customer-name": "Customer", // fixme
  };

  const ref = useRef(null);

  // useEffect(() => {
  //   setTitles(accountLinks.map((link) => link.title));
  // }, []);

  // if (titles) {
  //   var vNames: any = [];
  //   for (var i: number = 0; i < titles.length; i++) {
  //     vNames[i] = document.getElementById(titles[i]);
  //   }
  // }

  // if (vNames) {
  //   vNames?.map((name: any) => console.log(name?.getBoundingClientRect()));
  // }

  useEffect(() => {
    if (data && data.customer) {
      setUser(data);
    }
  }, [data]);

  const handleScroll = (e: any) => {
    if (e.target.scrollLeft === 0) {
      setLeftArrow(false);
    } else {
      setLeftArrow(true);
    }
    if (
      e.target.scrollWidth - e.target.scrollLeft <=
      e.target.clientWidth + 2
    ) {
      setRightArrow(false);
    } else {
      setRightArrow(true);
    }
  };

  const scrollRight = () => {
    //@ts-ignore
    ref.current.scrollTo({
      top: 0,
      //@ts-ignore
      left: ref?.current?.scrollWidth,
      behavior: "smooth",
    });
  };

  const scrollLeft = () => {
    //@ts-ignore
    ref.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const accountLinks = [
    {
      id: 0,
      title: "My Collection",
      url: "/my-account",
      img: require("../assets/account-icons/collection.svg"),
    },
    {
      id: 2,
      title: "My Payments",
      url: "/my-account/my-payments",
      img: require("../assets/account-icons/payments.svg"),
    },
    {
      id: 3,
      title: "My Orders",
      url: "/my-account/my-orders",
      img: require("../assets/account-icons/orders.svg"),
    },
    {
      id: 4,
      title: "My VOUCHERS",
      url: "/my-account/my-gifts",
      img: require("../assets/account-icons/gifts.svg"),
    },
    {
      id: 5,
      title: "My Details",
      url: "/my-account/details",
      img: require("../assets/account-icons/details.svg"),
    },
  ];

  const helpLinks = [
    {
      id: 0,
      text: "FAQs",
      href: "",
      url: "/terms/faq",
    },
    {
      id: 1,
      text: "Contact",
      href: "mailto:contact@caskshare.com?subject=Message from Caskshare site",
      url: "",
    },
    {
      id: 2,
      text: "Use Voucher",
      href: "",
      url: "/claim-a-gift",
    },
  ];

  if (!data || loading) {
    return <LoadingLogo />;
  }

  return (
    <>
      <Joyride
        disableOverlayClose
        showProgress
        callback={handleCallback}
        run={joyrideState.run}
        stepIndex={joyrideState.stepIndex}
        steps={joyrideState.steps}
        scrollToFirstStep={false}
        scrollOffset={300}
        disableCloseOnEsc
        showSkipButton
        hideCloseButton
        continuous
        styles={{
          options: {
            primaryColor: "#e76852",
          },
          buttonNext: {
            backgroundColor: "transparent",
            border: "#e76852 2px solid",
            outlineColor: "#e76852",
            color: "#e76852",
          },
          buttonBack: {
            backgroundColor: "transparent",
            border: "black 2px solid",
            outlineColor: "black",
            borderRadius: "4px",
            color: "black",
          },
          // options: {},
        }}
      />
      <Container style={{ paddingTop: "50px", minHeight: "60vh" }}>
        <Helmet>
          <title>
            {getMetaText("ACCOUNT_OVERVIEW_PAGE", "title", textSubstitutions)}
          </title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="keywords"
            content={getMetaText(
              "ACCOUNT_OVERVIEW_PAGE",
              "keywords",
              textSubstitutions,
            )}
          />
          <meta
            name="description"
            content={getMetaText(
              "ACCOUNT_OVERVIEW_PAGE",
              "description",
              textSubstitutions,
            )}
          />
          <meta
            name="author"
            content={getMetaText(
              "ACCOUNT_OVERVIEW_PAGE",
              "author",
              textSubstitutions,
            )}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content={getMetaText(
              "ACCOUNT_OVERVIEW_PAGE",
              "title",
              textSubstitutions,
            )}
          />
          <meta
            property="og:description"
            content={getMetaText(
              "ACCOUNT_OVERVIEW_PAGE",
              "description",
              textSubstitutions,
            )}
          />
          <meta
            property="og:image"
            content={
              window.location.origin + require("../assets/home-page/splash.jpg")
            }
          />
        </Helmet>

        <Col>
          <Row className="account-top-row">
            {user?.customer?.balance > 0 ? (
              <div id="#first" className={"barrelbankWrap"}>
                <img
                  src={require("../assets/account-icons/BB.svg")}
                  alt="BarrelBank icon"
                />
                <div className={"cta"}>
                  <h5>
                    {formatCurrency(shop?.currency, 0).format(
                      user?.customer?.balance + user?.customer?.interest,
                    )}
                  </h5>
                  {user?.customer?.barrelBankActive ? (
                    <Link to="/caskshares">Spend</Link>
                  ) : (
                    <a onClick={() => history.replace("/the-collective")}>
                      Join Collective
                    </a>
                  )}
                </div>
              </div>
            ) : null}
            <Col md={12} lg={3} className="account-wrapper">
              <Row className="account-side-menu align-items-center">
                <h4 style={{ paddingBottom: "20px" }}>
                  My account{" "}
                  {beaconVisible && (
                    <div onClick={handleJoyride} className="show-beacon"></div>
                  )}
                </h4>

                <Col
                  md={6}
                  lg={12}
                  className={`account-links ${
                    rightArrow === true ? "mr" : "ml"
                  }`}
                  ref={ref}
                  id="all"
                  onScroll={handleScroll}
                >
                  {accountLinks.map((link: any) => (
                    <NavLink
                      exact
                      to={link.url}
                      className="side-nav-header"
                      key={link.id}
                    >
                      <Row className="account-menu-item">
                        <div className="inner">
                          <div />
                        </div>
                        <img src={link.img} alt="icon" />
                        <h6 id={link.title}>{link.title}</h6>
                      </Row>
                    </NavLink>
                  ))}
                </Col>
                <Col md={6} lg={12} className="need-help">
                  <p>Need help?</p>
                  <ul className="list-unstyled">
                    {helpLinks.map((link: any) => (
                      <li key={link.id}>
                        {link.url ? (
                          <Link to={link.url}>
                            {" "}
                            <i
                              style={{
                                width: "30px",
                                color: "rgb(238, 94, 77)",
                              }}
                              className="fa fa-long-arrow-right fa-fw"
                              aria-hidden="true"
                            />
                            {link.text}
                          </Link>
                        ) : null}
                        {link.href ? (
                          <a href={link.href}>
                            {" "}
                            <i
                              style={{
                                width: "30px",
                                color: "rgb(238, 94, 77)",
                              }}
                              className="fa fa-long-arrow-right fa-fw"
                              aria-hidden="true"
                            />
                            {link.text}
                          </a>
                        ) : null}
                      </li>
                    ))}
                    <li>
                      <a href="#" onClick={handleJoyride}>
                        <i
                          style={{
                            width: "30px",
                            color: "rgb(238, 94, 77)",
                          }}
                          className="fa fa-long-arrow-right fa-fw"
                          aria-hidden="true"
                        />
                        Guide Tour
                      </a>
                    </li>
                  </ul>
                </Col>
                {leftArrow ? (
                  <button
                    className="scroll-left scroll-button"
                    onClick={scrollLeft}
                  >
                    {"<"}
                  </button>
                ) : null}
                {rightArrow ? (
                  <button
                    className="scroll-right scroll-button"
                    onClick={scrollRight}
                  >
                    {">"}
                  </button>
                ) : null}
              </Row>
            </Col>
            <Col
              md={12}
              lg={9}
              style={{ position: "relative" }}
              className="account-wrapper-content"
            >
              <Switch>
                <Route exact path="/my-account">
                  <Suspense fallback={<LoadingLogo />}>
                    <AccountCollection
                      data={user}
                      loading={loading}
                      joyrideState={joyrideState}
                      setJoyrideState={setJoyrideState}
                    />
                  </Suspense>
                </Route>
                <Route path="/my-account/my-gifts">
                  <Suspense fallback={<LoadingLogo />}>
                    <AccountGifts
                      data={user}
                      joyrideState={joyrideState}
                      setJoyrideState={setJoyrideState}
                    />
                  </Suspense>
                </Route>
                <Route path="/my-account/my-payments">
                  <Suspense fallback={<LoadingLogo />}>
                    <AccountPayments
                      data={user}
                      joyrideState={joyrideState}
                      setJoyrideState={setJoyrideState}
                    />
                  </Suspense>
                </Route>
                <Route path="/my-account/details">
                  <Suspense fallback={<LoadingLogo />}>
                    <AccountDetails
                      data={user}
                      joyrideState={joyrideState}
                      setJoyrideState={setJoyrideState}
                    />
                  </Suspense>
                </Route>
                <Route path="/my-account/my-orders">
                  <Suspense fallback={<LoadingLogo />}>
                    <AccountOrders
                      data={user}
                      joyrideState={joyrideState}
                      setJoyrideState={setJoyrideState}
                    />
                  </Suspense>
                </Route>
                {/* <Route path="/my-account/barrel-bank">
                  <Suspense fallback={<LoadingLogo />}>
                    <AccountBarrelBank data={user} />
                  </Suspense>
                </Route> */}
                {/* <Route path="/my-account/refer-a-friend">
                  <Suspense fallback={<LoadingLogo />}>
                    <AccountReferFriend data={user} />
                  </Suspense>
                </Route> */}
              </Switch>
            </Col>
          </Row>
          <Row className="need-help-mobile">
            <Col md={6} lg={12} className="need-help">
              <p>Need help?</p>
              <ul className="list-unstyled">
                {helpLinks.map((link: any) => (
                  <li key={link.id}>
                    {link.url ? (
                      <Link to={link.url}>
                        {" "}
                        <i
                          style={{
                            width: "30px",
                            color: "rgb(238, 94, 77)",
                          }}
                          className="fa fa-long-arrow-right fa-fw"
                          aria-hidden="true"
                        />
                        {link.text}
                      </Link>
                    ) : null}
                    {link.href ? (
                      <a href={link.href}>
                        {" "}
                        <i
                          style={{
                            width: "30px",
                            color: "rgb(238, 94, 77)",
                          }}
                          className="fa fa-long-arrow-right fa-fw"
                          aria-hidden="true"
                        />
                        {link.text}
                      </a>
                    ) : null}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Col>
      </Container>
      {/* {user?.customer ? (
        <Container fluid>
          <CommunityResources />
        </Container>
      ) : null} */}
    </>
  );
};

export default AccountOverviewPage;
