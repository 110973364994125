import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Toast, Spinner } from "react-bootstrap";
import { RootState } from "../../state/rootReducer";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../utils/currency";
import { FaUniversity, FaPiggyBank, FaPoundSign } from "react-icons/fa";
import { GET_CUSTOMER, UPDATE_CUSTOMER } from "../../graphql/User";
import { useQuery } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";
import {
  COLLECTIVE_CHECKOUT,
  DEPOSIT_AMOUNT_UPDATE,
  COLLECTIVE_CARD_UPDATE,
} from "../../graphql/Collective";
import { loadStripe } from "@stripe/stripe-js";
import styles from "./Account.module.scss";
import RangeSlider from "./RangeSlider";
import PaymentFailureModal from "../checkout/PaymentFailureModal";
import PaymentSuccessModal from "../checkout/PaymentSuccessModal";
import Tooltip from "../app/Tooltip";
import LoadingView from "../app/LoadingView";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "../cask-calculator/ProgressProvider";
import LoadingLogo from "../app/LoadingLogo";
import { feature } from "../../utils/featureFlag";

const TheCollective = ({ data }: { data?: any }) => {
  const shop = useSelector((state: RootState) => state.shop);
  const [collectiveCheckout, { loading: collectiveCheckoutLoading }] =
    useMutation(COLLECTIVE_CHECKOUT, {
      refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
    });
  const [depositAmountUpdate] = useMutation(DEPOSIT_AMOUNT_UPDATE, {
    refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
  });
  const [collectiveCardUpdate, { loading: collectiveCardUpdateLoading }] =
    useMutation(COLLECTIVE_CARD_UPDATE, {
      refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
    });
  const [customerUpdate] = useMutation(UPDATE_CUSTOMER, {
    refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
  });

  const { refetch, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      shop: shop.id,
    },
    fetchPolicy: "network-only",
  });

  const [isBarrelBankActive, setIsBarrelBankActive] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [paymentError, setPaymentError] = useState<any>(null);
  const [paymentSuccess, setPaymentSuccess] = useState<any>(null);
  const [surpriseMeChecked, setSurpriseMeChecked] = useState<boolean>(false);
  const [checkLoaded, setCheckedLoaded] = useState(false);
  const [balance, setBalance] = useState(0);
  const [interest, setInterest] = useState(0);
  const [spendable, setSpendable] = useState(0);

  useEffect(() => {
    if (!data) {
      return;
    } else {
      setIsBarrelBankActive(data.barrelBankActive);
      setDepositAmount(data.depositAmount);
      setBalance(data.balance);
      setInterest(data.interest);
      setSpendable(data.balance + data.interest);
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data) {
      setSurpriseMeChecked(data.surpriseMe);
      setCheckedLoaded(true);
    }
  }, [data]);

  // useEffect(() => {
  //   refetch();
  // }, [data?.collectiveActive]);

  const closePaymentSuccessModal = () => {
    setPaymentSuccess("");
  };
  const closePaymentFailureModal = () => {
    setPaymentError("");
  };

  const collectiveSetMonthlyCredit = async (e: any) => {
    e.preventDefault();
    const stripe = await loadStripe(shop.stripePublishableKey);

    const { data: collectiveData } = await collectiveCheckout({
      variables: {
        shop: shop.id,
        quantity: depositAmount && Number(depositAmount),
        product: "BarrelBank",
        successPath: "my-account/collective/deposit-setup/success",
        cancelPath: "my-account/collective/deposit-setup/cancel",
      },
    });

    if (
      collectiveData.collectiveCheckout.sessionId &&
      !collectiveCheckoutLoading
    ) {
      stripe?.redirectToCheckout({
        sessionId: collectiveData.collectiveCheckout.sessionId,
      });
    } else {
      setPaymentError(
        "There was a problem re-directing you to stripe. Please, try again later.",
      );
    }
  };

  const collectiveUpdateMonthlyCredit = async (e: any) => {
    e.preventDefault();

    if (data.depositAmount === depositAmount) {
      setPaymentError(
        `The BarrelBank deposit amount must be different than the current deposit amount`,
      );
    } else {
      const { data: depositAmountData } = await depositAmountUpdate({
        variables: {
          shop: shop.id,
          quantity: depositAmount && Number(depositAmount),
        },
      });

      if (depositAmountData.depositAmountUpdate.result === true) {
        setPaymentSuccess(
          `You have successfully updated your monthly BarrelBank credit to £${depositAmount}`,
        );
      } else {
        setPaymentError(
          `We are sorry, we were unable to update your BarrelBank deposit amount at this time. Please, try again later.`,
        );
      }
    }
  };

  const submitSurpriseMe = async (e: any) => {
    e.preventDefault();
    const result = await customerUpdate({
      variables: {
        shop: shop.id,
        surpriseMe: surpriseMeChecked,
      },
    });
    if (result.errors) {
      console.warn("submitInfoForm() failed", result.errors);
      alert("Sorry we were not able to update your details");
    } else {
      // setFormOpen(false);
    }
  };

  if (!data || loading) {
    return <LoadingLogo minHeight="500px" />;
  }

  let tooltipText =
    "<p>You can use the slider or the text input field below to change the amount you wish to deposit each month. <br /> <br /> Any change you make will take effect as of your next scheduled payment.</p>";
  let surpriseText =
    "<p>This is the information about surprise me functionality.</p>";

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  return (
    <>
      <Col xs={12}>
        {/* <h5>BarrelBank</h5> */}
        <Row className={styles.balanceWrap}>
          <div className={`${styles.innerWrap} ${styles.circleBar}`}>
            <div className={styles.iconTitle}>
              <FaUniversity /> <p>Total Balance</p>
            </div>
            <ProgressProvider valueStart={0} valueEnd={Number(balance)}>
              {(value: any) => (
                <CircularProgressbar
                  value={value}
                  text={formatCurrency(shop.currency, 2).format(
                    Number(balance),
                  )}
                  maxValue={value + 0.01}
                  className="progressOne"
                  circleRatio={1}
                  styles={buildStyles({
                    textSize: "1rem",
                    pathTransitionDuration: 1,
                  })}
                />
              )}
            </ProgressProvider>
          </div>

          <div className={`${styles.innerWrap} ${styles.circleBar}`}>
            <div className={styles.iconTitle}>
              <FaPiggyBank /> <p>Your Reward</p>
            </div>
            <ProgressProvider valueStart={0} valueEnd={Number(interest)}>
              {(value: any) => (
                <CircularProgressbar
                  value={value}
                  text={formatCurrency(shop.currency, 2).format(
                    Number(interest),
                  )}
                  maxValue={value + 0.001}
                  className="progressOne"
                  circleRatio={1}
                  styles={buildStyles({
                    textSize: "1rem",
                    pathTransitionDuration: 1,
                  })}
                />
              )}
            </ProgressProvider>
          </div>

          <div className={`${styles.innerWrap} ${styles.circleBar}`}>
            <div className={styles.iconTitle}>
              <FaPoundSign className={styles.pound} /> <p>Total To Spend</p>
            </div>
            <ProgressProvider valueStart={0} valueEnd={Number(spendable)}>
              {(value: any) => (
                <CircularProgressbar
                  value={value}
                  text={formatCurrency(shop.currency, 2).format(
                    Number(spendable),
                  )}
                  maxValue={value + 0.001}
                  className="progressTwo"
                  circleRatio={1}
                  styles={buildStyles({
                    textSize: "1rem",
                    pathTransitionDuration: 1,
                  })}
                />
              )}
            </ProgressProvider>
          </div>
        </Row>

        {feature("surprise_me") === true && (
          <div className={styles.barrelBank}>
            <div className={styles.rangeSlider}>
              <h5>
                Surprise me
                <Tooltip lineOne={surpriseText} />
              </h5>
              {checkLoaded ? (
                <Form className={styles.formWrap} onSubmit={submitSurpriseMe}>
                  <Form.Row>
                    <Form.Group as={Col}>
                      {surpriseMeChecked === true ? (
                        <Form.Check
                          checked={true}
                          id="surpriseMe"
                          onChange={() =>
                            setSurpriseMeChecked(!surpriseMeChecked)
                          }
                          label="I agree to surprise me"
                        />
                      ) : (
                        <Form.Check
                          checked={false}
                          id="surpriseMe"
                          onChange={() =>
                            setSurpriseMeChecked(!surpriseMeChecked)
                          }
                          label="I agree to surprise me"
                        />
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Button
                    className={styles.btnPrimary}
                    disabled={surpriseMeChecked === data?.surpriseMe}
                    type="submit"
                  >
                    Confirm
                  </Button>
                </Form>
              ) : null}
            </div>
          </div>
        )}

        <div className={styles.barrelBank}>
          <div className={styles.rangeSlider}>
            <h5>
              deposit amount
              <Tooltip lineOne={tooltipText} />
            </h5>
            <RangeSlider
              depositAmount={depositAmount}
              setDepositAmount={setDepositAmount}
              onSubmit={collectiveSetMonthlyCredit}
              updateSubmit={collectiveUpdateMonthlyCredit}
              isBarrelBankActive={isBarrelBankActive}
            />
          </div>
        </div>
      </Col>
      <PaymentSuccessModal
        message={paymentSuccess}
        doClose={closePaymentSuccessModal}
        show={Boolean(paymentSuccess)}
      />
      <PaymentFailureModal
        message={paymentError}
        doClose={closePaymentFailureModal}
        show={Boolean(paymentError)}
      />
    </>
  );
};

export default TheCollective;
