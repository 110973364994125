import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./GiftIdeas.module.scss";

const GiftingPageSuccess = ({}: {}) => {
  return (
    <div className={styles.successWrap}>
      <h4>Success!</h4>
      <p>
        Thank you for your submission! Click the button below to view the
        Gifting Page.
      </p>
      <Button as={Link} to="/gifting">
        Gifting
      </Button>
    </div>
  );
};

export default GiftingPageSuccess;
