import { store } from "../../../state/store";
import { formatCurrency } from "../../../utils/currency";

const currency = store.getState().shop.currency;

export const voucherData = () => {
  const voucher = [
    {
      id: 0,
      name: "BarrelBankGift",
      quantity: 25,
      value: `${formatCurrency(store.getState().shop.currency, 0).format(25)}`,
    },
    {
      id: 1,
      name: "BarrelBankGift",
      quantity: 50,
      value: `${formatCurrency(store.getState().shop.currency, 0).format(50)}`,
    },
    {
      id: 2,
      name: "BarrelBankGift",
      quantity: 75,
      value: `${formatCurrency(store.getState().shop.currency, 0).format(75)}`,
    },
    {
      id: 3,
      name: "BarrelBankGift",
      quantity: 100,
      value: `${formatCurrency(store.getState().shop.currency, 0).format(100)}`,
    },
    {
      id: 4,
      name: "BarrelBankGift",
      quantity: 150,
      value: `${formatCurrency(store.getState().shop.currency, 0).format(150)}`,
    },
  ];
  return voucher;
};

export const membershipData = () => {
  const membership = [
    {
      id: 0,
      name: "collectiveGift",
      title: "Collective Gift",
      value: 1,
    },
    // {
    //   id: 1,
    //   name: "CollectivePremiumGift",
    //   title: "Collective Premium",
    //   value: 1,
    // },
  ];
  return membership;
};

export const memberBenefits = () => {
  const benefits = [
    "EXCLUSIVE & FIRST ACCESS",
    "BARRELBANK 10% UPLIFT",
    "FREE SHIPPING",
    "MONTHLY EVENTS AT OUR PRIVATE BAR AND VIRTUALLY",
    "UPGRADE WITH A BOTTLE OR A TASTING PACK",
  ];
  return benefits;
};
