import React, { useState, useEffect } from "react";

const ProgressProvider = ({
  valueStart,
  valueEnd,
  valueStart2,
  valueEnd2,
  children,
}: {
  valueStart?: number;
  valueEnd?: number;
  valueStart2?: number;
  valueEnd2?: number;
  children?: any;
}) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    setInterval(() => setValue(valueEnd), 1000);
  }, [valueEnd, valueEnd2]);

  return children(value);
};
export default ProgressProvider;
