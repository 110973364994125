import { store } from "../state/store";

export type CurrencyCode = string | "USD" | "GBP" | "EUR";

export const formatCurrency = (currencyCode?: CurrencyCode, decimal?: any) => {
  const formatter = new Intl.NumberFormat(
    navigator?.language || navigator?.languages?.[0] || "en-US",
    {
      style: "currency",
      currency: currencyCode || store.getState().shop.currency,
      maximumFractionDigits: decimal < 2 ? decimal : 2,
      minimumFractionDigits: decimal,
      currencyDisplay: "symbol",
    },
  );
  return formatter;
};
