import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import Fuse from "fuse.js";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";
import { BsSliders } from "react-icons/bs";

import { getMetaText, getCopyText } from "../AppText";
import { RootState } from "../state/rootReducer";
import { GET_CUSTOMER } from "../graphql/User";
import { CASKS, Cask } from "../graphql/Casks";
import CaskCard from "../caskshare-v3/components/casks/caskCard/CaskCard";
import CasksharesFilter from "../components/casks/.v2/Caskshares/CasksharesFilter";
import CasksharesSearch from "../components/casks/CasksharesSearch";
import CasksharesSorter from "../components/casks/CasksharesSorter";
import LoadingView from "../components/app/LoadingView";
import { ReactComponent as OneShareEqOneBottle } from "../assets/images/one-share-eq-one-bottle.svg";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import LoadingLogo from "../components/app/LoadingLogo";
import reverseCaskshareStatus from "../caskshare-v3/components/utils/reverseCaskshareStatus";

const CasksharesPage = () => {
  const { t } = useTranslation();
  hotjar.initialize(2882523, 6);
  // @ts-ignore
  const { tag, filters } = useParams();
  const [openFilterPane, setOpenFilterPane] = useState(false);
  const [filterVisible, setFilterVisible] = useState(true);
  const [customer, setCustomer] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [zeroCasks, setZeroCasks] = useState("");
  const [productBottles, setProductBottles] = useState(0);
  const [newSearchResults, setNewSearchResults] = useState<any>([]);
  const [clearVisible, setClearVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768;

  const [filter, setFilter] = useState<any>({
    locations: [],
    distilleries: [],
    shippingTimeframe: [],
    statuses: ["published", "comingsoon", "bottled"],
    spiritStyles: [],
    flavours: [],
    priceBrackets: [],
    bottlingAgeBrackets: [],
    yearsToBottlingBrackets: [],
    tag: "",
  });
  const [searchSort, setSearchSort] = useState<any>({
    term: "featured",
    order: "asc",
  });

  const history = useHistory();
  const shopId = useSelector((state: RootState) => state.shop.id);
  const loggedInUser = useSelector((state: RootState) => state.user);
  const { data, loading, refetch } = useQuery(CASKS, {
    variables: {
      shop: shopId,
      filters: filter,
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER,
    {
      variables: {
        shop: shopId,
      },
    },
  );

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (customerData && customerData.customer) {
      setCustomer(customerData);
    }
    return () => {
      setCustomer({});
    };
  }, [customerData, customerData?.customer, loggedInUser?.email]);

  useEffect(() => {
    if (history.location.state) {
      let filterState = { ...history.location.state };
      let searchState = { ...history.location.state };
      // @ts-ignore
      delete filterState?.searchSort;
      // @ts-ignore
      setSearchSort(searchState?.searchSort);
      setFilter(filterState);
    }
  }, [history.location?.state]);

  useEffect(() => {
    const savedFilter = sessionStorage.getItem("filter");
    if (savedFilter) {
      setFilter(JSON.parse(savedFilter));
    }
  }, []);

  useEffect(() => {
    if (!filters) {
      return;
    } else {
      const filteredResults = [...filters.split(",")];
      let timeframe: string[] = [];
      for (let i = 0; i < filteredResults.length; i++) {
        timeframe.push(
          String(reverseCaskshareStatus({ status: filteredResults[i] })),
        );
      }
      const separateCommaSeparatedStrings = (arr: string[]) => {
        let result: string[] = [];

        arr.forEach((item) => {
          if (item.includes(",")) {
            const separatedStrings: string[] = item.split(",");
            result = result.concat(separatedStrings);
          } else {
            result.push(item);
          }
        });

        return result;
      };
      setFilter({
        ...filter,
        shippingTimeframe: separateCommaSeparatedStrings(timeframe),
      });
    }
  }, [filters]);

  useEffect(() => {
    if (data && data?.casks) {
      const filteredResults = data.casks;
      let results;
      if (searchTerm) {
        const fuse = new Fuse(filteredResults, {
          isCaseSensitive: false,
          includeScore: true,
          shouldSort: true,
          minMatchCharLength: 1,
          threshold: 0.1,
          keys: [
            "barrelType",
            "barrelVariant",
            "title",
            "intro",
            "code",
            "distillery.name",
            "distillery.overview",
            "tags",
          ],
        });
        results = fuse.search(searchTerm).map((i) => i.item);
      } else {
        results = filteredResults;
      }
      const sortedResults = [...results];
      if (searchSort.term == "timeToBottling") {
        sortedResults.sort((a: any, b: any) => {
          if (!a.bottlingYear) {
            return 1 * (searchSort.order === "asc" ? 1 : -1);
          }
          if (!b.bottlingYear) {
            return -1 * (searchSort.order === "asc" ? 1 : -1);
          }

          const bottlingDateA = new Date(
            a.bottlingYear,
            a.bottlingMonth + 1,
            1,
          );
          const bottlingDateB = new Date(
            b.bottlingYear,
            b.bottlingMonth + 1,
            1,
          );

          return (
            (bottlingDateA.valueOf() - bottlingDateB.valueOf()) *
            (searchSort.order === "desc" ? -1 : 1)
          );
        });
      } else if (searchSort.term == "amount") {
        sortedResults.sort(
          (a: any, b: any) =>
            (a?.defaultVariant?.price[searchSort.term] -
              b?.defaultVariant?.price[searchSort.term]) *
            (searchSort.order === "desc" ? -1 : 1),
        );
      } else if (searchSort.term == "featured") {
        sortedResults
          .sort((a: any) =>
            a.status === "allocated" ? 1 : a.status === "" ? 1 : -1,
          )
          .sort((a: any, b: any) => {
            if (a.featuredOrdinal === b.featuredOrdinal) {
              return 0;
            }
            if (a.featuredOrdinal === null) {
              return 1;
            }
            if (b.featuredOrdinal === null) {
              return -1;
            }
            return a.featuredOrdinal < b.featuredOrdinal ? -1 : 1;
          });
      } else {
        sortedResults.sort(
          (a: any, b: any) =>
            (a[searchSort.term] - b[searchSort.term]) *
            (searchSort.order === "desc" ? -1 : 1),
        );
      }
      setNewSearchResults(sortedResults);
    }
    return () => {
      setNewSearchResults([]);
    };
  }, [data?.casks, searchSort, searchTerm, filter]);

  useEffect(() => {
    if (tag && tag !== undefined) {
      setFilter({
        ...filter,
        tag: tag,
      });
      // history.replace("/caskshares");
    }
  }, [tag]);

  useEffect(() => {
    if (
      filter.locations?.length >= 1 ||
      filter.distilleries?.length >= 1 ||
      filter.shippingTimeframe?.length >= 1 ||
      filter.spiritStyles?.length >= 1 ||
      filter.flavours?.length >= 1 ||
      filter.priceBrackets?.length >= 1 ||
      filter.bottlingAgeBrackets?.length >= 1 ||
      filter.tag?.length >= 1
    ) {
      setClearVisible(true);
    } else {
      setClearVisible(false);
    }
    return () => {
      setClearVisible(false);
    };
  }, [filter]);

  if (loading) {
    return <LoadingLogo />;
  }

  const caskDetails = async (caskToAdd: Cask) => {
    const caskId = caskToAdd.defaultVariant.slug
      ? caskToAdd.defaultVariant.slug
      : caskToAdd.defaultVariant.id.split("/")[4];
    sessionStorage.setItem("filter", JSON.stringify(filter));
    history.push(`/caskshares/${caskId}`, { ...filter, searchSort });
  };

  const clearFilters = () => {
    setFilter({
      ...filter,
      locations: [],
      distilleries: [],
      shippingTimeframe: [],
      spiritStyles: [],
      flavours: [],
      priceBrackets: [],
      bottlingAgeBrackets: [],
      tag: "",
    });
    setSearchSort({
      term: "featured",
      order: "asc",
    });
    sessionStorage.removeItem("filter");
    history.replace("/caskshares");
  };

  const asyncTimeout = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const handleSwitch = async (e: any) => {
    let value = e.target.value;
    await asyncTimeout(50);

    if (value === "cask" && filter.statuses.length) {
      setFilter({
        ...filter,
        statuses: [],
      });
    } else if (value === "cask" && !filter.statuses.length) {
      setFilter({
        ...filter,
        statuses: ["published", "comingsoon", "bottled"],
      });
    }

    if (value === "filter") {
      setFilterVisible(!filterVisible);
    }
  };

  const handleApplyFilter = () => {
    refetch();
    setOpenFilterPane(false);
  };

  const handleClearFilter = () => {
    clearFilters();
    refetch();
    setOpenFilterPane(false);
  };

  return (
    <>
      <Helmet>
        <title>{getMetaText("CASKS_PAGE", "title")}</title>
        <link rel="canonical" href={`${window.location.origin}/caskshares`} />
        <meta name="keywords" content={getMetaText("CASKS_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("CASKS_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("CASKS_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${window.location.origin}/caskshares`} />
        <meta
          property="og:title"
          content={getMetaText("CASKS_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("CASKS_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../assets/home-page/.v2/hero-carousel/shipping-now.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("CASKS_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("CASKS_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../assets/home-page/.v2/hero-carousel/shipping-now.jpg")
          }
        />
      </Helmet>

      <div
        className="breakout-head caskshares-page-head"
        style={{
          backgroundImage: `url(${require("../assets/home-page/.v2/hero-carousel/shipping-now.jpg")})`,
        }}
      >
        <Container style={{ paddingBottom: "0" }}>
          <Row as="section" className="reserve-mature-enjoy">
            {/* <Col lg={2} className="d-none d-lg-block" />
            <Col xs={12} sm={6} lg={4}>
              <h4>
                Reserve, <br /> mature, <br /> enjoy
              </h4>
            </Col>
            <Col sm={1} className="d-none d-sm-block" />
            <Col xs={12} sm={4} lg={2} className="d-flex align-items-end">
              <p>{getCopyText("CASKS_PAGE", "reserve-mature-enjoy")}</p>
            </Col> */}
            <div className="head-content">
              <h4>
                One of a kind<br />Single Cask<br />Whisky
              </h4>
              <p>{getCopyText("CASKS_PAGE", "reserve-mature-enjoy")}</p>
            </div>
          </Row>
          <Row className="head-search-row">
            <Col
              xs={12}
              md={6}
              lg={{ span: 5, offset: 3 }}
              className="head-search-area"
            >
              <CasksharesSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </Col>
            {width > breakpoint && (
              <Col className="switchesWrap" xs={12} md={6} lg={4}>
                <div className="switches">
                  <Form>
                    <Form.Check
                      type="switch"
                      className="custom-switch"
                      value="cask"
                      label="Only available bottles"
                      defaultChecked={filter?.statuses?.length}
                      onClick={handleSwitch}
                    />
                    <Form.Check
                      type="switch"
                      className="custom-switch"
                      value="filter"
                      label="Filters"
                      defaultChecked={filterVisible}
                      onClick={handleSwitch}
                    />
                  </Form>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="the-casks" as="section">
          <Col xs={12} className="the-casks-wrap pt-2 pb-2 d-block d-md-none">
            <div className="mobile-filter-wrap">
              <div className="cask-counter-wrap">
                <h4>Bottles</h4>
                {newSearchResults.length >= 1 ? (
                  <h4 className="cask-count"> ({newSearchResults.length})</h4>
                ) : null}
              </div>

              <Button
                variant="outline-dark"
                onClick={() => setOpenFilterPane(true)}
              >
                Filter
                <BsSliders />
              </Button>
            </div>
            <SlidingPane
              className="casks-page-filter-sliding-pane"
              overlayClassName="casks-page-filter-sliding-pane-overlay"
              isOpen={openFilterPane}
              from="left"
              width="90vw"
              title="Filter"
              onRequestClose={() => {
                setOpenFilterPane(false);
              }}
            >
              <Row className="sort-wrap">
                <Col xs={5} className="sort-area">
                  <CasksharesSorter
                    searchSort={searchSort}
                    setSearchSort={setSearchSort}
                  />
                </Col>
                <Col xs={7} className="sort-area">
                  <Form>
                    <span>Only available bottles</span>
                    <Form.Check
                      type="switch"
                      className="custom-switch"
                      value="cask"
                      label=""
                      defaultChecked={filter?.statuses?.length}
                      onClick={handleSwitch}
                    />
                  </Form>
                </Col>
              </Row>

              <Row>
                <Col xs={12} className="filter-area-sliding">
                  <CasksharesFilter
                    filter={filter}
                    setFilter={setFilter}
                    refetch={refetch}
                    clearVisible={clearVisible}
                    setSearchSort={setSearchSort}
                  />
                </Col>
              </Row>
              <Row className="mobile-filter-buttons-wrap">
                <Col xs={12} className="apply-button-area">
                  <Button
                    variant="outline-dark"
                    onClick={() => handleClearFilter()}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="outline-dark"
                    onClick={() => handleApplyFilter()}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </SlidingPane>
          </Col>

          <Col
            md={filterVisible ? 3 : 0}
            className={`${"filter-area-fixed d-none d-md-block"} ${
              !filterVisible && "hide-filters"
            }`}
          >
            <div className="filter-area-clear-wrapper">
              <h4>Bottles</h4>
              {newSearchResults.length >= 1 ? (
                <h4 className="cask-count"> ({newSearchResults.length})</h4>
              ) : null}
            </div>

            <CasksharesFilter
              filter={filter}
              setFilter={setFilter}
              refetch={refetch}
              width={width}
              breakpoint={breakpoint}
              clearVisible={clearVisible}
              setSearchSort={setSearchSort}
            />
          </Col>

          <Col md={filterVisible ? 9 : 12} className="casks-area">
            {width > breakpoint && (
              <Row className="search-area d-none d-sm-flex">
                <Col xs={12} md={6}>
                  <CasksharesSorter
                    searchSort={searchSort}
                    setSearchSort={setSearchSort}
                  />
                </Col>
                <Col xs={12} md={6} className="mt-2 text-left text-md-right">
                  {/* <OneShareEqOneBottle /> */}
                </Col>
              </Row>
            )}
            {zeroCasks != "" ? (
              <Row className="zeroCasksWrap">
                <Col>
                  <h4>{zeroCasks.split(".")[0]}</h4>
                  <p>{zeroCasks.split(".")[1]}...</p>
                </Col>
              </Row>
            ) : null}
            <Row className="">
              {!newSearchResults.length ? (
                <Col style={{ paddingTop: "25px" }}>
                  <h3>No casks found</h3>
                </Col>
              ) : (
                <>
                  {newSearchResults.map((cask: Cask, idx: any) => (
                    <Col
                      xs={12}
                      sm={6}
                      lg={filterVisible ? 4 : 3}
                      key={idx}
                      className={`${"search-cask-card-item"} ${
                        !filterVisible && "no-filter"
                      }`}
                    >
                      <CaskCard
                        onClick={caskDetails}
                        cask={cask}
                        customer={customer}
                      />
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CasksharesPage;
