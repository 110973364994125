import React from "react";
import { Image } from "react-bootstrap";
import { SelectWrapper } from "./SelectWrapper";
import styles from "./SelectWrapper.module.scss";
import { IMAGE_FALLBACKS } from "../../../../config";
import ProgressiveImg from "../../../.v2/general/ProgressiveImg";
import { Events } from "../../../../graphql/User";

type StateData = {
  image: string;
  seats: number;
  maxSeats: number;
  event?: Events;
  disableAdd?: boolean;
  disableSub?: boolean;
  availableSeatsMessage?: string;
};

type SelectProps = StateData & {
  updateState: (state: Partial<StateData>) => void;
};

export function SelectSeats({
  image,
  seats,
  event,
  maxSeats,
  disableAdd,
  disableSub,
  availableSeatsMessage,
  updateState,
}: SelectProps) {
  return (
    <SelectWrapper
      title={event?.title || "Select your seats"}
      imageComp={
        <ProgressiveImg
          src={image}
          placeholderSrc={IMAGE_FALLBACKS.caskImageCaskBottles}
          props={{ alt: "product-image" }}
        />
      }
      show={false}
    >
      {/* <small>Only 5 available per user</small> */}
      {event?.description && <p>{event.description}</p>}
      <div className={styles.qtyPicker}>
        <button
          type="button"
          className={""}
          disabled={seats === 1 || disableSub}
          onClick={() => seats > 1 && updateState({ seats: seats - 1 })}
        >
          -
        </button>
        <p className="">{seats}</p>
        <button
          className={""}
          type="button"
          disabled={seats === maxSeats || disableAdd}
          onClick={() => seats < maxSeats && updateState({ seats: seats + 1 })}
        >
          +
        </button>
      </div>
      {availableSeatsMessage && (
        <div className={styles.message}>
          <small>{availableSeatsMessage}</small>
        </div>
      )}
    </SelectWrapper>
  );
}
