import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdShoppingBasket } from "react-icons/md";
import { RootState } from "../../../state/rootReducer";
import { LineItem } from "../../../graphql/LineItem";
import { ReactComponent as BasketIcon } from "../../../assets/images/BasketIcon.svg";
import styles from "./CheckoutIcon.module.scss";

const CheckoutIcon = ({
  theme = "dark",
  setOpenShopDropdown,
  setOpenDiscoverDropdown,
  mobile,
  setMobileMenu,
}: {
  theme?: "light" | "dark" | null;
  setOpenShopDropdown?: any;
  setOpenDiscoverDropdown?: any;
  mobile?: boolean;
  setMobileMenu?: any;
}) => {
  const bundleChecker = (item: any) =>
    (item.bundleId === null && item.isABundle === false) ||
    (item.bundleId && item.isABundle === true);

  const checkout = useSelector((state: RootState) => state.checkout);
  const checkoutItems =
    checkout?.lineItems && checkout?.status != "completed"
      ? checkout.lineItems
          .filter((a: LineItem) => bundleChecker(a))
          .reduce(
            (acc: number, item: LineItem) =>
              (acc += item.productType === "gift" ? 1 : item.quantity),
            0,
          )
      : 0;
  return (
    <Link to="/basket">
      <div
        className={`${styles.checkoutIcon} ${mobile && styles.mobile}`}
        onClick={() => {
          setMobileMenu && setMobileMenu(false);
          setOpenShopDropdown && setOpenShopDropdown(false);
          setOpenDiscoverDropdown && setOpenDiscoverDropdown(false);
        }}
        // style={{
        //   borderColor: `${theme === "dark" ? "black" : "#ECE6DD"}`,
        //   color: theme === "dark" ? "black" : "#ECE6DD",
        // }}
      >
        {checkoutItems ? <span>{checkoutItems}</span> : <span>0</span>}
        <BasketIcon />
        {/* <MdShoppingBasket /> */}
      </div>
    </Link>
  );
};

export default CheckoutIcon;
