import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Col, Row, Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";
import { getMetaText } from "../../../AppText";
import { RootState } from "../../../state/rootReducer";
import {
  CASK,
  Cask,
  CASK_INIT,
  RECOMMENDED_CASKS_FROM_FLAVOUR_MAP,
} from "../../../graphql/Casks";

import { increaseCheckoutQuantity } from "../../../state/slices/checkoutSlice";
import { IMAGE_FALLBACKS } from "../../../config";
import Error from "../../../components/app/Error";
import styles from "./CasksharePage.module.scss";

import TagManager from "react-gtm-module";
import LoadingLogo from "../../../components/app/LoadingLogo";
import BottleImage from "../../../caskshare-v3/components/casks/caskCard/BottleImage";
import { getSpiritStyle } from "../../.././AppText";

import FeaturedBottlesComp from "../../components/app/FeaturedBottlesComp";
import FlavourComp from "../../components/casks/flavourComponent/FlavourComp";
import { formatCurrency } from "../../../utils/currency";
import caskshareStatus from "../../components/utils/caskshareStatus";
import CaskshareSteps from "./CaskshareSteps";
import AddToBasket from "./AddToBasket";
import { NetworkStatus } from "apollo-boost";
import { isNetworkRequestInFlight } from "apollo-client/core/networkStatus";

const CasksharePage = () => {
  // @ts-ignore
  const { caskId, locale } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  hotjar.initialize(2882523, 6);
  const descriptionRef: any = useRef<HTMLDivElement>();
  const artistDescRef: any = useRef<HTMLDivElement>();
  const distilleryDescRef: any = useRef<HTMLDivElement>();
  const formatDate = (d: Date) =>
    d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();

  const shop = useSelector((state: RootState) => state.shop);
  const currency = useSelector((state: RootState) => state.shop.currency);
  const checkout = useSelector((state: RootState) => state.checkout);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );
  const [price, setPrice] = useState<number>(0);
  const [user, setUser] = useState<ReactNode>({});
  const [cask, setCask] = useState<Cask>(CASK_INIT);
  const [readFullDesc, setReadFullDesc] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [shadowDistillery, setShadowDistillery] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [smBreakpoint] = useState(767);
  const [flavour, setFlavour] = useState<any>({
    flavourMap: {
      complexity: 0,
      fruitiness: 0,
      smokiness: 0,
      spice: 0,
      sweetness: 0,
    },
  });

  const defaultBottleImg = require("../../../assets/home-page/.v3/db-lg.png");
  const defaultBottleImgPlc = require("../../../assets/home-page/.v3/db-sm.png");
  const [bottleImg, setBottleImg] = useState(defaultBottleImg);
  const [bottleImgPlc, setBottleImgPlc] = useState(defaultBottleImgPlc);

  const caskVariables = (() => {
    if (caskId.indexOf("-")) {
      return { shop: shop.id, slug: caskId };
    } else {
      return { shop: shop.id, id: caskId };
    }
  })();

  const { data: caskData, loading: caskLoading, networkStatus } = useQuery(CASK, {
    variables: caskVariables,
  });

  const { data: recommendedCasks, loading: recommendedLoading } = useQuery(
    RECOMMENDED_CASKS_FROM_FLAVOUR_MAP,
    {
      variables: {
        shop: shop.id,
        flavourMap: flavour,
      },
      // notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    const abortController = new AbortController();
    const handleWindowResize = () => setWidth(window.innerWidth);
    if (!abortController.signal.aborted) {
      window.addEventListener("resize", handleWindowResize);
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      abortController.abort();
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const abortController = new AbortController();

    if (
      caskData &&
      !collectiveActive &&
      !caskData?.cask?.product &&
      !abortController.signal.aborted
    ) {
      setPrice(caskData?.cask.defaultVariant.price.amount);
    } else if (
      loggedInUser &&
      caskData?.cask &&
      collectiveActive &&
      !caskData?.cask?.product &&
      !abortController.signal.aborted
    ) {
      setPrice(caskData?.cask?.memberPrice);
    } else if (
      caskData &&
      caskData.cask.product &&
      !collectiveActive &&
      !abortController.signal.aborted
    ) {
      setPrice(caskData?.cask?.product?.defaultVariant?.price?.amount);
    } else if (
      caskData &&
      caskData.cask.product &&
      collectiveActive &&
      loggedInUser &&
      !abortController.signal.aborted
    ) {
      setPrice(caskData?.cask?.product?.defaultVariant?.memberPrice);
    }
    return () => {
      abortController.abort();
    };
  }, [loggedInUser, caskData?.cask, caskData?.cask?.product, collectiveActive]);

  useEffect(() => {
    const abortController = new AbortController();

    if (caskData?.cask && !abortController.signal.aborted) {
      setCask(caskData.cask);
      let newFlavour = caskData?.cask?.flavourMap;
      delete newFlavour.__typename;
      setFlavour(newFlavour);
    }
    return () => {};
  }, [caskData?.cask]);

  useEffect(() => {
    const abortController = new AbortController();

    if (caskData?.cask?.attachments && !abortController.signal.aborted) {
      const large = caskData?.cask.attachments.find(
        ({ role }: any) => role === "large",
      );
      large ? setBottleImg(large?.url) : setBottleImg(defaultBottleImg);
      const small = caskData.cask.attachments.find(
        ({ role }: any) => role === "small",
      );
      small ? setBottleImgPlc(small?.url) : setBottleImgPlc(defaultBottleImgPlc);
    }
    return () => {
      abortController.abort();
    };
  }, [caskData?.cask?.attachments]);

  useEffect(() => {
    const abortController = new AbortController();

    if (descriptionRef?.current && !abortController.signal.aborted) {
      if (
        // @ts-ignore
        descriptionRef?.current.offsetHeight <
        descriptionRef?.current.scrollHeight
      ) {
        setShowReadMore(true);
      } else {
        setShowReadMore(false);
      }
    }
    if (artistDescRef?.current && !abortController.signal.aborted) {
      if (
        // @ts-ignore
        artistDescRef?.current.offsetHeight <
        artistDescRef?.current.scrollHeight
      ) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    }
    if (distilleryDescRef?.current && !abortController.signal.aborted) {
      if (
        // @ts-ignore
        distilleryDescRef?.current.offsetHeight <
        distilleryDescRef?.current.scrollHeight
      ) {
        setShadowDistillery(true);
      } else {
        setShadowDistillery(false);
      }
    }
    return () => {
      abortController.abort();
    };
  }, [
    descriptionRef?.current,
    artistDescRef?.current,
    distilleryDescRef?.current,
  ]);

  // locale will disappear once the store is selected
  if (caskLoading || locale || isNetworkRequestInFlight(networkStatus)) {
    return <LoadingLogo />;
  }

  // if (!caskData || !caskData.cask) {
  //   return <Error message="Cask Not Found" />;
  // }

  // filter recommended casks to eliminate those that are already in the basket
  //     or are the same as the cask being purchased
  const lineItems = checkout?.lineItems || [];
  const lineItemIds = [
    ...lineItems?.map((i: any) => i.variant.id),
    cask?.defaultVariant?.id,
  ];
  const inCheckout = (c: Cask) =>
    lineItemIds.find((i: any) => i === c.defaultVariant.id);
  const allSimilarCasks: Cask[] = recommendedCasks?.recommendedCasks;
  const similarCasks = allSimilarCasks?.filter((c) => !inCheckout(c));

  // Google Analytics 4
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  var tagManagerArgs = {
    dataLayer: {
      event: "view_item",
      ecommerce: {
        currency: currency,
        items: [
          {
            item_id: `${cask?.distillery?.name}-${cask?.code}`,
            item_brand: cask?.seller?.title,
            item_name: cask?.title,
            price: price,
            item_category: "caskshare",
          },
        ],
      },
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  const getDistilleryImg1 = (cask: Cask) => {
    let images = cask.distillery.attachments.filter(
      (a) => a.role === "insert-1",
    );
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPageEnviron;
    return image;
  };

  const getDistilleryImg2 = (cask: Cask) => {
    let images = cask.distillery.attachments.filter(
      (a) => a.role === "insert-2",
    );
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };

  const getArtistPortrait = (cask: Cask) => {
    let images = cask.artist.attachments.filter((a) => a.role === "artist");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };

  const textSubstitutions = {
    "Cask-name": `${cask.distillery.name} - ${cask.title}`,
    "Cask-description": cask.shortDescription,
  };

  let bottledDate;
  if (cask.bottlingMonth && cask.bottlingYear) {
    bottledDate = formatDate(
      new Date(cask.bottlingYear, cask.bottlingMonth - 1, 1),
    );
  } else {
    bottledDate = "Bottled";
  }

  const distilleryDescriptionMarkup = (description?: string) => {
    return {
      __html: `
    ${
      description
        ? `<p class="preserve-new-lines">${description}</p>`
        : `<p style="display: none"></p>`
    }
    `,
    };
  };

  return (
    <>
      <Helmet>
        <title>{getMetaText("CASK_PAGE", "title", textSubstitutions)}</title>
        <link rel="canonical" href={`${window.location.origin}/caskshares/${caskId}`} />
        <meta
          name="keywords"
          content={getMetaText("CASK_PAGE", "keywords", textSubstitutions)}
        />
        <meta
          name="description"
          content={getMetaText("CASK_PAGE", "description", textSubstitutions)}
        />
        <meta
          name="author"
          content={getMetaText("CASK_PAGE", "author", textSubstitutions)}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${window.location.origin}/caskshares/${caskId}`} />
        <meta
          property="og:title"
          content={getMetaText("CASK_PAGE", "title", textSubstitutions)}
        />
        <meta
          property="og:description"
          content={getMetaText("CASK_PAGE", "description", textSubstitutions)}
        />
        {/* <meta
          property="og:image"
          content={window.location.origin + getBannerImage(cask)}
        /> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("CASK_PAGE", "title", textSubstitutions)}
        />
        <meta
          name="twitter:description"
          content={getMetaText("CASK_PAGE", "description", textSubstitutions)}
        />
        {/* <meta
          name="twitter:image"
          content={window.location.origin + getBannerImage(cask)}
        /> */}
      </Helmet>
      <Container fluid className={styles.casksharePageWrap}>
        <div className={styles.blackBG}>
          <Container className={styles.sectionWrap}>
            <Row className={styles.activeStepRow}>
              <Col className={styles.activeStep}>
                <h3>
                  {caskshareStatus({
                    status: cask.shippingTimeframe,
                    showNumber: true,
                  })}
                </h3>
                <Button
                  onClick={() =>
                    history.location.state
                      ? history.push("/caskshares", history.location.state)
                      : history.push("/caskshares")
                  }
                  className={styles.btnWhite}
                >
                  See all bottles
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.beigeBG}>
          <Container
            className={`${styles.sectionWrap} ${styles.caskDetailSection}`}
          >
            <Row className={`${styles.caskDetailRow} ${styles.whiteBG}`}>
              <Col xs={12} md={6} className={styles.imgWrap}>
                <BottleImage
                  src={bottleImg}
                  placeholderSrc={bottleImgPlc}
                  props={{ alt: cask?.title }}
                />
              </Col>
              <Col xs={12} md={6} className={styles.contentWrap}>
                <div className={styles.titleWrap}>
                  <div className={styles.distillery}>
                    <p>{cask.distillery.name}</p>
                  </div>
                  <h3 className={styles.title}>{cask.title}</h3>
                  <div className={styles.descriptionWrap}>
                    <p
                      ref={descriptionRef}
                      className={`${readFullDesc && styles.fullDescription} ${
                        styles.description
                      }`}
                    >
                      {cask.description}
                    </p>
                    {!readFullDesc && showReadMore ? (
                      <p
                        className={styles.more}
                        onClick={() => setReadFullDesc((prev) => !prev)}
                      >
                        read more
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className={styles.detailWrap}>
                  <div className={styles.caskInfoWrap}>
                    <div className={styles.inner}>
                      <p>Bottling strength</p>
                      {cask.isCaskStrength ? (
                        <p className={styles.info}>Cask strength</p>
                      ) : cask.bottlingStrength ? (
                        <p className={styles.info}>{cask.bottlingStrength}%</p>
                      ) : (
                        <p className={styles.info}>TBA..</p>
                      )}
                    </div>
                    <div className={styles.inner}>
                      <p>Sold by</p>
                      <p className={styles.info}>{cask.seller.title}</p>
                    </div>
                    <div className={styles.inner}>
                      <p>Cask code</p>
                      <p className={styles.info}>{cask.code}</p>
                    </div>
                    <div className={styles.inner}>
                      <p>Spirit style</p>
                      <p className={styles.info}>
                        {cask.spiritStyle && cask.spiritStyle !== "plain"
                          ? getSpiritStyle(cask.spiritStyle, "text")
                          : "TBA.."}
                      </p>
                    </div>
                    <div className={styles.inner}>
                      <p>Est. bottling date</p>
                      <p className={styles.info}>
                        {bottledDate ? bottledDate : "Bottled"}
                      </p>
                    </div>
                    {cask.totalBottles ? (
                      <div className={styles.inner}>
                        <p>Est. yield</p>
                        <p className={styles.info}>
                          {cask.totalBottles} Bottles
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>

                <AddToBasket cask={cask} />
              </Col>
              <Col className={styles.flavourWrap} xs={12}>
                {cask ? <FlavourComp caskData={cask} /> : null}
              </Col>
            </Row>
            {width > smBreakpoint ? (
              <Row className={styles.howItWorksRow}>
                <Col className={styles.inner}>
                  <h3>How it works</h3>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
        <div className={styles.whiteBG}>
          <Container className={styles.sectionWrap}>
            {width < Number(smBreakpoint + 1) && (
              <Row className={styles.howItWorksRow}>
                <Col className={styles.inner}>
                  <h3>How it works</h3>
                </Col>
              </Row>
            )}
            <Row
              className={`${styles.stepCardsRow} ${
                cask?.distillery?.physicalAddress && cask?.artist
                  ? ""
                  : !cask?.distillery?.physicalAddress && cask?.artist
                  ? styles.noMargin
                  : !cask?.distillery?.physicalAddress && !cask?.artist
                  ? ""
                  : ""
              }`}
            >
              {CaskshareSteps.filter(
                (step: any) =>
                  step.title !==
                  caskshareStatus({
                    status: cask.shippingTimeframe,
                    showNumber: true,
                  }),
              ).map((step) => (
                <Col xs={12} md={6} key={step.id} className={styles.stepCard}>
                  <div className={styles.imgWrap}>
                    <img src={step.url} alt="caskshare maturation step image" />
                  </div>
                  <div className={styles.contentWrap}>
                    <h4>{step.title}</h4>
                    <p>{step.text}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        {!caskData?.cask ? null : (
          <>
            {(cask?.distillery?.physicalAddress && cask?.distillery?.status === 'published') && (
              <div className={styles.beigeBG}>
                <Container className={styles.sectionWrap}>
                  <Row className={styles.distilleryRow}>
                    <Col xs={12} md={6} className={styles.imgsWrap}>
                      <div id="enjoyImgLeft" className={styles.topImg}>
                        <img
                          src={getDistilleryImg1(cask)}
                          alt={`${cask?.distillery?.name} distillery image`}
                        />
                      </div>
                      <div id="enjoyImgRight" className={styles.bottomImg}>
                        <img
                          src={getDistilleryImg2(cask)}
                          alt={`${cask?.distillery?.name} distillery image`}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6} className={styles.contentWrap}>
                      <div className={styles.inner}>
                        <h3>{cask?.distillery?.name}</h3>
                        {/* <p>{cask?.distillery?.overview}</p> */}
                        <div
                          className={`${shadowDistillery && styles.showShadow} ${
                            styles.description
                          }`}
                          ref={distilleryDescRef}
                          dangerouslySetInnerHTML={distilleryDescriptionMarkup(
                            cask?.distillery?.overview,
                          )}
                        />

                        <Button
                          onClick={() =>
                            history.push(`/distillery/${cask?.distillery?.slug}`)
                          }
                          className={styles.btnBlackOutline}
                        >
                          See whisky from this distillery
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
            {cask?.artist && (
              <div className={styles.whiteBG}>
                <Container className={styles.sectionWrap}>
                  <Row className={styles.artistRow}>
                    <Col xs={12} md={6} className={styles.contentWrap}>
                      <div className={styles.inner}>
                        <h3>{cask.artist.name}</h3>

                        <div
                          className={`${shadow && styles.showShadow} ${
                            styles.description
                          }`}
                          ref={artistDescRef}
                          dangerouslySetInnerHTML={distilleryDescriptionMarkup(
                            cask.artist.description,
                          )}
                        />

                        {width > smBreakpoint && (
                          <Button
                            onClick={() => window.open(`${cask.artist.linkUrl}`)}
                            className={styles.btnBlackOutline}
                          >
                            {cask.artist.linkText}
                          </Button>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6} className={styles.imgsWrap}>
                      <div id="enjoyImgLeft" className={styles.artistImg}>
                        <img
                          src={getArtistPortrait(cask)}
                          alt={`${cask?.distillery?.name} distillery image`}
                        />
                      </div>
                      <div id="enjoyImgRight" className={styles.bottleImg}>
                        <img src={bottleImg} alt={`${cask?.title} image`} />
                      </div>
                    </Col>

                    {width < Number(smBreakpoint + 1) && (
                      <Col className={styles.contentWrap}>
                        <Button
                          onClick={() => window.open(`${cask.artist.linkUrl}`)}
                          className={styles.btnBlackOutline}
                        >
                          {cask.artist.linkText}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Container>
              </div>
            )}

            <div className={`${styles.blackBG} ${styles.featuredComp}`}>
              <FeaturedBottlesComp
                caskData={similarCasks}
                user={user}
                casksLoading={recommendedLoading}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default CasksharePage;
