import { gql } from "apollo-boost";

export const COLLECTIVE_CHECKOUT = gql`
  mutation collectiveCheckout(
    $shop: ID
    $quantity: Int!
    $product: String!
    $stripePrice: String
    $successPath: String!
    $cancelPath: String!
    $referralCode: String
    $gifted: Boolean
    $giftData: GiftDataAttributes
    $addOnStripePrices: [String!]
  ) {
    collectiveCheckout(
      shop: $shop
      quantity: $quantity
      product: $product
      stripePrice: $stripePrice
      referralCode: $referralCode
      successPath: $successPath
      cancelPath: $cancelPath
      gifted: $gifted
      giftData: $giftData
      addOnStripePrices: $addOnStripePrices
    ) {
      sessionId
    }
  }
`;

export const DEPOSIT_AMOUNT_UPDATE = gql`
  mutation depositAmountUpdate($shop: ID, $quantity: Int!) {
    depositAmountUpdate(shop: $shop, quantity: $quantity) {
      result
    }
  }
`;

export const COLLECTIVE_CARD_UPDATE = gql`
  mutation collectiveCardUpdate(
    $shop: ID
    $successPath: String!
    $cancelPath: String!
  ) {
    collectiveCardUpdate(
      shop: $shop
      successPath: $successPath
      cancelPath: $cancelPath
    ) {
      sessionId
    }
  }
`;
