import React from 'react';

import styles from './TeamCard.module.scss';
import profileIcon from '../../assets/images/user.png';

// const caskDetails = async (caskToAdd: Cask) => {
//   const caskId = caskToAdd.defaultVariant.slug ? caskToAdd.defaultVariant.slug : caskToAdd.defaultVariant.id.split(‘/’)[4]
//   history.push(`/caskshares/${caskId}`)
// }

const TeamCard = ({ team }: { team: any }) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.imgWrapper}>
        <img
          src={team.attachments[0] ? team.attachments[0].url : profileIcon}
          alt={team.attachments[0] ? team.attachments[0].title : 'picture'}
        />
      </div>
      <div className={styles.nameWrapper}>
        <h4>{team.title}</h4>
      </div>
      <div className={styles.titleWrapper}>
        <p>{team.body}</p>
      </div>
      <div className={styles.typeWrapper}>
        <div className={styles.innerWrapper}>
          <small>favourite flavour</small>
          <small>{team.favouriteFlavour}</small>
        </div>
        <div className={styles.innerWrapper}>
          <small>favorite cask</small>
          <small>{team.cask.title}</small>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
