import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/rootReducer";
import { MdWarning } from "react-icons/md";
import { Form, Col, Button, Toast, Spinner } from "react-bootstrap";

import { Countries, UsStates } from "../CountryData";
import styles from "./AddressForm.module.scss";

const AddressForm = ({
  onSubmit,
  checkout,
  setFormOpen,
  addressError,
  isEditAddress,
  accountDetails,
  handleNewAddress,
  setAccountDetails,
  isProcessing = false,
  setAddressFormVisible,
  setShippingAddressVisible,
}: {
  onSubmit?: any;
  setFormOpen?: any;
  addressError?: any;
  checkout?: boolean;
  accountDetails?: any;
  handleNewAddress?: any;
  isProcessing?: boolean;
  isEditAddress?: boolean;
  setAccountDetails?: any;
  setAddressFormVisible?: any;
  setShippingAddressVisible?: any;
}) => {
  const user = useSelector((state: RootState) => state.user);
  useEffect(() => {
    let isSubscribed = true;
    if (!accountDetails || accountDetails.id !== "") {
      return;
    } else if (accountDetails && accountDetails.id === "" && isSubscribed) {
      setAccountDetails({
        ...accountDetails,
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        phone: "",
        email: user.email || "",
        city: "",
        stateCode: "",
        countryCode: "",
        postalCode: "",
        deliveryInstruction: "",
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [accountDetails.id]);
  return (
    <Form
      className={`${styles.formWrap} ${
        !checkout && !isEditAddress ? styles.formWrapAccount : ""
      }`}
      onSubmit={onSubmit}
    >
      {accountDetails.id !== "" ? (
        <h5>Edit Address</h5>
      ) : (
        <h5>Add New Address</h5>
      )}
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Control
            type="text"
            required
            placeholder={"First Name"}
            value={accountDetails.firstName || ""}
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                firstName: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            type="text"
            required
            placeholder={"Last Name"}
            value={accountDetails.lastName || ""}
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                lastName: e.target.value,
              });
            }}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Control
            type="text"
            placeholder={"Email Address"}
            required
            value={accountDetails.email}
            disabled={user?.email ? true : false}
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                email: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            type="text"
            value={accountDetails.phone || ""}
            placeholder={"Phone Number"}
            required
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                phone: e.target.value,
              });
            }}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Control
          type="text"
          required
          value={accountDetails.address1 || ""}
          placeholder={"Address line 1"}
          onChange={(e) => {
            setAccountDetails({
              ...accountDetails,
              address1: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          value={accountDetails.address2 || ""}
          placeholder={"Address line 2"}
          onChange={(e) => {
            setAccountDetails({
              ...accountDetails,
              address2: e.target.value,
            });
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          required
          value={accountDetails.city || ""}
          placeholder={"City"}
          type="text"
          onChange={(e) => {
            setAccountDetails({ ...accountDetails, city: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Control
            value={accountDetails.countryCode || ""}
            placeholder={"Country / Region"}
            as="select"
            required
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                countryCode: e.target.value,
              });
            }}
          >
            {Countries &&
              Countries.map((e, key) => {
                return (
                  <option key={key} value={e.Key}>
                    {e.Value}
                  </option>
                );
              })}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            value={accountDetails.postalCode || ""}
            placeholder={"Postcode"}
            type="text"
            required
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                postalCode: e.target.value,
              });
            }}
          />
        </Form.Group>
      </Form.Row>

      {accountDetails.countryCode === "US" ? (
        <Form.Group>
          <Form.Control
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                stateCode: e.target.value,
              });
            }}
            value={accountDetails.stateCode}
            as="select"
            required={accountDetails.countryCode === "US"}
          >
            {UsStates &&
              UsStates.map((e, key) => {
                return (
                  <option key={key} value={e.Key}>
                    {e.Value}
                  </option>
                );
              })}
          </Form.Control>
        </Form.Group>
      ) : null}

      <Form.Row>
        <Form.Group className={styles.textarea}>
          <Form.Control
            type="text"
            as="textarea"
            value={accountDetails.deliveryInstruction || ""}
            placeholder={"Delivery instructions (optional)"}
            onChange={(e) => {
              setAccountDetails({
                ...accountDetails,
                deliveryInstruction: e.target.value,
              });
            }}
          />
        </Form.Group>
      </Form.Row>

      {addressError?.length > 0 && (
        <Form.Row>
          <Col className={styles.errorMessage}>
            <MdWarning />
            <p>{addressError[0]}</p>
          </Col>
        </Form.Row>
      )}

      <div className={styles.btnWrap}>
        {!checkout ? (
          <Button
            className={styles.btnLight}
            onClick={() => setFormOpen(false)}
          >
            Cancel
          </Button>
        ) : (
          <Button className={styles.btnLight} onClick={handleNewAddress}>
            Cancel
          </Button>
        )}

        <Button
          className={styles.btnPrimary}
          disabled={isProcessing}
          type="submit"
        >
          {/* {accountDetails.id !== "" ? "Update" : "Add"} */}
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export default AddressForm;
