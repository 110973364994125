import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";

import AddOnModal from "../../../caskshare-v3/pages/collective-marketing/AddOnModal";
import ScrollToSection from "../../../components/app/ScrollToSection";
import { PRODUCTS, Product } from "../../../graphql/Products";
import { formatCurrency } from "../../../utils/currency";
import { RootState } from "../../../state/rootReducer";
import { memberBenefits } from "./giftingData";
import { getMetaText } from "../../../AppText";
import styles from "./GiftIdeas.module.scss";
import { LineItem } from "../../../graphql/LineItem";

const GiftIdeas = () => {
  hotjar.initialize(2882523, 6);
  const shop = useSelector((state: RootState) => state.shop);
  const checkoutItems = useSelector(
    (state: RootState) => state.checkout.lineItems,
  );
  const { hash } = useLocation();
  const history = useHistory();
  const [scroll, setScroll] = useState("");
  const [voucherGift, setVoucherGift] = useState<any>(null);
  const [collectiveGift, setCollectiveGift] = useState<any>(null);
  const [collectiveGiftBundles, setCollectiveGiftBundles] = useState<any>(null);
  const [collectiveGiftInBasket, SetCollectiveGiftInBasket] = useState(false);
  const [addOnModalOpen, setAddOnModalOpen] = useState(false);
  const currency = shop?.currency;

  const { data, loading } = useQuery(PRODUCTS, {
    variables: {
      shop: shop.id,
      type: "gift",
    },
  });

  useEffect(() => {
    if (data?.products && !loading) {
      const voucherGift = data?.products.find(
        (v: any) => v.title === "BarrelBank Voucher",
      );
      const collectiveGift = data?.products.find(
        (v: any) => v.title === "Collective Gift",
      );
      const collectiveGiftBundles = data?.products
        .filter((v: any) => v.isABundle)
        .sort(
          (a: LineItem, b: LineItem) =>
            a.defaultVariant.price.amount - b.defaultVariant.price.amount,
        );

      if (!voucherGift && !collectiveGift) {
        return;
      }
      setVoucherGift(voucherGift);
      setCollectiveGift(collectiveGift);
      setCollectiveGiftBundles(collectiveGiftBundles);
    }
    return () => {
      setVoucherGift(null);
      setCollectiveGift(null);
    };
  }, [data?.products]);

  useEffect(() => {
    if (checkoutItems) {
      if (
        checkoutItems.some(
          (item) =>
            item.productType === "gift" &&
            item.title.toLocaleLowerCase().includes("collective"),
        )
      ) {
        SetCollectiveGiftInBasket(true);
      }
    }
    return () => {
      SetCollectiveGiftInBasket(false);
    };
  }, [checkoutItems]);

  useEffect(() => {
    if (hash) {
      const el = document.getElementById(hash.split("#")[1]);
      if (el) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setScroll(el.id);
        }, 1000);
      }
    }
  }, [hash]);

  const handleAddON = (product?: Product) => {
    setAddOnModalOpen(false);
    if (product?.id) {
      history.push(
        product ? "/gifting/membership" : "/gifting",
        collectiveGift ? { product } : null,
      );
      return;
    }

    history.push(
      collectiveGift ? "/gifting/membership" : "/gifting",
      collectiveGift ? { collectiveGift } : null,
    );
  };

  const modalText =
    "Why not add a bottle or a tasting pack?\nThis is the ideal gift to kickstart the lucky adventurer’s Caskshare journey!";

  return (
    <>
      <ScrollToSection scroll={scroll} setScroll={setScroll} />
      <Helmet>
        <title>{getMetaText("GIFTING_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("GIFTING_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("GIFTING_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("GIFTING_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("GIFTING_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("GIFTING_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/images/gifting-hero.png")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("GIFTING_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("GIFTING_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/images/gifting-hero.png")
          }
        />
      </Helmet>
      <Container fluid className={styles.giftingOuterWrap}>
        {/* -------------------Hero-------------------- */}
        <Row className={styles.heroWrap}>
          <Col className={styles.content}>
            <div className={styles.header}>
              <h3>
                Gift whisky and <br /> share the love
              </h3>
            </div>
            <div className={styles.note}>
              <p>
                From reserving a Caskshare for someone special to a year's
                membership in our club – give the gift of rare and unique
                whisky.
              </p>
            </div>
            <div className={styles.links}>
              {/* <a
                onClick={() => setScroll("caskshares")}
                className={styles.link}
              >
                Caskshares
                <i
                  style={{ width: "30px" }}
                  className="fa fa-long-arrow-down fa-fw"
                  aria-hidden="true"
                />
              </a> */}
              <a
                onClick={() => setScroll("membership")}
                className={styles.link}
              >
                Collective membership
                <i
                  style={{ width: "30px" }}
                  className="fa fa-long-arrow-down fa-fw"
                  aria-hidden="true"
                />
              </a>
              <a onClick={() => setScroll("vouchers")} className={styles.link}>
                Vouchers
                <i
                  style={{ width: "30px" }}
                  className="fa fa-long-arrow-down fa-fw"
                  aria-hidden="true"
                />
              </a>
            </div>
          </Col>
        </Row>
        {/* -------------------Voucher-------------------- */}
        {/* <Row id="vouchers" className={styles.voucherWrap}>
          <Col className={styles.imgWrap}>
            <div className={styles.top}>
              <img
                src={require("../../../assets/gifting-page/giftingTop.jpg")}
                alt="caskshare marketing image"
              />
            </div>
            <div className={styles.bottom}>
              <img
                src={require("../../../assets/cask-page/bottle-sample.jpg")}
                alt="caskshare marketing image"
              />
            </div>
          </Col>
          <Col xs={12} md={5} className={styles.contentWrap}>
            <div className={styles.header}>
              <h4>Gift a Voucher</h4>
            </div>
            <div className={styles.content}>
              <p>
                Let your gift recipient make their own choice. With our
                'BarrelBank' vouchers we will load your gift recipient's account
                with the value of your gift voucher. Not only that, we will add
                10% reward to be spent with us. It doesn't matter if the gift
                recipient doesn't have an account with us, we will take care of
                guiding them through this.
              </p>
              <Button
                className={styles.btnSecondary}
                as={Link}
                to={{
                  pathname: voucherGift ? "/gifting/voucher" : "/gifting",
                  state: voucherGift ? { voucherGift } : null,
                }}
              >
                Gift a voucher
              </Button>
            </div>
          </Col>
        </Row> */}
        {/* -------------------Membership-------------------- */}
        <Container className={styles.membershipWrap}>
          <Row id="membership" className={styles.headerRow}>
            <Col className={styles.content}>
              <h4>Gift a One-Year Membership</h4>
              <p>
                Gift a one-year Caskshare Collective Experience to let that
                special person in your life to take advantage of access to
                special releases, exclusive whisky tasting events, discounts and
                more...
              </p>
            </Col>
          </Row>
          <Row className={styles.cardsRow}>
            <Col xs={12} className={styles.cardWrap}>
              <div className={styles.card}>
                <img
                  src={require("../../../assets/gifting-page/samplePack2.jpg")}
                  alt="caskshare gifting product image"
                />
                <div className={styles.title}>
                  <h4>Collective</h4>
                  <small>for pioneers and collectors</small>
                </div>
                <div className={styles.benefits}>
                  {currency &&
                    memberBenefits()?.map((b: any, i: any) => (
                      <div key={i} className={styles.benefit}>
                        <span />
                        <p>{b}</p>
                      </div>
                    ))}
                </div>
                <div className={styles.price}>
                  <p>
                    {collectiveGift?.defaultVariant?.price?.amount && currency
                      ? formatCurrency(currency, 0)?.format(
                          collectiveGift?.defaultVariant?.price?.amount,
                        )
                      : null}
                  </p>
                </div>
                <Button
                  className={styles.btnPrimary}
                  type="button"
                  onClick={() =>
                    collectiveGiftBundles.length > 0
                      ? setAddOnModalOpen(true)
                      : handleAddON()
                  }
                  disabled={collectiveGiftInBasket}
                >
                  Gift Collective
                </Button>
                {collectiveGiftInBasket && (
                  <p className={styles.collectiveGiftNotice}>
                    You already have a Collective gift in your basket
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <small style={{ fontWeight: 300 }}>UK only*</small>
            </Col>
          </Row>

          {/* -------------------Caskshares-------------------- */}
          {false ? (
            <Row id="caskshares" className={styles.caskshareRow}>
              <Col className={styles.contentWrap}>
                <div className={styles.header}>
                  <h4>Gift a Caskshare</h4>
                </div>
                <div className={styles.inner}>
                  <p>
                    One share equals one bottle. Gift a Caskshare of a maturing
                    cask and we'll ship out the bottle to that special someone
                    when it's ready. With a range of maturation dates and cask
                    types, we've got the perfect gift all wrapped up.
                  </p>
                  <div className={styles.giftIcons}>
                    <div className={styles.inner}>
                      <img
                        src={require("../../../assets/gifting-page/iconCask.svg")}
                        alt="caskshare gift icon"
                      />
                      <p>Buy a share of single cask whisky</p>
                    </div>
                    <div className={styles.inner}>
                      <img
                        src={require("../../../assets/gifting-page/iconGift.svg")}
                        alt="caskshare gift icon"
                      />
                      <p>Gift any of your shares via Your Account</p>
                    </div>
                  </div>
                  <Button
                    as={Link}
                    to="/my-account"
                    className={styles.btnSecondary}
                  >
                    Browse your caskshares
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={5} className={styles.imgWrap}>
                <div className={styles.img}>
                  <img
                    src={require("../../../assets/gifting-page/giftingCasks.jpg")}
                    alt="caskshare marketing image"
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          {/* -------------------Voucher-------------------- */}
          <Row id="voucher" className={styles.caskshareRow}>
            {/* <Col className={styles.contentWrap}>
              <div className={styles.header}>
                <h4>Gift a Caskshare</h4>
              </div>
              <div className={styles.inner}>
                <p>
                  One share equals one bottle. Gift a Caskshare of a maturing
                  cask and we'll ship out the bottle to that special someone
                  when it's ready. With a range of maturation dates and cask
                  types, we've got the perfect gift all wrapped up.
                </p>
                <div className={styles.giftIcons}>
                  <div className={styles.inner}>
                    <img
                      src={require("../../../assets/gifting-page/iconCask.svg")}
                      alt="caskshare gift icon"
                    />
                    <p>Buy a share of single cask whisky</p>
                  </div>
                  <div className={styles.inner}>
                    <img
                      src={require("../../../assets/gifting-page/iconGift.svg")}
                      alt="caskshare gift icon"
                    />
                    <p>Gift any of your shares via Your Account</p>
                  </div>
                </div>
                <Button
                  as={Link}
                  to="/my-account"
                  className={styles.btnSecondary}
                >
                  Browse your caskshares
                </Button>
              </div>
            </Col> */}
            <Col className={styles.contentWrap}>
              <div className={styles.header}>
                <h4>Gift a Voucher</h4>
              </div>
              <div className={styles.content}>
                <p>
                  Let your gift recipient make their own choice. With our
                  'BarrelBank' vouchers we will load your gift recipient's
                  account with the value of your gift voucher. Not only that, we
                  will add 10% reward to be spent with us. It doesn't matter if
                  the gift recipient doesn't have an account with us, we will
                  take care of guiding them through this.
                </p>
                <Button
                  className={styles.btnSecondary}
                  as={Link}
                  to={{
                    pathname: voucherGift ? "/gifting/voucher" : "/gifting",
                    state: voucherGift ? { voucherGift } : null,
                  }}
                >
                  Gift a voucher
                </Button>
              </div>
            </Col>
            <Col xs={12} md={5} className={styles.imgWrap}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/gifting-page/giftingVoucherTop.jpg")}
                  alt="caskshare marketing image"
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* -------------------Footer Block-------------------- */}
        <Row className={styles.startCollection}>
          <Col xs={12} className={styles.inner}>
            <span />
            <h2>Need help?</h2>
            <h5>get in touch...</h5>
            <div className={styles.contactDetails}>
              <FaEnvelope />{" "}
              <a href="mailto:contact@caskshare.com">contact@caskshare.com</a>
            </div>
            <div className={styles.contactDetails}>
              <FaPhoneAlt /> <a href="tel:00441313812275">+44-131-381-2275</a>
            </div>
          </Col>
        </Row>
      </Container>

      {addOnModalOpen && collectiveGiftBundles.length > 0 ? (
        <div className={styles.addOnModalWrap}>
          <AddOnModal
            onClick={handleAddON}
            addOns={collectiveGiftBundles}
            msg={modalText}
            itemPriceLabel="Total cost"
          />
        </div>
      ) : null}
    </>
  );
};

export default GiftIdeas;
