import * as React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const BuyButtonComponent = ({
  buyButtonId,
  publishableKey
}: {
  buyButtonId: string;
  publishableKey: string;
}) => {

  React.useEffect(() => {
    const script = document.createElement("script");

    script.async = true;
    script.type = "text/javascript";
    script.src =
      "https://js.stripe.com/v3/buy-button.js";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  })

  return (
    <stripe-buy-button
      buy-button-id={buyButtonId}
      publishable-key={publishableKey}
    />
  );
};

export default BuyButtonComponent;
