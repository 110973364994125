import { gql } from "apollo-boost";
import { Attachment } from "./Distillery";

export interface Shop {
  id: string;
  currency: string;
  market: string;
  features: [
    {
      feature: string;
      active: boolean;
    }
  ];
  presentmentCurrencies: string[];
  stripePublishableKey: string;
  mollieProfileId: string;
  isCurrentUserLocation: boolean;
  affiliateClubs: {
    id: string;
    name: string;
  };
  shippingMonthIncrement: number;
  heroItems: [
    {
      strapline1: string;
      strapline2: string;
      buttonText: string;
      buttonUrl: string;
      page: string;
      ordinal: number;
      attachments: Attachment[];
    },
  ];
  howItWorksSections: [
    {
      id: string;
      navButtonText: string;
      title: string;
      description: string;
      buttonText: string;
      buttonUrl: string;
      nextStepId: string;
      nextStepText: string;
      attachments: Attachment[];
    }
  ];
}

export interface CarouselSlide {
  strapline1: string;
  strapline2: string;
  buttonText: string;
  buttonUrl: string | any;
  page: string;
  ordinal: number;
  attachments: Attachment[];
}
export interface howItWorksSection {
  id: string;
  navButtonText: string;
  title: string;
  description: string;
  buttonText: string;
  buttonUrl: string;
  nextStepId: string;
  nextStepText: string;
  attachments: Attachment[];
}

const SHOP_FRAGMENT = gql`
  fragment StoreFragment on Shop {
    id
    currency
    presentmentCurrencies
    market
    stripePublishableKey
    mollieProfileId
    isCurrentUserLocation
    affiliateClubs {
      id
      name
    }
    features {
      feature
      active
    }
    shippingMonthIncrement
    heroItems {
      strapline1
      strapline2
      buttonText
      buttonUrl
      page
      ordinal
      attachments {
        role
        title
        url
      }
    }
    howItWorksSections {
      id
      navButtonText
      title
      description
      buttonText
      buttonUrl
      nextStepId
      nextStepText
      attachments {
        role
        title
        url
      }
    }
  }
`;
export const SHOPS = gql`
  query Shops {
    shops {
      ...StoreFragment
    }
  }
  ${SHOP_FRAGMENT}
`;

export const DEFAULT_SHOP = gql`
  query {
    defaultShop {
      ...StoreFragment
    }
  }
  ${SHOP_FRAGMENT}
`;
