import React, { useEffect, useState } from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Closed</span>
    </div>
  );
};

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  const [minutesChanged, setMinutesChanged] = useState(false);
  useEffect(() => {
    setMinutesChanged(true);
    setTimeout(() => {
      setMinutesChanged(false);
    }, 1000);
  }, [minutes]);
  return (
    <div className="countdown-wrap">
      {days >= 1 && (
        <>
          <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 1} />
          <p>:</p>
        </>
      )}
      {days === 0 && hours < 1 ? null : (
        <>
          <DateTimeDisplay value={hours} type={"Hours"} isDanger={hours <= 1} />
          <p>:</p>
        </>
      )}
      <DateTimeDisplay
        props={{ minutesChanged: minutesChanged }}
        value={minutes}
        type={"Mins"}
        isDanger={false}
      />
      <p>:</p>
      <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
    </div>
  );
};

const CountdownTimer = ({
  targetDate,
  setRunRefetch,
}: {
  targetDate: number;
  setRunRefetch?: (status: boolean) => void;
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  useEffect(() => {
    if (days + hours + minutes + seconds === 0 && setRunRefetch) {
      setRunRefetch(true);
      setTimeout(() => {
        setRunRefetch(false);
      }, 1000);
    }
  }, [days, hours, minutes, seconds]);

  if (days + hours + minutes + seconds <= 0) {
    // return <ExpiredNotice />;
    return <></>;
  } else {
    return (
      <div className="countdown-wrapper">
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      </div>
    );
  }
};

export default CountdownTimer;
