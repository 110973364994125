import React from "react";
import { useTranslation } from "react-i18next";
import CaskImage from "../../../components/casks/.v2/CaskImage";
import styles from "./GiftingPage.module.scss";

const elideStr = (str: string, len: number) =>
  str != null && str.length > len ? str.slice(0, len) + "..." : str;

const GiftedCask = ({ reservation }: { reservation?: any }) => {
  const { t } = useTranslation();

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  if (!reservation || !reservation?.cask) {
    return <></>;
  }
  return (
    <div className={styles.caskWrap}>
      <div className={styles.imgWrap}>
        <CaskImage
          cask={reservation.cask}
          reservation={reservation}
          hideCount
        />
      </div>
      <h5>{t(elideStr(reservation.cask.title, 45))}</h5>
      {/* <p>{reservation.cask.distillery.name} Distillery</p> */}
    </div>
  );
};

export default GiftedCask;
