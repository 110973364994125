import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Credentials = {
  client: string;
  accessToken: string;
  tokenType: string;
};

interface UserReducer {
  email: string;
  firstName: string;
  collectiveActive: boolean;
  credentials: Partial<Credentials>;
}

const initialState: Partial<UserReducer> = {
  email: "",
  firstName: "",
  collectiveActive: false,
  credentials: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      const {
        payload: { authenticatable, credentials },
      } = action;
      state.email = authenticatable.email;
      state.firstName = authenticatable.firstName;
      state.collectiveActive = authenticatable.collectiveActive;
      state.credentials = credentials;
    },
    resetUser(state) {
      state.email = initialState.email;
      state.firstName = initialState.firstName;
      state.collectiveActive = initialState.collectiveActive;
      state.credentials = initialState.credentials;
    },
    setCollective(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.collectiveActive = payload;
    },
  },
});

export const { setUser, resetUser, setCollective } = userSlice.actions;

export default userSlice.reducer;
