import { combineReducers } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import checkoutReducer from './slices/checkoutSlice'
import shopReducer from './slices/shopSlice'

const rootReducer = combineReducers({
  user:     userReducer,
  shop:     shopReducer,
  checkout: checkoutReducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
