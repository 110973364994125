import { gql } from "apollo-boost";

export type Attachment = {
  role: string;
  title: string;
  url: string;
};

export interface Seller {
  id: string;
  title: string;
  slug: string;
  description: string;
  attachments: Attachment[];
  signatureSeries: boolean;
  curatorName: string;
  signatureCasks: string;
  earlyLife: string;
  career: string;
  legacy: string;
  awards: string;
  videoUrl: string;
}

const SELLER_FRAGMENT = gql`
  fragment SellerFragment on Seller {
    id
    title
    slug
    description
    attachments {
      role
      title
      url
    }
    earlyLife
    career
    legacy
    awards
    signatureSeries
    signatureCasks
    curatorName
    videoUrl
  }
`;

export const SELLER = gql`
  query seller($shop: ID, $slug: String!) {
    seller(shop: $shop, slug: $slug) {
      ...SellerFragment
    }
  }
  ${SELLER_FRAGMENT}
`;
