import React from "react";

import styles from "./Timeline.module.scss";

// const caskDetails = async (caskToAdd: Cask) => {
//   const caskId = caskToAdd.defaultVariant.slug ? caskToAdd.defaultVariant.slug : caskToAdd.defaultVariant.id.split(‘/’)[4]
//   history.push(`/caskshares/${caskId}`)
// }

function getMonthName(month: any) {
	const d = new Date();
	d.setMonth(month - 1);
	const monthName = d.toLocaleString("default", { month: "short" });
	return monthName;
}

const Timeline = ({ item }: { item: any }) => {
	return (
		<div className={styles.timelineItem}>
			<small>{getMonthName(item.publishedDate.slice(5, 7))}</small>
			<h3>{item.publishedDate.slice(0, 4)}</h3>
			<p>{item.body}</p>
			<p>{item.bodyTwo}</p>
		</div>
	);
};

export default Timeline;
