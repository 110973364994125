import React, { useState } from "react";
import { MdError } from "react-icons/md";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";

import { setUser } from "../state/slices/userSlice";
import { RESET_PASSWORD } from "../graphql/User";
import { LOGIN, AuthPayload } from "../graphql/Auth";
import { RootState } from "../state/rootReducer";
import styles from "./PasswordResetPage.module.scss";

const PasswordResetPage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const token = new URLSearchParams(location.search).get(
    "reset_password_token",
  );
  const shopId = useSelector((state: RootState) => state.shop.id);
  const [customerLogin] = useMutation<{ customerLogin: AuthPayload }, any>(
    LOGIN,
  );
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    variables: {
      shop: shopId,
      password,
      passwordConfirmation,
      resetPasswordToken: token,
    },
  });

  if (!token) {
    history.replace("/404");
  }

  // FIXME - Only render this component if reset_password_token URL param is set

  const formSubmission = async (e: any) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const { data, errors } = await resetPassword();
      if (
        !errors &&
        data?.customerUpdatePasswordWithToken?.authenticatable?.email
      ) {
        const email =
          data?.customerUpdatePasswordWithToken?.authenticatable?.email;
        const { data: loginData, errors: loginErrors } = await customerLogin({
          variables: {
            shop: shopId,
            email,
            password,
          },
        });
        if (!loginErrors) {
          dispatch(setUser(loginData?.customerLogin));
          history.replace("/my-account");
        }
      }
      if (errors) {
        setError(errors[0].message);

        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (errors) {
      console.error(errors);
    }
    setIsProcessing(false);
  };

  return (
    <Row>
      <Col
        style={{
          minHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col md={6}>
          <h4>Reset your password</h4>
          <Form onSubmit={formSubmission}>
            <Form.Group>
              <Form.Label>New password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="New password"
                required
                minLength={6}
                maxLength={40}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="Confirm password"
                required
                minLength={6}
                maxLength={40}
                value={passwordConfirmation}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                }}
              />
            </Form.Group>
            {error ? (
              <Form.Row className={`${styles.resetPassword}`}>
                <MdError />
                <span>{error}</span>
              </Form.Row>
            ) : null}
            <Form.Group>
              <Button
                type="submit"
                disabled={
                  loading || password !== passwordConfirmation || isProcessing
                }
                block
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </>
                ) : (
                  "Reset my password"
                )}
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    </Row>
  );
};

export default PasswordResetPage;
