import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "../QuizPage.module.scss";

const QuestionsPage = ({
  quizAnswers,
  setQuizAnswers,
  quizzes,
  setPage,
}: {
  quizAnswers?: any;
  setQuizAnswers?: any;
  quizzes?: any;
  setPage?: any;
}) => {
  const { t } = useTranslation();

  const [displayQuestion, setDisplayQuestion] = useState(1);
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [questions, setQuestions] = useState<any>([]);

  const handleAnswerSelection = async (
    quiz?: any,
    answer?: any,
    answerId?: any,
  ) => {
    // console.log(quiz);
    setCurrentQuestionId(quiz.id);
    setCurrentAnswer(answer);
    setQuestions([
      ...questions,
      {
        id: quiz.id,
        answer: {
          id: answerId,
        },
      },
    ]);
  };

  const handleSubmit = () => {
    setQuizAnswers({
      id: "1",
      questions: questions,
    });
    setPage(2);
  };

  const handlePreviousQuestion = async (quizId?: any) => {
    setCurrentQuestionId("");
    setCurrentAnswer("");
    setDisplayQuestion(Number(quizId) - 1);
  };

  const handleNextQuestion = async (quizId?: any) => {
    setCurrentQuestionId("");
    setCurrentAnswer("");
    setDisplayQuestion(Number(quizId) + 1);
  };

  // const asyncTimeout = (ms: number) => {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, ms);
  //   });
  // };

  return (
    <>
      {quizzes?.map((quiz: any) =>
        displayQuestion === Number(quiz.id) ? (
          <div key={quiz.id} className={styles.questionsWrap}>
            <h4>{quiz.title}</h4>
            <div className={styles.questions}>
              {quiz?.quizAnswers?.map((answer: any) => (
                <div key={answer.id} className={styles.radioWrap}>
                  <input
                    type="radio"
                    id={answer.id}
                    value={answer.title}
                    required
                    checked={
                      currentQuestionId === quiz.id &&
                      currentAnswer === answer.title
                        ? true
                        : false
                    }
                    className={`${styles.input} ${styles.activeInput}`}
                    onChange={(e: any) =>
                      handleAnswerSelection(
                        quiz,
                        e.currentTarget.value,
                        answer.id,
                      )
                    }
                  />
                  <label
                    className={`${styles.label} ${
                      currentAnswer === answer.title && styles.activeLabel
                    }`}
                    htmlFor={answer.id}
                  >
                    {t(answer.title)}
                  </label>
                </div>
              ))}
            </div>
            <div className={styles.buttonWrap}>
              <button
                className={styles.btnPrimary}
                disabled={displayQuestion === 1}
                onClick={() => handlePreviousQuestion(quiz.id)}
              >
                Previous
              </button>
              {displayQuestion !== quizzes.length ? (
                <button
                  className={styles.btnPrimary}
                  disabled={
                    !currentAnswer || displayQuestion === quizzes.length
                  }
                  onClick={() => handleNextQuestion(quiz.id)}
                >
                  Next
                </button>
              ) : (
                <button
                  className={styles.btnPrimary}
                  disabled={!currentAnswer}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        ) : null,
      )}
    </>
  );
};

export default QuestionsPage;
