import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const FacebookTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const options = {
    autoConfig: true,
    debug: false,
  };

  useEffect(() => {
    if (initialized) {
      return;
    }

    if (
      !window.location.href.includes("localhost") &&
      !window.location.href.includes("staging")
    ) {
      ReactPixel.init("504016554005978", undefined, options);
    }

    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactPixel.pageView();
    }
  }, [initialized, location]);
};

export default FacebookTracking;
