import React from "react";
import { useQuery } from "react-apollo";
import { GET_ABOUT_ENTRIES } from "../../graphql/AboutEntry";

import styles from "../../pages/AboutPage/AboutPage.module.scss";
import LoadingView from "../../components/app/LoadingView";
import defaultLogo from "../../assets/images/Scotsman_logo.png";
import LoadingLogo from "../app/LoadingLogo";

const Press = () => {
  const { data, loading } = useQuery(GET_ABOUT_ENTRIES, {
    variables: {
      entryType: "press_entry_image",
    },
  });
  if (loading) {
    return <LoadingLogo />;
  }
  return data.aboutEntries.slice(0, 6).map((item: any) => (
    <div className={`${styles.pressItem} ${"pressItem"}`} key={item.id}>
      <img
        src={item.attachments[0] ? item.attachments[0].url : defaultLogo}
        alt={item.attachments[0] ? item.attachments[0].title : "picture"}
      />
    </div>
  ));
};

export default Press;
