import React from "react";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TeamCard from "../../components/team/TeamCard";
import styles from "./TeamMobile.module.scss";

const TeamMobile = ({ data }: { data: any }) => {
  var settings = {
    speed: 500,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className={styles.slickWrapper}>
      <Slider {...settings}>
        {data?.aboutEntries
          ?.sort((a: any, b: any) => {
            if (a.ordinal === b.ordinal) {
              return 0;
            }
            if (a.ordinal === null) {
              return 1;
            }
            if (b.ordinal === null) {
              return -1;
            }
            return a.ordinal < b.ordinal ? -1 : 1;
          })
          ?.map((team: any) => (
            <TeamCard team={team} key={team.id} />
          ))}
      </Slider>
    </div>
  );
};

export default TeamMobile;
