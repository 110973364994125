import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import { ImPlay3 } from "react-icons/im";
import Color, { useColor } from "color-thief-react";

import { getMetaText } from "../../AppText";

import { useQuery } from "react-apollo";
import LoadingView from "../../components/app/LoadingView";
import { RootState } from "../../state/rootReducer";
import { SELLER, Seller } from "../../graphql/Seller";
import { SELLER_CASKS, Cask } from "../../graphql/Casks";
import { IMAGE_FALLBACKS } from "../../config";

import styles from "./SignatureSeries.module.scss";

import SignUpForNew from "../../components/app/SignUpForNew/SignUpForNew";
import BioSlider from "../../components/signature-series/BioSlider";
import SignatureCard from "../../components/signature-series/SignatureCard";

const SignatureSeries = () => {
  // @ts-ignore
  const { slug } = useParams();
  const history = useHistory();
  const shopId = useSelector((state: RootState) => state.shop.id);
  const title = (seller: Seller) => {
    return seller.curatorName ? seller.curatorName : seller.title;
  };
  const [player, setPlayer] = useState(false);

  const { data: sellerData, loading: sellerLoading } = useQuery(SELLER, {
    variables: {
      shop: shopId,
      slug,
    },
  });

  const { data: casksData, loading: casksLoading } = useQuery(SELLER_CASKS, {
    variables: {
      shop: shopId,
      sellerId: sellerData?.seller.id,
    },
  });

  if (sellerLoading || casksLoading) {
    return <LoadingView />;
  }

  const seller = sellerData?.seller;
  const distilleryCasks = casksData?.casks;

  const caskDetails = async (caskToAdd: Cask) => {
    const caskId = caskToAdd.defaultVariant.slug
      ? caskToAdd.defaultVariant.slug
      : caskToAdd.defaultVariant.id.split("/")[4];
    history.push(`/caskshares/${caskId}`);
  };

  const getProfileImage = (seller: Seller) => {
    let images = seller.attachments.filter((a) => a.role === "profile-pic");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPageEnviron;
    return image;
  };
  const profilePicture = getProfileImage(seller);

  const getBannerImage = (seller: Seller) => {
    let images = seller.attachments.filter((a) => a.role === "banner");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPageEnviron;
    return image;
  };

  const getInsert1Image = (seller: Seller) => {
    let images = seller.attachments.filter((a) => a.role === "insert-1");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };

  const getInsert2Image = (seller: Seller) => {
    let images = seller.attachments.filter((a) => a.role === "insert-2");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPageInterior;
    return image;
  };

  const textSubstitutions = {
    "Seller-title": title(seller),
    "Seller-description": seller.description,
  };

  return (
    <>
      <Helmet>
        <title>
          {getMetaText("SIGNATURE_SERIES_PAGE", "title", textSubstitutions)}
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText(
            "SIGNATURE_SERIES_PAGE",
            "keywords",
            textSubstitutions,
          )}
        />
        <meta
          name="description"
          content={getMetaText(
            "SIGNATURE_SERIES_PAGE",
            "description",
            textSubstitutions,
          )}
        />
        <meta
          name="author"
          content={getMetaText(
            "SIGNATURE_SERIES_PAGE",
            "author",
            textSubstitutions,
          )}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText(
            "SIGNATURE_SERIES_PAGE",
            "title",
            textSubstitutions,
          )}
        />
        <meta
          property="og:description"
          content={getMetaText(
            "SIGNATURE_SERIES_PAGE",
            "description",
            textSubstitutions,
          )}
        />
        <meta
          property="og:image"
          content={window.location.origin + getBannerImage(seller)}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("SIGNATURE_SERIES_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("SIGNATURE_SERIES_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={window.location.origin + getBannerImage(seller)}
        />
      </Helmet>
      <div
        className={`${styles.heroImage} ${"heroImage"}`}
        style={{
          backgroundImage: `url(${getBannerImage(seller)})`,
        }}
      >
        <Row>
          <Col xs={12} className={`${""} ${"text-center"}`}>
            <div className={styles.headerWrap}>
              <h3>caskshare</h3>
              <div className={styles.ss}>
                <h3>
                  <span>signature</span> <br /> series
                </h3>
              </div>
              <h3 className={styles.name}>{title(seller)}</h3>
            </div>
            <div className={styles.scrollDown}>
              <h5>discover</h5>
              <i className="fa fa-long-arrow-down fa-fw i-xs" />
            </div>
          </Col>
          <div className={styles.profilePic}>
            <img src={profilePicture} alt={seller.attachments[0].title} />
            {seller.videoUrl ? (
              <>
                <Color
                  src={profilePicture}
                  format="rgbString"
                  crossOrigin={"anonymous"}
                >
                  {({ data, loading, error }) =>
                    !loading ? (
                      <div
                        className={styles.pulseOverlay}
                        style={{
                          backgroundColor: data,
                        }}
                      ></div>
                    ) : null
                  }
                </Color>
                <ImPlay3 onClick={() => setPlayer(true)} />
              </>
            ) : null}
          </div>
        </Row>
      </div>

      <Container className={`${styles.signatureWrapper} ${""}`}>
        <Row>
          <Col xs={12} lg={7}>
            <h3 className="mb-5">
              {title(seller)} <br />{" "}
              {seller.signatureSeries === true ? "Signature Collection" : ""}
            </h3>
            <BioSlider seller={seller} />
          </Col>
          <Col xs={12} lg={5}>
            <div className={styles.bioImgWrapper}>
              <img
                src={getInsert1Image(seller)}
                alt={seller.attachments[2].title}
              />
              <img
                className={styles.bioImg2}
                src={getInsert2Image(seller)}
                alt={seller.attachments[3].title}
              />
            </div>
          </Col>
        </Row>
        <Row as="section" className="footNoteWrapper">
          <Col xs={12} className="footNoteHeading">
            <h3>
              {seller.signatureSeries === true
                ? "Caskshare signature series - "
                : ""}{" "}
              {title(seller)}
            </h3>
            <div className="allCask">
              <Link to="/caskshares">
                Browse All
                <i
                  className="fa fa-long-arrow-right fa-fw i-xs"
                  aria-hidden="true"
                />
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="distillery-casks-area">
          {!distilleryCasks?.length ? (
            <Col style={{ paddingTop: "25px" }}>
              <h5>There are currently no casks for this distillery</h5>
            </Col>
          ) : (
            <>
              {distilleryCasks
                .sort(
                  (a: Cask, b: Cask) => (a.bottlingYear - b.bottlingYear) * 1,
                )
                .map((cask: Cask) => (
                  <Col
                    className={styles.signatureCardWrapper}
                    sm={12}
                    key={cask.variants[0].id}
                  >
                    <SignatureCard onClick={caskDetails} cask={cask} />
                  </Col>
                ))}
            </>
          )}
        </Row>
      </Container>
      <div style={{ overflow: "hidden" }}>
        <SignUpForNew />
      </div>
      {player && (
        <div
          className={styles.playerPopup}
          onClick={() => {
            setPlayer(false);
          }}
        >
          <div className={styles.playerWrap}>
            <span
              onClick={() => {
                setPlayer(false);
              }}
            >
              <i className="fa fa-times"></i>
            </span>
            <ReactPlayer
              url={seller?.videoUrl}
              controls={true}
              width="100%"
              height="100%"
              playIcon={<ImPlay3 />}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SignatureSeries;
