import {
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloClient,
} from "apollo-boost";
// @ts-ignore
import apolloLogger from "apollo-link-logger";
import { onError } from "apollo-link-error";
import { setContext } from "@apollo/link-context";
import { store } from "./store";
import { resetUser } from "./slices/userSlice";
import { resetCheckout } from "./slices/checkoutSlice";

const httpLink = new HttpLink({ uri: "/api/v1/graphql" });

const setFetchUri = setContext(async (request) => {
  const authPoints = [
    "customerUpdatePasswordWithToken",
    "customerSendPasswordResetWithToken",
    "customerUpdatePassword",
    "customerLogin",
    "customerLogout",
    "customerRegister",
    "activateAccount",
  ];
  if (authPoints.includes(request.operationName as string)) {
    return {
      uri: "/api/v1/customer/auth",
    };
  }
  return {
    uri: "/api/v1/graphql",
  };
});

const middlewareLink = setContext(async (_, { headers }) => {
  const loggedInUser = store.getState().user;
  const shopId = store.getState().shop.id;
  if (loggedInUser && loggedInUser.email) {
    return {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        accept: "application/json",
        uid: loggedInUser.email,
        client: loggedInUser.credentials?.client,
        "access-token": loggedInUser.credentials?.accessToken,
        "token-type": "Bearer",
        shop: shopId,
      },
    };
  }
  return {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
  };
});

const errorLink = onError(({ networkError }) => {
  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode == 401
  ) {
    // Logout
    const loggedInUser = store.getState().user;
    const addressInStorage = sessionStorage.getItem("shippingAddress");
    if (addressInStorage) {
      sessionStorage.removeItem("shippingAddress");
    }
    if (loggedInUser.email) {
      store.dispatch(resetUser());
      store.dispatch(resetCheckout());
      window.location.href = window.location.origin;
    }
  }
});

const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([
    apolloLogger,
    middlewareLink,
    errorLink,
    // @ts-ignore
    setFetchUri.concat(httpLink),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;
