import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/rootReducer";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-apollo";
import { Helmet } from "react-helmet";

import { getMetaText, getCopyText } from "../../AppText";
import CheckoutBasket from "./CheckoutBasket";
import CheckoutTotals from "./CheckoutTotals";
import { GET_CUSTOMER } from "../../graphql/User";
import {
  GET_MEMBER_PRODUCTS,
  MemberProduct,
} from "../../graphql/MemberProduct";
import { GET_CHECKOUT } from "../../graphql/Checkout";

import { formatCurrency } from "../../utils/currency";
import LoadingView from "../app/LoadingView";

import { setCheckoutStatus } from "../../state/slices/checkoutSlice";
import { store } from "../../state/store";
import LoadingLogo from "../app/LoadingLogo";

const CheckoutSuccess = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);
  const checkout = useSelector((state: RootState) => state.checkout);
  const shop = useSelector((state: RootState) => state.shop);
  const history = useHistory();
  const isMember = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );
  const [stripeCollective, setStripeCollective] = useState<MemberProduct>({
    id: "",
    title: "",
    name: "",
    value: 0,
    stripePrice: "",
    memberAddOns: [],
  });

  // Reload the checkout to update our cache and confirm whether completed or not
  const { data: checkoutQuery, loading: checkoutDataLoading } = useQuery(
    GET_CHECKOUT,
    {
      fetchPolicy: "network-only",
      variables: {
        shop: shop.id,
        id: checkout.id,
      },
    },
  );

  useEffect(() => {
    if (
      checkoutQuery?.checkout?.status === "completed" &&
      checkout.status !== "completed"
    ) {
      dispatch(setCheckoutStatus("completed"));
    }
  }, [checkoutQuery]);

  const { data: memberData, loading: memberDataLoading } = useQuery(
    GET_MEMBER_PRODUCTS,
    {
      variables: {
        shop: shop.id,
      },
      skip: !shop.id,
    },
  );

  useEffect(() => {
    if (memberData?.memberProducts) {
      const collective = memberData?.memberProducts.find(
        (m: MemberProduct) => m.name === "Collective",
      );
      if (!collective) {
        return;
      }
      setStripeCollective(collective);
    }
    return () => {
      setStripeCollective({
        id: "",
        title: "",
        name: "",
        value: 0,
        stripePrice: "",
        memberAddOns: [],
      });
    };
  }, [memberData]);

  if (checkoutDataLoading || memberDataLoading) {
    return <LoadingLogo />;
  }

  if (!checkoutQuery?.checkout) {
    history.push("/");
  }

  return (
    <Container className="checkout-success-component">
      <Helmet>
        <title>{getMetaText("CHECKOUT_SUCCESS_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("CHECKOUT_SUCCESS_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("CHECKOUT_SUCCESS_PAGE", "description")}
        />
        <meta
          name="author"
          content={getMetaText("CHECKOUT_SUCCESS_PAGE", "author")}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("CHECKOUT_SUCCESS_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("CHECKOUT_SUCCESS_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../assets/home-page/splash.jpg")
          }
        />
      </Helmet>

      <Col className="congratulations-area">
        <h4>Congratulations, your transaction has been successful!</h4>
        <p>
          Your payment has been received. A confirmation will be sent shortly to
          <span style={{ fontWeight: "bold" }}> {checkout?.email}</span>
        </p>
      </Col>
      <Row>
        <Col md={7} className="checkout-basket-area">
          <CheckoutBasket canEditQuantities={false} hideTotals />
          <CheckoutTotals checkoutData={checkout} />
        </Col>
        <Col md={5} className="what-happens-next-area">
          {user?.email ? (
            <>
              <Col>
                <h4>What happens next?</h4>
              </Col>
              <Col>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getCopyText(
                      "CHECKOUT_SUCCESS_PAGE",
                      "what-happens-next",
                    ),
                  }}
                />
              </Col>
              <Col>
                <Button as={Link} to="/my-account" variant="light" block>
                  Track Progress in my account
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col>
                <h4>What happens next?</h4>
              </Col>
              <Col>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getCopyText(
                      "GUEST_CHECKOUT_SUCCESS_PAGE",
                      "what-happens-next",
                    ),
                  }}
                />
              </Col>
              <Col>
                <Button as={Link} to="/register" variant="light" block>
                  Create an account
                </Button>
              </Col>
            </>
          )}
          {stripeCollective.value > 0 && !isMember ? (
            <Col className="collective-banner">
              <h4>Save on your next order by joining The Collective</h4>
              <p>
                Receive a welcome bottle, up to 20% off all future purchases and
                access to exclusive content for just{" "}
                {formatCurrency(shop.currency, 0).format(
                  stripeCollective.value,
                )}{" "}
                annually.
              </p>

              <Button as={Link} to="/the-collective" variant="light" block>
                Tell me more
              </Button>
            </Col>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutSuccess;
