import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { string } from "prop-types";
import { Shop } from "../../graphql/Shops";

const initialState: Shop = {
  id: "",
  currency: "",
  market: "",
  features: [
    {
      feature: "",
      active: false,
    },
  ],
  presentmentCurrencies: [],
  stripePublishableKey: "",
  mollieProfileId: "",
  isCurrentUserLocation: false,
  affiliateClubs: {
    id: "",
    name: "",
  },
  shippingMonthIncrement: 0,
  heroItems: [
    {
      strapline1: "",
      strapline2: "",
      buttonText: "",
      buttonUrl: "",
      page: "",
      ordinal: 0,
      attachments: [],
    },
  ],
  howItWorksSections: [
    {
      id: "",
      navButtonText: "",
      title: "",
      description: "",
      buttonText: "",
      buttonUrl: "",
      nextStepId: "",
      nextStepText: "",
      attachments: [],
    },
  ],
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShop(state, action: PayloadAction<Shop>) {
      const {
        payload: {
          id,
          currency,
          market,
          features,
          presentmentCurrencies,
          stripePublishableKey,
          mollieProfileId,
          isCurrentUserLocation,
          affiliateClubs,
          shippingMonthIncrement,
        },
      } = action;
      state.id = id;
      state.currency = currency;
      state.market = market;
      state.features = features;
      state.presentmentCurrencies = presentmentCurrencies;
      state.stripePublishableKey = stripePublishableKey;
      state.mollieProfileId = mollieProfileId;
      state.isCurrentUserLocation = isCurrentUserLocation;
      state.affiliateClubs = affiliateClubs;
      state.shippingMonthIncrement = shippingMonthIncrement;
    },
  },
});

export const { setShop } = shopSlice.actions;

export default shopSlice.reducer;
