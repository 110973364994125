import { gql } from "apollo-boost";
import { Attachment } from "./Distillery";
import { Product } from "./Products";

export type Events = {
  url: string;
  date: Date;
  orderDeadline: Date;
  slug: string;
  title: string;
  product: Product;
  upsellProduct: Product;
  featured: boolean;
  category: string;
  eventType: string;
  virtual: boolean;
  maximumSeats: number;
  availableSeats: number;
  description: string;
  attachments: Attachment[];
};

export const GET_CUSTOMER = gql`
  query getCustomer($shop: ID!) {
    customer(shop: $shop) {
      email
      firstName
      lastName
      fullName
      balance
      interest
      acceptsMarketing
      surpriseMe
      depositAmount
      barrelBankActive
      collectiveActive
      cardOnFile
      collectiveType
      collectiveRenewalDate
      collective {
        startDate
        endDate
        value
        promotionCode
      }
      wasMigrated
      eventTickets {
        event {
          slug
        }
        code
      }
      customerEvents {
        slug
        seats
      }
      recievedGifts {
        value
        giftType
        giftNote
        gifterName
      }
      addresses {
        id
        firstName
        lastName
        address1
        address2
        city
        company
        phone
        deliveryInstruction
        postalCode
        stateCode
        countryCode
      }
      billingAddresses {
        id
        firstName
        lastName
        address1
        address2
        city
        company
        phone
        postalCode
        stateCode
        countryCode
      }
      shippingAddresses {
        id
        firstName
        lastName
        address1
        address2
        city
        company
        phone
        deliveryInstruction
        postalCode
        stateCode
        countryCode
      }
      shippingAddress {
        id
        firstName
        lastName
        address1
        address2
        city
        company
        phone
        deliveryInstruction
        postalCode
        stateCode
        countryCode
      }
      billingAddress {
        id
        firstName
        lastName
        address1
        address2
        city
        company
        phone
        deliveryInstruction
        postalCode
        stateCode
        countryCode
      }
      reservations {
        bottles
        id
        pricePaid
        purchasedAt
        transferStatus
        giftStatus
        status
        shippingPaid
        cask {
          attachments {
            url
            role
          }
          title
          bottlingYear
          bottlingMonth
          fillingDate
          isCaskStrength
          bottlingStrength
          bottledCaskshare {
            slug
            id
            defaultVariant {
              id
            }
            type
          }
          distillery {
            physicalAddress {
              country
              countryCode
            }
            name
            attachments {
              role
              title
              url
            }
          }
          defaultVariant {
            price {
              amount
              currencyCode
              maxDate
              maxBottles
            }
            image
          }
          code
          comments {
            author
            body
            createdAt
            updatedAt
          }
          seller {
            title
            signatureSeries
            slug
            curatorName
            attachments {
              role
              title
              url
            }
          }
        }
      }
      transactions {
        total
        subtotal
        type
        purchasedAt
        bottles
        isABundle
        bundleId
        bundleChild
        purchasedProduct {
          purchasedAt
          id
          quantity
          product {
            slug
            title
            seller {
              title
              signatureSeries
              slug
            }
            image
            defaultVariant {
              price {
                amount
                currencyCode
                maxDate
                maxBottles
              }
              image
            }
          }
        }
        cask {
          title
          seller {
            title
            signatureSeries
            slug
          }
          distillery {
            name
            attachments {
              role
              title
              url
            }
          }
          defaultVariant {
            slug
            price {
              amount
              currencyCode
              maxDate
              maxBottles
            }
            image
          }
        }
      }
      addresses {
        id
      }
    }
  }
`;

export const GET_GUEST = gql`
  query guest($email: String!, $shop: ID!) {
    guest(email: $email, shop: $shop) {
      email
      firstName
      lastName
      fullName
    }
  }
`;

export const EVENTS = gql`
  query events($shop: ID!) {
    events(shop: $shop) {
      url
      date
      orderDeadline
      slug
      title
      product {
        image
        title
        description
        id
        slug
        type
        defaultVariant {
          id
        }
      }
      upsellProduct {
        image
        title
        description
        id
        slug
        type
        defaultVariant {
          id
        }
      }
      featured
      category
      eventType
      virtual
      description
      maximumSeats
      availableSeats
      attachments {
        role
        title
        url
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation customerUpdate(
    $shop: ID!
    $addresses: [AddressAttributes!]
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
    $acceptsMarketing: Boolean
    $surpriseMe: Boolean
  ) {
    customerUpdate(
      shop: $shop
      addresses: $addresses
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      acceptsMarketing: $acceptsMarketing
      surpriseMe: $surpriseMe
    ) {
      customer {
        email
      }
    }
  }
`;

// FIXME - what to do here?
export const CONTACT_US = gql`
  mutation customerUpdate(
    $shop: ID!
    $addresses: [AddressAttributes!]
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
  ) {
    customerUpdate(
      shop: $shop
      addresses: $addresses
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
    ) {
      customer {
        email
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation customerUpdatePasswordWithToken(
    $shop: ID!
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    customerUpdatePasswordWithToken(
      shop: $shop
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      authenticatable {
        email
        firstName
        collectiveActive
      }
    }
  }
`;

export const ADDRESS_UPSERT = gql`
  mutation addressUpsert(
    $shop: ID!
    $email: String!
    $input: AddressAttributes!
    $category: String!
  ) {
    addressUpsert(
      shop: $shop
      email: $email
      input: $input
      category: $category
    ) {
      address {
        id
      }
    }
  }
`;

export const ADDRESS_DELETE = gql`
  mutation addressDelete($shop: ID!, $id: ID!) {
    addressDelete(shop: $shop, id: $id) {
      success
    }
  }
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount(
    $shop: ID!
    $password: String!
    $passwordConfirmation: String!
    $activationUrl: String!
  ) {
    activateAccount(
      shop: $shop
      password: $password
      passwordConfirmation: $passwordConfirmation
      activationUrl: $activationUrl
    ) {
      email
    }
  }
`;

export const CUSTOMER_SEND_PASSWORD_RESET_WITH_TOKEN = gql`
  mutation customerSendPasswordResetWithToken(
    $shop: ID!
    $email: String!
    $redirectUrl: String!
  ) {
    customerSendPasswordResetWithToken(
      shop: $shop
      email: $email
      redirectUrl: $redirectUrl
    ) {
      message
    }
  }
`;

export const CUSTOMER_UPDATE_PASSWORD = gql`
  mutation customerUpdatePassword(
    $shop: ID!
    $password: String!
    $passwordConfirmation: String!
  ) {
    customerUpdatePassword(
      shop: $shop
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      authenticatable {
        email
      }
    }
  }
`;
export const GENERATE_CERTIFICATE = gql`
  mutation generateCertificate(
    $reservationId: ID!
    $recipientName: String!
    $fromName: String
  ) {
    generateCertificate(
      reservationId: $reservationId
      recipientName: $recipientName
      fromName: $fromName
    ) {
      url
    }
  }
`;
export const GIFT_RESERVATION = gql`
  mutation giftReservation(
    $reservationId: ID!
    $giftData: GiftDataAttributes!
  ) {
    giftReservation(reservationId: $reservationId, giftData: $giftData) {
      success
    }
  }
`;

export const EVENT_REGISTRATION = gql`
  mutation eventRegistration($eventSlug: String!, $seats: Int!) {
    eventRegistration(eventSlug: $eventSlug, seats: $seats) {
      success
    }
  }
`;

export const SEND_REFERRAL = gql`
  mutation sendReferral($referralData: ReferralDataAttributes!) {
    sendReferral(referralData: $referralData) {
      success
    }
  }
`;

export const GIFT_CLAIM = gql`
  mutation giftClaim($code: String!) {
    giftClaim(code: $code) {
      success
      giftType
    }
  }
`;
