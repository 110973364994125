import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Container,
  Button,
} from "react-bootstrap";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Fuse from "fuse.js";
import { useHistory } from "react-router-dom";
import pluralize from "pluralize";
import { PRODUCTS, Product } from "../graphql/Products";
import { RootState } from "../state/rootReducer";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";

import LoadingView from "../components/app/LoadingView";
import { getMetaText, getCopyText } from "../AppText";
import SignUpForNew from "../components/app/SignUpForNew/SignUpForNew";

import ProductItem from "../components/products/ProductItem";
import ProductFilter from "../components/products/ProductFilter";
import ProductSorter from "../components/products/ProductSorter";
import Pagination from "../components/pagination/Pagination";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import LoadingLogo from "../components/app/LoadingLogo";

const ShopPage = () => {
  // @ts-ignore
  const { t } = useTranslation();
  hotjar.initialize(2882523, 6);
  const [openFilterPane, setOpenFilterPane] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [brands, setBrands] = useState<Set<string>>(new Set());
  const [countries, setCountries] = useState<Set<string>>(new Set());
  const [uniqueCollectionTitles, setUniqueCollectionTitles] = useState<any>([]);
  const [activeFilters, setActiveFilters] = useState<any>({
    country: "",
    brand: "",
    collection: "",
    priceFilter: { min: 0, max: 400 },
  });

  const history = useHistory();
  // const [priceFilter, setPriceFilter] = useState({ min: 0, max: 400 });
  const [searchSort, setSearchSort] = useState<any>({
    term: "featured",
    order: "asc",
  });

  const shopId = useSelector((state: RootState) => state.shop.id);
  const variables = { shop: shopId };

  const { data, loading } = useQuery(PRODUCTS, {
    variables,
  });

  useEffect(() => {
    if (!data?.products) {
      return;
    }
    // Currently grabbing unique sections client-side. Should really be done on server
    setBrands(new Set(data.products.map((p: Product) => p.seller.title)));
    setCountries(
      new Set(
        data.products.map((p: Product) => {
          p?.country?.replace(/^\s+|\s+$/gm, "")?.toLowerCase();
        }),
      ),
    );
  }, [data?.products]);

  useEffect(() => {
    let url = history?.location?.pathname?.split("/")[2];
    if (
      history?.location?.pathname === "/shop" ||
      history?.location?.pathname === `/shop/${url}`
    ) {
      history.replace("/caskshares");
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (!data?.products) {
      return;
    }
    const { min, max } = activeFilters.priceFilter;
    const filteredResults = data.products
      .filter((p: any) =>
        activeFilters.collection
          ? p.collections.find((a: any) => a.title === activeFilters.collection)
          : p,
      )
      .filter(
        (p: Product) =>
          p.defaultVariant.price.amount >= min &&
          p.defaultVariant.price.amount <= max,
      )
      .filter((p: Product) =>
        activeFilters.country
          ? p.country.replace(/^\s+|\s+$/gm, "").toLowerCase() ===
            activeFilters.country
          : p,
      )
      .filter((p: Product) =>
        activeFilters.brand ? p.seller.title === activeFilters.brand : p,
      );
    let results;
    if (searchTerm) {
      const fuse = new Fuse(filteredResults, {
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: true,
        minMatchCharLength: 1,
        threshold: 0.1,
        keys: ["description", "title"],
      });
      results = fuse.search(searchTerm).map((i) => i.item);
    } else {
      results = filteredResults;
    }

    if (searchSort.term === "price") {
      results.sort(
        (a: any, b: any) =>
          (a["defaultVariant"]["price"]["amount"] -
            b["defaultVariant"]["price"]["amount"]) *
          (searchSort.order === "asc" ? 1 : -1),
      );
    } else if (searchSort.term === "featured") {
      results.sort((a: any, b: any) => b[searchSort.term] - a[searchSort.term]);
    } else {
      results.sort((a: any, b: any) => {
        const A = a[searchSort.term];
        const B = b[searchSort.term];
        if (searchSort.order === "asc") {
          return A.localeCompare(B);
        } else {
          return B.localeCompare(A);
        }
      });
    }
    setSearchResults(results);
  }, [activeFilters, data?.products, searchTerm, searchSort]);

  // create a set of 8 products per each array
  useEffect(() => {
    setRows(
      searchResults.reduce(function (rows: any, key: any, index: any) {
        return (
          (index % 8 === 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows
        );
      }, []),
    );
  }, [searchResults]);

  useEffect(() => {
    if (data) {
      let collectionsWithProducts = data.products.map(
        (a: any) => a.collections,
      );
      setUniqueCollectionTitles([
        ...Array.from(
          new Set(collectionsWithProducts.flat().map((b: any) => b.title)),
        ),
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (history.location.state) {
      setActiveFilters(history.location.state);
    }
  }, [history.location]);

  if (loading || !data?.products) {
    return <LoadingLogo />;
  }

  const productDetails = async (product: Product) => {
    // Note on casks we split on the defaultVariant Id, but on products it's just ID
    const productId = product.slug;
    history.push(`/products/${productId}`, activeFilters);
  };

  return (
    <>
      <Helmet>
        <title>{getMetaText("SHOP_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText("SHOP_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("SHOP_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("SHOP_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={getMetaText("SHOP_PAGE", "title")} />
        <meta
          property="og:description"
          content={getMetaText("SHOP_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../assets/caskshares-page/water-over-rocks.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("SHOP_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("SHOP_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../assets/caskshares-page/water-over-rocks.jpg")
          }
        />
      </Helmet>

      <div
        className="breakout-head caskshares-page-head"
        style={{
          backgroundImage: `url(${require("../assets/caskshares-page/water-over-rocks.jpg")})`,
        }}
      >
        <Container style={{ paddingBottom: "0" }}>
          <Row as="section" className="reserve-mature-enjoy">
            <Col lg={2} className="d-none d-lg-block" />
            <Col xs={12} sm={5} lg={3}>
              <h4>{getCopyText("SHOP_PAGE", "shop-craft-whisky")}</h4>
            </Col>
            <Col xs={12} sm={4} lg={4} className="d-flex align-items-end">
              <p>{getCopyText("SHOP_PAGE", "paragraph-craft-whisky")}</p>
            </Col>
            <Col sm={1} className="d-none d-sm-block" />
          </Row>
          <Row className="head-search-row">
            <Col
              xs={12}
              sm={11}
              md={{ span: 6, offset: 3 }}
              className="head-search-area"
            >
              <InputGroup className="mb-3">
                <FormControl
                  onChange={(e: any) => {
                    setCurrentPage(1);
                    setSearchTerm(e.target.value);
                  }}
                  aria-label="Search Whiskies"
                  placeholder="Search whiskies"
                />
                <InputGroup.Append>
                  <InputGroup.Text>
                    <i
                      style={{ width: "30px" }}
                      className="fa fa-search fa-fw"
                      aria-hidden="true"
                    />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col
              xs={0}
              sm={1}
              className="d-none d-sm-block head-search-area-whiteout-area"
            >
              <div className="whiteout" />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="no-padding">
          <Col
            xs={8}
            className="pt-2 pb-2 d-block d-lg-none"
            style={{ marginBottom: "50px" }}
          >
            <Button
              variant="outline-dark"
              onClick={() => setOpenFilterPane(true)}
            >
              Filter
            </Button>

            <SlidingPane
              className="casks-page-filter-sliding-pane"
              overlayClassName="casks-page-filter-sliding-pane-overlay"
              isOpen={openFilterPane}
              from="left"
              width="320px"
              title="Filter"
              onRequestClose={() => {
                setOpenFilterPane(false);
              }}
            >
              <Row>
                <Col xs={12} className="sort-area">
                  <ProductSorter
                    searchSort={searchSort}
                    setSearchSort={setSearchSort}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="filter-area-sliding">
                  <ProductFilter
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    brands={brands}
                    countries={countries}
                    setCurrentPage={setCurrentPage}
                    searchResults={searchResults}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="apply-button-area text-right">
                  <Button
                    variant="outline-dark"
                    className="mt-2"
                    onClick={() => setOpenFilterPane(false)}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </SlidingPane>
          </Col>

          <Col
            lg={3}
            className="filter-area-fixed d-none d-lg-block"
            style={{ paddingTop: "70px" }}
          >
            <div className="spirit-type-wrap">
              <h5
                onClick={() => {
                  setActiveFilters({
                    country: "",
                    brand: "",
                    collection: "",
                    priceFilter: { min: 0, max: 400 },
                  });
                  setCurrentPage(1);
                }}
                className={`explainer-header ${
                  !activeFilters.collection ? "active" : ""
                }`}
              >
                <div
                  className={`${"indicator-bar"} ${
                    !activeFilters.collection ? "active" : ""
                  }`}
                />
                All
              </h5>

              {uniqueCollectionTitles.map((c: any, i: any) => (
                <h5
                  key={i}
                  onClick={() => {
                    setActiveFilters({
                      ...activeFilters,
                      collection: c,
                    });
                    setCurrentPage(1);
                  }}
                  className={`explainer-header ${
                    activeFilters.collection === c ? "active" : ""
                  }`}
                >
                  <div
                    className={`${"indicator-bar"} ${
                      activeFilters.collection === c ? "active" : ""
                    }`}
                  />
                  {c}
                </h5>
              ))}
            </div>
            <Row className="filter-shop">
              <Col lg={6} md={12}>
                <h4>Filter</h4>
              </Col>
              <Col lg={6} md={12}>
                <p>{pluralize("product", searchResults.length, true)}</p>
              </Col>
            </Row>
            <ProductFilter
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              brands={brands}
              countries={countries}
              setCurrentPage={setCurrentPage}
              searchResults={searchResults}
            />
          </Col>
          <Col lg={9}>
            <Row className="search-area d-none d-md-flex">
              <Col
                xs={12}
                md={6}
                style={{ paddingBottom: "50px", paddingTop: "20px" }}
              >
                <ProductSorter
                  searchSort={searchSort}
                  setSearchSort={setSearchSort}
                />
              </Col>
            </Row>
            <Row className="product-row">
              {!searchResults.length && !searchTerm && !activeFilters ? (
                <Col style={{ paddingTop: "25px" }}>
                  <h3>No products found</h3>
                </Col>
              ) : (
                <>
                  <Pagination
                    data={rows}
                    RenderComponent={ProductItem}
                    pageLimit={rows.length}
                    dataLimit={1}
                    onClick={productDetails}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <div>
        <SignUpForNew />
      </div>
    </>
  );
};

export default ShopPage;
