import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Socials from "./Socials";
import { RootState } from "../../../state/rootReducer";
import { ReactComponent as Logo } from "../../../assets/images/caskshare-rebrand-logo.svg";
import { ReactComponent as PaymentLogos } from "../../../assets/images/payment-logos.svg";
import styles from "./Footer.module.scss";

const Footer = () => {
  const shop = useSelector((state: RootState) => state.shop);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 992;
  const history = useHistory();

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const handleOpenNewsLetter = () => {
    // @ts-ignore
    window._klOnsite = window._klOnsite || [];
    // @ts-ignore
    window._klOnsite.push(["openForm", "VTZnPC"]);
    window.addEventListener("klaviyoForms", function (e: any) {
      if (e.detail.type == "open") {
        // @ts-ignore
        _learnq.push([
          "identify",
          {
            Market: shop.market,
          },
        ]);
      }
    });
  };

  return (
    <Container fluid className={styles.outerFooterWrap}>
      <Container className={styles.innerFooterWrap}>
        <Row className={styles.footerSection}>
          <Col xs={12} sm={5} className={styles.logoArea}>
            <div
              className={styles.logoWrap}
              onClick={() => {
                history.push("/");
              }}
            >
              <Logo />
            </div>
            <div className={styles.copyRight}>
              <p>
                © 2016-{new Date().getFullYear()} Copyright Caskshare
                <br/>
                23 Manor Place, Edinburgh, EH3 7DX
              </p>
            </div>
            <div className={styles.newsletter}>
              <Button
                className={styles.btnCoralOutline}
                onClick={() => handleOpenNewsLetter()}
              >
                Newsletter Sign-Up
              </Button>
            </div>
          </Col>
          <Col xs={12} sm={7} className={styles.footerLinks}>
            <div className={styles.linksWrap}>
              <NavLink
                to="/caskshares"
                className={`${styles.footerLink} ${styles.shopLink}`}
              >
                Shop
              </NavLink>

              <NavLink to="/gifting/voucher" className={styles.footerLink}>
                Gift Vouchers
              </NavLink>

              <NavLink to="/the-collective" className={styles.footerLink}>
                Join the Collective
              </NavLink>

              <NavLink to="/about-us" className={styles.footerLink}>
                About Us
              </NavLink>

              <NavLink
                to="/journal"
                target="_blank"
                className={styles.footerLink}
              >
                Journal
              </NavLink>

              <NavLink to="/terms/faq" className={styles.footerLink}>
                FAQ
              </NavLink>

              <a
                href="
               mailto:contact@caskshare.com?subject=Message from Caskshare site"
                className={styles.footerLink}
              >
                Contact
              </a>
            </div>

            <div className={styles.readMe}>
              <NavLink to="/terms/terms-and-conditions">
                Terms &amp; Conditions
              </NavLink>
              <NavLink to="/terms/terms-of-use">Terms of Use</NavLink>
              <NavLink to="/terms/privacy-policy">
                Cookies &amp; Privacy
              </NavLink>
            </div>

            <div className={styles.payment}>
              <Socials variant={"light"} />
              <PaymentLogos />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
