import React from "react";

const DateTimeDisplay = ({
  value,
  type,
  isDanger,
  props,
}: {
  value: number;
  type: string;
  isDanger: boolean;
  props?: any;
}) => {
  return (
    <div className={`${isDanger ? "countdown danger" : "countdown"}`}>
      <p
        className={`${type === "Seconds" ? "bounceSec" : ""} ${
          props?.minutesChanged ? "bounceTime" : ""
        }`}
      >
        {value < 10 ? `0${value}` : value}
      </p>
      {/* <span>{type}</span> */}
    </div>
  );
};

export default DateTimeDisplay;
