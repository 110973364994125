import React, { useState } from "react";

import styles from "./ArticleButton.module.scss";

interface MonthYear {
  month: number;
  year: number;
}

// Number to month
function getMonthName(monthVar: number) {
  const d = new Date();
  d.setMonth(monthVar - 1);
  const monthName = d.toLocaleString("default", { month: "long" });
  return monthName;
}

const ArticleButton = ({
  monthYear,
  filter,
  data,
}: {
  monthYear: MonthYear[];
  filter: any;
  data: Array<string | number>;
}) => {
  // get all months
  const allMonths = [
    ...data
      .map((item: any) => new Date(item.publishedDate).getMonth() + 1)
      .sort((a: number, b: number) => a - b),
  ];

  const count = data.reduce((acc: any, item: any) => {
    const date = new Date(item.publishedDate);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const key = `${month}-${year}`;
    acc[key] = acc[key] === undefined ? 1 : (acc[key] += 1);
    return acc;
  }, {});

  const [isActive, setActive] = useState<MonthYear>({ month: -1, year: -1 });

  const handleActive = (month: number, year: number) => {
    setActive({ month, year });
  };

  return (
    <>
      {monthYear.map(({ month, year }: MonthYear, i: number) => {
        return (
          <div
            className={`${styles.dateWrapper} ${
              isActive.month === month && isActive.year === year ? styles.active : ""
            }`}
            key={i}
          >
            <p
              onClick={() => {
                filter(month, year);
                handleActive(month, year);
              }}
            >
              {month === -1 ? "All" : getMonthName(month) + ` ${year}`}
            </p>
            <hr className={styles.line}></hr>
            <small>
              {month === -1
                ? "(" + allMonths.length + ")"
                : "(" + count[`${month}-${year}`] + ")"}
            </small>
          </div>
        );
      })}
    </>
  );
};

export default ArticleButton;
