import React from 'react';

import styles from './ArticleCard.module.scss';
import arrowGoTo from '../../assets/images/GoToArrow.png';

const ArticleCard = ({ menuItem }: { menuItem: any }) => {
  return (
    <>
      {menuItem.map((item: any) => {
        return (
          <div className={styles.articleWrapper} key={item.id}>
            <h4>{item.title}</h4>
            <p>{item.body}</p>
            <button>
              <a href={item.url} target='_blank'>
                Go To Article <img src={arrowGoTo} alt='arrow' />
              </a>
            </button>
          </div>
        );
      })}
    </>
  );
};

export default ArticleCard;
