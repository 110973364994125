import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { collectiveCards } from "./collectiveData";
import styles from "./CollectivePage.module.scss";
import { Shop } from "../../../graphql/Shops";

const CollectiveCardsSlider = ({
  shop,
}: {
  shop: Shop
}) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 0,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          infinite: false,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <div className={styles.cardsWrap}>
      <Slider {...settings}>
        {collectiveCards().filter((card: any) => {
          if (! card.markets) {
            return true;
          } else if (card.markets.includes(shop.market)) {
            return true;
          } else {
            return false;
          }
        })
        .map((card: any) => (
          <div key={card.id} className={styles.card}>
            <div className={styles.img}>
              <img src={card.url} alt={card.title} />
            </div>
            <div className={styles.content}>
              <h5>{card.title}</h5>
              <p dangerouslySetInnerHTML={{__html: card.text}}></p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default CollectiveCardsSlider;
