import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Account.module.scss";

const CollectiveSuccess = ({}: {}) => {
  console.log("rendered");
  return (
    <div className={styles.successWrap}>
      <h4>
        Your Caskshare <span>Collective</span> Account has been created!
      </h4>
      <p>
        Thank you for subscribing! Click the button below to view your
        collective account page.
      </p>
      <Button as={Link} to="/my-account/collective">
        BarrelBank
      </Button>
    </div>
  );
};

export default CollectiveSuccess;
