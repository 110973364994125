import React, { useState, useEffect } from "react";

const CurvedTexts = ({
  text = "",
  objectSize = 80,
  spacing = 25,
  offset = 35,
  overlap = false,
  fontSize = "",
  scale = "",
  reverse = false,
}) => {
  const d = objectSize + spacing * 2;
  const r = objectSize / 2 + spacing;

  return (
    <div
      className="curved-text"
      style={{
        transform: scale,
        marginBottom: overlap ? `-${r}px` : "0",
        width: `${d + offset * 2}px`,
        height: `height: ${r + offset}px`,
      }}
    >
      <svg viewBox={`0 0 ${d + offset * 2} ${r + offset * 2}`}>
        {reverse ? (
          <path
            id="curveReverse"
            d={`M${offset},${r + offset} A${r},${r} 0 0,0 ${d + offset},${
              r + offset
            }`}
          />
        ) : (
          <path
            id="curve"
            d={`M${offset},${r + offset} A${r},${r} 0 0,1 ${d + offset},${
              r + offset
            }`}
          />
        )}
        <text width="500" style={{ fontSize: fontSize }}>
          <textPath
            xlinkHref={reverse ? "#curveReverse" : "#curve"}
            startOffset="50%"
          >
            {text}
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default CurvedTexts;
