const CollectiveInfo = [
  {
    id: 0,
    url: require("../../../assets/home-page/.v3/Women drinking whisky at a bar-0833 (credit OurWhisky Foundation_Jo Hanley).jpg"),
    title: "1. reserve",
    text: "Browse our online range of exclusive whisky casks, and reserve a share in a cask to secure your bottle of something truly exciting.",
  },
  {
    id: 1,
    url: require("../../../assets/home-page/.v3/man looking after casks of whisky.jpg"),
    title: "2. mature",
    text: "Let the cask mature and enjoy the benefits of our Collective with tastings, meet-the-maker events and exclusive content whilst you wait.",
  },
  {
    id: 2,
    url: require("../../../assets/home-page/.v3/caskshare agitator whisky.jpg"),
    title: "3. enjoy",
    text: "The wait is over. The cask is full matured and your bottle of unique, single cask whisky will be delivered to you. Share your bottle, your passion and your stories with friends and enjoy a dram of something special together.",
  },
];
export default CollectiveInfo;
