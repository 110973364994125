import React, {useEffect, useState} from "react";
import {Button, Form, Spinner} from "react-bootstrap";
import {formatCurrency} from "../../../../utils/currency";
import {useSelector} from "react-redux";
import {RootState} from "../../../../state/rootReducer";
import LoadingView from "../../../app/LoadingView";

const PaymentMollie = (
    {
        paymentAmount = 0,
        isLoading = false,
        isPaymentEnabled = false,
        onPaymentSubmitted = () => {},
        onPaymentError = () => {},
    } : {
        paymentAmount: number,
        isLoading: boolean,
        isPaymentEnabled: boolean,
        onPaymentSubmitted: (cardToken: string) => void,
        onPaymentError: (errorMessage: string) => void,
    }
) => {

    const shop = useSelector((state: RootState) => state.shop);
    const [isMollieJsLoaded, setIsMollieJsLoaded] = useState(typeof(Mollie) !== 'undefined');
    const [mollieJsInstance, setMollieJsInstance] = useState(window.mollie);
    const [isMollieComponentsInitDone, setIsMollieComponentsInitDone] = useState(false);
    const [isMollieCreatingToken, setIsMollieCreatingToken] = useState(false);

    const [isApplePayEnabled, setIsApplePayEnabled] = useState(true);

    const mollieJsComponentOptions = {
        styles: {
            base: {
                fontSize: "18px",
                color: "#162941",
                "::placeholder": {
                    color: "rgba(41, 58, 81)",
                },
            },
        },
    };

    useEffect(() => {
        if (window.ApplePaySession) {
            var merchantIdentifier = 'example.com.store';
            var promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
            promise.then(function (canMakePayments) {
                if (canMakePayments) {
                    // Display Apple Pay button here.
                    console.log('Display Apple Pay button');
                    setIsApplePayEnabled(true);
                }
            });
        }
    }, []);
    // https://rangen.medium.com/dynamically-load-google-scripts-with-react-and-the-useeffect-hook-3700b908e50f
    useEffect(() => {
        console.log('PaymentMollie.tsx useEffect[]');
        if (shop.market !== 'EU' || isMollieJsLoaded) return;

        const existingScript = document.getElementById('mollieJs');
        if (existingScript) {
            setIsMollieJsLoaded(true);
            return;
        }

        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://js.mollie.com/v1/mollie.js';
        scriptTag.id = 'mollieJs';
        scriptTag.addEventListener('load', () => setIsMollieJsLoaded(true));
        document.body.appendChild(scriptTag);
    }, []);

    useEffect(() => {
        if (typeof(Mollie) === 'undefined') return;

        if (!window.mollie) {
            window.mollie = Mollie(shop.mollieProfileId, {
                locale: 'en_GB',
                testmode: false
            });
            setMollieJsInstance(window.mollie);
        }
        const mollieCardholderElement = initMollieCardholderElement(window.mollie);
        const mollieCardnumberElement = initMollieCardnumberElement(window.mollie);
        const mollieExpiryDateElement = initMollieExpiryDateElement(window.mollie);
        const mollieVerificationCodeElement = initMollieVerificationCodeElement(window.mollie);
        setTimeout(() => {
            setIsMollieComponentsInitDone(true);
        }, 1000);
        return (() => {
            mollieCardholderElement.unmount();
            mollieCardnumberElement.unmount();
            mollieExpiryDateElement.unmount();
            mollieVerificationCodeElement.unmount();
        });
    }, [isMollieJsLoaded]);

    function initMollieCardholderElement(mollieJsInstance) {
        var cardHolder = mollieJsInstance.createComponent('cardHolder', mollieJsComponentOptions);
        cardHolder.mount('#card-holder');
        var cardHolderError = document.getElementById("card-holder-error");
        cardHolder.addEventListener("change", function (event) {
            toggleMollieFieldClass({elementId: "card-holder", toggleClassesObject: event});

            if (event.error && event.touched) {
                cardHolderError.textContent = event.error;
            } else {
                cardHolderError.textContent = "";
            }
        });

        cardHolder.addEventListener("focus", function () {
            toggleMollieFieldClass({
                elementId: "card-holder",
                toggleClassesObject: {focussed: true},
            });
        });
        cardHolder.addEventListener("blur", function () {
            toggleMollieFieldClass({
                elementId: "card-holder",
                toggleClassesObject: {focussed: false},
            });
        });
        return cardHolder;
    }

    function initMollieCardnumberElement(mollieJsInstance) {
        var cardNumber = mollieJsInstance.createComponent('cardNumber', mollieJsComponentOptions);
        cardNumber.mount('#card-number');
        var cardNumberError = document.getElementById("card-number-error");
        cardNumber.addEventListener("change", function (event) {
            toggleMollieFieldClass({elementId: "card-number", toggleClassesObject: event});

            if (event.error && event.touched) {
                cardNumberError.textContent = event.error;
            } else {
                cardNumberError.textContent = "";
            }
        });

        cardNumber.addEventListener("focus", function () {
            toggleMollieFieldClass({
                elementId: "card-number",
                toggleClassesObject: {focussed: true},
            });
        });
        cardNumber.addEventListener("blur", function () {
            toggleMollieFieldClass({
                elementId: "card-number",
                toggleClassesObject: {focussed: false},
            });
        });
        return cardNumber;
    }

    function initMollieExpiryDateElement(mollieJsInstance) {
        var expiryDate = mollieJsInstance.createComponent('expiryDate', mollieJsComponentOptions);
        expiryDate.mount('#expiry-date');
        var expiryDateError = document.getElementById("expiry-date-error");
        expiryDate.addEventListener("change", function (event) {
            toggleMollieFieldClass({elementId: "expiry-date", toggleClassesObject: event});

            if (event.error && event.touched) {
                expiryDateError.textContent = event.error;
            } else {
                expiryDateError.textContent = "";
            }
        });

        expiryDate.addEventListener("focus", function () {
            toggleMollieFieldClass({
                elementId: "expiry-date",
                toggleClassesObject: {focussed: true},
            });
        });
        expiryDate.addEventListener("blur", function () {
            toggleMollieFieldClass({
                elementId: "expiry-date",
                toggleClassesObject: {focussed: false},
            });
        });
        return expiryDate;
    }

    function initMollieVerificationCodeElement(mollieJsInstance) {
        var verificationCode = mollieJsInstance.createComponent('verificationCode', mollieJsComponentOptions);
        verificationCode.mount('#verification-code');
        var verificationCodeError = document.getElementById("verification-code-error");
        verificationCode.addEventListener("change", function (event) {
            toggleMollieFieldClass({
                elementId: "verification-code",
                toggleClassesObject: event,
            });

            if (event.error && event.touched) {
                verificationCodeError.textContent = event.error;
            } else {
                verificationCodeError.textContent = "";
            }
        });

        verificationCode.addEventListener("focus", function () {
            toggleMollieFieldClass({
                elementId: "verification-code",
                toggleClassesObject: {focussed: true},
            });
        });
        verificationCode.addEventListener("blur", function () {
            toggleMollieFieldClass({
                elementId: "verification-code",
                toggleClassesObject: {focussed: false},
            });
        });
        return verificationCode;
    }

    function toggleMollieFieldClass(elementClassObj) {
        var element = document.getElementById(elementClassObj.elementId);

        Object.keys(elementClassObj.toggleClassesObject).forEach(function (key) {
            if (typeof elementClassObj.toggleClassesObject[key] !== "boolean") {
                return;
            }

            if (elementClassObj.toggleClassesObject[key]) {
                element.parentNode.classList.add("is-" + key);
            } else {
                element.parentNode.classList.remove("is-" + key);
            }
        });
    }

    async function handleSubmit() {
        setIsMollieCreatingToken(true);
        const { token, error } = await mollieJsInstance.createToken();

        if (error) {
            // Something wrong happened while creating the token. Handle this situation gracefully.
            console.error('Mollie createToken() error: ', error);
            onPaymentError(error.message);
            setTimeout(() => {
                setIsMollieCreatingToken(false);
            }, 500);
            return;
        }

        onPaymentSubmitted(token);
    }

    return (
        <>
            { isApplePayEnabled && (
                <div className="apple-pay-button apple-pay-button-black">
                </div>
            )}
            { isMollieComponentsInitDone || <LoadingView minHeight="192px"/> }
            <Form.Group className="mollie-wrapper" style={{ display: isMollieComponentsInitDone ? 'block' : 'none' }}>
                <div className="mollie-form">
                    <div className="mollie-row">
                        <div className="mollie-form-group mollie-form-group--card-number">
                            <label className="mollie-label" htmlFor="card-holder">Name on card</label>
                            <div id="card-holder"></div>
                            <div
                                id="card-holder-error"
                                className="field-error"
                                role="alert"
                            ></div>
                        </div>
                    </div>

                    <div className="mollie-row">
                        <div className="mollie-form-group mollie-form-group--card-number">
                            <div id="card-number"></div>
                            <label className="mollie-label" htmlFor="card-number">Card number</label>
                            <div
                                id="card-number-error"
                                className="field-error"
                                role="alert"
                            ></div>
                        </div>

                        <div className="mollie-form-group mollie-form-group--expiry-date">
                            <label className="mollie-label" htmlFor="expiry-date">Expiry date</label>
                            <div id="expiry-date"></div>
                            <div
                                id="expiry-date-error"
                                className="field-error"
                                role="alert"
                            ></div>
                        </div>

                        <div className="mollie-form-group mollie-form-group--verification-code">
                            <label className="mollie-label" htmlFor="verification-code">CVC</label>
                            <div id="verification-code"></div>
                            <div
                                id="verification-code-error"
                                className="field-error"
                                role="alert"
                            ></div>
                        </div>
                    </div>
                </div>
            </Form.Group>

            <Form.Group style={{ display: "flex" }} className="checkout-pay">
                <div className="checkout-total">
                    <p>Total:</p>
                    <p>
                        {formatCurrency(undefined, 2).format(
                            paymentAmount,
                        )}
                    </p>
                </div>
                <Button disabled={!isPaymentEnabled || isLoading || isMollieCreatingToken} onClick={handleSubmit}>
                    {(isLoading || isMollieCreatingToken) ? (
                        <>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />{" "}Pay
                        </>
                    ) : (
                        "Pay"
                    )}
                </Button>
            </Form.Group>
        </>
    )
}

export default PaymentMollie;
