import React from "react";
import { ApolloProvider } from "react-apollo";
import { PersistGate } from "redux-persist/integration/react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import { Provider as ReduxProvider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { Helmet } from "react-helmet";

import TrackingPixels from "./components/app/TrackingPixels";

import { store, persistor } from "./state/store";
import client from "./state/configure-client";
import i18n from "./i18n";
import TopNavContainer from "./caskshare-v3/components/app/TopNavContainer";
import { ErrorBoundary } from "./components/app/ErrorBoundary";
import { PrivateRoute } from "./components/app/PrivateRoute";
import { RequireAuth } from "./components/app/RequireAuth";

import Footer from "./caskshare-v3/components/app/Footer";
import ScrollToTop from "./components/app/ScrollToTop";
import CookieConsentStrip from "./components/app/CookieConsentStrip";
import CheckoutSuccess from "./components/checkout/CheckoutSuccess";

import AboutPage from "./pages/AboutPage/AboutPage";
import AccountOverViewPage from "./pages/AccountOverviewPage";
import ClaimGiftPage from "./pages/ClaimGiftPage";
import BasketPage from "./pages/BasketPage";
import CasksharesPage from "./pages/CasksharesPage";
// import CasksharePage from "./pages/.v2/CasksharePages/CasksharePage";
import CasksharePage from "./caskshare-v3/pages/caskshare-page/CasksharePage";
import CheckoutPage from "./pages/CheckoutPage";
import ContactPage from "./pages/ContactPage";
import DiscoverPage from "./pages/DiscoverPage";
import DistilleriesPage from "./pages/DistilleriesPage";
import DistilleryPage from "./pages/DistilleryPage";
import HomePage3 from "./caskshare-v3/pages/home/HomePage3";
import LoginPage from "./pages/.v2/RegisterPage/LoginPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import ActivateAccountPage from "./pages/ActivateAccountPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PressPage from "./pages/PressPage/PressPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import RegisterPage from "./pages/.v2/RegisterPage/RegisterPage";
import ShopPage from "./pages/ShopPage";
// import SelectPlan from "./pages/.v2/RegisterPage/SelectPlan";
import SignatureSeries from "./pages/SignatureSeries/SignatureSeries";
import TermsPage from "./pages/TermsPage";
import GiftIdeas from "./pages/.v2/GiftingPage/GiftIdeas";
import GiftingPage from "./pages/.v2/GiftingPage/GiftingPage";
import GiftingPageSuccess from "./pages/.v2/GiftingPage/GiftingPageSuccess";
import NotFoundPage from "./pages/NotFoundPage";
import SellYourCask from "./pages/SellYourCask";
import Collective from "./pages/.v2/CollectivePage/Collective";
import CaskThumbnailPage from "./pages/CaskThumbnailPage";
import RafflePage from "./pages/HiddenPages/RafflePage";
import MeetMaker from "./pages/HiddenPages/MeetMaker";
import QuizPage from "./pages/HiddenPages/QuizPages/QuizPage";
import WelcomeToCaskshare from "./pages/.v2/RegisterPage/WelcomeToCaskshare";
import InsideCollective from "./components/account/collective/InsideCollective";
import BallotSuccess from "./pages/HiddenPages/PrivateSalePage/BallotSuccess";
import BallotFallback from "./pages/HiddenPages/PrivateSalePage/BallotFallback";
import AgeRestriction from "./components/app/.v2/AgeRestriction/AgeRestriction";
import { setTimeout } from "timers";
import PrivateSale from "./pages/HiddenPages/PrivateSalePage/PrivateSale";
import EmailOpt from "./pages/HiddenPages/EmailOpt";
import CollectivePage from "./caskshare-v3/pages/collective-marketing/CollectivePage";
import CollectiveSuccess from "./components/account/CollectiveSuccess";
import CollectiveCancel from "./components/account/CollectiveCancel";
import CollectiveDepositSuccess from "./components/account/CollectiveDepositSuccess";
import CollectiveCardUpdateSuccess from "./components/account/CollectiveCardUpdateSuccess";
import WelcomePage from "./pages/WelcomePage";
import EmbeddedCheckoutPage from "./pages/EmbeddedCheckoutPage";
import PromoProductPage from "./pages/PromoProductPage";

function App() {
  const location = window.location.href;
  return (
    <ErrorBoundary>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <ReduxProvider store={store}>
            <PersistGate persistor={persistor}>
              <Router>
                {location.includes("cask-thumbnail") ? (
                  <Route exact path="/cask-thumbnail/:caskId">
                    <CaskThumbnailPage />
                  </Route>
                ) : (
                  <>
                    <TrackingPixels />
                    <ScrollToTop />
                    <TopNavContainer />
                    <Switch>
                      <Route exact path="/:locale(gb|us|eu)?">
                        <HomePage3 />
                      </Route>
                      <Route path="/:locale(gb|us|eu)?/login">
                        <LoginPage />
                      </Route>
                      <Route
                        exact
                        path={["/account/reset", "/account/reset/:slug"]}
                      >
                        <PasswordResetPage />
                      </Route>
                      <Route path="/account/activate/:customerId/:activationToken">
                        <ActivateAccountPage />
                      </Route>
                      <Route path="/:locale(gb|us|eu)?/forgot-password">
                        <ForgotPasswordPage />
                      </Route>
                      <Route
                        exact
                        path={[
                          "/:locale(gb|us|eu)?/register",
                          "/:locale(gb|us|eu)?/register/:slug",
                        ]}
                      >
                        <RegisterPage />
                      </Route>
                      <Route
                        exact
                        path={[
                          "/find-your-flavour",
                          "/find-your-flavour/:slug",
                        ]}
                      >
                        <QuizPage />
                      </Route>
                      <Route
                        exact
                        path={[
                          "/:locale(gb|us|eu)?/caskshares",
                          "/:locale(gb|us|eu)?/caskshares/selection/:tag",
                          "/:locale(gb|us|eu)?/caskshares/filters/:filters",
                        ]}
                      >
                        <CasksharesPage />
                      </Route>
                      <Route exact path="/contact">
                        <ContactPage />
                      </Route>
                      <Route exact path="/welcome">
                        <WelcomePage />
                      </Route>
                      <Route exact path="/discover">
                        <DiscoverPage />
                      </Route>
                      <Route exact path="/:locale(gb|us|eu)?/the-collective">
                        <CollectivePage />
                      </Route>
                      <Route exact path="/about-us">
                        <AboutPage />
                      </Route>
                      <Route exact path="/in-the-press">
                        <PressPage />
                      </Route>
                      <Route exact path="/distilleries">
                        <DistilleriesPage />
                      </Route>
                      <Route exact path="/distillery/:slug">
                        <DistilleryPage />
                      </Route>
                      <Route exact path="/signature-series/:slug">
                        <SignatureSeries />
                      </Route>
                      <Route path="/products/:productId">
                        <ProductDetailsPage />
                      </Route>
                      <Route path="/product/:productId">
                        <PromoProductPage />
                      </Route>
                      <Route
                        path={[
                          "/:locale(gb|us|eu)?/caskshares/:caskId",
                          "/caskshares/:caskId",
                        ]}
                      >
                        <CasksharePage />
                      </Route>
                      <Route path="/raffle/:raffleId">
                        <RafflePage />
                      </Route>
                      <Route
                        exact
                        path={[
                          "/private-sale/:slug",
                          "/ballot/:slug",
                          "/special-release/:slug",
                        ]}
                      >
                        <PrivateSale />
                      </Route>
                      <Route exact path="/ballot">
                        {/* <BallotFallback /> */}
                        <Redirect to="/ballot/macallan-32" />
                      </Route>
                      <Route
                        exact
                        path={[
                          "/email",
                          "/email/:action",
                          "/email/:campaign",
                          "/email/:action/:campaign",
                        ]}
                      >
                        <EmailOpt />
                      </Route>
                      <Route
                        exact
                        path={["/ballot-success", "/ballot-success/:slug"]}
                      >
                        <BallotSuccess />
                      </Route>
                      <Route
                        exact
                        path={[
                          "/:locale(gb|us|eu)?/shop",
                          "/:locale(gb|us|eu)?/shop/:collectionId",
                        ]}
                      >
                        <ShopPage />
                      </Route>
                      <Route path="/basket">
                        <BasketPage />
                      </Route>
                      <Route path="/checkout">
                        <CheckoutPage />
                      </Route>
                      <Route path="/terms/:slug">
                        <TermsPage />
                      </Route>
                      <Route exact path="/gifting">
                        <GiftIdeas />
                      </Route>
                      <Route exact path="/gifting/:slug">
                        <GiftingPage />
                      </Route>
                      <Route exact path="/gifting-success">
                        <GiftingPageSuccess />
                      </Route>
                      <Route exact path="/checkout-success">
                        <CheckoutSuccess />
                      </Route>

                      <Route exact path="/campaign/:campaign">
                        <EmbeddedCheckoutPage />
                      </Route>

                      <Route
                        exact
                        path="/my-account/collective"
                        render={() => (
                          <RequireAuth>
                            <InsideCollective />
                          </RequireAuth>
                        )}
                      />

                      <PrivateRoute exact path="/my-account/collective/success">
                        <CollectiveSuccess />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path="/my-account/collective/deposit-setup/success"
                      >
                        <CollectiveDepositSuccess />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={[
                          "/my-account/collective/deposit-setup/cancel",
                          "/my-account/collective/card-update/cancel",
                          "/my-account/collective/cancel",
                        ]}
                      >
                        <CollectiveCancel />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path="/my-account/collective/card-update/success"
                      >
                        <CollectiveCardUpdateSuccess />
                      </PrivateRoute>
                      <PrivateRoute path="/my-account">
                        <AccountOverViewPage />
                      </PrivateRoute>
                      <PrivateRoute path="/welcome-to-caskshare">
                        <WelcomeToCaskshare />
                      </PrivateRoute>

                      <Route exact path="/welcome/joining-offer">
                        <WelcomeToCaskshare unauthenticated={true} />
                      </Route>

                      <PrivateRoute path="/claim-a-gift">
                        <ClaimGiftPage />
                      </PrivateRoute>
                      <PrivateRoute path="/meet-the-maker">
                        <MeetMaker />
                      </PrivateRoute>
                      {/* Any redirects needed before 404 */}
                      <Route exact path="/macallan-32">
                        <Redirect to="/ballot/macallan-32" />
                        {/* <Redirect to="/ballot" /> */}
                      </Route>
                      <Route>
                        <NotFoundPage />
                      </Route>
                    </Switch>
                    <CookieConsentStrip />
                    <AgeRestriction />
                    {/* <CurrencyModal /> */}
                    <Footer />
                  </>
                )}
              </Router>
            </PersistGate>
          </ReduxProvider>
        </I18nextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

function wait(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export default App;
