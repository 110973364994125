import React from "react";
import { Col } from "react-bootstrap";
import TeamCard from "../../components/team/TeamCard";
import styles from "./TeamDesktop.module.scss";

const TeamDesktop = ({ data }: { data: any }) => {
  return (
    <>
      {data?.aboutEntries
        ?.sort((a: any, b: any) => {
          if (a.ordinal === b.ordinal) {
            return 0;
          }
          if (a.ordinal === null) {
            return 1;
          }
          if (b.ordinal === null) {
            return -1;
          }
          return a.ordinal < b.ordinal ? -1 : 1;
        })
        ?.map((team: any) => (
          <Col
            key={team.id}
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            className={styles.teamCardWrapper}
          >
            <TeamCard team={team} />
          </Col>
        ))}
    </>
  );
};

export default TeamDesktop;
