import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IMAGE_FALLBACKS } from "../../config";
import { Product } from "../../graphql/Products";
import { HashLink } from "react-router-hash-link";

import shopImg1 from "../../assets/images/shop-img-1.png";
import shopImg2 from "../../assets/images/shop-img-2.png";

const Pagination = ({
  data,
  RenderComponent,
  pageLimit,
  dataLimit,
  currentPage,
  setCurrentPage,
  onClick,
}: {
  data: any;
  RenderComponent: any;
  currentPage: any;
  setCurrentPage: any;
  pageLimit: number;
  dataLimit: number;
  onClick: (product: Product) => void;
}) => {
  // const [currentPage, setCurrentPage] = useState(1);
  const pages = Math.round(data.length / dataLimit);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, [currentPage]);

  function goToNextPage() {
    setCurrentPage((page: any) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page: any) => page - 1);
  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit)
      .fill(undefined)
      .map((_, idx) => start + idx + 1);
  };

  return (
    <>
      <Row className="dataContainer">
        {getPaginatedData().map((d: any, idx: any) => (
          <RenderComponent key={idx} data={d} onClick={onClick} />
        ))}
        {getPaginatedData()[0]?.length < 8 ? null : (
          <Col xs={12} sm={6} lg={4} className="whisky-card extra-card">
            <div className="shop-extra-card">
              <div className="text-wrap">
                <h4>
                  single cask <br /> whisky <br /> for all
                </h4>
              </div>
              <div className="img-wrap">
                <Button
                  smooth
                  to={{ pathname: "/caskshares" }}
                  target="_blank"
                  as={HashLink}
                  variant="primary"
                  className="shop-btn"
                >
                  Browse Caskshares
                </Button>
                <img src={shopImg1} alt="holding whisky bottle at the beach" />
              </div>
              <div className="overlay-img">
                <img src={shopImg2} alt="warehouse full of whisky casks" />
              </div>
            </div>
          </Col>
        )}
      </Row>
      {data.length <= 1 ? null : (
        <div className="pagination">
          <button
            onClick={goToPreviousPage}
            className={`prev ${currentPage === 1 ? "disabled" : ""}`}
          >
            Prev
          </button>
          {getPaginationGroup().map((item, index) => (
            <button
              key={index}
              onClick={changePage}
              className={`paginationItem ${
                currentPage === item ? "active" : null
              }`}
            >
              <span>{item}</span>
            </button>
          ))}
          <button
            onClick={goToNextPage}
            className={`next ${currentPage === pages ? "disabled" : ""}`}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};
export default Pagination;
