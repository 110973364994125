import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga"

const GATracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    if (!window.location.href.includes("localhost") &&
        !window.location.href.includes("staging")) {
      ReactGA.initialize("UA-71716577-4");
    } else {
      ReactGA.initialize("UA-71716577-6");
    }
    ReactGA.plugin.require("ecommerce");
    setInitialized(true);
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname);
    }
  }, [initialized, location])
}

export default GATracking
