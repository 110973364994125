import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "react-apollo";

import { Col, Row, Button, Modal, Container } from "react-bootstrap";
import styles from "./SignatureCard.module.scss";

import { formatCurrency } from "../../utils/currency";
import { Cask } from "../../graphql/Casks";
import { GET_CUSTOMER } from "../../graphql/User";
import { RootState } from "../../state/rootReducer";
import FlavourMapGraphic from "../cask/FlavourMapGraphic";
import { IMAGE_FALLBACKS } from "../../config";
import CaskImage from "../casks/CaskImage";

const elideStr = (str: string, len: number) =>
  str != null && str.length > len ? str.slice(0, len) + "..." : str;

const SignatureCard = ({
  cask,
  onClick,
}: {
  cask: Cask;
  onClick: (cask: Cask) => void;
}) => {
  const [price, setPrice] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);
  const [collectiveNote, setCollectiveNote] = useState("");

  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      shop: shop.id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!data || !data.customer) {
      setPrice(cask.defaultVariant.price.amount);
      setCollectiveUser(false);
      setCollectiveNote(
        `${Number(cask.percentageDiscount) * 100}% off for Collective members`,
      );
    } else if (loggedInUser && data && data.customer.collectiveActive) {
      setPrice(cask.memberPrice);
      setCollectiveUser(true);
      setCollectiveNote(
        `${Number(cask.percentageDiscount) * 100}% Collective discount applied`,
      );
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("5% off for Collective members");
    };
  }, [loggedInUser, data, data?.customer?.collectiveActive]);

  const formatDate = (d: Date) =>
    d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();

  let bottlingDate;
  if (cask.bottlingMonth && cask.bottlingYear) {
    bottlingDate = formatDate(
      new Date(cask.bottlingYear, cask.bottlingMonth - 1, 1),
    );
  } else {
    bottlingDate = "TBA...";
  }

  const compareDates = (type: string) => {
    let today =
      Number(new Date().getFullYear()) + Number(new Date().getMonth()) / 100;
    let caskDate = cask.bottlingYear + cask.bottlingMonth / 100;

    if (type === "bottling" && caskDate === 0) {
      return "bottling";
    } else if (type === "bottling" && Number(caskDate) <= Number(today)) {
      return "bottled";
    } else if (type === "bottling" && Number(caskDate) >= Number(today)) {
      return "bottling";
    } else if (type === "purchase" && caskDate === 0) {
      return "Reserve";
    } else if (type === "purchase" && Number(caskDate) <= Number(today)) {
      return "Buy";
    } else if (type === "purchase" && Number(caskDate) >= Number(today)) {
      return "Reserve";
    } else {
      return;
    }
  };

  const title = (seller: any) => {
    return seller.curatorName ? seller.curatorName : seller.title;
  };

  const getProfilePic = (attachments: any[]) => {
    let images = attachments.filter((a) => a.role === "profile-pic");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPageEnviron;
    return image;
  };

  const getProfileTitle = (attachments: any[]) => {
    let images = attachments.filter((a) => a.role === "profile-pic");
    let image = images.length ? images[0].title : "";
    return image;
  };

  return (
    <Row as="section">
      <Col>
        <Row>
          <Col xs={12} lg={8} className={styles.imgInfoWrapper}>
            <Row>
              <Col sm={5}>
                <CaskImage onClick={() => onClick(cask)} cask={cask} />
              </Col>
              <Col sm={7}>
                <h4 className={styles.caskTitle}>{cask.title}</h4>
                <p className={styles.caskCode}>Cask code: {cask.code}</p>
                <p className={styles.caskCode}>{cask.storedAt}</p>
                <p className={styles.caskDescription}>
                  {cask.shortDescription}
                </p>

                <Row>
                  <Col xs={5} className={styles.caskInfoCenter}>
                    <small className={styles.caskInfoTitle}>Distilled</small>
                    <p className={styles.caskInfoText}>
                      {cask.bondYear ? cask.bondYear : "TBA..."}
                    </p>
                  </Col>
                  <Col xs={7} className={styles.caskInfoCenter}>
                    <small className={styles.caskInfoTitle}>
                      {compareDates("bottling")}
                    </small>
                    <p className={styles.caskInfoText}>{bottlingDate}</p>
                  </Col>
                </Row>

                <Row className={styles.priceInfo}>
                  <Col xs={12} className={styles.priceWrap}>
                    <div className={styles.price}>
                      <small>Price / 70cl bottle</small>
                      <p
                        className={
                          collectiveUser
                            ? styles.collectivePrice
                            : styles.nonCollective
                        }
                      >
                        {formatCurrency(shop.currency, 2).format(price)}
                      </p>
                    </div>
                    <div className={styles.button}>
                      <Button
                        className={styles.btnLight}
                        onClick={() => onClick(cask)}
                      >
                        Shop now
                      </Button>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    className={`${styles.banner} ${
                      collectiveUser
                        ? styles.collectivePrice
                        : styles.nonCollective
                    }`}
                  >
                    <p>{collectiveNote}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={4} className={styles.flavourNotesArea}>
            {!cask.flavourMap.complexity &&
            !cask.flavourMap.fruitiness &&
            !cask.flavourMap.smokiness &&
            !cask.flavourMap.spice &&
            !cask.flavourMap.sweetness &&
            cask.seller.signatureSeries ? (
              <div className="sig-series-profile-link">
                <h4>
                  <span>signature</span> series {title(cask.seller)}
                </h4>
                <Link
                  to={`/signature-series/${cask.seller.slug}`}
                  className="sig-series-img"
                >
                  <img
                    src={getProfilePic(cask.seller.attachments)}
                    alt={getProfileTitle(cask.seller.attachments)}
                    className="img-fluid"
                  />
                </Link>
              </div>
            ) : (
              <>
                <small className={styles.flavourNotesTitle}>
                  Flavour profile
                </small>
                <p className={styles.flavourNotesText}>
                  {elideStr(cask.flavourNotes, 160)}
                </p>
                <div className={styles.flavourMapGraphicArea}>
                  <FlavourMapGraphic cask={cask} />
                </div>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignatureCard;
