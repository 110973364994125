import React, { useState } from 'react'
import { Form, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { store } from '../../state/store'
import { formatCurrency } from '../../utils/currency'

// -------------------------------------------------------------------------------------------------
// this is here for now - but we need to get this from somewhere!
const shippingOptions = [ {
    code: 'standard',
    name: 'Standard',
    description: 'Royal Mail, second class',
    cost: {
      gbp: 0,
      usd: 0
    }
  }, {
    code: 'first-class',
    name: 'First Class',
    description: 'Royal Mail, first class',
    cost: {
      gbp: 3.2,
      usd: 6.38
    }
  }
]
// -------------------------------------------------------------------------------------------------
const CheckoutShipping = () => {
  let hasSelection = true;
  const currencyCode = store.getState().shop.currency
  const [shippingOption, setShippingOption] = useState<any>(null)

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleSubmit = (event: any) => {
    event.preventDefault()
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const onSelectOption = async (event: any) => {
    event.preventDefault()
    hasSelection = true;
    setShippingOption(event.currentTarget.value);
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const formatCost = (cost: any, currencyCode: string) => {
    const costForCurrency = cost[currencyCode];
    if(costForCurrency === undefined) {
      return 'UNKNOWN'
    } else if(!costForCurrency) {
      return 'FREE'
    }
    return `${formatCurrency().format(costForCurrency)}`
  }

  // -----------------------------------------------------------------------------------------------
  return (
    <Form onSubmit={handleSubmit}>

      <Form.Label>
        Shipping option
      </Form.Label>

      {
        shippingOptions.map((option, idx) => (
          <Form.Row>
            <Form.Group>
              <Form.Check
                id={option.code + idx}
                key={option.code + idx}
                name={'shipping-option'}
                onChange={onSelectOption}
                value={option.code}
                label={option.description + ' - ' + formatCost(option.cost, currencyCode)}
                type="radio"
                checked={option.code === shippingOption}
              />
            </Form.Group>
          </Form.Row>
        ))
      }

      <Form.Row>
        <Form.Group
          style={{ display: 'flex' }}
          className="justify-content-end">
          <Button as={Link} to="/checkout/payment" disabled={!hasSelection}>
            To Payment
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  )
}

export default CheckoutShipping
