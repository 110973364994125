import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getMetaText, getCopyText } from "../AppText";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const DiscoverPage = () => {
  return (
    <>
      <Container>
        <Helmet>
          <title>{getMetaText("DISCOVER_PAGE", "title")}</title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="keywords"
            content={getMetaText("DISCOVER_PAGE", "keywords")}
          />
          <meta
            name="description"
            content={getMetaText("DISCOVER_PAGE", "description")}
          />
          <meta
            name="author"
            content={getMetaText("DISCOVER_PAGE", "author")}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content={getMetaText("DISCOVER_PAGE", "title")}
          />
          <meta
            property="og:description"
            content={getMetaText("DISCOVER_PAGE", "description")}
          />
          <meta
            property="og:image"
            content={
              window.location.origin +
              require("../assets/discover-page/looking-out-of-window.png")
            }
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@thecaskshare" />
          <meta
            name="twitter:title"
            content={getMetaText("DISCOVER_PAGE", "title")}
          />
          <meta
            name="twitter:description"
            content={getMetaText("DISCOVER_PAGE", "description")}
          />
          <meta
            name="twitter:image"
            content={
              window.location.origin +
              require("../assets/discover-page/looking-out-of-window.png")
            }
          />
        </Helmet>

        <Row className="not-a-club" as="section">
          <Col>
            <Row>
              <Col md={5}>
                <Row>
                  <Col xs={12}>
                    <h3>
                      Not a club, a <u>movement</u>
                    </h3>
                  </Col>
                  <Col className="looking-out-of-window-area" style={{}}>
                    <div
                      className="looking-out-of-window-div-image"
                      style={{
                        backgroundImage: `url(${require("../assets/discover-page/looking-out-of-window.png")})`,
                      }}
                    />
                    <div
                      className="rows-of-casks-div-image"
                      style={{
                        backgroundImage: `url(${require("../assets/discover-page/rows-of-casks.png")})`,
                      }}
                    />
                    <img
                      className="d-md-none caskshare-stamp-div-image-discover"
                      alt="Not a club, a movement!"
                      src={require("../assets/images/caskshare-stamp-not-a-club-letters-black.svg")}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="not-a-club-a-movement-text-area">
                <p>
                  {getCopyText("DISCOVER_PAGE", "not-a-club-a-movement-text")}
                </p>
              </Col>
              <Col md={3} className="d-none d-md-block">
                <img
                  className="caskshare-stamp-div-image-discover"
                  alt="Not a club, a movement!"
                  src={require("../assets/images/caskshare-stamp-not-a-club-letters-black.svg")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="born-from-a-love" as="section">
          <Col sm={7} className="text-area">
            <Row>
              <Col sm={12}>
                <h4>
                  {getCopyText("DISCOVER_PAGE", "born-from-a-love-heading")}
                </h4>
              </Col>
              <Col sm={6}>
                <p>{getCopyText("DISCOVER_PAGE", "born-from-a-love-para-1")}</p>
              </Col>
              <Col sm={6}>
                <p>{getCopyText("DISCOVER_PAGE", "born-from-a-love-para-2")}</p>
              </Col>
            </Row>
          </Col>
          <Col
            sm={5}
            className="cliffs-and-lighthouse-div-image"
            style={{
              backgroundImage: `url(${require("../assets/discover-page/cliffs-and-lighthouse.png")})`,
            }}
          />
        </Row>
        <Row className="the-uncompromised-experience" as="section" style={{}}>
          <Col>
            <Row>
              <Col xs={0} lg={1} />
              <Col
                xs={12}
                lg={10}
                className="justify-content-center align-items-center d-flex flex-column"
              >
                <div className="separator" />
                <h4
                  style={{
                    textAlign: "center",
                  }}
                >
                  The uncompromised experience of single cask whisky, by the
                  bottle
                </h4>
              </Col>
              <Col xs={0} lg={1} />
              <Col xs={0} lg={1} />

              <Col xs={12} lg={10}>
                <SelectMatureEnjoy />
              </Col>
              <Col xs={0} lg={1} />
            </Row>
          </Col>
        </Row>

        <Row className="an-investment-with-character" as="section" style={{}}>
          <Col xs={12} sm={4} className="dram-glass-area">
            <div
              className="dram-glass-div-image"
              style={{
                backgroundImage: `url(${require("../assets/discover-page/dram-glass.png")})`,
              }}
            />
          </Col>
          <Col sm={3} className="heading-area">
            <h4>An investment with character</h4>
          </Col>
          <Col sm={1}></Col>
          <Col sm={4} className="text-area">
            <p>
              {getCopyText("DISCOVER_PAGE", "an-investment-with-character")}
            </p>
          </Col>
        </Row>
      </Container>

      <div
        className="partnering-with-the-finest-breakout"
        style={{
          backgroundImage: `url(${require("../assets/images/woodgrain-lines.svg")})`,
        }}
      >
        <Container>
          <Row className="partnering-with-the-finest" as="section">
            <Col>
              <Row>
                <Col xs={12} sm={6} md={4} className="gold-text-area">
                  <h4>Partnering with the finest distilleries</h4>
                </Col>
                <Col xs={12} sm={6} md={3} className="para-text-area">
                  <p>
                    {getCopyText(
                      "DISCOVER_PAGE",
                      "partnering-with-the-finest-distilleries",
                    )}
                  </p>
                  <Button
                    as={Link}
                    to="/distilleries"
                    variant="outline-light"
                    className="explore-button big"
                  >
                    Explore all Distilleries
                  </Button>
                </Col>
                <Col xs={0} md={1}></Col>
                <Col md={4} className="d-none d-md-block">
                  <div className="view-over-loch-div-mask" />
                  <div
                    className="view-over-loch-div-image"
                    style={{
                      backgroundImage: `url(${require("../assets/discover-page/view-over-loch.png")})`,
                    }}
                  />
                  <div
                    className="raasay-distillery-premises-div-image"
                    style={{
                      backgroundImage: `url(${require("../assets/discover-page/raasay-distillery-premises.png")})`,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// this could be a separate component but its only used on this page, being
//   is separated out to make DiscoverPage more managable. So I'm keeping it
//   here for now
const SelectMatureEnjoy = () => {
  return (
    <div
      className="select-mature-enjoy-mature-component"
      style={{
        backgroundImage: `url(${require("../assets/images/timeline/dotted-line.svg")})`,
      }}
    >
      <div className="icon-and-text-area">
        <img
          className="icon-image"
          src={require("../assets/discover-page/select-mature-enjoy/rosette.svg")}
        />
        <div className="text-area">
          <h5>Select &amp; Reserve</h5>
          <p>
            {getCopyText(
              "DISCOVER_PAGE",
              "select-mature-enjoy-select-and-reserve",
            )}
          </p>
        </div>
      </div>

      <div className="icon-and-text-area">
        <img
          className="icon-image"
          src={require("../assets/discover-page/select-mature-enjoy/cask.svg")}
        />
        <div className="text-area">
          <h5>Mature</h5>
          <p>{getCopyText("DISCOVER_PAGE", "select-mature-enjoy-mature")}</p>
        </div>
      </div>
      <div className="icon-and-text-area">
        <img
          className="icon-image"
          src={require("../assets/discover-page/select-mature-enjoy/bottle.svg")}
        />
        <div className="text-area">
          <h5>Enjoy</h5>
          <p>{getCopyText("DISCOVER_PAGE", "select-mature-enjoy-enjoy")}</p>
        </div>
      </div>
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default DiscoverPage;
