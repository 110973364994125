import React from "react";
import { Shop } from "../../graphql/Shops";

const DeliveryInfo = ({
  setDeliveryInfo,
  title,
  message,
  shop,
}: {
  setDeliveryInfo: any;
  title?: any;
  message?: any;
  shop: Shop
}) => {
  return (
    <div className="delivery-info-popup">
      <div className="delivery-info-wrap">
        <div className="fixed">
          {!title ? (
            <h4>
              Important <br /> Delivery Information
            </h4>
          ) : (
            title
          )}
          <span
            onClick={() => {
              setDeliveryInfo(false);
            }}
          >
            <i className="fa fa-times"></i>
          </span>
        </div>
        {/* <p>All Bottle Shop orders will be shipped within five working days.</p> */}
        {!message ? (
          <>
            {shop.market == 'GB' ? (
              <>
                <p>
                  For Caskshares that are already bottled, we will ship to you within 2-4
                  business days. For maturing products we will contact you to arrange
                  shipping after bottling.
                </p>
                <p>
                  Shipping within the UK is via DHL Parcel.
                </p>
                <p>
                  If you are buying from outside the UK, please check the additional tax
                  implications for shipping internationally ahead of purchasing with us.
                  It is the recipient's responsibility to settle any local import tax and
                  duties.
                </p>
                <p>
                  We can ship to most EU countries using DHL Express. Shipping costs are 
                  calculated at checkout.
                </p>
                <p>
                  We can ship to all contiguous USA States. Additionally, Alaska - Anchorage
                  only (at additional charge). Prices vary from £55 to £100 for the first 6
                  bottles. Shipping costs will be calculated at checkout.
                </p>
                <p>
                  We can ship to most other Asia Pacific, South America and Rest of the
                  World countries via DHL Express. Shipping costs will be calculated at
                  checkout.
                </p>
                <p>
                  In all cases, you may also opt to collect your Caskshares from
                  Edinburgh, Scotland.
                </p>
              </>
            ) : shop.market == 'US' ? (
              <>
                <p>
                  Our retail sellers can ship to all US Continguous States with the
                  following exceptions:
                </p>
                <p>
                    MA AK HI UT PR GU MI SD OK IL
                </p>
                <p>
                  Shipping costs are calculated at checkout.
                </p>
              </>
            ) : shop.market == 'EU' ? (
                <>
                  <p>
                    We can ship to the following EU countries: France, Belgium, France,
                    Germany, Italy, Netherlands, Sweden
                  </p>
                  <p>
                    Shipping costs will be calculated at checkout.
                  </p>
                </>
            ) : null
            }
            <p>
              Please contact us at the email address below if you require further information.
            </p>
            <p>
              <a
                href="
              mailto:contact@caskshare.com?subject=Message from Caskshare site"
                className="footer-link"
              >
                contact@caskshare.com
              </a>
              .
            </p>
            <p>
              Slainte Mhath! <br />
              The Caskshare Team
            </p>
          </>
        ) : (
          message
        )}
      </div>
    </div>
  );
};

export default DeliveryInfo;
