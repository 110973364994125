import React, { useState, useEffect } from "react";
import { Container, Form, Col, Row, Button, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";
import { loadStripe } from "@stripe/stripe-js";
import { IoMdLock } from "react-icons/io";

import { getMetaText, getCopyText } from "../../../AppText";
import { setUser } from "../../../state/slices/userSlice";
import { RootState } from "../../../state/rootReducer";
import { REGISTER } from "../../../graphql/Auth";
import { SHOPS, Shop } from "../../../graphql/Shops";
import { GET_CUSTOMER } from "../../../graphql/User";
import { COLLECTIVE_CHECKOUT } from "../../../graphql/Collective";
import {
  GET_MEMBER_PRODUCTS,
  MemberProduct,
} from "../../../graphql/MemberProduct";
import { ReactComponent as Logo } from "../../../assets/images/rebrand-logo.svg";
import styles from "./RegisterPage.module.scss";
import { formatCurrency } from "../../../utils/currency";

const RegisterPage = () => {
  // @ts-ignore
  const { slug } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectVisible, setSelectVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [whiskyClub, setWhiskyClub] = useState("");
  const [selectedPlan, setSelectedPlan] = useState<any>({
    id: "",
    title: "",
    name: "",
    value: 0,
    stripePrice: "",
    referralCode: "",
    memberAddOns: [],
  });

  const [error, setError] = useState("");
  const [customerRegister] = useMutation(REGISTER);
  const history = useHistory();
  const dispatch = useDispatch();
  const shop = useSelector((state: RootState) => state.shop);
  const checkout = useSelector((state: RootState) => state.checkout);

  const { data: memberData } = useQuery(GET_MEMBER_PRODUCTS, {
    variables: {
      shop: shop.id,
    },
    skip: !shop.id,
  });

  const { data, loading: shopsLoading } = useQuery(SHOPS);
  const [collectiveCheckout, { loading: collectiveCheckoutLoading }] =
    useMutation(COLLECTIVE_CHECKOUT, {
      refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
    });
  const { refetch, loading: customerLoading } = useQuery(GET_CUSTOMER, {
    variables: {
      shop: shop.id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (slug && memberData?.memberProducts) {
      const collective = memberData?.memberProducts.find(
        (m: MemberProduct) => m.name === "Collective",
      );
      setSelectedPlan({
        ...selectedPlan,
        referralCode: slug || "",
        name: collective.name,
        value: collective.value,
        stripePrice: collective.stripePrice,
        memberAddOns: collective.memberAddOns,
      });
    }
    return () => {
      setSelectedPlan({
        ...selectedPlan,
        referralCode: "",
        name: "",
        value: 0,
        stripePrice: "",
        memberAddOns: [],
      });
    };
  }, [slug, memberData?.memberProducts]);

  useEffect(() => {
    if (history.location.state) {
      setSelectedPlan(history.location.state);
    }
    return () => {
      setSelectedPlan({});
    };
  }, [history.location]);

  if (tooltipVisible === true) {
    const body = document.querySelector("body");
    body?.addEventListener("click", () => setTooltipVisible(!tooltipVisible));
  }

  const onRegister = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setTimeout(() => {
        setError("");
      }, 3000);
      setLoading(false);
      return;
    }
    try {
      const { data: registerData, errors } = await customerRegister({
        variables: {
          shop: shop.id,
          email: email,
          password: password,
          passwordConfirmation: confirmPassword,
          affiliateClub: whiskyClub,
          redirectUrl: `${window.location.origin}/account/reset`,
        },
      });
      if (errors) {
        setError(errors[0].message);
        setTimeout(() => {
          setError("");
        }, 3000);
        setLoading(false);
        return;
      }
      dispatch(setUser(registerData?.customerRegister));

      if (selectedPlan.value >= 1) {
        const stripe = await loadStripe(shop.stripePublishableKey);
        const { data: collectiveData } = await collectiveCheckout({
          variables: {
            shop: shop.id,
            quantity: 1,
            product: selectedPlan.name,
            stripePrice: selectedPlan.stripePrice,
            successPath: selectedPlan.referralCode
              ? "welcome-to-caskshare/referred"
              : "welcome-to-caskshare",
            cancelPath: "select-your-plan",
            referralCode: selectedPlan.referralCode,
          },
        });

        if (
          collectiveData.collectiveCheckout.sessionId &&
          !collectiveCheckoutLoading
        ) {
          stripe?.redirectToCheckout({
            sessionId: collectiveData.collectiveCheckout.sessionId,
          });
        } else {
          setError(
            "There was a problem re-directing you to the stripe. Please, try again later.",
          );
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      }

      await refetch();

      if (checkout?.lineItems.length !== 0) {
        history.replace("/basket");
      } else if (window.sessionStorage.location) {
        history.replace({
          pathname: window.sessionStorage.location,
          state: { from: "/register" },
        });
      } else {
        history.replace("/welcome-to-caskshare");
      }
    } catch (registerError) {
      // @ts-ignore
      setError(registerError.message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
    setLoading(false);
  };

  const marketString = (market: string) => {
    switch (market) {
      case "GB":
        return "UK";
      case "US":
        return "US";
      case "EU":
        return "EU";
      default:
        return "";
    }
  };

  const isAddOnPrice = () => {
    if (selectedPlan?.addOn?.price)
      return selectedPlan?.value + Number(selectedPlan?.addOn?.price);
    else return selectedPlan?.value;
  };

  return (
    <>
      <Helmet>
        <title>{getMetaText("REGISTER_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("REGISTER_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("REGISTER_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("REGISTER_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("REGISTER_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("REGISTER_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("REGISTER_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("REGISTER_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      <Container fluid className={styles.outerRegisterWrap}>
        <Container className={styles.innerRegisterWrap}>
          <Row className={styles.logoWrapper}>
            <Col xs={12}>
              <div className={styles.logo} onClick={() => history.push("/")}>
                <Logo />
              </div>
            </Col>
          </Row>
          <Row className={styles.registerWrapper}>
            <Col xs={12} lg={4} className={styles.joinWrap}>
              <div className={styles.header}>
                <h3>Join the Community</h3>
              </div>
              <div
                className={`${styles.chosenSub} ${
                  selectedPlan.value <= 0 && styles.noPad
                }`}
              >
                {selectedPlan.value <= 0 ? (
                  <img
                    src={require("../../../assets/registration-pages/cambus.jpg")}
                    alt="Cambus Distillery Cask Photograph"
                  />
                ) : (
                  <>
                    {/* <small>Your choice</small> */}
                    <h5>
                      {selectedPlan?.name ? selectedPlan?.name : "Collective"}{" "}
                      <br /> Subscription
                      {selectedPlan?.addOn?.[selectedPlan?.addOn?.type]
                        ?.title && (
                        <>
                          <br />+ Add-on
                        </>
                      )}
                    </h5>
                    {/* <Link className={styles.link} to="/the-collective">
                      change
                    </Link> */}
                    <p className={styles.price}>
                      {selectedPlan?.value &&
                        formatCurrency(shop.currency, 0).format(
                          Number(isAddOnPrice()),
                        )}
                    </p>
                    <span>
                      {selectedPlan?.addOn &&
                        selectedPlan.value &&
                        formatCurrency(shop.currency, 0).format(
                          selectedPlan?.value,
                        ) + " "}
                      billed annually
                    </span>
                  </>
                )}
              </div>
            </Col>
            <Col
              xs={12}
              lg={{ span: 7, offset: 1 }}
              className={styles.formWrap}
            >
              <div className={styles.header}>
                <h5>Create your account</h5>
                <Link className={styles.link} to="/login">
                  Already have an account? Log in
                </Link>
              </div>
              <Form onSubmit={onRegister}>
                <Form.Row className={styles.formRow}>
                  <Form.Group className={styles.emailInput}>
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      size="lg"
                      type="text"
                      required
                      placeholder={"Email address"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className={styles.formRow}>
                  <Form.Group className={`${styles.formGroup} ${styles.input}`}>
                    {/* <Form.Label>Password</Form.Label> */}
                    <Form.Control
                      size="lg"
                      type="password"
                      required
                      placeholder={"Password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={`${styles.formGroup} ${styles.input}`}>
                    {/* <Form.Label>Confirm Password</Form.Label> */}
                    <Form.Control
                      size="lg"
                      type="password"
                      required
                      placeholder={"Confirm password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>
                {data?.shops[0].affiliateClubs.length >= 1 ? (
                  <Form.Row className={styles.formRow}>
                    <Form.Group
                      className={`${styles.formGroup} ${styles.selectInput}`}
                    >
                      {selectVisible ? null : (
                        <div style={{ display: "flex", cursor: "pointer" }}>
                          <Form.Check
                            type="checkbox"
                            className={styles.formCheck}
                            onChange={() => {
                              setSelectVisible(true);
                            }}
                          />
                          <p
                            onClick={() => {
                              setSelectVisible(true);
                            }}
                          >
                            {getCopyText("REGISTER_PAGE", "affiliate-question")}
                          </p>
                        </div>
                      )}
                      {selectVisible ? (
                        <Form.Row className={styles.formRow}>
                          <Form.Group className={styles.formGroup}>
                            <Form.Label
                              style={{ position: "relative", width: "100%" }}
                            >
                              Select your club...
                              <small
                                onClick={() => {
                                  setTooltipVisible(!tooltipVisible);
                                }}
                              >
                                <br /> {"   "}
                                (why do we ask this?)
                              </small>
                              {tooltipVisible ? (
                                <div
                                  className="custom-tooltip"
                                  style={{ bottom: "35px" }}
                                >
                                  <p className="">
                                    We work with some whisky and rum clubs to
                                    organise tasting events.
                                  </p>
                                  {/* <span
                            className="tooltip-close"
                            onClick={() => {
                              setTooltipVisible(false);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </span> */}
                                  <span className="tooltip-arrow"></span>
                                </div>
                              ) : null}
                            </Form.Label>
                            <Form.Control
                              style={{ color: "rgba(73,80,87,.8)" }}
                              as="select"
                              size="lg"
                              value={whiskyClub}
                              onChange={(e) => {
                                setWhiskyClub(e.target.value);
                              }}
                            >
                              <option>None</option>
                              {data?.shops[0].affiliateClubs.map((e: any) => {
                                return (
                                  <option key={e.id} value={e.id}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                      ) : null}
                    </Form.Group>
                  </Form.Row>
                ) : null}
                {selectedPlan.value <= 0 ? (
                  <>
                    <Form.Row
                      className={`${styles.formRow} ${styles.freeAccount}`}
                    >
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {error || null}
                      </span>
                    </Form.Row>
                    <br />
                    <Form.Row
                      className={`${styles.formRow} ${styles.freeAccount}`}
                    >
                      <p
                        className={styles.underlineLinks}
                        style={{ marginTop: "1rem" }}
                      >
                        By creating an account, you agree to our{" "}
                        <Link
                          to="/terms/terms-and-conditions"
                          style={{ textDecoration: "underLine" }}
                        >
                          {" "}
                          Terms
                        </Link>{" "}
                        and{" "}
                        <Link
                          to="/terms/privacy-policy"
                          style={{ textDecoration: "underLine" }}
                        >
                          Privacy Policy
                        </Link>
                      </p>
                      <Button
                        type="submit"
                        disabled={loading || !!error}
                        className={styles.btnSecondary}
                      >
                        Create {marketString(shop.market)} account
                      </Button>
                    </Form.Row>
                  </>
                ) : (
                  <>
                    <Form.Row className={styles.formRow}>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {error || null}
                      </span>
                    </Form.Row>
                    <Form.Row className={styles.formRow}>
                      <Form.Group className={styles.formGroup}>
                        <p
                          className={styles.underlineLinks}
                          style={{ marginTop: "1rem" }}
                        >
                          By creating an account, you agree to our{" "}
                          <Link
                            to="/terms/terms-and-conditions"
                            style={{ textDecoration: "underLine" }}
                          >
                            {" "}
                            Terms,
                          </Link>{" "}
                          <Link
                            to="/terms/privacy-policy"
                            style={{ textDecoration: "underLine" }}
                          >
                            Privacy Policy
                          </Link>
                        </p>
                        <p
                          className={styles.underlineLinks}
                          style={{ marginBottom: "1rem" }}
                        >
                          After creating an account, you will be redirected to
                          our secure Stripe checkout.
                        </p>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className={styles.formRow}>
                      <Form.Group className={styles.formGroup}>
                        <Button
                          type="submit"
                          disabled={loading || !!error}
                          className={styles.btnSecondary}
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading...
                            </>
                          ) : (
                            <>
                              <IoMdLock /> Continue to Payment
                            </>
                          )}
                        </Button>
                        <div className={styles.stripe}>
                          <img
                            src={require("../../../assets/home-page/.v2/stripe-logo.png")}
                            alt="Stripe Logo Image"
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default RegisterPage;
