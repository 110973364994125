import React from "react";
import { Col } from "react-bootstrap";

const CheckoutStockIssues = ({
  checkoutStockIssue,
}: {
  checkoutStockIssue: any;
}) => {
  return (
    <Col xs="12" className="stock-issues">
      <p>
        Oops, looks like this item is oversold. Please, reduce the quantity to
        maximum {checkoutStockIssue.remaining}.
      </p>
    </Col>
  );
};

export default CheckoutStockIssues;
