import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Toast,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { MdError } from "react-icons/md";

import { getMetaText, getCopyText } from "../../../AppText";
import { LOGIN, AuthPayload } from "../../../graphql/Auth";
import { setUser } from "../../../state/slices/userSlice";
import { addValidationCounter } from "../../../state/slices/checkoutSlice";
import { RootState } from "../../../state/rootReducer";
import { ReactComponent as Logo } from "../../../assets/images/rebrand-logo.svg";
import styles from "./LoginPage.module.scss";

const LoginPage = ({ noRedirect }: { noRedirect?: boolean }) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [giftingMessage, setGiftingMessage] = useState("");
  const [customerLogin] = useMutation<{ customerLogin: AuthPayload }, any>(
    LOGIN,
  );
  const dispatch = useDispatch();
  const { state: locationState } = useLocation();
  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const checkout = useSelector((state: RootState) => state.checkout);

  useEffect(() => {
    // @ts-ignore
    if (locationState?.from?.pathname === "/gifting/voucher") {
      setButtonText("Login to gift");
      setGiftingMessage(
        "So we can keep you up-to-date with your gift, please login and/or create an account.",
      );
    } else {
      setButtonText(`Login to Caskshare ${marketString(shop?.market)}`);
    }
    return () => {
      setButtonText("");
      setGiftingMessage("");
    };
  }, [locationState, shop?.market]);

  const onLogin = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    try {
      const { data, errors } = await customerLogin({
        variables: {
          shop: shop.id,
          email,
          password,
          redirectUrl: `${window.location.origin}/account/reset`,
        },
      });
      if (errors) {
        setError(errors[0].message);

        setTimeout(() => {
          setError("");
        }, 3000);

        setLoading(false);
        return;
      }
      dispatch(setUser(data?.customerLogin));

      if (checkout.lineItems.length !== 0) {
        dispatch(addValidationCounter()); // Only used to update the checkout on login
      }

      setLoading(false);

      if (noRedirect) {
        return;
      }
      // @ts-ignore
      if (locationState?.from.hash) {
        // @ts-ignore
        history.replace(locationState.from.pathname + locationState.from.hash);
        // @ts-ignore
      } else if (locationState?.from.pathname === "/register") {
        history.push("/");
        // @ts-ignore
      } else if (locationState?.from) {
        // @ts-ignore
        history.push(locationState.from.pathname, locationState.state);
      } else {
        history.replace("/caskshares");
      }
    } catch (loginError) {
      setError(loginError.message);
      setTimeout(() => {
        setError("");
      }, 3000);
      setLoading(false);
    }
  };

  const marketString = (market: string) => {
    switch (market) {
      case "GB":
        return "UK";
      case "US":
        return "US";
      case "EU":
        return "EU";
      default:
        return "";
    }
  };

  return (
    <>
      <Helmet>
        <title>{getMetaText("LOGIN_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText("LOGIN_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("LOGIN_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("LOGIN_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("LOGIN_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("LOGIN_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("LOGIN_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("LOGIN_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      <Container fluid className={styles.outerLoginWrap}>
        <Container className={styles.innerLoginWrap}>
          <Row className={styles.logoWrapper}>
            <Col xs={12}>
              <div className={styles.logo} onClick={() => history.push("/")}>
                <Logo />
              </div>
            </Col>
          </Row>
          <Row className={styles.loginWrapper}>
            <Col xs={12} lg={4} className={styles.joinWrap}>
              <div className={styles.header}>
                <h3>Welcome to Caskshare {marketString(shop.market)}</h3>
              </div>
              <div className={styles.chosenSub}>
                <img
                  src={require("../../../assets/registration-pages/cambus.jpg")}
                  alt="Cambus Distillery Cask Photograph"
                />
              </div>
            </Col>
            <Col
              xs={12}
              lg={{ span: 7, offset: 1 }}
              className={styles.formWrap}
            >
              <div className={styles.header}>
                <h5>{t("login")}</h5>
                <Link className={styles.link} to="/register">
                  Don't have an account? Sign up
                </Link>
              </div>
              <Form onSubmit={onLogin} className={styles.formWrap}>
                <Form.Row className={styles.formRow}>
                  <Form.Group className={styles.formGroup}>
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      size="lg"
                      type="text"
                      required
                      placeholder={"Email address"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className={styles.formRow}>
                  <Form.Group className={styles.formGroup}>
                    {/* <Form.Label>Password</Form.Label> */}
                    <Form.Control
                      size="lg"
                      type="password"
                      required
                      placeholder={"Password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>

                {error ? (
                  <Form.Row
                    className={`${styles.formRow} ${styles.resetPassword}`}
                  >
                    <MdError />
                    <span>{error}</span>
                  </Form.Row>
                ) : null}

                <Form.Row className={styles.formRow}>
                  <Form.Group className={styles.formGroup}>
                    <Link
                      to={{
                        pathname: "/forgot-password",
                        state: { email },
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Form.Group>
                </Form.Row>

                {giftingMessage && (
                  <Form.Row className={styles.formRow}>
                    <Form.Group className={styles.formGroup}>
                      <p>{giftingMessage}</p>
                    </Form.Group>
                  </Form.Row>
                )}

                <Form.Row className={styles.formRow}>
                  <Form.Group className={styles.formGroup}>
                    <Button
                      type="submit"
                      disabled={loading || !!error}
                      className={styles.btnSecondary}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Loading...
                        </>
                      ) : (
                        t(buttonText)
                      )}
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <div className={styles.chosenSub}>
          <img
            src={require("../../../assets/registration-pages/cambus.jpg")}
            alt="Cambus Distillery Cask Photograph"
          />
        </div>
      </Container>
    </>
  );
};

export default LoginPage;
