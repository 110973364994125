import React, { useEffect, useState } from "react";
import { Row, Col, Container, Breadcrumb } from "react-bootstrap";
import { Switch, NavLink, Link, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { Helmet } from "react-helmet";

import { getMetaText, getCopyText } from "../AppText";
import Payment from "../components/checkout/.v2/Payment/Payment";
import CheckoutInfo from "../components/checkout/CheckoutInfo";
import CheckoutShipping from "../components/checkout/CheckoutShipping";
import CheckoutBasket from "../components/checkout/CheckoutBasket";
import { RootState } from "../state/rootReducer";
import { PrivateRoute } from "../components/app/PrivateRoute";
import CheckoutSuccess from "../components/checkout/CheckoutSuccess";
import LoadingView from "../components/app/LoadingView";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const CheckoutPage = () => {
  const shop = useSelector((state: RootState) => state.shop);
  const checkout = useSelector((state: RootState) => state.checkout);
  const shippingDetails = useSelector(
    (state: RootState) => state.checkout.shippingAddress,
  );
  const [isOversold, setIsOversold] = useState(false);

  useEffect(() => {
    if (checkout.stockIssues.length > 0) {
      setIsOversold(true);
    } else {
      setIsOversold(false);
    }
  }, [checkout.stockIssues]);

  const disabledBreadcrumb = (e: any) => {
    if (
      !shippingDetails.address1 ||
      !shippingDetails.city ||
      !shippingDetails.postalCode ||
      !shippingDetails.countryCode ||
      !shippingDetails.firstName ||
      !shippingDetails.lastName
    ) {
      e.preventDefault();
    }
  };
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(shop.stripePublishableKey),
  );
  const [showShipping] = useState(false);

  if (!checkout?.lineItems?.length) {
    return (
      <Container
        style={{
          minHeight: "60vh",
          paddingTop: "50px",
          paddingBottom: "100px",
        }}
      >
        <h4>Your basket is empty</h4>
      </Container>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <Container style={{ minHeight: "60vh", paddingTop: "50px" }}>
        <Helmet>
          <title>{getMetaText("CHECKOUT_PAGE", "title")}</title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="keywords"
            content={getMetaText("CHECKOUT_PAGE", "keywords")}
          />
          <meta
            name="description"
            content={getMetaText("CHECKOUT_PAGE", "description")}
          />
          <meta
            name="author"
            content={getMetaText("CHECKOUT_PAGE", "author")}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content={getMetaText("CHECKOUT_PAGE", "title")}
          />
          <meta
            property="og:description"
            content={getMetaText("CHECKOUT_PAGE", "description")}
          />
          <meta
            property="og:image"
            content={
              window.location.origin + require("../assets/home-page/splash.jpg")
            }
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@thecaskshare" />
          <meta
            name="twitter:title"
            content={getMetaText("CHECKOUT_PAGE", "title")}
          />
          <meta
            name="twitter:description"
            content={getMetaText("CHECKOUT_PAGE", "description")}
          />
          <meta
            name="twitter:image"
            content={
              window.location.origin + require("../assets/home-page/splash.jpg")
            }
          />
        </Helmet>

        {showShipping ? (
          <Breadcrumb>
            <NavLink
              to="/checkout/information"
              className="disabled breadcrumb-item"
            >
              Information
            </NavLink>
            <NavLink
              to="/checkout/shipping"
              onClick={disabledBreadcrumb}
              className="breadcrumb-item"
            >
              Shipping
            </NavLink>
            <NavLink
              to="/checkout/payment"
              onClick={disabledBreadcrumb}
              className="breadcrumb-item"
            >
              Payment
            </NavLink>
          </Breadcrumb>
        ) : // <Link to="/basket">
        //   <h5 style={{ paddingBottom: "40px", width: "fitContent" }}>
        //     <i
        //       style={{ width: "30px" }}
        //       className="fa fa-long-arrow-left fa-fw"
        //       aria-hidden="true"
        //     />
        //     My basket
        //   </h5>
        // </Link>
        null}
        <Row className="checkout-wrapper">
          <Col md={7}>
            <Switch>
              <PrivateRoute exact path="/checkout/information">
                <CheckoutInfo />
              </PrivateRoute>
              <PrivateRoute exact path="/checkout/shipping">
                <CheckoutShipping />
              </PrivateRoute>
              <Route exact path="/checkout/payment">
                <Payment
                  isOversold={isOversold}
                  setIsOversold={setIsOversold}
                />
              </Route>
              <PrivateRoute exact path="/checkout/payment/mollie/status">
                <Payment
                  isOversold={isOversold}
                  setIsOversold={setIsOversold}
                  isFinalPaymentRedirect
                />
              </PrivateRoute>
            </Switch>
          </Col>
          <Col
            md={5}
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CheckoutBasket isOversold={isOversold} canEditQuantities={false} />
          </Col>
        </Row>
      </Container>
    </Elements>
  );
};

export default CheckoutPage;
