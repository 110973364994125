import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-apollo";
import { IoMdLock } from "react-icons/io";
import { hotjar } from "react-hotjar";
import moment from "moment";

import PaymentFailureModal from "../../../components/checkout/PaymentFailureModal";
import { increaseCheckoutQuantity } from "../../../state/slices/checkoutSlice";
import { GIFT_RESERVATION, GET_CUSTOMER } from "../../../graphql/User";
import { voucherData, membershipData } from "./giftingData";
import { RootState } from "../../../state/rootReducer";
import { feature } from "../../../utils/featureFlag";
import styles from "./GiftingPage.module.scss";
import GiftedCask from "./GiftedCask";

import TagManager from "react-gtm-module";
import { MemberProduct } from "../../../graphql/MemberProduct";

const dateForPicker = (date: any) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};

const GiftedComponent = () => {
  hotjar.initialize(2882523, 6);
  // @ts-ignore
  const { slug } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const shop = useSelector((state: RootState) => state.shop);
  const checkout = useSelector((state: RootState) => state.checkout);
  const loggedInUser = useSelector((state: RootState) => state.user);

  const [giftReservation, { data: giftReservationData }] = useMutation(
    GIFT_RESERVATION,
    {
      refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
    },
  );

  const [validated, setValidated] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [paymentErrorClose, setPaymentErrorClose] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [giftedProduct, setGiftedProduct] = useState<any>({});
  const [caskDetails, setCaskDetails] = useState<any>({});
  const [addOns, setAddOns] = useState<MemberProduct["memberAddOns"]>([]);
  const [addOnModalOpen, setAddOnModalOpen] = useState(false);
  const [giftingData, setGiftingData] = useState<any>({
    gifterName: loggedInUser?.firstName || "",
    gifterEmail: loggedInUser?.email || "",
    recipientName: "",
    recipientEmail: "",
    giftNote: "",
    giftDate: dateForPicker(Date.now()),
    giftCard: false,
  });

  const role = (() => {
    switch (slug) {
      case "voucher":
        return "voucher";
      case "membership":
        return "membership";
      case "caskshares":
        return "caskshares";
      default:
        return "voucher";
    }
  })();

  useEffect(() => {
    if (location.state && role === "voucher") {
      setGiftedProduct({
        // @ts-ignore
        ...location.state?.voucherGift,
        quantity: 25,
      });
    }
    if (location.state && role === "membership") {
      setGiftedProduct({
        // @ts-ignore
        ...location.state?.collectiveGift,
        quantity: 1,
      });
    }
    if (location.state && role === "caskshares") {
      setCaskDetails(location.state);
    }
    // @ts-ignore
    if (location.state?.product && role === "membership") {
      setGiftedProduct({
        // @ts-ignore
        ...location.state?.product,
        quantity: 1,
      });
    } else if (!location.state) {
      history.push("/gifting");
    }
    return () => {
      setGiftedProduct({});
    };
  }, [location?.state, role]);

  useEffect(() => {
    if (checkout.lineItems) {
      const lineItems = checkout.lineItems;
      if (lineItems.some((item: any) => item?.giftData?.recipientName)) {
        const filterGiftData: any = [
          ...lineItems.filter((item) => item.giftData),
        ];
        const checkoutGiftData = filterGiftData[0].giftData;
        setGiftingData({
          ...giftingData,
          recipientName: checkoutGiftData?.recipientName,
          recipientEmail: checkoutGiftData?.recipientEmail,
          giftNote: checkoutGiftData?.giftNote,
          giftCard: checkoutGiftData?.giftCard,
        });
      }
    }
  }, [checkout.lineItems]);

  useEffect(() => {
    // Google Analytics 4
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    var tagManagerArgs = {
      dataLayer: {
        event: "view_item",
        ecommerce: {
          currency: shop.currency,
          items: [
            {
              item_id: role,
              item_brand: "Caskshare",
              item_name: giftedProduct.title,
              price: giftedProduct.quantity,
              item_category: "gift",
            },
          ],
        },
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [giftedProduct]);

  const openAddOnnModal = () => {
    setAddOnModalOpen(true);
  };

  const addProductToBasket = () => {
    dispatch(
      increaseCheckoutQuantity({
        product: giftedProduct,
        giftData: giftingData,
        giftQuantity: giftedProduct?.quantity,
      }),
    );
    history.push(`/basket`, giftingData);
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleSubmit = async (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (form.checkValidity() === true) {
      e.preventDefault();
      e.stopPropagation();

      if (role === "caskshares") {
        const { data } = await giftReservation({
          variables: {
            reservationId: caskDetails.id,
            giftData: giftingData,
          },
        });
        if (data?.giftReservation.success === false) {
          console.warn("transferStart() failed");
          // alert("Sorry we were not able gift your reservation");
          setPaymentError(
            "There was a problem gifting the caskshare. Please, try again later.",
          );
        } else {
          setConfirmationModalOpen(true);
        }
      } else {
        addProductToBasket();
      }
    }
    setValidated(true);
  };

  const closePaymentFailureModal = () => {
    setPaymentError("");
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className={styles.formRow}>
          {role === "voucher" ? (
            <>
              {/* ---------------------Voucher----------------------- */}
              <Col className={styles.title} xs={12}>
                <small>Amount</small>
              </Col>
              <div className={styles.priceWrap}>
                {voucherData()?.map((voucher: any) => (
                  <Form.Group
                    key={voucher.id}
                    as={Col}
                    name={voucher.name}
                    value={voucher.quantity}
                    className={`${styles.price} ${
                      giftedProduct.quantity === voucher.quantity &&
                      styles.activePrice
                    }`}
                    onClick={(e: any) =>
                      setGiftedProduct({
                        ...giftedProduct,
                        quantity: voucher.quantity,
                      })
                    }
                  >
                    {voucher.quantity}
                  </Form.Group>
                ))}
              </div>
            </>
          ) : role === "membership" ? (
            <>
              {/* ---------------------Membership----------------------- */}
              <Col className={styles.title} xs={12}>
                <small>Plan</small>
              </Col>
              <div className={styles.priceWrap}>
                {membershipData()?.map((member: any) => (
                  <Form.Group
                    key={member.id}
                    as={Col}
                    name={member.name}
                    value={member.quantity}
                    className={`${styles.price} ${
                      giftedProduct?.title?.includes(member.title) &&
                      styles.activePrice
                    }`}
                  >
                    {member.title}
                    {giftedProduct?.title?.includes("Bundle") && " + add-on"}
                  </Form.Group>
                ))}
              </div>
            </>
          ) : role === "caskshares" ? (
            <>
              {/* ---------------------Caskshares----------------------- */}
              <Col className={styles.caskCol}>
                <GiftedCask reservation={caskDetails} />
              </Col>
            </>
          ) : null}
        </Row>
        {/* ---------------------TO----------------------- */}
        <Row className={styles.formRow}>
          <Col className={styles.title} xs={12}>
            <small>To</small>
          </Col>
          <Form.Group className={`${styles.half} ${styles.formInput}`} as={Col}>
            <Form.Control
              required
              type="text"
              placeholder="Recipient's name"
              value={giftingData.recipientName}
              onChange={(e) =>
                setGiftingData({
                  ...giftingData,
                  recipientName: e.target.value,
                })
              }
            />
            <Form.Control
              required
              type="email"
              placeholder="Recipient's email"
              value={giftingData?.recipientEmail}
              onChange={(e) =>
                setGiftingData({
                  ...giftingData,
                  recipientEmail: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className={styles.formInput} as={Col} xs={12}>
            <Form.Control
              type="text"
              placeholder="Add a message (optional)"
              as="textarea"
              rows={3}
              value={giftingData?.giftNote}
              onChange={(e) =>
                setGiftingData({
                  ...giftingData,
                  giftNote: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className={styles.sendVoucher} as={Col} xs={4}>
            {role === "voucher" ? (
              <small>Send voucher</small>
            ) : giftedProduct?.title?.includes("Bundle") ? (
              <small>Gift collective + add-on</small>
            ) : role === "membership" ? (
              <small>Gift collective</small>
            ) : role === "caskshares" ? (
              <small>Gift caskshare</small>
            ) : null}
          </Form.Group>
          <Form.Group className={styles.formInput} as={Col} xs={8}>
            <Form.Control
              type="date"
              placeholder={giftingData.giftDate}
              required
              value={giftingData.giftDate}
              onChange={(e) =>
                setGiftingData({
                  ...giftingData,
                  giftDate: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className={styles.checkbox} as={Col} xs={12}>
            {feature("gift_card") === true && role !== "caskshares" ? (
              <div className={styles.giftCard}>
                <Form.Check
                  type="checkbox"
                  label="Would you like to add a Gift Card?"
                  id="gift-card"
                  className={""}
                  checked={giftingData.giftCard}
                  onChange={() => {
                    setGiftingData({
                      ...giftingData,
                      giftCard: !giftingData.giftCard,
                    });
                  }}
                />
              </div>
            ) : null}
          </Form.Group>
        </Row>

        <div className={styles.btnWrap}>
          <Button className={styles.btnSecondary} type="submit">
            {role === "caskshares" ? (
              "Send Gift"
            ) : (
              <>
                <IoMdLock /> Continue to Payment
              </>
            )}
          </Button>
        </div>
      </Form>
      <PaymentFailureModal
        message={paymentError}
        doClose={closePaymentFailureModal}
        show={Boolean(paymentError)}
      />
      {confirmationModalOpen && (
        <div className={styles.confirmationModalWrap}>
          <div className={styles.inner}>
            <h4>You're all set!</h4>
            <p>
              We will email <span>{giftingData?.recipientName}</span> with their
              Caskshare gift on{" "}
              {moment(giftingData?.giftDate).format("DD-MM-YYYY")}.
            </p>
            <p>Thanks for using Caskshare.</p>
            <p>The Caskshare Team.</p>
            <Button as={Link} to="/my-account" className={styles.btnPrimary}>
              My Account
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default GiftedComponent;
