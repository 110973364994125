import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import { hotjar } from "react-hotjar";

import { RootState } from "../state/rootReducer";
import { PRODUCT, Product } from "../graphql/Products";
import styles from "./PromoProductPage.module.scss";

import TagManager from "react-gtm-module";
import LoadingLogo from "../components/app/LoadingLogo";
import BottleImage from "../caskshare-v3/components/casks/caskCard/BottleImage";
import { getMetaText } from "../AppText";
import { Helmet } from "react-helmet";
import AddProductToBasket from "../caskshare-v3/pages/caskshare-page/AddProductToBasket";

const PromoProductPage = () => {
  hotjar.initialize(2882523, 6);

  const shop = useSelector((state: RootState) => state.shop);
  const currency = useSelector((state: RootState) => state.shop.currency);

  // @ts-ignore
  const { productId } = useParams();
  const { data, loading } = useQuery(PRODUCT, {
    variables: {
      shop: shop.id,
      id: productId,
    },
  });

  const descriptionRef: any = useRef<HTMLDivElement>();
  const defaultBottleImg = require("../assets/home-page/.v3/db-lg.png");
  const defaultBottleImgPlc = require("../assets/home-page/.v3/db-sm.png");
  const defaultNonBottleImg = require("../assets/images/1x1.png");
  const defaultNonBottleImgPlc = require("../assets/images/1x1.png");
  const [bottleImg, setBottleImg] = useState(defaultNonBottleImg);
  const [bottleImgPlc, setBottleImgPlc] = useState(defaultNonBottleImgPlc);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [readFullDesc, setReadFullDesc] = useState(true);
  const [showReadMore, setShowReadMore] = useState(false);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (data?.product != null) {
      setProduct(data?.product);
      if (! data?.product?.nonBottleImage) {
        setBottleImg(defaultBottleImg);
        setBottleImgPlc(defaultBottleImgPlc);
      }
    }
  }, [data?.product]);

  useEffect(() => {
    if (product?.image) {
      setBottleImg(product.image ?? defaultBottleImg);
    }
  }, [product?.image]);

  useEffect(() => {
    if (descriptionRef?.current) {
      if (
        descriptionRef.current.offsetHeight <
        descriptionRef.current.scrollHeight
      ) {
        setShowReadMore(true);
      }
    }
  }, [descriptionRef?.current]);

  useEffect(() => {
    if (product?.defaultVariant) {
      setPrice(product.defaultVariant.price.amount);
    }
  }, [product?.defaultVariant]);

  if (loading || product == null) {
    return <LoadingLogo />;
  }

  // Google Analytics 4
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  var tagManagerArgs = {
    dataLayer: {
      event: "view_item",
      ecommerce: {
        currency: currency,
        items: [
          {
            item_id: `${product.seller.title}-${product.sku}`,
            item_brand: product.vendor,
            item_name: product.title,
            price: price,
            item_category: "product",
          },
        ],
      },
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  return (
    <>
      <Helmet>
        <title>{getMetaText("WELCOME_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("WELCOME_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("WELCOME_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("WELCOME_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("WELCOME_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      <Container className={styles.pageWrapper}>
        <div className={styles.BGWrap}>
          <Container
            className={`${styles.sectionWrap} ${styles.caskDetailSection}`}
          >
            <Row className={`${styles.caskDetailRow} ${styles.whiteBG}`}>
              <Col xs={12} md={6} className={styles.imgWrap}>
                <BottleImage
                  src={bottleImg}
                  placeholderSrc={bottleImgPlc}
                  props={{ alt: product.title }}
                  hideOverlay={product?.nonBottleImage}
                />
              </Col>
              <Col xs={12} md={6} className={styles.contentWrap}>
                <div className={styles.titleWrap}>
                  <div className={styles.distillery}>
                    <p>{product.seller.title}</p>
                  </div>
                  <h3 className={styles.title}>{product.title}</h3>
                  <div className={styles.descriptionWrap}>
                    <p
                      ref={descriptionRef}
                      className={`${readFullDesc && styles.fullDescription} ${
                        styles.description
                      }`}
                    >
                      {product.description}
                    </p>
                    {!readFullDesc && showReadMore ? (
                      <p
                        className={styles.more}
                        onClick={() => setReadFullDesc((prev) => !prev)}
                      >
                        read more
                      </p>
                    ) : null}
                  </div>
                </div>

                <AddProductToBasket product={product} />
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
};

export default PromoProductPage;
