import React from "react";

const LoadingLogo = ({
  minHeight,
  dark = true,
}: {
  minHeight?: string;
  dark?: boolean;
}) => (
  <div
    style={{
      minHeight: minHeight || "70vh",
      color: dark ? "black" : "white",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <img
      src={require("../../assets/images/logo-loading.gif")}
      alt="logo loading gif"
    />
  </div>
);

export default LoadingLogo;
