import React from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/rootReducer";
import { removePromoCodeFromCheckout } from "../../state/slices/checkoutSlice";
import {
  BsChevronDown,
  BsCheck2Circle,
  BsFillXCircleFill,
} from "react-icons/bs";
import styles from "./Checkout.module.scss";

const PromoCode = ({
  promoFormVisible,
  handlePromoCode,
  promoCode,
  setPromoCode,
  setPromoFormVisible,
  promoError,
  setPromoError,
  promoLoading,
  promoValidationLoading,
  promoCodeApplied,
}: {
  promoFormVisible?: boolean;
  promoCode?: string;
  promoError?: string;
  promoLoading?: boolean;
  promoValidationLoading?: boolean;
  promoCodeApplied?: boolean;
  setPromoCode?: any;
  setPromoError?: any;
  handlePromoCode?: any;
  setPromoFormVisible?: any;
}) => {
  const dispatch = useDispatch();
  const checkout = useSelector((state: RootState) => state.checkout);

  return (
    <Row className={styles.promoCodeArea}>
      <Col className={styles.innerWrap}>
        <div
          onClick={() =>
            setPromoFormVisible && setPromoFormVisible((prev: boolean) => !prev)
          }
          className={styles.promoHeader}
        >
          <p>Do you have a discount code?</p>
          <BsChevronDown className={promoFormVisible ? styles.rotate : ""} />
        </div>
        {promoFormVisible && (
          <Form
            className={`${promoFormVisible ? styles.visible : ""} ${
              styles.promoForm
            }`}
            onSubmit={handlePromoCode}
          >
            <Form.Group className={styles.inputWrap}>
              <Form.Control
                size="lg"
                type="text"
                placeholder={"Discount code"}
                value={promoCode}
                onChange={(e: any) => {
                  setPromoCode && setPromoCode(e.target.value);
                }}
                isInvalid={Boolean(promoError !== "")}
              />
              {promoError && (
                <Form.Control.Feedback
                  style={{ marginBottom: ".5rem" }}
                  type="invalid"
                >
                  {promoError}
                </Form.Control.Feedback>
              )}

              <Button
                type="button"
                disabled={!checkout.promoCode.code}
                className={styles.clearCode}
                onClick={async () => {
                  dispatch(removePromoCodeFromCheckout());
                  setPromoError && setPromoError("");
                  setPromoCode && setPromoCode("");
                }}
              >
                <BsFillXCircleFill />
              </Button>
            </Form.Group>

            <Button
              disabled={!promoCode || promoValidationLoading || promoLoading}
              type="submit"
            >
              {promoValidationLoading ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Apply code"
              )}
            </Button>
            {checkout?.promoCode?.provider && (
              <p style={{ marginTop: ".5rem" }}>
                Provided by: {checkout?.promoCode?.provider}
              </p>
            )}
          </Form>
        )}
        {promoCodeApplied && checkout?.promoCode?.code && (
          <div className={styles.success}>
            <BsCheck2Circle /> Discount code applied
          </div>
        )}
      </Col>
    </Row>
  );
};

export default PromoCode;
