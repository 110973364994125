import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import styles from "./BottleImage.module.scss";

const BottleImage = ({
  placeholderSrc,
  src,
  onClick,
  props,
  hideOverlay = false,
}: {
  placeholderSrc: string;
  src: string;
  onClick?: () => void;
  props?: any;
  hideOverlay?: boolean;
}) => {
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const [imgHeight, setImgHeight] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
      // @ts-ignore
      setImgHeight(imgRef?.current?.height);
    };
    // @ts-ignore
  }, [src, imgRef?.current?.height, imgSrc]);

  const customClass =
    placeholderSrc && imgSrc === placeholderSrc
      ? styles.imgLoading
      : styles.imgLoaded;

  return (
    <Col className={styles.bottleImage}>
      {!hideOverlay && imgHeight > 1 && (
        <div
          style={{
            width: Number(imgHeight * 0.9),
            height: Number(imgHeight * 0.9),
          }}
          className={styles.overlay}
        ></div>
      )}
      <img
        {...{ src: imgSrc, ...props }}
        onClick={onClick}
        ref={imgRef}
        alt={props?.alt || ""}
        className={customClass}
      />
    </Col>
  );
};
export default BottleImage;
