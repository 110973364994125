const reverseCaskshareStatus = ({ status }: { status: string }) => {
  switch (status) {
    case "shop_now":
      return "shipping_now";
    case "reserve":
      return ["shipping_three_months", "shipping_one_year"];
    case "mature":
      return "maturing";
    default:
      return "";
  }
  return;
};

export default reverseCaskshareStatus;
