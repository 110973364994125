import { gql } from "apollo-boost";

export type Attachment = {
  role: string;
  title: string;
  url: string;
};

export interface Distillery {
  overview: string;
  id: string;
  name: string;
  physicalAddress: {
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    countryCode: string;
    postalCode: string;
  };
  attachments: Attachment[];
  intro: string;
  slug: string;
  strapline: string;
  history: string;
  signature: string;
}

const DISTILLERY_FRAGMENT = gql`
  fragment DistilleryFragment on Distillery {
    overview
    id
    name
    physicalAddress {
      address1
      address2
      city
      country
      countryCode
      postalCode
    }
    attachments {
      role
      title
      url
    }
    intro
    slug
    strapline
    history
    signature
  }
`;

export const DISTILLERIES = gql`
  query distillieries($shop: ID) {
    distilleries(shop: $shop) {
      ...DistilleryFragment
    }
  }
  ${DISTILLERY_FRAGMENT}
`;

export const DISTILLERY = gql`
  query distillery($shop: ID, $slug: String!) {
    distillery(shop: $shop, slug: $slug) {
      ...DistilleryFragment
    }
  }
  ${DISTILLERY_FRAGMENT}
`;
