import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { PRODUCT, Product } from "../../graphql/Products";
import { RootState } from "../../state/rootReducer";
import ScrollToSection from "../../components/app/ScrollToSection";
import styles from "./RafflePage.module.scss";

const RafflePage = () => {
  const [scroll, setScroll] = useState("");
  const hero = useRef(null);
  const charityTicket = useRef(null);
  //@ts-ignore
  const { raffleId } = useParams();
  const shopId = useSelector((state: RootState) => state.shop.id);
  const { data, loading } = useQuery(PRODUCT, {
    variables: {
      shop: shopId,
      id: raffleId,
    },
  });

  useEffect(() => {
    gsap.to(hero.current, { visibility: "visible", opacity: "1" });
  });

  useEffect(() => {
    if (charityTicket.current) {
      // @ts-ignore
      const text = charityTicket.current.firstChild;
      // @ts-ignore
      const bottle = charityTicket.current.lastElementChild;

      gsap.from(text, {
        duration: 1,
        y: 50,
        opacity: 0,
      });

      gsap.from(bottle, {
        duration: 1,
        y: 80,
        opacity: 0,
      });
    }
  }, [data]);

  if (!data || loading) {
    return <></>;
  }
  const raffle: Product = data.product;

  return (
    <div className={styles.charityRaffleWrapper}>
      <ScrollToSection scroll={scroll} setScroll={setScroll} />
      <Container ref={hero} className={styles.charityRaffleContainer}>
        <Row ref={charityTicket} className={styles.innerWrap}>
          <Col className={styles.textWrap}>
            <div className={styles.titleWrap}>
              <div className={styles.title}>
                DEC Charity <br /> Raffle Ticket
              </div>
              <div className={styles.price}>
                <span>£</span>
                {raffle.defaultVariant.price.amount}
              </div>
            </div>
            <div className={styles.descriptionWrap}>{raffle.description}</div>
            <div className={styles.buttonsWrap}>
              <Button
                as={Link}
                to={`/products/${raffleId}`}
                className={styles.primary}
              >
                Enter
              </Button>
              {/* <span style={{ width: "3rem" }}></span> */}
              <Link
                to="#"
                className={styles.secondary}
                onClick={() => setScroll("scrollHere")}
              >
                <h5>View Detail</h5>
              </Link>
            </div>
          </Col>
          <Col className={styles.bottleWrap}>
            <img
              src={require("../../assets/home-page/Mossburn-Springbank-opt.png")}
              alt="Mossburn’s Springbank 22 year old release for Disasters Emergency Committee (DEC)"
            />
            <div className={styles.shadow}></div>
          </Col>
        </Row>
        {/* <div className={styles.shadow}></div> */}
        <a id="scrollHere" />
        <Row className={styles.detailInfoWrap}>
          <Col className={styles.raffleInfoWrap}>
            <div className={styles.separator}></div>
            <div className={styles.innerRaffleWrap}>
              <div className={styles.img}>
                <img
                  src={require("../../assets/home-page/caskshare-raffle-ticket.png")}
                  alt="Caskshare Charity Raffle Ticket Graphic"
                  className={styles.first}
                />
                <img
                  src={require("../../assets/home-page/caskshare-raffle-ticket.png")}
                  alt="Caskshare Charity Raffle Ticket Graphic"
                  className={styles.second}
                />
              </div>
              <div className={styles.text}>
                <h3>Details</h3>
                <p>
                  We are donating our only bottle of{" "}
                  <span>Mossburn’s 22 Year Old Springbank</span> worth over £400
                  to one lucky winner of our Charity Raffle.{" "}
                </p>
                <p>
                  Each raffle ticket is £5 and with this you will receive one
                  entry into our prize draw. You can purchase as many tickets as
                  you wish and all funds raised from the sale of raffle tickets
                  will be donated to the Disasters Emergency Committee's Ukraine
                  Humanitarian Appeal.
                </p>
                <p>
                  This raffle will run from Thursday 14th of April 2022 until
                  midnight on Friday 29th of April 2022. One winner will be
                  picked at random in the week beginning on the 2nd of May, and
                  shipped a bottle of Single Cask Springbank 22 Year Old
                  privately bottled by Mossburn Distillers & Blenders.
                </p>
                <p>
                  Open to UK residents 18 years and over only. Ineligble raffle
                  tickets will be disregarded.
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <div className={styles.separator}></div>
        <Row className={styles.charityInfoWrap}>
          <Col
            xs={0}
            lg={{ span: 2, offset: 0 }}
            className={styles.empty}
          ></Col>
          <Col xs={12} sm={3} lg={2} className={styles.title}>
            <Link
              to={{
                pathname:
                  "https://www.dec.org.uk/appeal/ukraine-humanitarian-appeal",
              }}
              target="_blank"
            >
              <div>
                Disasters <span> Emergency Committee</span>
              </div>
            </Link>
          </Col>
          <Col xs={12} sm={9} lg={6} className={styles.text}>
            <p>
              Disasters Emergency Committee's{" "}
              <span>Ukraine Humanitarian Appeal</span> has been set up to help
              provide food, water, shelter, healthcare, and protection to
              families feeling the conflict in Ukraine.{" "}
            </p>
            <p>
              More than 4 million people have fled Ukraine since the conflict
              began leaving behind jobs, belongings and loved ones.
            </p>
            <p>
              DEC charities are in Ukraine and neighboring countries meeting the
              needs of all refugees and displaced people:
            </p>
            <ul>
              <li>
                £30 could provide essential hygiene supplies for three people
                for one month.
              </li>
              <li>£50 could provide blankets for four families.</li>
              <li>
                £100 could provide emergency food for two families for one
                month.
              </li>
            </ul>
            <p>
              Find out more about;{" "}
              <Link
                to={{
                  pathname: "https://www.dec.org.uk",
                }}
                target="_blank"
              >
                DEC
              </Link>{" "}
              and their{" "}
              <Link
                to={{
                  pathname:
                    "https://www.dec.org.uk/appeal/ukraine-humanitarian-appeal",
                }}
                target="_blank"
              >
                Ukrainian appeal
              </Link>
            </p>
          </Col>
          <Col
            xs={0}
            lg={{ span: 2, offset: 0 }}
            className={styles.empty}
          ></Col>
          {/* <div className={styles.logo}>
            <img
              src={require("../../assets/home-page/dec.png")}
              alt="Disasters Emergency Committee Logo"
            />
          </div> */}
        </Row>
        <div className={styles.separator}></div>
        <Row className={styles.charityInfoWrap}>
          <Col>
            <h3>Terms</h3>
            <p>
              This raffle is only open to individuals who are 18 years or older
              and resident of the United Kingdom. Ineligible entries (howsoever
              received) will be disregarded.
            </p>
            <p>
              Insofar as is permitted by law, the promoter, its agents,
              employees, and/or representatives shall in no circumstances be
              responsible or liable to compensate any entrant who participates
              in the competition and/or the winner who claim(s) the prize for
              any loss, damage, personal injury or death whatsoever and
              howsoever caused, whether in contract, tort (including
              negligence), breach of statutory duty, or otherwise, for any
              direct, indirect or consequential losses arising out of or in
              connection with their participation in this competition.
              Competition entrants’ statutory rights are not affected.
            </p>
            <p>UK Shipping only.</p>
            <p>
              We will choose one winner at random out of all qualifying entries.
              The selection of the winner will be at the promoter's absolute
              discretion and will be final. No correspondence or discussion will
              be entered into.
            </p>
            <p>
              By entering this competition, entrants confirm they have read,
              understand and agree that these terms and the{" "}
              <a href="/terms/privacy-policy">Caskshare.com privacy policy</a>{" "}
              (and the way we process personal data) are binding to them.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RafflePage;
