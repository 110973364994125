import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingView = ({
  minHeight,
  dark = true,
}: {
  minHeight?: string;
  dark?: boolean;
}) => (
  <div
    style={{
      minHeight: minHeight || "70vh",
      color: dark ? "black" : "white",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

export default LoadingView;
