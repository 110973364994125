import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container, Button } from "react-bootstrap";
import { useQuery } from "react-apollo";
import { GET_ABOUT_ENTRIES } from "../../graphql/AboutEntry";
import { Helmet } from "react-helmet";

import LoadingView from "../../components/app/LoadingView";
import TeamDesktop from "../../components/team/TeamDesktop";
import TeamMobile from "../../components/team/TeamMobile";
import Timeline from "../../components/timeline/Timeline";
import Press from "../../components/press/Press";

import { getMetaText, getCopyText } from "../../AppText";

import styles from "./AboutPage.module.scss";

import DavidImg from "../../assets/images/nicol.png";
import caskshareLogo from "../../assets/images/share-dots.svg";
import svg from "../../assets/images/woodgrain-lines-white.svg";
import quote from "../../assets/images/quote@2x.png";
import arrowDown from "../../assets/images/arrow-down.png";
import LoadingLogo from "../../components/app/LoadingLogo";

const AboutPage = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const { data, loading } = useQuery(GET_ABOUT_ENTRIES, {
    variables: {
      entryType: "team_member",
    },
  });

  const { data: eventData, loading: eventLoading } = useQuery(
    GET_ABOUT_ENTRIES,
    {
      variables: {
        entryType: "mile_stone",
      },
    },
  );
  if (loading || eventLoading) {
    return <LoadingLogo />;
  }

  function getMonthName(month: any) {
    const d = new Date();
    d.setMonth(month - 1);
    const monthName = d.toLocaleString("default", { month: "short" });
    return monthName;
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 0,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 0,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{getMetaText("ABOUT_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText("ABOUT_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("ABOUT_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("ABOUT_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("ABOUT_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("ABOUT_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../../assets/images/nicol.png")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("ABOUT_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("ABOUT_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../../assets/images/nicol.png")
          }
        />
      </Helmet>

      <div className="overflow">
        <Container className={`${styles.whoWeAre}`}>
          <Row className={styles.innerWrap}>
            {/* <Col xs={12} md={5} className={styles.contentCol}>
              <p>
                <span>Reserve</span>, <span>mature</span>, <span>enjoy</span>.
                At caskshare, we want to bring you unique whisky from the latest
                and brightest distilleries, before they bottle.{" "}
              </p>
              <p>
                <span>Reserve</span> a share in a cask to secure your bottle of
                something truly exciting. Watch it <span>mature</span> and enjoy
                the benefits of our Collective with tastings, meet the maker
                events and exclusive content. <span>Enjoy</span> your whisky
                when it’s ready. We’ll let you know once your share is ready to
                bottle and winging its way to you.
              </p>
              <p>
                With a range of ready to bottle and maturing shares - we have
                everything you need to build your whisky collection with rare
                finds you’ll not see on the supermarket shelves.
              </p>
              <p>Sound good? Browse our Caskshare collection now.</p>
            </Col>
            <Col xs={12} md={7} className={styles.titleCol}>
              <h3>
                From single cask to <span>glass</span>
              </h3>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../assets/about-page/who-we-are.jpg")}
                  alt="team photo"
                />
              </div>
            </Col> */}
            <Col xs={12} md={4} className={styles.titleCol}>
              <div className={styles.imgWrap}>
                <img src={caskshareLogo} alt="caskshare logo" />
              </div>
            </Col>
            <Col xs={12} md={8} className={styles.contentCol}>
              <h3>
                From single cask <br /> <span>to glass</span>
              </h3>
              <p>
                <span>Reserve</span>, <span>mature</span>, <span>enjoy</span>.
                At caskshare, we want to bring you unique whisky from the latest
                and brightest distilleries, before they bottle.{" "}
              </p>
              <p>
                <span>Reserve</span> a share in a cask to secure your bottle of
                something truly exciting. Watch it <span>mature</span> and enjoy
                the benefits of our Collective with tastings, meet the maker
                events and exclusive content. <span>Enjoy</span> your whisky
                when it’s ready. We’ll let you know once your share is ready to
                bottle and winging its way to you.
              </p>
              <p>
                With a range of ready to bottle and maturing shares - we have
                everything you need to build your whisky collection with rare
                finds you’ll not see on the supermarket shelves.
              </p>
              <p>Sound good? Browse our Caskshare collection now.</p>
            </Col>
          </Row>
        </Container>

        <Container className={`${styles.aboutUs} ${styles.mobileView}`}>
          <section
            id="team"
            className={`${styles.teamWrapper} ${styles.spaceTop}`}
          >
            <Row>
              <Col className={styles.teamHeading}>
                <h3>Meet the spirited team behind Caskshare</h3>
                <p>
                  We’re small but mighty - we’ve got a team full of dram
                  enthusiasts, experts and passionate people.
                </p>
              </Col>
            </Row>
            <Row className={styles.spaceTop}>
              {width < breakpoint ? (
                <TeamMobile data={data} />
              ) : (
                <TeamDesktop data={data} />
              )}
            </Row>
          </section>
        </Container>

        <Container className={`${styles.aboutUs} ${styles.mobileView}`}>
          <section
            id="press"
            className={`${styles.press} ${"styles.spaceTop"}`}
          >
            <Row>
              <Col md={6} xs={12} className={styles.headingCol}>
                <h3>
                  We're <br /> democratising <br /> single cask <br />{" "}
                  <span className={styles.underline}>whisky</span>
                </h3>
                <img src={DavidImg} alt="DavidImg" />
              </Col>
              <Col md={6} xs={12} className={styles.quoteCol}>
                <p>{getCopyText("ABOUT_PAGE", "about-statement-1")}</p>
                <p>{getCopyText("ABOUT_PAGE", "about-statement-2")}</p>
                <div className={styles.link}>
                  <img src={arrowDown} alt="arrow" />
                  <Link to="/in-the-press">
                    In the Press
                    <i className="fa fa-long-arrow-right fa-fw i-xs" />
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12} className={styles.imageCol}>
                <img src={DavidImg} alt="DavidImg" />
              </Col>
              <Col md={6} xs={12} className={`${styles.quoteWrapper} ${"a"}`}>
                <img src={quote} alt="quote" />
                <h5 className={styles.quote}>
                  {getCopyText("ABOUT_PAGE", "quote-from-david")}
                </h5>
                <small>{getCopyText("ABOUT_PAGE", "co-founder")}</small>
              </Col>
            </Row>
          </section>
        </Container>

        <div className={`${styles.aboutUs}`}>
          <section id="our-journey" className={styles.ourJourney}>
            <img src={svg} alt="image" />
            <Row>
              <Col
                lg={{ span: 8, offset: 2 }}
                md={11}
                className={styles.titleCol}
              >
                <h3>{getCopyText("ABOUT_PAGE", "our-journey")}</h3>
                <p>{getCopyText("ABOUT_PAGE", "journey")}</p>
              </Col>
            </Row>

            <Row className="pt-4">
              <Col lg={8} md={11} className={styles.timelineGrid}>
                {eventData.aboutEntries.map((item: any) => (
                  <Timeline item={item} key={item.id} />
                ))}
              </Col>
            </Row>
          </section>

          <section id="press" className={styles.pressWrapper}>
            <Row>
              <Col xs={12} className={styles.pressGrid}>
                <Press />
                <div className={styles.link}>
                  <Link to="/in-the-press">
                    {getCopyText("ABOUT_PAGE", "in-the-press")}
                    <i className="fa fa-long-arrow-right fa-fw i-xs" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} className={styles.parallaxCol}>
                <div className={styles.imgWrapper}>
                  <img src={caskshareLogo} alt="exampleTeam" />
                </div>
              </Col>
            </Row>
          </section>
        </div>

        <Container fluid className={styles.aboutUs}>
          <section id="cask" className={styles.caskWrapper}>
            <Row>
              <Col xs={12} className={styles.caskHeading}>
                <h3>
                  {getCopyText("ABOUT_PAGE", "the-uncompromised-experience")}
                </h3>
              </Col>
              <Col xs={12} className={styles.caskButton}>
                <Button
                  as={Link}
                  to="/caskshares"
                  className={styles.btnPrimary}
                >
                  Browse Caskshares
                </Button>
              </Col>
            </Row>
            <Row className={styles.spaceTop}>
              <Col xs={12} className={styles.pouringCol}>
                <div className={styles.pouringWrapper}>
                  <div className={styles.heading}>
                    <h3>
                      An experience <br /> that's worth <br /> the wait
                    </h3>
                    <p>{getCopyText("ABOUT_PAGE", "caskshare-para-1")}</p>
                    <p>{getCopyText("ABOUT_PAGE", "caskshare-para-2")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </div>
    </>
  );
};

export default AboutPage;
