import { gql } from "apollo-boost";

type ProductInput = {
  id: string;
  quantity: number;
  type: "product" | "cask";
};

export interface CheckoutInput {
  email?: string;
  lineItems: {
    products: ProductInput[];
  };
  currency: string;
}

const CHECKOUT_FRAGMENT = gql`
  fragment CheckoutFragment on Checkout {
    id
    amountTotal
    amountTax
    amountUndiscounted
    amountUntaxed
    amountPayable
    email
    redeemedFunds {
      amount
      deposited
      interest
    }
    status
    shippingAddress {
      address1
      address2
      city
      phone
      company
      country
      countryCode
      firstName
      lastName
      deliveryInstruction
    }
    lineItems {
      id
      inventoryId
      type
      image
      title
      bundleId
      isABundle
      bundleChild
      priceTax
      priceTotal
      priceUnit
      publicTotalPrice
      publicUnitPrice
      reservationId
      productType
      quantity
      sku
      gift
      giftData {
        giftCard
        recipientName
        recipientEmail
        giftNote
        giftDate
      }
      variant {
        id
      }
      cask {
        # NB here a reservation is the same thing as a cask
        barrelType
        barrelVariant
        bondQuarter
        bondYear
        bottlingAge
        bottlingMonth
        bottlingYear
        description
        fillingDate
        flavourNotes
        shortDescription
        storedAt
        title
        totalBottles
        totalReservedBottles
        type
        wholesalePrice
        yearsToBottling
        memberPrice
        fixedDiscount
        percentageDiscount
        distillery {
          name
          attachments {
            url
            role
          }
        }
        defaultVariant {
          id
          slug
          price {
            amount
            currencyCode
            maxDate
            maxBottles
          }
          nonBottleImage
        }
        seller {
          title
          signatureSeries
          slug
          curatorName
          attachments {
            role
            title
            url
          }
        }
        attachments {
          role
          title
          url
        }
      }
      product {
        id
        abv
        title
        description
        descriptionHtml
        createdAt
        slug
        country
        collectiveOnly
        onePerCustomer
        type
        quantity
        fixedDiscount
        percentageDiscount
        defaultVariant {
          id
          sku
          memberPrice
          price {
            amount
            currencyCode
          }
          displayName
          image
          nonBottleImage
        }
        volume
        vendor
        seller {
          title
        }
        image
        nonBottleImage
      }
      image
      promoCode
    }
    shippingLine {
      id
      title
      priceTotal
      priceTax
    }
    giftCardLine {
      id
      title
      priceTotal
      priceTax
      quantity
    }
  }
`;

export const CHECKOUT_UPDATE = gql`
  mutation checkoutUpdate($shop: ID, $input: CheckoutUpdateAttributes!) {
    checkoutUpdate(shop: $shop, input: $input) {
      checkout {
        ...CheckoutFragment
      }
      stockIssues {
        id
        remaining
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_FINALISE = gql`
  mutation checkoutFinalise($shop: ID, $input: CheckoutFinaliseAttributes!) {
    checkoutFinalise(shop: $shop, input: $input) {
      checkout {
        id
        ...CheckoutFragment
      }
      paymentPending
      insufficientBalance
      stockIssues {
        id
        remaining
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_COMPLETE = gql`
  mutation checkoutComplete(
    $shop: ID
    $id: ID!
    $email: String!
    $tokenizedPayment: TokenizedPaymentAttributes!
  ) {
    checkoutComplete(
      shop: $shop
      id: $id
      email: $email
      tokenizedPayment: $tokenizedPayment
    ) {
      checkout {
        id
      }
    }
  }
`;

export const CHECKOUT_INTENT = gql`
  mutation checkoutPaymentIntent(
    $id: ID!
    $shop: ID
    $currency: String!
    $firstName: String
    $lastName: String
    $email: String!
    $cardToken: String
  ) {
    checkoutPaymentIntent(
      shop: $shop
      id: $id
      currency: $currency
      firstName: $firstName
      lastName: $lastName
      email: $email
      cardToken: $cardToken
    ) {
      paymentIntent {
        id
        clientSecret
      }
      scaRedirectUrl
    }
  }
`;

export const GET_CHECKOUT = gql`
  query Checkout($shop: ID!, $id: ID!) {
    checkout(shop: $shop, id: $id) {
      id
      status
    }
  }
`;

export const CHECKOUT_PAYMENT_FAILED = gql`
  mutation checkoutPaymentFailed($shop: ID, $id: ID!) {
    checkoutPaymentFailed(shop: $shop, id: $id) {
      checkout {
        id
      }
    }
  }
`;

export const GET_PROMO_CODE = gql`
  query PromoCode($shop: ID!, $code: String!) {
    promoCode(shop: $shop, code: $code) {
      code
      valid
      provider
      description
    }
  }
`;
