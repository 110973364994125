import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo";
import { useSelector } from "react-redux";

import { RootState } from "../../../state/rootReducer";
import { getMetaText, getCopyText } from "../../../AppText";
import { GET_QUIZ } from "../../../graphql/Quiz";

import WelcomePage from "./Components/WelcomePage";
import QuestionsPage from "./Components/QuestionsPage";
import EmailPage from "./Components/EmailPage";
import ResultsPage from "./Components/ResultsPage";
import styles from "./QuizPage.module.scss";

const QuizPage = () => {
  const location = useLocation();
  const complexity = new URLSearchParams(location.search).get("complexity");
  const fruitiness = new URLSearchParams(location.search).get("fruitiness");
  const smokiness = new URLSearchParams(location.search).get("smokiness");
  const spice = new URLSearchParams(location.search).get("spice");
  const sweetness = new URLSearchParams(location.search).get("sweetness");
  const shopId = useSelector((state: RootState) => state.shop.id);

  const [page, setPage] = useState(0);
  const [quizzes, setQuizzes] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [flavourMap, setFlavourMap] = useState({});
  const [quizAnswers, setQuizAnswers] = useState({
    id: "1",
    questions: [{ id: "", answer: { id: "" } }],
  });

  const { data, loading } = useQuery(GET_QUIZ, {
    variables: {
      title: "Flavour Quiz",
    },
  });

  useEffect(() => {
    if (complexity && fruitiness && smokiness && spice && sweetness)
      setFlavourMap({
        complexity,
        fruitiness,
        smokiness,
        spice,
        sweetness,
      });
    return () => {
      setFlavourMap({});
    };
  }, [complexity, fruitiness, smokiness, spice, sweetness]);

  useEffect(() => {
    if (data && data?.quiz?.quizQuestions) {
      setQuizzes(data.quiz.quizQuestions);
    }
    return () => {
      setQuizzes({});
    };
  }, [data, data?.quiz?.quizQuestions]);

  const PageDisplay = () => {
    if (
      location.search &&
      new URLSearchParams(location.search).get("fruitiness") &&
      new URLSearchParams(location.search).get("smokiness") &&
      new URLSearchParams(location.search).get("spice") &&
      new URLSearchParams(location.search).get("sweetness") &&
      new URLSearchParams(location.search).get("complexity")
    ) {
      return (
        <ResultsPage
          setPage={setPage}
          flavourMap={flavourMap}
          setUserEmail={setUserEmail}
          setQuizAnswers={setQuizAnswers}
        />
      );
    } else if (page === 0) {
      return <WelcomePage page={page} loading={loading} setPage={setPage} />;
    } else if (page === 1) {
      return (
        <QuestionsPage
          quizAnswers={quizAnswers}
          setQuizAnswers={setQuizAnswers}
          quizzes={quizzes}
          setPage={setPage}
        />
      );
    } else if (page === 2) {
      return (
        <EmailPage
          setUserEmail={setUserEmail}
          userEmail={userEmail}
          setPage={setPage}
          quizAnswers={quizAnswers}
        />
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{getMetaText("QUIZ_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText("QUIZ_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("QUIZ_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("QUIZ_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={getMetaText("QUIZ_PAGE", "title")} />
        <meta
          property="og:description"
          content={getMetaText("QUIZ_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("QUIZ_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("QUIZ_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      <Container className={styles.quizPageContainer}>
        <div className={styles.bodyWrapper}>
          <div className={styles.innerCol}>{PageDisplay()}</div>
        </div>
      </Container>
    </>
  );
};

export default QuizPage;
