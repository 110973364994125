import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-apollo";

import { formatCurrency } from "../../../utils/currency";
import { RootState } from "../../../state/rootReducer";
import { Cask } from "../../../graphql/Casks";
import CaskImage from "./CaskImage";

import styles from "./CaskCard.module.scss";

const elideStr = (str: string, len: number) =>
  str != null && str.length > len ? str.slice(0, len) + ".." : str;
const CaskCard = ({
  cask,
  customer,
  disableOwnedCask,
  onClick,
}: {
  cask: Cask;
  customer?: any;
  disableOwnedCask?: boolean;
  onClick: (cask: Cask) => void;
}) => {
  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const collectiveActiveUser = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  const [ownedCask, setOwnedCask] = useState(false);
  const [numberOfOwnedCasks, setNumberOfOwnedCasks] = useState(0);
  const [price, setPrice] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);
  const [collectiveNote, setCollectiveNote] = useState("");

  useEffect(() => {
    if (customer && customer.customer) {
      let codes = customer.customer.reservations.filter(
        (a: any) => a.cask.code === cask.code,
      );
      codes?.length ? setOwnedCask(true) : setOwnedCask(false);
      const countCasks = (attr: string, value: string) => {
        return codes?.reduce(
          (acc: any, cur: any) => (cur[attr] == value ? ++acc : acc),
          0,
        );
      };
      let countedCasks = countCasks(
        cask.title,
        customer.customer.reservations.cask?.title,
      );
      if (countedCasks > 0) {
        setNumberOfOwnedCasks(countedCasks);
      }
    }
    return () => {
      setOwnedCask(false);
    };
  }, [customer, cask]);

  useEffect(() => {
    // @ts-ignore
    let percentageDiscount = cask?.percentageDiscount;
    if (!collectiveActiveUser) {
      setPrice(cask.defaultVariant.price.amount);
      setCollectiveUser(false);
      percentageDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(percentageDiscount) * 100).toFixed(
            0,
          )}% off for Collective members`,
        );
    } else if (loggedInUser && collectiveActiveUser) {
      setPrice(cask.memberPrice);
      setCollectiveUser(true);
      percentageDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(percentageDiscount) * 100).toFixed(
            0,
          )}% Collective discount applied`,
        );
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("");
    };
  }, [loggedInUser, collectiveActiveUser, cask]);

  useEffect(() => {
    let bottleDiscount = cask?.product?.percentageDiscount;
    if (cask?.product && !collectiveActiveUser) {
      setPrice(cask?.product?.defaultVariant?.price?.amount);
      bottleDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(bottleDiscount) * 100).toFixed(
            0,
          )}% off for Collective members`,
        );
    } else if (cask.product && collectiveActiveUser && loggedInUser) {
      setPrice(cask?.product?.defaultVariant?.memberPrice);
      bottleDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(bottleDiscount) * 100).toFixed(
            0,
          )}% Collective discount applied`,
        );
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("");
    };
  }, [loggedInUser, cask?.product, collectiveActiveUser]);

  // distillery link
  const distilleryLink = () => {
    if (
      cask.distillery.slug &&
      cask.distillery.status === "published" &&
      cask.distillery.physicalAddress !== null
    ) {
      return (
        <p
          onClick={() => history.push(`/distillery/${cask.distillery.slug}`)}
          className="cask-info-text dis-link"
        >
          {cask.distillery.name} Distillery
        </p>
      );
    } else if (cask.distillery.physicalAddress !== null) {
      return (
        <p className="cask-info-text">{cask.distillery.name} Distillery</p>
      );
    } else {
      return <p className="cask-info-text">{cask.distillery.name}</p>;
    }
  };

  // shop link
  const shopLink = () => {
    if (cask.product != null && cask.product.slug) {
      return (
        <Button
          onClick={() => history.push(`/products/${cask.product.slug}`)}
          variant="light"
          block
        >
          Bottles Available Now
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.caskCardComponent}>
      <Row className={styles.caskImageWrapper}>
        <CaskImage
          cask={cask}
          onClick={() => onClick(cask)}
          ownedCask={ownedCask}
          disableOwnedCask={disableOwnedCask}
          numberOfOwnedCasks={numberOfOwnedCasks}
        />
      </Row>
      <Row className={styles.contentWrapper}>
        <Col className={styles.distilleryName}>{distilleryLink()}</Col>
        <Col className={styles.caskTitle}>
          <h5 onClick={() => onClick(cask)}>{elideStr(cask.title, 48)}</h5>
        </Col>
        <Col className={styles.flavourNotes}>
          <small>flavour notes</small>
          <p>{elideStr(cask.flavourNotes, 60)}</p>
        </Col>
        <Col className={styles.priceWrap}>
          <div className={styles.price}>
            <small>Price per bottle</small>
            <p
              className={
                collectiveUser ? styles.collectivePrice : styles.nonCollective
              }
            >
              {price === 0
                ? null
                : formatCurrency(shop.currency, 2).format(price)}
            </p>
          </div>
          <div className={styles.button}>
            <Button
              className={styles.btnLight}
              onClick={() => onClick(cask)}
              disabled={
                cask.status != "published" &&
                cask.status != "bottled" &&
                cask.status != "comingsoon"
              }
            >
              {cask.status == "comingsoon" && !cask.ballotActive
                ? "On request"
                : cask.status == "comingsoon" && cask.ballotActive
                ? "Learn more"
                : cask.status == "published" || cask.status == "bottled"
                ? "Shop now"
                : "Sold out"}
            </Button>
          </div>
        </Col>
        <Col className={styles.collectiveNote}>
          <div className={styles.innerWrap}>
            <p
              className={
                collectiveUser ? styles.collectivePrice : styles.nonCollective
              }
            >
              {collectiveNote ? (
                collectiveNote
              ) : !collectiveNote && collectiveActiveUser ? (
                <Link to="/my-account/refer-a-friend">
                  Refer a Friend to The Collective
                </Link>
              ) : (
                <Link to="/the-collective">Join the Collective</Link>
              )}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CaskCard;
