import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./SocialMobile.module.scss";
import Socials from "../app/Socials";

import social2 from "../../assets/images/social/social2.jpg";
import social3 from "../../assets/images/social/social3.jpg";
import social4 from "../../assets/images/social/social4.jpg";
import social5 from "../../assets/images/social/social5.jpg";
import social6 from "../../assets/images/social/social6.jpg";
import social7 from "../../assets/images/social/social7.jpg";

const images = [
  {
    id: 1,
    img: social2,
  },
  {
    id: 2,
    img: social3,
  },
  {
    id: 3,
    img: social4,
  },
  {
    id: 4,
    img: social5,
  },
  {
    id: 5,
    img: social6,
  },
  {
    id: 6,
    img: social7,
  },
];

const SocialMobile = () => {
  return (
    <>
      <div className={styles.socialWrap}>
        <h3>Share the love</h3>
        <div className={styles.socialLink}>
          <small>@caskshare</small>
          <small>#caskshare</small>
        </div>
      </div>
      <div className={styles.slickWrapper}>
        <Slider
          dots={true}
          infinite={false}
          responsive={[
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
          slidesToShow={1}
          slidesToScroll={1}
          swipeToSlide={true}
          adaptiveHeight={true}
        >
          {images.map((img: any) => (
            <div className={styles.imgWrap}>
              <img src={img.img} alt="social-img" key={img.id} />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default SocialMobile;
