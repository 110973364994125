import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row, Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";
import { gsap } from "gsap";
import { getMetaText } from "../../../AppText";
import { RootState } from "../../../state/rootReducer";
import { loadStripe } from "@stripe/stripe-js";
import { CASKS } from "../../../graphql/Casks";
import { GET_CUSTOMER } from "../../../graphql/User";
import { COLLECTIVE_CHECKOUT } from "../../../graphql/Collective";
import {
  GET_MEMBER_PRODUCTS,
  MemberProduct,
} from "../../../graphql/MemberProduct";

import { collectiveInfo, largeCardCollectiveInfo } from "./collectiveData";
import Error from "../../../components/app/Error";
import styles from "./CollectivePage.module.scss";
import LoadingLogo from "../../../components/app/LoadingLogo";
import FeaturedBottlesComp from "../../components/app/FeaturedBottlesComp";
import CollectiveCardsSlider from "./CollectiveCardsSlider";
import ProgressiveImg from "../../../components/.v2/general/ProgressiveImg";
import { formatCurrency } from "../../../utils/currency";
import AddOnModal from "./AddOnModal";
import { MemberAddOn } from "../../../graphql/MemberProduct";

const CollectivePage = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const history = useHistory();
  hotjar.initialize(2882523, 6);
  const pageWrap: any = useRef<HTMLDivElement>();

  const shop = useSelector((state: RootState) => state.shop);
  const { email: userLoggedIn, collectiveActive } = useSelector(
    (state: RootState) => state.user,
  );

  const [bottleImg, setBottleImg] = useState("");
  const [addOns, setAddOns] = useState<MemberProduct["memberAddOns"]>([]);
  const [addOnModalOpen, setAddOnModalOpen] = useState(false);
  const [bottleImgPlc, setBottleImgPlc] = useState(
    require("../../../assets/fallbacks/wb-sm.png"),
  );
  const [stripeCollective, setStripeCollective] = useState<MemberProduct>({
    id: "",
    title: "",
    name: "",
    value: 0,
    stripePrice: "",
    memberAddOns: [],
  });

  const [collectiveCheckout, { loading: collectiveCheckoutLoading }] =
    useMutation(COLLECTIVE_CHECKOUT, {
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          variables: {
            shop: shop.id,
            awaitRefetchQueries: true,
          },
        },
      ],
    });

  const { data: memberData, loading: memberDataLoading } = useQuery(
    GET_MEMBER_PRODUCTS,
    {
      variables: {
        shop: shop.id,
      },
      skip: !shop.id,
    },
  );

  const { data: caskshares, loading: casksharesLoading } = useQuery(CASKS, {
    variables: {
      shop: shop.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (memberData?.memberProducts) {
      const collectiveMemberProduct = memberData?.memberProducts.find(
        (m: MemberProduct) => m.name === "Collective",
      );
      if (!collectiveMemberProduct) {
        return;
      }
      setAddOns(
        collectiveMemberProduct.memberAddOns.sort(
          (a: MemberAddOn, b: MemberAddOn) => a.price - b.price,
        ),
      );
      setStripeCollective(collectiveMemberProduct);
    }
    return () => {
      setStripeCollective({
        id: "",
        title: "",
        name: "",
        value: 0,
        stripePrice: "",
        memberAddOns: [],
      });
    };
  }, [memberData, memberData?.memberAddOns]);

  useEffect(() => {
    const abortController = new AbortController();

    if (bottleImg === "" && !abortController.signal.aborted) {
      setBottleImg(require("../../../assets/fallbacks/wb-lg.png"));
    }
    return () => {
      abortController.abort();
    };
  }, [bottleImg]);

  useEffect(() => {
    gsap.to(pageWrap.current, { visibility: "visible", opacity: "1" });
  });

  const openAddOnnModal = () => {
    if (addOns.length > 0) {
      setAddOnModalOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleAddON = async (selectedAddOn: MemberAddOn) => {
    await handleSubmit(selectedAddOn);
  };

  const handleSubmit = async (addOn?: MemberAddOn) => {
    if (!userLoggedIn) {
      setAddOnModalOpen(false);
      history.push({
        pathname: "/register",
        state: {
          id: 1,
          value: stripeCollective.value,
          name: "Collective",
          addOn,
        },
      });
    } else {
      const stripe = await loadStripe(shop.stripePublishableKey);
      const { data: collectiveData } = await collectiveCheckout({
        variables: {
          shop: shop.id,
          quantity: 1,
          product: "Collective",
          stripePrice: stripeCollective.stripePrice,
          successPath: "welcome-to-caskshare",
          cancelPath: "the-collective",
          addOnStripePrices: addOn ? addOn.stripePrice : [],
        },
      });

      if (
        collectiveData.collectiveCheckout.sessionId &&
        !collectiveCheckoutLoading
      ) {
        stripe?.redirectToCheckout({
          sessionId: collectiveData.collectiveCheckout.sessionId,
        });
      }
    }
    setAddOnModalOpen(false);
  };

  const modalText =
    "Why not add a bottle or a tasting pack? \nThis is the ideal way to kickstart your Caskshare journey!";

  if (casksharesLoading) {
    return <LoadingLogo />;
  }

  return (
    <>
      <Helmet>
        <title>{getMetaText("THE_COLLECTIVE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("THE_COLLECTIVE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("THE_COLLECTIVE", "description")}
        />
        <meta name="author" content={getMetaText("THE_COLLECTIVE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("THE_COLLECTIVE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("THE_COLLECTIVE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../caskshare-v3/assets/collective-page/collective_hero_img.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("THE_COLLECTIVE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("THE_COLLECTIVE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../caskshare-v3/assets/collective-page/collective_hero_img.jpg")
          }
        />
      </Helmet>

      <Container
        fluid
        className={`${styles.collectivePageWrap}`}
        ref={pageWrap}
      >
        <Row className={styles.heroWrap}>
          <div className={styles.heroImgWrapper}>
            <div className={styles.inner}>
              <div className={styles.innerWrap}>
                <div className={styles.imgWrap}>
                  <img
                    src={require("../../../caskshare-v3/assets/collective-page/collective_hero_img.jpg")}
                    alt="Caskshare Collective Page Hero Banner Welcome Bottle"
                  />
                </div>
                <Container className={styles.content}>
                  <div className={styles.head}>
                    <h2>Collective</h2>
                    <h2>
                      <span>Membership</span>
                    </h2>
                  </div>
                  <div className={styles.buttonWrap}>
                    <Button
                      className={styles.newButton}
                      onClick={openAddOnnModal}
                      disabled={collectiveActive}
                    >
                      Join now
                    </Button>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Row>
        <Container className={styles.sectionWrap}>
          <div className={styles.spacer} />
          <Row className={styles.joinBanner}>
            <Col className={styles.wrap}>
              <div className={styles.inner}>
                <h3>the Collective</h3>
                <p>
                  The Caskshare Collective is the only whisky membership you'll
                  ever need. Join today for access to a range of online
                  tastings, in-person events and discounts on everything we
                  sell.
                </p>
                <Button
                  disabled={collectiveActive}
                  className={styles.btnBlackOutline}
                  onClick={openAddOnnModal}
                >
                  Join now
                </Button>
              </div>
            </Col>
          </Row>
          <div className={styles.spacer} />
          <Row className={styles.promoBanner}>
            <Col className={styles.wrap}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../caskshare-v3/assets/collective-page/collective-member-smiling-and-enjoying-single-cask-whisky-caskshare.jpg")}
                  alt="Caskshare Collective Member Enjoying Single Cask Whisky"
                />
              </div>
              <div className={styles.contentWrap}>
                <p>
                  Run by a small team but passionate team, we're on hand for
                  everything you need and we’ll gladly share our knowledge (and
                  a dram) with you as a member.
                </p>
                <p>
                  By joining The Caskshare Collective, you're getting a taste of
                  what's to come. We offer access samples from casks that are
                  still maturing and distillery exclusives.
                </p>
              </div>
            </Col>
          </Row>
          <div className={styles.spacer} />
          <Row className={styles.benefitsWrap}>
            <Col className={styles.wrap}>
              <div className={styles.title}>
                <h3>
                  Collective <span>benefits</span>
                </h3>
              </div>
              {shop?.currency && <CollectiveCardsSlider shop={shop} />}
              <Button
                disabled={collectiveActive}
                className={styles.btnBlackOutline}
                onClick={openAddOnnModal}
              >
                Join now
              </Button>
            </Col>
          </Row>
        </Container>
        <div className={styles.blackBG}>
          <Container className={styles.sectionWrap}>
            <div className={styles.spacer} />
            <Row className={styles.collectiveSection}>
              <div className={styles.spacer} />
              <Col xs={12} className={styles.wrap}>
                <div className={styles.left}>
                  <div className={styles.title}>
                    <h3>
                      Join the Collective <br />{" "}
                      <span>
                        for just{" "}
                        {stripeCollective?.value &&
                          shop?.currency &&
                          formatCurrency(shop.currency, 0).format(
                            stripeCollective?.value,
                          )}{" "}
                      </span>
                    </h3>
                    <p>
                      The Caskshare Collective is the only whisky membership
                      you’ll ever need. From exclusive discounts to expertly
                      guided meet the maker events – our club gives you access
                      to exciting new distilleries, insightful content and
                      unique whisky before it’s bottled.
                    </p>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.imgTop}>
                    <img
                      src={require("../../../caskshare-v3/assets/collective-page/port_charlotte_bottle.jpg")}
                      alt="Caskshare Collective Members Enjoying Single Cask Whisky"
                    />
                  </div>
                </div>
                <div className={styles.left}>
                  <div className={styles.benefits}>
                    {shop?.currency && collectiveInfo().filter((item: any) => {
                        if (! item.markets) {
                          return true;
                        } else if (item.markets.includes(shop.market)) {
                          return true;
                        } else {
                          return false;
                        }
                      })
                      .map((item: any) => (
                        <div key={item.id} className={styles.inner}>
                          <h5>
                            {item.title} <br />
                            <span>{item.title2}</span>
                          </h5>
                          <p>{item.text}</p>
                        </div>
                      ))}
                  </div>
                  <Button
                    disabled={collectiveActive}
                    className={styles.btnWhiteOutline}
                    onClick={openAddOnnModal}
                  >
                    Join now
                  </Button>
                </div>
                <div className={styles.right}>
                  <div className={styles.imgBottom}>
                    <img
                      src={require("../../../caskshare-v3/assets/collective-page/collective_member_smiling_2.jpg")}
                      alt="Caskshare Collective Member Enjoying Single Cask Whisky"
                    />
                  </div>
                </div>
                <div className={styles.spacer} />
              </Col>
            </Row>
          </Container>
        </div>

        <Container className={styles.sectionWrap}>
          <Row className={styles.testimonial}>
            <Col xs={12} className={styles.wrap}>
              <div className={styles.contentWrap}>
                <div className={styles.inner}>
                  <h3>
                    A word from <br /> <span>Our Members</span>
                  </h3>
                  <p>
                    "When I joined Caskshare collective premium one of the
                    attractions was the chance to take part in Meet the Maker
                    tastings. The Annandale tasting was a great event, being
                    very informative and, best of all, amazing whiskies I’d
                    never tasted. Loving Caskshare’s approach to everything and
                    looking forward to what’s next."
                  </p>
                  <small>A Collective Member</small>
                </div>
              </div>
              <div className={styles.imgWrap}>
                <div id="enjoyImgRight" className={styles.bottleImg}>
                  <ProgressiveImg
                    placeholderSrc={bottleImgPlc}
                    src={bottleImg}
                    props={{
                      alt: "HIGHLAND PARK 35 YEAR OLD OLOROSO SINGLE CASK CASKSHARE BOTTLE",
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className={styles.beigeBG}>
          <Container className={styles.sectionWrap}>
            <Row className={styles.barrelbank}>
              {shop?.currency &&
                largeCardCollectiveInfo().filter((item: any) => {
                  if (! item.markets) {
                    return true;
                  } else if (item.markets.includes(shop.market)) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((item: any) => (
                  <Col key={item.id} xs={12} md={6} className={styles.wrap}>
                    <div className={styles.inner}>
                      <div className={styles.contentWrap}>
                        <h3>{item.title}</h3>
                        <p>{item.body}</p>
                      </div>
                      <div className={styles.imgWrap}>
                        <img
                          src={item.url}
                          alt="Caskshare Collective Benefits"
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              <Col xs={12} className={styles.buttonWrap}>
                <Button
                  disabled={collectiveActive}
                  className={styles.btnBlackOutline}
                  onClick={openAddOnnModal}
                >
                  Join now
                </Button>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={`${styles.blackBG} ${styles.featuredComp}`}>
          <FeaturedBottlesComp
            caskData={caskshares?.casks.splice(0, 8)}
            user={""}
            casksLoading={casksharesLoading}
          />
        </div>
      </Container>

      {addOnModalOpen && addOns.length > 0 ? (
        <div className={styles.addOnModalWrap}>
          <AddOnModal
            onClick={handleAddON}
            addOns={addOns}
            msg={modalText}
            itemPriceLabel="Add for"
          />
        </div>
      ) : null}
    </>
  );
};

export default CollectivePage;
