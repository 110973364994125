import React from "react";
import QuizPage from "../QuizPage";

import styles from "../QuizPage.module.scss";

const WelcomePage = ({
  page,
  setPage,
  loading,
}: {
  page?: number;
  loading?: boolean;
  setPage?: any;
}) => {
  return (
    <div className={styles.welcomePage}>
      <div className={styles.header}>
        <h4>Find your flavour profile</h4>
      </div>
      <div className={styles.body}>
        <p>
          Answer <span>5 questions</span> and discover what type of whisky
          suits you best. From flavour notes to cask type, you'll get your
          personalised flavour map and three new whisky recommendations that
          match your profile.
        </p>
        <button
          className={styles.btnPrimary}
          disabled={loading}
          onClick={() => setPage(1)}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default WelcomePage;
