export const Countries = [
  {Key: '', Value: 'Please Select...'},
  {Key: 'GB', Value:'United Kingdom'},
  {Key: 'AU', Value:'Australia'},
  {Key: 'AT', Value:'Austria'},
  {Key: 'BB', Value:'Barbados'},
  {Key: 'BE', Value:'Belgium'},
  {Key: 'BM', Value:'Bermuda'},
  {Key: 'VG', Value:'British Virgin Islands'},
  {Key: 'CA', Value: 'Canada' },
  {Key: 'KY', Value:'Cayman Islands'},
  {Key: 'HR', Value:'Croatia'},
  {Key: 'CZ', Value:'Czech Republic'},
  {Key: 'DK', Value:'Denmark'},
  {Key: 'EE', Value:'Estonia'},
  {Key: 'FI', Value: 'Finland'},
  {Key: 'FR', Value:'France'},
  {Key: 'PF', Value:'French Polynesia'},
  {Key: 'GI', Value:'Gibraltar'},
  {Key: 'DE', Value:'Germany'},
  {Key: 'GR', Value:'Greece'},
  {Key: 'HK', Value:'Hong Kong'},
  {Key: 'IE', Value:'Ireland'},
  {Key: 'IM', Value:'Isle of Man'},
  {Key: 'IT', Value:'Italy'},
  {Key: 'JE', Value:'Jersey'},
  {Key: 'JP', Value:'Japan'},
  {Key: 'GG', Value:'Guernsey'},
  {Key: 'LI', Value:'Liechtenstein'},
  {Key: 'LT', Value:'Lithuania'},
  {Key: 'LU', Value:'Luxembourg'},
  {Key: 'MO', Value:'Macao'},
  {Key: 'MT', Value:'Malta'},
  {Key: 'MQ', Value:'Martinique'},
  {Key: 'MC', Value:'Monaco'},
  {Key: 'NL', Value:'Netherlands'},
  {Key: 'NC', Value:'New Caledonia'},
  {Key: 'NZ', Value:'New Zealand'},
  {Key: 'NO', Value:'Norway'},
  {Key: 'PL', Value:'Poland'},
  {Key: 'PT', Value:'Portugal'},
  {Key: 'SM', Value:'San Marino'},
  {Key: 'SC', Value:'Seychelles'},
  {Key: 'SK', Value:'Slovakia'},
  {Key: 'SI', Value:'Slovenia'},
  {Key: 'SG', Value:'Singapore'},
  {Key: 'ZA', Value:'South Africa'},
  {Key: 'KR', Value:'South Korea'},
  {Key: 'ES', Value:'Spain'},
  {Key: 'SE', Value:'Sweden'},
  {Key: 'CH', Value:'Switzerland'},
  {Key: 'TW', Value:'Taiwan'},
  {Key: 'US', Value:'United States'},
  {Key: 'VA', Value:'Vatican City'},
]

export const UsStates = [
  { Key: '', Value: 'Please Select...' },
  { Key: 'AL', Value: 'Alabama' },
  { Key: 'AK', Value: 'Alaska' },
  { Key: 'AS', Value: 'American Samoa' },
  { Key: 'AZ', Value: 'Arizona' },
  { Key: 'AR', Value: 'Arkansas' },
  { Key: 'CA', Value: 'California' },
  { Key: 'CO', Value: 'Colorado' },
  { Key: 'CT', Value: 'Connecticut' },
  { Key: 'DE', Value: 'Delaware' },
  { Key: 'DC', Value: 'District Of Columbia' },
  { Key: 'FM', Value: 'Federated States Of Micronesia' },
  { Key: 'FL', Value: 'Florida' },
  { Key: 'GA', Value: 'Georgia' },
  { Key: 'GU', Value: 'Guam' },
  { Key: 'HI', Value: 'Hawaii' },
  { Key: 'ID', Value: 'Idaho' },
  { Key: 'IL', Value: 'Illinois' },
  { Key: 'IN', Value: 'Indiana' },
  { Key: 'IA', Value: 'Iowa' },
  { Key: 'KS', Value: 'Kansas' },
  { Key: 'KY', Value: 'Kentucky' },
  { Key: 'LA', Value: 'Louisiana' },
  { Key: 'ME', Value: 'Maine' },
  { Key: 'MH', Value: 'Marshall Islands' },
  { Key: 'MD', Value: 'Maryland' },
  { Key: 'MA', Value: 'Massachusetts' },
  { Key: 'MI', Value: 'Michigan' },
  { Key: 'MN', Value: 'Minnesota' },
  { Key: 'MS', Value: 'Mississippi' },
  { Key: 'MO', Value: 'Missouri' },
  { Key: 'MT', Value: 'Montana' },
  { Key: 'NE', Value: 'Nebraska' },
  { Key: 'NV', Value: 'Nevada' },
  { Key: 'NH', Value: 'New Hampshire' },
  { Key: 'NJ', Value: 'New Jersey' },
  { Key: 'NM', Value: 'New Mexico' },
  { Key: 'NY', Value: 'New York' },
  { Key: 'NC', Value: 'North Carolina' },
  { Key: 'ND', Value: 'North Dakota' },
  { Key: 'MP', Value: 'Northern Mariana Islands' },
  { Key: 'OH', Value: 'Ohio' },
  { Key: 'OK', Value: 'Oklahoma' },
  { Key: 'OR', Value: 'Oregon' },
  { Key: 'PW', Value: 'Palau' },
  { Key: 'PA', Value: 'Pennsylvania' },
  { Key: 'PR', Value: 'Puerto Rico' },
  { Key: 'RI', Value: 'Rhode Island' },
  { Key: 'SC', Value: 'South Carolina' },
  { Key: 'SD', Value: 'South Dakota' },
  { Key: 'TN', Value: 'Tennessee' },
  { Key: 'TX', Value: 'Texas' },
  { Key: 'UT', Value: 'Utah' },
  { Key: 'VT', Value: 'Vermont' },
  { Key: 'VI', Value: 'Virgin Islands' },
  { Key: 'VA', Value: 'Virginia' },
  { Key: 'WA', Value: 'Washington' },
  { Key: 'WV', Value: 'West Virginia' },
  { Key: 'WI', Value: 'Wisconsin' },
  { Key: 'WY', Value: 'Wyoming' }
]
