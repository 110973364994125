import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import styles from "./AgeRestriction.module.scss";
import { RootState } from "../../../../state/rootReducer";
import { ReactComponent as Logo } from "../../../../assets/images/rebrand-logo.svg";

const AgeRestriction = () => {
  const shop = useSelector((state: RootState) => state.shop);
  const [showAgeRestrictionModal, setShowAgeRestrictionModal] = useState(true);
  const [error, setError] = useState("");
  const [ageChecked, setAgeChecked] = useState(false);

  // const isUK = Intl.DateTimeFormat()
  //   .resolvedOptions()
  //   .timeZone.includes("Europe/London");

  useEffect(() => {
    const findCookie = new Object(
      document.cookie
        .split(";")
        .map((c) => c.split("="))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(value),
          }),
          {},
        ),
    );

    // @ts-ignore
    if (findCookie?.age_checked && findCookie?.age_checked === "true") {
      setShowAgeRestrictionModal(false);
    } else {
      setShowAgeRestrictionModal(true);
    }
    return () => {
      setShowAgeRestrictionModal(true);
    };
  }, [document.cookie, ageChecked]);

  const handleAgeRestriction = (e: any) => {
    const value = e.target.value;
    if (value === "false") {
      setError(
        "You must be of legal drinking age in your country to order from this website.",
      );
    } else if (value === "true") {
      let expDate = new Date();
      expDate.setDate(expDate.getDate() + 31);
      document.cookie = `age_checked=true; expires=${expDate}`;
      setError("");
      setAgeChecked(true);
    }
  };

  return (
    <>
      {(shop.market === "EU" || shop.market === "US") && showAgeRestrictionModal ? (
        <div className={styles.modalWrapper}>
          <div className={styles.container}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.headline}>
              <h4>Welcome to caskshare</h4>
            </div>
            <div className={styles.paragraph}>
              <p>Are you of legal drinking age in your country?</p>
            </div>
            <div className={styles.buttonsWrap}>
              <Button
                value="true"
                onClick={handleAgeRestriction}
                className={styles.btnPrimary}
              >
                Yes
              </Button>
              <Button
                value="false"
                onClick={handleAgeRestriction}
                className={styles.btnSecondary}
              >
                No
              </Button>
            </div>
            <div
              className={styles.error}
              style={{ display: error && "initial" }}
            >
              {error && (
                <p className={styles.showError}>
                  You must be of legal drinking age in your country to order
                  from this website.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AgeRestriction;
