import gaTracking from "../../utils/GATracking";
import fbTracking from "../../utils/FacebookTracking";
import gtmTracking from "../../utils/GTMTracking";
import klaviyoIntegration from "../../utils/KlaviyoIntegration";

const TrackingPixels = () => {
  gaTracking();
  fbTracking();
  gtmTracking();
  klaviyoIntegration();

  return null;
};

export default TrackingPixels;
