import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Spinner, Toast } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "react-apollo";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { getMetaText } from "../AppText";

const WelcomePage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [howHear, setHowHear] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
  }, [email, message]);

  const onContactUs = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  };
  return (
    <Col xs={12}>
      <Helmet>
        <title>{getMetaText("WELCOME_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("WELCOME_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("WELCOME_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("WELCOME_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("WELCOME_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
      </Helmet>

      <Row>
          <Col
            style={{
              minHeight: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          <div className="col-md-8">
            <div className="klaviyo-form-Ur9rbf"></div>
          </div>
        </Col>
      </Row>
    </Col>

  );
};
export default WelcomePage;
