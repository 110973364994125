import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/rootReducer";

import { SUBMIT_QUIZ  } from "../../../../graphql/Quiz";
import { GET_CUSTOMER } from "../../../../graphql/User";
import LoadingLogo from "../../../../components/app/LoadingLogo";

import { useMutation, useQuery } from "react-apollo";
import styles from "../QuizPage.module.scss";

function EmailPage({
  userEmail,
  setUserEmail,
  setPage,
  quizAnswers,
}: {
  userEmail?: string;
  setUserEmail?: any;
  setPage?: any;
  quizAnswers?: any;
}) {
  const history = useHistory();
  const [error, setError] = useState("");
  const [submitQuiz, { loading: quizIsSubmitting }] =
    useMutation(SUBMIT_QUIZ);
  const isValidEmail = (email: any) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const shopId = useSelector((state: RootState) => state.shop.id);
  const [customer, setCustomer] = useState<any>({});

  const loggedInUser = useSelector((state: RootState) => state.user);

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER,
    {
      variables: {
        shop: shopId,
      },
    },
  );

  useEffect(() => {
    if (customerData && customerData.customer) {
      setCustomer(customerData.customer);
    }
    return () => {
      setCustomer({});
    };
  }, [customerData, customerData?.customer, loggedInUser?.email]);

  // console.log(quizAnswers);
  // console.log(userEmail);

  const handleSaveEmail = async (email?: string) => {
    if (!isValidEmail(email)) {
      setError("Email is invalid");
      setTimeout(() => {
        setError("");
      }, 1000);
    } else {
      setError("");
      setPage(3);
      const { data: flavourMap } = await submitQuiz({
        variables: {
          email: userEmail,
          submittedQuiz: quizAnswers,
        }
      });
      const fv = flavourMap.submitQuiz.flavourMap; // FIXME - Better way to do this??
      history.push(`/find-your-flavour?complexity=${fv.complexity}&fruitiness=${fv.fruitiness}&spice=${fv.spice}&sweetness=${fv.sweetness}&smokiness=${fv.smokiness}`);
    }
  };

  if (customerLoading) {
    return <LoadingLogo />;
  }

  return (
    <div className={styles.emailPage}>
      <div className={styles.header}>
        <h4>Your Flavour Profile Is Ready.</h4>
      </div>
      <div className={styles.email}>
        <>
          <input
            type="email"
            placeholder={"enter email address"}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          {error ? <p className={styles.errorMessage}>{error}</p> : null}
        </>
      </div>
      <div className={styles.body}>
        {customer && customer.acceptsMarketing ? (
          <p>
            We'll email you the results so you can start discovering whisky that's
            made for you.
          </p>
        ) : (
          <>
            <p>
              We'll email you the results so you can start discovering whisky that's
              made for you.
            </p>
            <p>
              By entering your email address, you consent to receive marketing from
              Caskshare. This will include exclusive deals, personalised offers, and
              first-look at new releases, straight to your inbox. We promise not to
              bombard you, and you can opt-out at any time.
            </p>
          </>
        )}
        <button
          className={styles.btnPrimary}
          disabled={error ? true : false}
          onClick={() => handleSaveEmail(userEmail)}
        >
          Show my results
        </button>
      </div>
    </div>
  );
}

export default EmailPage;
