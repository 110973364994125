import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCheckSquare } from "react-icons/fa";

const PaymentSuccessModal = ({
  message,
  doClose,
  show,
  ...props
}: {
  message: string;
  doClose: () => any;
  show: boolean;
  props?: any;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <FaCheckSquare
            style={{
              width: "3rem",
              height: "3rem",
              marginRight: "1.5rem",
            }}
          />
          {`${t("We are pleased to confirm")}...`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{ marginBottom: "1rem" }}>{`${t(
          "The request was successful",
        )}`}</h5>
        <p>{`${message}`}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={doClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentSuccessModal;
