import React, { ReactNode } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import FeaturedBottlesSlider from "../../components/sliders/FeaturedBottelsSlider";
import LoadingView from "../../../components/app/LoadingView";
import { RootState } from "../../../state/rootReducer";
import { Cask } from "../../../graphql/Casks";
import styles from "./FeaturedBottlesComp.module.scss";

const FeaturedBottlesComp = ({
  user,
  caskData,
  casksLoading,
}: {
  user: ReactNode;
  caskData: any;
  casksLoading: boolean;
}) => {
  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);

  return (
    <Container className={styles.contentSection}>
      <Row className={styles.featuredSection}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <div className={styles.howItWorks}>
              <p>Reserve Now</p>
            </div>
            <div className={styles.innerContent}>
              <h3>
                Featured <br /> <span>Bottles</span>
              </h3>
              <p>
                Choose your next whisky from our range of maturing single casks.
              </p>
              <Button
                onClick={() =>
                  shop.market === "GB"
                    ? history.push("/caskshares", {
                        locations: [],
                        distilleries: [],
                        shippingTimeframe: [],
                        statuses: ["published", "comingsoon", "bottled"],
                        spiritStyles: [],
                        flavours: [],
                        priceBrackets: [],
                        bottlingAgeBrackets: [],
                        yearsToBottlingBrackets: [],
                        tag: "",
                        searchSort: { term: "featured", order: "asc" },
                      })
                    : history.push("/caskshares")
                }
                className={styles.btnBeigeOutline}
              >
                See all bottles
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.cardsWrap}>
          {casksLoading ? (
            <LoadingView dark={false} />
          ) : (
            <FeaturedBottlesSlider customer={user} casks={caskData} />
          )}
        </div>
      </Row>
    </Container>
  );
};

export default FeaturedBottlesComp;
