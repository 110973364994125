import { gql } from "apollo-boost";

export type Quizzes = {
  id: string;
  quizQuestions: {
    id: string;
    title: string;
    quizAnswers: {
      id: string;
      title: string;
    };
  };
};

export interface Quiz {
  quiz: Quizzes[];
}

export const GET_QUIZ = gql`
  query quiz($title: String!) {
    quiz(title: $title) {
      id
      quizQuestions {
        id
        title
        quizAnswers {
          id
          title
        }
      }
    }
  }
`;

export const SUBMIT_QUIZ = gql`
  mutation submitQuiz($email: String! $submittedQuiz: SubmittedQuizAttributes!) {
    submitQuiz(email: $email, submittedQuiz: $submittedQuiz){
      flavourMap{
        spice
        smokiness
        sweetness
        complexity
        fruitiness
      }
    }
  }
  
`;
