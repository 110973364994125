import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { RootState } from "../../state/rootReducer";
import LoadingView from "../app/LoadingView";
import CheckoutTotals from "./CheckoutTotals";

const CheckoutLogin = ({
  isOversold,
  promoFormVisible,
  handlePromoCode,
  promoCode,
  setPromoCode,
  setPromoFormVisible,
  promoError,
  setPromoError,
  promoLoading,
  promoValidationLoading,
  promoCodeApplied,
}: {
  isOversold: boolean;
  promoFormVisible?: boolean;
  promoCode?: string;
  promoError?: string;
  promoLoading?: boolean;
  promoValidationLoading?: boolean;
  promoCodeApplied?: boolean;
  setPromoCode?: any;
  setPromoError?: any;
  handlePromoCode?: any;
  setPromoFormVisible?: any;
}) => {
  const checkout = useSelector((state: RootState) => state.checkout);
  const history = useHistory();
  const loggedInUser = useSelector((state: RootState) => state.user);
  const [showShipping] = useState(false);

  if (!checkout) {
    return null;
  }

  const onwardLink = showShipping ? "checkout/information" : "checkout/payment";
  return (
    <>
      <CheckoutTotals checkoutData={checkout} />
      <div style={{ paddingTop: "30px" }}>
        {loggedInUser.credentials?.accessToken ? (
          <Button
            disabled={isOversold}
            block
            onClick={() => {
              history.push({
                pathname: "checkout/payment",
                state: {
                  from: {
                    pathname: "/basket",
                  },
                },
              });
            }}
          >
            Checkout
          </Button>
        ) : (
          <>
            <Button
              block
              onClick={() => {
                history.push({
                  pathname: "/login",
                  state: {
                    from: {
                      pathname: "/basket",
                    },
                  },
                });
              }}
            >
              Login to checkout
            </Button>
            <Button
              block
              onClick={() => {
                history.push({
                  pathname: "checkout/payment",
                  state: {
                    from: {
                      pathname: "/basket",
                    },
                    type: "guest",
                  },
                });
              }}
            >
              Guest checkout
            </Button>
            <Button as={Link} to="/register" block variant="dark">
              Don't have an account yet? Sign up
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default CheckoutLogin;
