import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-apollo";

import { formatCurrency } from "../../../../utils/currency";
import { RootState } from "../../../../state/rootReducer";
import { Cask } from "../../../../graphql/Casks";
import CaskImage from "../../../../components/casks/CaskImage";
import styles from "./CaskCard.module.scss";
import BottleImage from "./BottleImage";
import caskshareStatus from "../../utils/caskshareStatus";

const CaskCard = ({
  cask,
  customer,
  disableOwnedCask,
  onClick,
}: {
  cask: Cask;
  customer?: any;
  disableOwnedCask?: boolean;
  onClick?: (cask: Cask) => void;
}) => {
  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const collectiveActiveUser = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  const [ownedCask, setOwnedCask] = useState(false);
  const [numberOfOwnedCasks, setNumberOfOwnedCasks] = useState(0);
  const [price, setPrice] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);
  const [collectiveNote, setCollectiveNote] = useState("");
  const [bottleImg, setBottleImg] = useState(
    require("../../../../assets/home-page/.v3/db-lg.png"),
  );
  const [bottleImgPlc, setBottleImgPlc] = useState(
    require("../../../../assets/home-page/.v3/db-sm.png"),
  );
  const [soldOut, setSoldOut] = useState(false);

  useEffect(() => {
    if (cask?.attachments) {
      const large = cask.attachments.find(({ role }: any) => role === "large");
      large && setBottleImg(large?.url);
      const small = cask.attachments.find(({ role }: any) => role === "small");
      small && setBottleImgPlc(small?.url);
    }
    return () => {
      setBottleImg(require("../../../../assets/home-page/.v3/db-lg.png"));
      setBottleImgPlc(require("../../../../assets/home-page/.v3/db-sm.png"));
    };
  }, [cask?.attachments]);

  useEffect(() => {
    // @ts-ignore
    let percentageDiscount = cask?.percentageDiscount;
    if (!collectiveActiveUser) {
      setPrice(cask.defaultVariant.price.amount);
      setCollectiveUser(false);
      percentageDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(percentageDiscount) * 100).toFixed(
            0,
          )}% off for Collective members`,
        );
    } else if (loggedInUser && collectiveActiveUser) {
      setPrice(cask.memberPrice);
      setCollectiveUser(true);
      percentageDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(percentageDiscount) * 100).toFixed(
            0,
          )}% Collective discount applied`,
        );
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("");
    };
  }, [loggedInUser, collectiveActiveUser, cask]);

  useEffect(() => {
    if (cask && cask?.product && !collectiveActiveUser) {
      setPrice(cask?.product?.defaultVariant?.price?.amount);
    } else if (cask && cask?.product && collectiveActiveUser && loggedInUser) {
      setPrice(cask?.product?.defaultVariant?.memberPrice);
    }
    return () => {
      setPrice(0);
      setCollectiveUser(false);
    };
  }, [loggedInUser, cask?.product, cask, collectiveActiveUser]);

  useEffect(() => {
    if (cask?.product) {
     if (cask.product.quantity === 0) {
      setSoldOut(true);
     }
    } else if (cask.status == "allocated" || (cask.totalBottles === cask.totalReservedBottles)) {
      setSoldOut(true);
    } else if (cask.status != "published" && cask.status != "bottled" && cask.status != "comingsoon") {
      setSoldOut(true);
    }
    return () => {
      setSoldOut(false);
    };
  }, [cask, cask?.product]);

  // distillery link
  const distilleryLink = () => {
    if (
      cask.distillery.slug &&
      cask.distillery.status === "published" &&
      cask.distillery.physicalAddress !== null
    ) {
      return (
        <p
          onClick={() => history.push(`/distillery/${cask.distillery.slug}`)}
          className={`${styles.distillery} ${styles.activeDistillery}`}
        >
          {cask.distillery.name} Distillery
        </p>
      );
    } else if (cask.distillery.physicalAddress !== null) {
      return (
        <p className={styles.distillery}>{cask.distillery.name} Distillery</p>
      );
    } else {
      return <p className={styles.distillery}>{cask.distillery.name}</p>;
    }
  };

  if (!cask) {
    return <></>;
  }

  return (
    <div className={styles.caskCardComponent}>
      <Row className={styles.imgWrap}>
        <BottleImage
          onClick={() => onClick && onClick(cask)}
          placeholderSrc={bottleImgPlc}
          src={bottleImg}
        />
        <div
          className={`${styles.badge} ${
            cask.shippingTimeframe === "shipping_now"
              ? styles.enjoy
              : cask.shippingTimeframe === "shipping_three_months"
              ? styles.mature
              : cask.shippingTimeframe === "shipping_one_year"
              ? styles.mature
              : styles.reserve
          }`}
        >
          {caskshareStatus({
            status: cask.shippingTimeframe,
            showNumber: false,
          })}
        </div>
      </Row>
      <Row className={styles.contentWrap}>
        <Col className={styles.content}>
          <p className={styles.distillery}>{cask.distillery.name}</p>
          <p className={styles.title}>{cask.title}</p>
          <p className={styles.notes}>{cask.flavourNotes}</p>
          <p className={styles.price}>
            {price === 0
              ? null
              : formatCurrency(shop.currency, 2).format(price)}
            <span>/bottle</span>
          </p>
          <p className={styles.collective}>{collectiveNote}</p>
          <Button
            className={styles.btnCoralOutline}
            onClick={() => onClick && onClick(cask)}
            disabled={soldOut}
          >
            {cask.status == "comingsoon" && !cask.ballotActive
              ? "On request"
              : cask.status == "comingsoon" && cask.ballotActive
              ? "View details"
              : (cask.status == "published" || cask.status == "bottled") && !soldOut
              ? "Shop now"
              : "Sold out"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CaskCard;
