import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import { addAddressToCheckout } from '../../state/slices/checkoutSlice'
import { RootState } from '../../state/rootReducer'
import { UPDATE_CUSTOMER } from '../../graphql/User'

const CheckoutInfo = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const checkout = useSelector((state: RootState) => state.checkout)
  const [shippingAddress, setShippingAddress] = useState<any>(checkout.shippingAddress)
  const shopId = useSelector((state: RootState) => state.shop.id)

  const [customerUpdate] = useMutation(UPDATE_CUSTOMER)

  const submitInfoForm = async (e: any) => {
    e.preventDefault()
    await customerUpdate({
      variables: {
        shop:      shopId,
        addresses: [
          shippingAddress
        ]
      }
    })
    dispatch(addAddressToCheckout(shippingAddress))
    history.push('/checkout/shipping')
  }

  return (
    <Form onSubmit={submitInfoForm}>
      <Form.Label>
        Shipping address
      </Form.Label>
      <Form.Row>
        <Form.Group as={Col} xs={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            value={shippingAddress.firstName}
            required
            onChange={(e) => {
              setShippingAddress({ ...shippingAddress, firstName: e.target.value })
            }}
          />
        </Form.Group>
        <Form.Group as={Col} xs={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            value={shippingAddress.lastName}
            required
            onChange={(e) => {
              setShippingAddress({ ...shippingAddress, lastName: e.target.value })
            }}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Label>Address</Form.Label>
        <Form.Control
          type="text"
          required
          value={shippingAddress.address1}
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, address1: e.target.value })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Apartment, suite, etc (optional)</Form.Label>
        <Form.Control
          type="text"
          value={shippingAddress.address2}
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, address2: e.target.value })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>City</Form.Label>
        <Form.Control
          required
          value={shippingAddress.city}
          type="text"
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, city: e.target.value })
          }}
        />
      </Form.Group>
      <Form.Row
        style={{ marginBottom: '1rem' }}>
        <Form.Group as={Col} xs={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Label>Country / Region</Form.Label>
          <Form.Control
            onChange={(e) => setShippingAddress({ ...shippingAddress, countryCode: e.target.value })}
            value={shippingAddress.countryCode}
            as="select"
          >
            <option value="GB">United Kingdom</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} xs={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Label>Postcode</Form.Label>
          <Form.Control
            value={shippingAddress.postalCode}
            type="text"
            required
            onChange={(e) => {
              setShippingAddress({ ...shippingAddress, postalCode: e.target.value })
            }}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group
        style={{ display: 'flex' }}
        className="justify-content-end">
        <Button type="submit">
          Submit
        </Button>
      </Form.Group>
    </Form>
  )
}

export default CheckoutInfo
