import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getMetaText, getCopyText } from "../AppText";

import { RootState } from "../state/rootReducer";

import CaskCard from "../caskshare-v3/components/casks/caskCard/CaskCard";
import LoadingView from "../components/app/LoadingView";
import ScrollToSection from "../components/app/ScrollToSection";
import SignUpForNew from "../components/app/SignUpForNew/SignUpForNew";
import DistilleryInfoSlider from "../components/distillery/DistilleryInfoSlider";

import { GET_CUSTOMER } from "../graphql/User";
import { DISTILLERY, Distillery } from "../graphql/Distillery";
import { DISTILLERY_CASKS, Cask } from "../graphql/Casks";
import { IMAGE_FALLBACKS } from "../config";
import LoadingLogo from "../components/app/LoadingLogo";

const DistilleryPage = () => {
  const [customer, setCustomer] = useState<any>({});
  const [scroll, setScroll] = useState("");
  //@ts-ignore
  const { slug } = useParams();
  const shopId = useSelector((state: RootState) => state.shop.id);
  const loggedInUser = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const { data: distilleryData, loading: distilleryLoading } = useQuery(
    DISTILLERY,
    {
      variables: {
        shop: shopId,
        slug,
      },
    },
  );

  const { data: casksData, loading: casksLoading } = useQuery(
    DISTILLERY_CASKS,
    {
      variables: {
        shop: shopId,
        distilleryId: distilleryData?.distillery.id,
      },
    },
  );

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER,
    {
      variables: {
        shop: shopId,
      },
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (customerData && customerData.customer) {
      setCustomer(customerData);
    }
    return () => {
      setCustomer({});
    };
  }, [customerData, customerData?.customer, loggedInUser?.email]);

  if (distilleryLoading || casksLoading) {
    return <LoadingLogo />;
  }

  const distillery = distilleryData?.distillery;
  const distilleryCasks = casksData?.casks;

  const caskDetails = async (caskToAdd: Cask) => {
    const caskId = caskToAdd.defaultVariant.slug
      ? caskToAdd.defaultVariant.slug
      : caskToAdd.defaultVariant.id.split("/")[4];
    history.push(`/caskshares/${caskId}`);
  };

  const getBannerImage = (distillery: Distillery) => {
    let images = distillery.attachments.filter((a) => a.role === "banner");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPageEnviron;
    return image;
  };

  const getInsert1Image = (distillery: Distillery) => {
    let images = distillery.attachments.filter((a) => a.role === "insert-1");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPagePremises;
    return image;
  };

  const getInsert2Image = (distillery: Distillery) => {
    let images = distillery.attachments.filter((a) => a.role === "insert-2");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.distilleryPageInterior;
    return image;
  };

  const textSubstitutions = {
    "Distillery-name": distillery.name + " " + "Distillery",
    "Distillery-description": distillery.intro,
  };

  return (
    <>
      <ScrollToSection scroll={scroll} setScroll={setScroll} />
      <Helmet>
        <title>
          {getMetaText("DISTILLERY_PAGE", "title", textSubstitutions)}
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText(
            "DISTILLERY_PAGE",
            "keywords",
            textSubstitutions,
          )}
        />
        <meta
          name="description"
          content={getMetaText(
            "DISTILLERY_PAGE",
            "description",
            textSubstitutions,
          )}
        />
        <meta
          name="author"
          content={getMetaText("DISTILLERY_PAGE", "author", textSubstitutions)}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("DISTILLERY_PAGE", "title", textSubstitutions)}
        />
        <meta
          property="og:description"
          content={getMetaText(
            "DISTILLERY_PAGE",
            "description",
            textSubstitutions,
          )}
        />
        <meta
          property="og:image"
          content={window.location.origin + getBannerImage(distillery)}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("DISTILLERY_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("DISTILLERY_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={window.location.origin + getBannerImage(distillery)}
        />
      </Helmet>
      <div
        className="distillery-page distillery-page-head breakout-head"
        style={{
          backgroundImage: `url(${getBannerImage(distillery)})`,
        }}
      >
        <div className="head-image-mask" />
        <Container>
          <Row className="justify-content-center">
            <Col
              style={{
                color: "#ECE6DD",
                textAlign: "center",
              }}
              md={8}
            >
              <h2 className="distillery-name">{distillery.name} Distillery</h2>
              <p className="distillery-strapline">{distillery.strapline}</p>
              <h6
                className="discover-link"
                style={{ paddingTop: "40px" }}
                onClick={() => setScroll("scrollHere")}
              >
                Discover <br />
                <i
                  style={{
                    paddingTop: "15px",
                    color: "rgb(212, 87, 73)",
                    width: "30px",
                  }}
                  className="fa fa-long-arrow-down fa-fw"
                  aria-hidden="true"
                />
              </h6>
            </Col>
          </Row>
        </Container>
      </div>

      <Container id="scrollHere" className="distillery-page">
        <Row className="back-to-browse-distilleries" as="section">
          <Col>
            <Link to="/distilleries" style={{ color: "black" }}>
              <Row>
                <i
                  style={{ width: "30px" }}
                  className="fa fa-arrow-left fa-fw"
                  aria-hidden="true"
                />
                <h5>Browse distilleries</h5>
              </Row>
            </Link>
          </Col>
        </Row>
        <Row className="distillery-info-area" as="section">
          <Col xs={12} md={7} lg={6}>
            <DistilleryInfoSlider distillery={distillery} />
          </Col>
          <Col xs={10} md={5} lg={6} className="images-area">
            <div
              className="distillery-premises-div-image"
              style={{
                backgroundImage: `url(${getInsert1Image(distillery)})`,
                backgroundPosition: "center",
              }}
            />
            <div
              className="distillery-interior-div-image"
              style={{
                backgroundImage: `url(${getInsert2Image(distillery)})`,
              }}
            />
          </Col>
        </Row>
        <Row className="distillery-name-reprise-area" as="section" style={{}}>
          <Col>
            <Row>
              <Col xs={0} lg={1} />
              <Col
                xs={12}
                lg={10}
                className="justify-content-center align-items-center d-flex flex-column"
              >
                <div className="separator" />
                <h4
                  style={{
                    textAlign: "center",
                  }}
                >
                  {distillery.name} Distillery
                </h4>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="distillery-casks-area">
          {!distilleryCasks?.length ? (
            <Col style={{ paddingTop: "25px" }}>
              <h5>There are currently no casks for this distillery</h5>
            </Col>
          ) : (
            <>
              {distilleryCasks
                .sort((a: any, b: any) => {
                  const year = new Date().getFullYear();
                  const month = new Date().getMonth();
                  const todayDate = new Date(year, month + 1, 1);
                  const bottlingDateA = new Date(
                    a.bottlingYear,
                    a.bottlingMonth + 1,
                    1,
                  );
                  const bottlingDateB = new Date(
                    b.bottlingYear,
                    b.bottlingMonth + 1,
                    1,
                  );

                  if (todayDate > bottlingDateA || todayDate > bottlingDateB) {
                    return (
                      (bottlingDateA.valueOf() - bottlingDateB.valueOf()) * -1
                    );
                  } else {
                    return (
                      (bottlingDateA.valueOf() - bottlingDateB.valueOf()) * 1
                    );
                  }
                })
                .map((cask: Cask) => (
                  <Col
                    className="distillery-cask-card"
                    style={{ paddingTop: "25px" }}
                    sm={6}
                    md={4}
                    key={cask.variants[0].id}
                  >
                    <CaskCard
                      onClick={caskDetails}
                      customer={customer}
                      cask={cask}
                    />
                  </Col>
                ))}
            </>
          )}
        </Row>
      </Container>
      <div style={{ overflow: "hidden" }}>
        <SignUpForNew />
      </div>
    </>
  );
};

export default DistilleryPage;
