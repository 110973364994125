import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { useSelector } from "react-redux";
import { Col, Row, Button, Form, Spinner } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { CUSTOMER_SEND_PASSWORD_RESET_WITH_TOKEN } from "../graphql/User";
import { RootState } from "../state/rootReducer";

const ForgotPassword = () => {
  const location = useLocation();
  const shopId = useSelector((state: RootState) => state.shop.id);
  // @ts-ignore
  const [resetEmail, setResetEmail] = useState<string>(location?.state?.email);
  const [completed, setCompleted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [customerSendPasswordResetWithToken, { loading }] = useMutation(
    CUSTOMER_SEND_PASSWORD_RESET_WITH_TOKEN,
    {
      variables: {
        shop: shopId,
        email: resetEmail,
        redirectUrl: `${window.location.origin}/account/reset`,
      },
    },
  );

  const asyncTimeout = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const submitPasswordSend = async (e: any) => {
    e.preventDefault();
    setIsProcessing(true);
    await asyncTimeout(300);
    try {
      await customerSendPasswordResetWithToken();
      setCompleted(true);
    } catch (error) {
      console.error(error);
    }
    setIsProcessing(false);
  };

  return (
    <Col xs={12}>
      <Row>
        <Col
          style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col md={6}>
            <h5 className="mb-2">Reset your password</h5>
            <Form onSubmit={submitPasswordSend}>
              <Form.Label>Email address</Form.Label>
              <Form.Group>
                <Form.Control
                  size="lg"
                  type="email"
                  placeholder="Email address"
                  required
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                {completed ? (
                  <>
                    <p>
                      We've sent a password reset link to your email address
                    </p>
                    <Button as={Link} to="/login">
                      Back to login
                    </Button>
                  </>
                ) : (
                  <Button type="submit" disabled={loading || isProcessing}>
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </>
                    ) : (
                      "Send me a password reset link"
                    )}
                  </Button>
                )}
              </Form.Group>
            </Form>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default ForgotPassword;
