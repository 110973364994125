import React, { useState, useEffect } from "react";
const ProgressiveImg = ({
  placeholderSrc,
  src,
  onClick,
  props,
}: {
  placeholderSrc: string;
  src: string;
  onClick?: () => void;
  props?: any;
}) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const customClass =
    placeholderSrc && imgSrc === placeholderSrc
      ? "progressing-img-loading"
      : "progressing-img-loaded";

  return (
    <img
      {...{ src: imgSrc, ...props }}
      onClick={onClick}
      alt={props?.alt || ""}
      className={`progressive-image ${customClass}`}
    />
  );
};
export default ProgressiveImg;
