import React, { useRef, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Seller } from "../../graphql/Seller";
import styles from "./SignatureCard.module.scss";

import { CardHeader } from "react-bootstrap/Card";

const BioSlider = ({ seller }: { seller?: Seller }) => {
  const [activeSlide, setActiveSlide] = useState({ activeId: 0 });
  const [sellersData, setSellersData] = useState([
    {
      id: 0,
      title: "Early Life",
      body: "",
    },
    {
      id: 1,
      title: "Career",
      body: "",
    },
    {
      id: 2,
      title: "Signature Series",
      body: "",
    },
    {
      id: 3,
      title: "Legacy",
      body: "",
    },
    {
      id: 4,
      title: "Awards",
      body: "",
    },
  ]);
  useEffect(() => {
    // @ts-ignore
    sliderRef.current.slickGoTo(Object.values(activeSlide));
  }, [activeSlide]);
  const sliderRef = useRef(null);

  useEffect(() => {
    if (seller) {
      setSellersData([
        {
          id: 0,
          title: "Early Life",
          body: seller.earlyLife,
        },
        {
          id: 1,
          title: "Career",
          body: seller.career,
        },
        {
          id: 2,
          title: "Signature Series",
          body: seller.signatureCasks,
        },
        {
          id: 3,
          title: "Legacy",
          body: seller.legacy,
        },
        {
          id: 4,
          title: "Awards & Nominations",
          body: seller.awards,
        },
      ]);
    }
  }, [seller]);

  const handleClick = (id: number) => {
    setActiveSlide({ activeId: id });
  };

  return (
    <Row className={styles.bioSliderComponent}>
      <Col xs={12} sm={6} lg={5} className={styles.indicatorArea}>
        {sellersData
          .filter((a: any) => a.body != "")
          .map((item: any, i: any) => (
            <h3
              key={item.id}
              onClick={() => handleClick(i)}
              className={`${styles.indicatorItem} ${
                activeSlide.activeId === i && styles.activeItem
              }`}
            >
              <div
                className={`${styles.indicatorBar} ${
                  activeSlide.activeId === i && styles.activeBar
                }`}
              />
              {item.title}
            </h3>
          ))}
      </Col>

      <Col xs={12} sm={6} lg={7} className={styles.slideArea}>
        <Slider
          ref={sliderRef}
          adaptiveHeight={true}
          touchMove={false}
          draggable={false}
          swipe={false}
        >
          {sellersData
            .filter((a: any) => a.body != "")
            .map((item: any) => (
              <div key={item.id} className={styles.slide}>
                <p>{item.body}</p>
              </div>
            ))}
        </Slider>
      </Col>
    </Row>
  );
};

export default BioSlider;
