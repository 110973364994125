import { gql } from "apollo-boost";

export type MemberAddOn = {
  price: number;
  type: string;
  stripePrice: string;
  product: {
    title: string;
    image: string;
    nonBottleImage: boolean;
    description: string;
  };
  cask: {
    title: string;
    image: string;
    nonBottleImage: boolean;
    description: string;
  };
  [key: string]: any; // Add this index signature
};

export type MemberProduct = {
  id: string;
  title: string;
  name: string;
  value: number;
  stripePrice: string;
  memberAddOns: MemberAddOn[];
};

export interface MemberProducts {
  memberProducts: MemberProduct[];
}

export const GET_MEMBER_PRODUCTS = gql`
  query memberProducts($shop: ID!) {
    memberProducts(shop: $shop) {
      id
      title
      name
      value
      stripePrice
      memberAddOns {
        price
        type
        stripePrice
        product {
          title
          image
          nonBottleImage
          description
        }
        cask {
          title
          image
          description
        }
      }
    }
  }
`;
