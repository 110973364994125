import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Account.module.scss";

const CollectiveCancel = ({}: {}) => {
  return (
    <div className={styles.successWrap}>
      <h4>Oops! Something went wrong...</h4>
      <p>
        We are sorry but we were unable to process your request. Click the
        button below to view your collective account page.
      </p>
      <Button as={Link} to="/my-account/collective">
        BarrelBank
      </Button>
    </div>
  );
};

export default CollectiveCancel;
