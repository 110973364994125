import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Distillery } from "../../graphql/Distillery";

import { IMAGE_FALLBACKS } from "../../config";

const DistilleryCard = ({
  distillery,
  onClick,
}: {
  distillery: Distillery;
  onClick: (distillery: Distillery) => void;
}) => {
  // console.log("DISTILLERY-INFO", distillery);

  let distilleryImages = distillery.attachments.filter(
    (a) => a.role === "insert-1",
  );
  let distilleryImage = distilleryImages.length
    ? distilleryImages[0].url
    : IMAGE_FALLBACKS.distilleryCard;
  return (
    <Col
      xs={12}
      sm={6}
      lg={4}
      className="distillery-card"
      style={{
        backgroundImage: `url(${distilleryImage})`,
      }}
      onClick={() => onClick(distillery)}
    >
      <div className="border-mask" />

      <div className="separator" />
      <h4 className="distillery-name">{distillery.name} Distillery</h4>
      <h5 className="distillery-country">
        {distillery.physicalAddress ? distillery.physicalAddress.country : ""}
      </h5>
    </Col>
  );
};
export default DistilleryCard;
