import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import styles from "./AddOn.module.scss";
import { gsap } from "gsap";
import { MemberAddOn } from "../../../graphql/MemberProduct";
import AddOnSlider from "./AddOnSlider";
import LoadingLogo from "../../../components/app/LoadingLogo";

const AddOnModal = ({
  onClick,
  addOns,
  msg,
  itemPriceLabel,
}: {
  onClick: (() => void) | any;
  addOns: MemberAddOn[];
  itemPriceLabel: string;
  msg?: string;
}) => {
  const pageWrap: any = useRef<HTMLDivElement>();
  useEffect(() => {
    gsap.to(pageWrap.current, { visibility: "visible", opacity: "1" });
    const storeOpt = document.getElementById("topNavContainer");
    storeOpt!.style.opacity = "0";
    storeOpt!.style.display = "none";
    return () => {
      storeOpt!.style.opacity = "1";
      storeOpt!.style.display = "block";
    };
  });
  return addOns.length < 1 ? (
    <LoadingLogo />
  ) : (
    <div ref={pageWrap} className={styles.container}>
      <div className={styles.modalWrap}>
        {msg && (
          <div className={styles.head}>
            <p>{msg}</p>
          </div>
        )}
        <div className={styles.content}>
          {addOns && <AddOnSlider addOns={addOns} onClick={onClick} itemPriceLabel={itemPriceLabel} />}
        </div>
        <div className={styles.buttons}>
          <Button variant="secondary" onClick={() => onClick([])}>
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddOnModal;
