import React, { useRef, useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "../general-slider/GeneralSlider.module.scss";

const GeneralSlider = ({
  data,
  collectiveClass,
  ballotClass,
  activeIndex,
}: {
  data: any;
  collectiveClass?: any;
  ballotClass?: any;
  activeIndex?: any;
}) => {
  const [activeSlide, setActiveSlide] = useState({
    activeId: activeIndex || 0,
  });
  const sliderRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    sliderRef.current.slickGoTo(Object.values(activeSlide));
  }, [activeSlide]);

  const handleClick = (id: number) => {
    setActiveSlide({ activeId: id });
  };

  const sliderMarkup = (body?: string) => {
    return {
      __html: `
    ${
      body
        ? `<p class="preserve-new-lines">${body}</p>`
        : `<p style="display: none"></p>`
    }
    `,
    };
  };
  return (
    <Row
      className={`${styles.generalSliderComponent} ${
        collectiveClass && styles.collectiveClass
      } ${ballotClass && styles.ballotClass}`}
    >
      <Col xs={12} sm={5} lg={4} className={styles.indicatorArea}>
        {data.map(
          (item: any) =>
            item?.body && (
              <h3
                key={item.id}
                onClick={() => handleClick(item.id)}
                className={`${styles.indicatorItem} ${
                  activeSlide.activeId === item.id && styles.active
                }`}
              >
                <div
                  className={`${styles.indicatorBar} ${
                    activeSlide.activeId === item.id && styles.active
                  }`}
                />
                {item.title}
              </h3>
            ),
        )}
      </Col>

      <Col xs={12} sm={7} lg={8} className={styles.slideArea}>
        <Slider
          ref={sliderRef}
          adaptiveHeight={true}
          touchMove={false}
          draggable={false}
          swipe={false}
        >
          {data.map((item: any) => (
            <div key={item.id} className={styles.slideWrap}>
              <div
                className={styles.slide}
                dangerouslySetInnerHTML={sliderMarkup(item.body)}
              >
                {/* <p className="preserve-new-lines">{item.body}</p> */}
              </div>

              {item?.buttonUrl && (
                <div className={styles.buttonWrap}>
                  <Button
                    className={styles.btnBlackOutline}
                    as={Link}
                    to={item?.buttonUrl}
                  >
                    {item.buttonName}
                  </Button>
                </div>
              )}
            </div>
          ))}
        </Slider>
      </Col>
    </Row>
  );
};

export default GeneralSlider;
