import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ImInfo } from "react-icons/im";

import { store } from "../../state/store";
import { RootState } from "../../state/rootReducer";
import { formatCurrency } from "../../utils/currency";
import { LineItem } from "../../graphql/LineItem";
import { getCopyText } from "../../AppText";
import { feature } from "../../utils/featureFlag";
import DeliveryInfo from "../../components/app/DeliveryInfo";
import PromoCode from "./PromoCode";
import { GET_PROMO_CODE } from "../../graphql/Checkout";
import { addPromoCodeToCheckout } from "../../state/slices/checkoutSlice";

const CheckoutTotals = ({ checkoutData }: { checkoutData: any }) => {
  const dispatch = useDispatch();
  const shop = useSelector((state: RootState) => state.shop);
  const checkout = useSelector((state: RootState) => state.checkout);

  const [isOpen, setIsOpen] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState(false);
  const [balance, setBalance] = useState(0);
  const [interest, setInterest] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);

  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  // Promo code logic start
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [promoFormVisible, setPromoFormVisible] = useState(false);
  const [promoValidationLoading, setPromoValidatedLoading] = useState(false);
  const [skipQuery, setSkipQuery] = useState(true);

  const {
    data,
    loading: promoLoading,
    refetch,
  } = useQuery(GET_PROMO_CODE, {
    fetchPolicy: "network-only",
    skip: skipQuery,
    variables: {
      shop: shop.id,
      code: promoCode,
    },
  });

  const handlePromoCode = async (e: any) => {
    e.preventDefault();
    await setSkipQuery(false);
    setPromoError("");
    setPromoValidatedLoading(true);

    if (checkout.promoCode.code) {
      setPromoError("Discount code has already been applied");
      setPromoValidatedLoading(false);
      return;
    }

    const promoCodeObj = (await refetch())?.data?.promoCode;

    if (promoCodeObj?.valid && !promoLoading) {
      await dispatch(addPromoCodeToCheckout(promoCodeObj));
      setPromoCodeApplied(true);
      setTimeout(() => {
        setPromoCodeApplied(false);
      }, 5000);
    } else {
      setPromoError("Sorry, discount code is not valid.");
    }
    setPromoValidatedLoading(false);
    setSkipQuery(true);
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (checkout?.promoCode?.code) {
      setPromoCode(checkout?.promoCode.code);
      setPromoFormVisible(true);
    }

    return () => {
      abortController.abort();
    };
  }, []);
  // Promo code logic end

  useEffect(() => {
    if (!collectiveActive) {
      setCollectiveUser(false);
    } else if (checkoutData?.email && collectiveActive) {
      setCollectiveUser(true);
    }
    return () => {
      setCollectiveUser(false);
    };
  }, [checkoutData?.email, collectiveActive]);

  useEffect(() => {
    if (checkoutData && checkoutData.redeemedFunds) {
      setBalance(checkoutData.redeemedFunds?.deposited);
      setInterest(checkoutData.redeemedFunds.interest);
    }
    return () => {
      setBalance(0);
      setInterest(0);
    };
  }, [checkoutData.redeemedFunds]);

  if (isOpen === true) {
    const body = document.querySelector("body");
    body?.addEventListener("click", () => setIsOpen(!isOpen));
  }

  if (!checkoutData.lineItems || !checkoutData.lineItems.length) {
    return null;
  }

  const currency = shop.currency;

  const {
    redeemedFunds,
    amountTotal,
    amountTax,
    amountUndiscounted,
    publicTotalPrice,
    amountUntaxed,
    amountPayable,
    shippingLine,
  } = checkoutData;

  const caskshares = checkoutData.lineItems.filter(
    (product: LineItem) => product.type === "cask",
  );

  const giftCardSet = checkoutData.giftCardLine;

  return (
    <Col className="checkout-totals">
      {!shippingLine || shippingLine.priceTotal > 0 ? (
        <div className="checkout-totals-inner">
          <Row>
            <Col className="no-padding">
              <p>Shipping</p>
            </Col>
            <Col className="no-padding text-right">
              {shippingLine ? (
                <p>{`${formatCurrency(currency, 2).format(
                  shippingLine.priceTotal,
                )}`}</p>
              ) : (
                <p style={{ fontSize: "12px" }} className="text-muted">
                  <i>No valid shipping calculated yet</i>
                </p>
              )}
            </Col>
          </Row>
        </div>
      ) : null}

      <div className="checkout-totals-inner">
        <Row>
          <Col className="no-padding">
            <p>Subtotal</p>
          </Col>
          <Col className="no-padding text-right">
            <p>{`${formatCurrency(currency, 2).format(amountUntaxed)}`}</p>
          </Col>
        </Row>
      </div>
      {giftCardSet?.priceTotal ? (
        <div className="checkout-totals-inner">
          <Row>
            <Col className="no-padding">
              <p>
                Gift Card{" "}
                {giftCardSet?.quantity ? `x${giftCardSet?.quantity}` : null}
              </p>
            </Col>
            <Col className="no-padding text-right">
              <p>{`${formatCurrency(currency, 2).format(
                giftCardSet?.priceTotal,
              )}`}</p>
            </Col>
          </Row>
        </div>
      ) : null}
      <div className="checkout-totals-inner">
        <Row>
          {redeemedFunds.amount && redeemedFunds.amount > 0 && (
            <>
              <Col className="no-padding">
                <p className="tooltip-p">
                  BarrelBank&nbsp;Funds
                  <sup onClick={() => setIsOpen(!isOpen)}>
                    <ImInfo />
                  </sup>
                </p>
                {isOpen ? (
                  <div className="custom-tooltip">
                    <p className="">
                      Balance used:{" "}
                      {`${formatCurrency(currency, 2).format(balance)}`}
                      <br />
                      Interest:{" "}
                      {`${formatCurrency(currency, 2).format(interest)}`}
                    </p>
                    <span className="tooltip-arrow"></span>
                  </div>
                ) : null}
              </Col>
              <Col className="no-padding text-right">
                <p>
                  -
                  {`${formatCurrency(currency, 2).format(
                    redeemedFunds.amount,
                  )}`}
                </p>
              </Col>
            </>
          )}
        </Row>
      </div>
      {checkoutData.amountTotal === checkoutData.amountPayable ? null : (
        <div className="checkout-totals-inner">
          <Row>
            <Col className="no-padding">
              <p>Total</p>
            </Col>
            <Col className="no-padding text-right">
              <p>{`${formatCurrency(currency, 2).format(amountTotal)}`}</p>
            </Col>
          </Row>
        </div>
      )}

      <div className="checkout-totals-inner">
        {amountTax === 0 && currency !== "gbp" ? (
          ""
        ) : (
          <Row>
            <Col className="no-padding">
              {currency === "gbp" ? <p>Tax (VAT)</p> : <p>Tax</p>}
            </Col>
            <Col className="no-padding text-right">
              <p>{`${formatCurrency(currency, 2).format(amountTax)}`}</p>
            </Col>
          </Row>
        )}
      </div>
      <div
        style={{ marginTop: ".5rem" }}
        className={
          caskshares.length || shippingLine.priceTotal == 0
            ? "caskshares-shipping-info"
            : "checkout-totals-inner"
        }
      >
        <Row>
          {/* Caskshare Shipping Info  */}
          {caskshares.length && shop.market != "US" ? (
            <div style={{ background: "rgb(245, 242, 238)", padding: "15px" }}>
              <span style={{ fontWeight: "bold" }}>
                <i
                  style={{ paddingRight: "5px", color: "#e76852" }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
                Caskshares: Shipping info
              </span>
              {!collectiveUser ? (
                <p className="small-title">
                  {getCopyText("CHECKOUT_TOTALS_COMPONENT", "shipping_note")}
                </p>
              ) : null}

              <p className="small-title mt-1">
                {getCopyText(
                  "CHECKOUT_TOTALS_COMPONENT",
                  "shipping_note_exclusions",
                )}
              </p>
              <Link to={{ pathname: "/terms/faq" }} target="_blank">
                <p
                  className="small-title mt-1"
                  style={{ textDecoration: "underline" }}
                >
                  {getCopyText(
                    "CHECKOUT_TOTALS_COMPONENT",
                    "shipping_more_detail",
                  )}
                </p>
              </Link>
            </div>
          ) : null}
        </Row>
      </div>
      <div className="checkout-totals-inner">
        <Row className="to-basket-wrap">
          <Col className="delivery-info">
            <p
              onClick={() => {
                setDeliveryInfo(true);
              }}
            >
              <i className="fa fa-truck" />
              Delivery information
              {/* {getCopyText("ABOUT_PAGE", "in-the-press")} */}
            </p>
          </Col>
        </Row>
        {feature("promo_codes") === true && (
          <PromoCode
            promoFormVisible={promoFormVisible}
            handlePromoCode={handlePromoCode}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            setPromoFormVisible={setPromoFormVisible}
            promoError={promoError}
            setPromoError={setPromoError}
            promoLoading={promoLoading}
            promoValidationLoading={promoValidationLoading}
            promoCodeApplied={promoCodeApplied}
          />
        )}
        <Row className="checkout-total-area">
          <Col className="no-padding">
            <p className="checkout-total-title">Amount Payable</p>
          </Col>
          <Col className="no-padding text-right">
            <p className="checkout-total-amount">
              {`${formatCurrency(currency, 2).format(amountPayable)}`}
            </p>
          </Col>
        </Row>
        { shop.market === 'US' ? (
          <Row>
            <Col className="no-padding">
              <p className="checkout-total-subtext">
                <small>* Sales taxes included in price.</small>
              </p>
            </Col>
          </Row>
        ) : null }
      </div>
      {deliveryInfo && (
        <DeliveryInfo setDeliveryInfo={setDeliveryInfo} shop={shop} />
      )}
    </Col>
  );
};

export default CheckoutTotals;
