import React, { useState, useEffect, FormEvent } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { MdEventSeat } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-apollo";
import { MdClose } from "react-icons/md";
import pluralize from "pluralize";
import moment from "moment";
import styles from "./GeneralModal.module.scss";
import { useMultistepForm } from "./useMultistepForm";
import {
  Events,
  EVENT_REGISTRATION,
  GET_CUSTOMER,
} from "../../../graphql/User";
import { Product } from "../../../graphql/Products";
import { increaseCheckoutQuantity } from "../../../state/slices/checkoutSlice";
import { IMAGE_FALLBACKS } from "../../../config";
import { SelectSeats } from "../../account/collective/components/SelectSeats";
import { SelectTastingPack } from "../../account/collective/components/SelectTastingPack";
import { CustomerEvents } from "../../account/collective/InsideCollective";
import { SelectExtraPack } from "../../account/collective/components/SelectExtraPack";
import { RootState } from "../../../state/rootReducer";

const GeneralModal = ({
  modalOpen,
  setModalOpen,
  customerEvents,
  item,
}: {
  modalOpen: boolean;
  customerEvents?: CustomerEvents[];
  setModalOpen: (status: boolean) => void;
  item: Events;
}) => {
  type SelectData = {
    seats: number;
    tastingPacks: number;
  };

  const INITIAL_DATA: SelectData = {
    seats: 1,
    tastingPacks: 1,
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState(INITIAL_DATA);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [availableSeatsMessage, setAvailableSeatsMessage] = useState("");
  const [addExtraPack, setAddExtraPack] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentlyBookedSeats, setCurrentlyBookedSeats] = useState({
    seats: 0,
  });
  const [disableAdd, setDisableAdd] = useState(false);
  const [disableSub, setDisableSub] = useState(false);

  const tastingPackImage = item.product.image
    ? item.product.image
    : IMAGE_FALLBACKS.glenrothesPlaceholder;
  const tastingPack = item?.product;
  const extraPack = item?.upsellProduct;
  const extraPackImage = item?.upsellProduct?.image
    ? item.upsellProduct.image
    : IMAGE_FALLBACKS.glenrothesPlaceholder;
  const maxSeats = item?.maximumSeats;
  const isVirtual = item?.virtual;
  const shop = useSelector((state: RootState) => state.shop);

  function updateState(state: Partial<SelectData>) {
    setEventData((prev) => {
      return { ...prev, ...state };
    });
  }

  function isInThePast() {
    return new Date(item.date).toISOString() > new Date().toISOString()
      ? false
      : true;
  }

  useEffect(() => {
    if (customerEvents?.length) {
      const bookedSeats = customerEvents.filter(
        (e: CustomerEvents) => e.slug === item.slug,
      );
      if (bookedSeats[0]?.seats) {
        setCurrentlyBookedSeats({ seats: bookedSeats[0].seats });
      } else setCurrentlyBookedSeats((prev) => prev);
    }
    return () => {
      setCurrentlyBookedSeats({ seats: 0 });
    };
  }, [item?.slug, customerEvents]);

  useEffect(() => {
    if (currentlyBookedSeats.seats >= 1) {
      let seatsLeft = maxSeats - currentlyBookedSeats.seats;
      setAvailableSeatsMessage(
        `${seatsLeft > 0 ? "Only" : ""} ${pluralize(
          "seat",
          seatsLeft,
          true,
        )} left`,
      );
      if (
        currentlyBookedSeats.seats + eventData.seats === maxSeats ||
        currentlyBookedSeats.seats === maxSeats ||
        maxSeats - currentlyBookedSeats.seats <= 1
      ) {
        setDisableAdd(true);
      } else setDisableAdd(false);

      if (currentlyBookedSeats.seats === maxSeats) {
        setDisableSub(true);
        goTo(1);
        setEventData({ ...eventData, seats: 0 });
      } else if (!isVirtual) {
        goTo(1);
        setEventData({ ...eventData, seats: 0 });
      } else {
        setDisableSub(false);
      }
    }

    if (isInThePast()) {
      goTo(1);
      setEventData({ ...eventData, seats: 0 });
    }

    return () => {
      setDisableAdd(false);
      setDisableSub(false);
      setAvailableSeatsMessage("");
    };
  }, [customerEvents, maxSeats, eventData.seats, currentlyBookedSeats.seats]);

  const {
    steps,
    currentStepIndex,
    step,
    back,
    next,
    isFirstStep,
    isLastStep,
    goTo,
  } = useMultistepForm([
    <SelectSeats
      {...eventData}
      event={item}
      maxSeats={maxSeats}
      image={tastingPackImage}
      disableAdd={disableAdd}
      disableSub={disableSub}
      updateState={updateState}
      availableSeatsMessage={availableSeatsMessage}
    />,
    <SelectTastingPack
      {...eventData}
      updateState={updateState}
      image={tastingPackImage}
      event={item}
      showConfirmation={showConfirmation}
    />,
    extraPack && (
      <SelectExtraPack
        {...eventData}
        updateState={updateState}
        image={extraPackImage}
        setAddExtraPack={setAddExtraPack}
        addExtraPack={addExtraPack}
        event={item}
        showConfirmation={showConfirmation}
      />
    ),
  ]);

  const [eventRegistration] = useMutation(EVENT_REGISTRATION, {
    refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
  });

  // const addToBasket = (product: Product, qty?: number) => {
  //   dispatch(
  //     increaseCheckoutQuantity({ product: product, giftQuantity: qty || 1 }),
  //   );
  //   setModalOpen(false);
  //   history.push("/basket");
  // };

  const redirectToBasket = () => {
    setModalOpen(false);
    history.push("/basket");
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!isLastStep) return next();
    if (isLastStep) {
      setIsProcessing(true);
      const { data } = await eventRegistration({
        variables: {
          eventSlug: item.slug,
          seats: eventData.seats,
        },
      });
      if (data?.eventRegistration.success === false) {
        alert("Something went wrong. Please, try again later.");
      } else {
        if (eventData.tastingPacks > 0) {
          await dispatch(
            increaseCheckoutQuantity({
              product: tastingPack,
              giftQuantity: eventData.tastingPacks || 1,
            }),
          );
        }
        if (addExtraPack) {
          await dispatch(
            increaseCheckoutQuantity({
              product: extraPack,
              giftQuantity: 1,
            }),
          );
        }
        setIsProcessing(false);
        setShowConfirmation(true);
      }
    }
  }

  return (
    <Modal
      show={modalOpen}
      dialogClassName="gifting-modal"
      aria-labelledby="contained-modal-title-vcenter"
      className={styles.generalModalWrap}
      animation={false}
      centered
    >
      <div className={styles.inner}>
        <Form onSubmit={handleSubmit} className={styles.formWrap}>
          {!showConfirmation && (
            <>
              <div className={styles.stepProgress}>
                {currentStepIndex + 1} / {steps.length}
              </div>
              <div
                className={styles.closeModal}
                onClick={() => setModalOpen(false)}
              >
                <MdClose />
              </div>
            </>
          )}
          {step}
          <div className={styles.buttonWrap}>
            {isFirstStep ? (
              <Button
                className={styles.btnLight}
                onClick={() => setModalOpen(false)}
              >
                Close
              </Button>
            ) : !showConfirmation ? (
              <Button
                className={styles.btnLight}
                onClick={back}
                disabled={
                  (currentlyBookedSeats.seats === maxSeats &&
                    steps.length === 2 &&
                    !isLastStep) ||
                  !isVirtual ||
                  (isInThePast() && steps.length === 2) ||
                  (isInThePast() && !isLastStep)
                }
              >
                Back
              </Button>
            ) : null}
            {!showConfirmation ? (
              <Button
                className={styles.btnPrimary}
                type="submit"
                disabled={eventData.seats < 0 || isProcessing}
              >
                {isLastStep ? (
                  isProcessing ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Submit
                    </>
                  ) : (
                    "Submit"
                  )
                ) : (
                  "Next"
                )}
              </Button>
            ) : eventData.tastingPacks > 0 || addExtraPack ? (
              <>
                <Button
                  className={styles.btnLight}
                  onClick={() => setModalOpen(false)}
                  disabled={!showConfirmation}
                >
                  Close
                </Button>
                <Button
                  className={styles.btnPrimary}
                  // onClick={() => addToBasket(tastingPack, eventData.tastingPacks)}
                  onClick={redirectToBasket}
                  disabled={!showConfirmation}
                >
                  Go to Basket
                </Button>
              </>
            ) : (
              <Button
                className={styles.btnLight}
                onClick={() => setModalOpen(false)}
                disabled={!showConfirmation}
              >
                Close
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default GeneralModal;
