import { gql } from "apollo-boost";

export interface Product {
  abv: string;
  description: string;
  descriptionHtml: string;
  id: string;
  slug: string;
  sku: string;
  country: string;
  createdAt: Date;
  collectiveOnly: boolean;
  onePerCustomer: boolean;
  image: string;
  featuredOrdinal: number;
  fixedDiscount: string;
  percentageDiscount: string;
  quantity: number;
  release: string;
  title: string;
  originalUnitPrice: number;
  collections: {
    title: string;
  };
  defaultVariant: {
    id: string;
    sku: string;
    memberPrice: number;
    price: {
      amount: number;
      currencyCode: string;
    };
  };
  vendor: string;
  seller: {
    title: string;
  };
  volume: string;
  variant: { id: string };
  type: "product";
  giftType: string;
  isABundle: boolean;
  nonBottleImage: boolean;
}

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    abv
    title
    description
    descriptionHtml
    createdAt
    id
    slug
    country
    release
    collectiveOnly
    onePerCustomer
    featured
    featuredOrdinal
    fixedDiscount
    percentageDiscount
    productType
    type
    quantity
    collections {
      title
    }
    defaultVariant {
      id
      sku
      memberPrice
      price {
        amount
        currencyCode
      }
      displayName
      image
    }
    volume
    vendor
    seller {
      title
    }
    image
    isABundle
    nonBottleImage
  }
`;

export const COLLECTIONS = gql`
  query Collections($shop: ID) {
    collections(shop: $shop) {
      id
      image
      description
      title
    }
  }
`;
export const PRODUCT = gql`
  query Product($shop: ID, $id: ID!) {
    product(shop: $shop, id: $id) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const PRODUCTS = gql`
  query Products($shop: ID, $collectionId: ID, $country: ID, $type: String) {
    products(
      shop: $shop
      collectionId: $collectionId
      country: $country
      type: $type
    ) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const RECOMMENDED_PRODUCTS = gql`
  query similarProducts($product: ID, $slug: String, $first: Int) {
    similarProducts(product: $product, slug: $slug, first: $first) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;
