import { gql } from "apollo-boost";
import { PRODUCT_FRAGMENT, Product } from "./Products";
import { CASK_FRAGMENT, Cask } from "./Casks";

export type Ballot = {
  id: string;
  title: string;
  pageTitle: string;
  distilleryName: string;
  slug: string;
  openAt: Date;
  closeAt: Date;
  DrawAt: Date;
  shippingAt: Date;
  quantity: number;
  cask: Cask;
  product: Product;
  type: Product["type"] | Cask["type"];
  stripePrice: string;
  status: string;
  description: string;
  privateSalesDescription: string;
  collaborationDetails: string;
  collaborationTitle: string;
  artistHeadline: string;
  artistDetails: string;
  paymentSummary: string;
};

export interface Quiz {
  quiz: Ballot[];
}

export const BALLOT = gql`
  query ballot($shop: ID!, $slug: String!) {
    ballot(shop: $shop, slug: $slug) {
      id
      title
      subtitle
      pageTitle
      distilleryName
      shop {
        market
      }
      slug
      cask {
        ...CaskFragment
      }
      product {
        ...ProductFragment
      }
      attachments {
        role
        title
        url
      }
      openAt
      closeAt
      drawAt
      shippingAt
      countdown
      quantityPicker
      quantity
      termsButtonText
      type
      status
      description
      privateSalesDescription
      collaborationDetails
      collaborationTitle
      artistHeadline
      artistDetails
      paymentSummary
      terms
      termsUrl
      priority
      gbp
      eur
      usd
    }
  }
  ${CASK_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`;

export const BALLOT_CHECKOUT = gql`
  mutation ballotCheckout(
    $shop: ID
    $quantity: Int!
    $item: String!
    $currencyCode: String!
    $successPath: String!
    $cancelPath: String!
  ) {
    ballotCheckout(
      shop: $shop
      quantity: $quantity
      item: $item
      currencyCode: $currencyCode
      successPath: $successPath
      cancelPath: $cancelPath
    ) {
      sessionId
    }
  }
`;
