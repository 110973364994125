import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../state/rootReducer";
import { useMutation, useQuery } from "react-apollo";
import { GIFT_CLAIM, GET_CUSTOMER, ADDRESS_UPSERT } from "../graphql/User";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getMetaText, getCopyText } from "../AppText";
import ClaimComplete from "../components/claim/ClaimComplete";
import AddressForm from "../components/account/AddressForm";
import LoadingLogo from "../components/app/LoadingLogo";

const ClaimGiftPage = ({ user }: { user?: any }) => {
  const shop = useSelector((state: RootState) => state.shop);
  const history = useHistory();
  const location = useLocation();

  const [code, setCode] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState<any>({
    // customer fields - from customer
    firstName: "",
    lastName: "",
    email: "",
    // address fields - from customer.addresses[0]
    id: "",
    address1: "",
    address2: "",
    phone: "",
    city: "",
    stateCode: "",
    countryCode: "",
    postalCode: "",
    deliveryInstruction: "",
  });

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.from === "/register") {
      window.sessionStorage.removeItem("location");
    }
  }, [location?.state]);

  const [claim] = useMutation(GIFT_CLAIM, {
    refetchQueries: [{ query: GET_CUSTOMER, variables: { shop: shop.id } }],
  });

  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      shop: shop.id,
    },
    fetchPolicy: "network-only",
  });

  const [addressUpsert] = useMutation(ADDRESS_UPSERT, {
    variables: {
      shop: shop.id,
      email: accountDetails.email,
      input: accountDetails,
      category: "shipping",
    },
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: { shop: shop.id, email: accountDetails.email },
      },
    ],
    awaitRefetchQueries: true,
  });

  const asyncTimeout = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const onClaim = async (e: any) => {
    e.preventDefault();
    const { data: transferData } = await claim({
      variables: {
        code: code,
      },
    });
    if (transferData.giftClaim.success === false) {
      console.warn("transfer() failed");
      alert("Sorry we were not able claim your gift!");
    } else {
      if (transferData.giftClaim.giftType.includes("Collective")) {
        history.push("/welcome-to-caskshare");
      } else {
        history.push("/my-account/my-gifts");
      }
    }
  };

  const submitAddressUpsert = async (e: any) => {
    e.preventDefault();
    await asyncTimeout(300);
    const address = {
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      email: accountDetails.email,
      address1: accountDetails.address1,
      address2: accountDetails.address2,
      phone: accountDetails.phone,
      id: accountDetails.id,
      city: accountDetails.city,
      stateCode: accountDetails.stateCode,
      countryCode: accountDetails.countryCode,
      postalCode: accountDetails.postalCode,
      deliveryInstruction: accountDetails.deliveryInstruction,
    };

    const result = await addressUpsert({
      variables: {
        shop: shop.id,
        email: address.email,
        input: address,
        category: "shipping",
      },
    });
    if (data?.customer?.billingAddresses?.length <= 0) {
      const result = await addressUpsert({
        variables: {
          shop: shop.id,
          email: address.email,
          input: address,
          category: "billing",
        },
      });
    }
    if (result.errors) {
      console.warn("submitAddressUpsert() failed", result.errors);
      alert("Sorry we were not able to update your details");
    } else {
      setFormOpen(false);
    }
  };

  if (!data?.customer || loading) {
    return <LoadingLogo />;
  }

  return (
    <Container style={{ paddingTop: "50px", minHeight: "70vh" }}>
      <Helmet>
        <title>{getMetaText("CLAIM_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText("CLAIM_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("CLAIM_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("CLAIM_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("CLAIM_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("CLAIM_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("CLAIM_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("CLAIM_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
      </Helmet>

      <Row className="back-to-browse-caskshares" as="section">
        <Col>
          <Row className="goBack" onClick={() => history.goBack()}>
            <i
              style={{ width: "30px", marginTop: "-2px" }}
              className="fa fa-arrow-left fa-fw"
              aria-hidden="true"
            />
            <h6>Browse Account</h6>
          </Row>
        </Col>
      </Row>

      {!formOpen && (
        <Col>
          <h4 className="mb-2">{getCopyText("CLAIM_PAGE", "redeem-code")}</h4>
          <p>{getCopyText("CLAIM_PAGE", "enter-gift-code")}:</p>
          <Form onSubmit={onClaim}>
            <Form.Group className="pt-4">
              <Form.Control
                size="lg"
                type="text"
                placeholder="Code"
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Button
                type="submit"
                size="lg"
                className="mt-4"
                disabled={
                  data?.customer?.shippingAddress?.address1 ? false : true
                }
                style={{
                  backgroundColor: "#003f5a",
                  borderColor: "#003f5a",
                  color: "white",
                  padding: "0.5rem 1.5rem",
                }}
              >
                {getCopyText("CLAIM_PAGE", "claim")}
              </Button>
            </Form.Group>
          </Form>
          {data?.customer?.shippingAddress?.address1 ? null : (
            <div className="add-address-link">
              <p>
                Please add your{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    setFormOpen(true);
                  }}
                >
                  shipping address
                </a>{" "}
                to use your voucher.
              </p>
            </div>
          )}
        </Col>
      )}

      {formOpen ? (
        <AddressForm
          onSubmit={submitAddressUpsert}
          setFormOpen={setFormOpen}
          accountDetails={accountDetails}
          setAccountDetails={setAccountDetails}
        />
      ) : null}
    </Container>
  );
};

export default ClaimGiftPage;
