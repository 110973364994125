import React, { useState, useEffect } from "react";
import { ImInfo } from "react-icons/im";

const Tooltip = ({
  lineOne,
  customIcon,
}: {
  lineOne?: any;
  customIcon?: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (isOpen) {
    const body = document.querySelector("body");
    body?.addEventListener("click", () => setIsOpen(!isOpen));
  }

  useEffect(() => {
    const elString = lineOne;
    const element = document.getElementById("tooltip");
    const parentEl = document.getElementById("tooltip")?.parentElement;
    if (parentEl) {
      parentEl.style.position = "relative";
    }
    const fragment = document.createRange().createContextualFragment(elString);
    element?.appendChild(fragment);
  }, [isOpen]);

  return (
    <>
      <sup
        onClick={(e: any) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        {customIcon ? customIcon : <ImInfo />}
      </sup>
      {isOpen ? (
        <div id="tooltip" className="custom-tooltip">
          <span className="tooltip-arrow"></span>
        </div>
      ) : null}
    </>
  );
};

export default Tooltip;
