import React, { useState, useEffect, useRef } from "react";

// @ts-ignore
import useDimensions from "react-use-dimensions";

import { Cask } from "../../graphql/Casks";

type FlavourMapName =
  | "fruitiness"
  | "sweetness"
  | "spice"
  | "smokiness"
  | "complexity";

const BLOB_ANGLES = {
  fruitiness: 90 - 72,
  sweetness: 90,
  spice: 90 + 72,
  smokiness: 90 + 72 + 72,
  complexity: 90 + 72 + 72 + 72,
};

const FlavourMapGraphic = ({ cask }: { cask: any }) => {
  const [animStep, setAnimStep] = useState(0);

  // @ts-ignore
  const [graphicRef, { width: graphicWid, height: graphicHgt }] =
    useDimensions();
  // console.log("GRAPHICDIMENSIONS", graphicRef, graphicWid, graphicHgt);

  // hard-coded values here
  const graphicCenter = { h: graphicWid * 0.484, v: graphicHgt * 0.49 };
  const blobRingRadius = graphicWid * 0.275;

  useEffect(() => {
    window.scrollBy(0, 0);
    setTimeout(() => window.scrollBy(0, 1), 30);
  }, []);

  const calcCenterMarkerStyle = () => {
    let dim = 10;
    return {
      left: -dim / 2 + graphicCenter.h + "px",
      top: -dim / 2 + graphicCenter.v + "px",
      width: dim + "px",
      height: dim + "px",
    };
  };
  const calcRingMarkerStyle = () => {
    let dim = blobRingRadius * 2;

    return {
      left: -dim / 2 + graphicCenter.h + "px",
      top: -dim / 2 + graphicCenter.v + "px",
      width: dim + "px",
      height: dim + "px",
    };
  };

  const calcBlobStyle = (cask: Cask, name: FlavourMapName) => {
    let blobAngle = BLOB_ANGLES[name];
    let blobCenter = {
      h: blobRingRadius * Math.cos((blobAngle * Math.PI) / 180),
      v: blobRingRadius * Math.sin((blobAngle * Math.PI) / 180),
    };
    blobCenter = {
      h: graphicCenter.h + blobCenter.h,
      v: graphicCenter.v + blobCenter.v,
    };
    let dim = cask?.flavourMap[name] * blobRingRadius * 0.13;
    if (animStep === 0) {
      dim *= 0.1;
      setTimeout(() => setAnimStep(1), 300);
    }
    return {
      left: -dim / 2 + blobCenter.h + "px",
      top: -dim / 2 + blobCenter.v + "px",
      width: dim + "px",
      height: dim + "px",
      transitionDuration: animStep === 0 ? "0" : "1s",
    };
  };

  return (
    <>
      <div
        ref={graphicRef}
        id="graphic"
        className="flavour-map-graphic-component"
      >
        <img
          className="flavour-map-background"
          src={require("../../assets/cask-page/flavour-map-background.svg")}
          aria-hidden="true"
        />
        {/* <div className="center-marker" style={calcCenterMarkerStyle()} /> */}

        <div
          className="blob fruitiness"
          style={calcBlobStyle(cask, "fruitiness")}
        />
        <div
          className="blob sweetness"
          style={calcBlobStyle(cask, "sweetness")}
        />
        <div className="blob spice" style={calcBlobStyle(cask, "spice")} />
        <div
          className="blob smokiness"
          style={calcBlobStyle(cask, "smokiness")}
        />
        <div
          className="blob complexity"
          style={calcBlobStyle(cask, "complexity")}
        />
        <div className="ring-marker" style={calcRingMarkerStyle()} />
      </div>
    </>
  );
};

export default FlavourMapGraphic;
