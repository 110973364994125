import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Cask } from "../../graphql/Casks";
import { IMAGE_FALLBACKS } from "../../config";
import { BiPurchaseTag } from "react-icons/bi";
import Tooltip from "../app/Tooltip";

const CaskImage = ({
  cask,
  hideCount = false,
  newReserved = -1,
  reservation = null,
  ownedCask,
  disableOwnedCask,
  numberOfOwnedCasks,
  onClick,
}: {
  cask: Cask;
  hideCount?: boolean;
  newReserved?: number;
  reservation?: any;
  ownedCask?: boolean;
  disableOwnedCask?: boolean;
  numberOfOwnedCasks?: number;
  onClick?: (cask: Cask) => void;
}) => {
  const caskYear = cask.fillingDate
    ? new Date(cask.fillingDate).getFullYear()
    : null;
  const reserved = newReserved >= 0 ? newReserved : cask.totalReservedBottles;

  let total: any;
  total = cask.totalBottles; // real

  const getCaskBottomImage = (cask: Cask) => {
    let images = cask.distillery.attachments.filter(
      (a) => a.role === "cask-bottom",
    );
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.caskImageCaskBottom;
    return image;
  };
  const getCaskBottlesImage = (cask: Cask) => {
    let caskImages = cask.seller.attachments?.filter(
      (a) => a.role === "cask-bottles",
    );
    let caskImage = caskImages?.length
      ? caskImages[0].url
      : IMAGE_FALLBACKS.caskImageCaskBottles;
    return caskImage;
  };

  let tooltipText = `<p>You have a ${numberOfOwnedCasks} bottle allocation from this cask.</p>`;

  const checkCaskStock = () => {
    if (cask.totalReservedBottles >= cask.totalBottles * 0.8) {
      return true;
    } else {
      return false;
    }
  };

  const fillIndicator = () => {
    if (checkCaskStock()) {
      return (
        <>
          <div
            className="fill-spacer"
            style={{
              top: `calc(${Math.min((reserved / total) * 100, 95)}% + 6px)`,
            }}
          >
            &nbsp;
          </div>
          <div
            className="fill-marker"
            style={{
              top: `${Math.min((reserved / total) * 100, 95)}%`,
            }}
          >
            <div className="dashed-line"></div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const caskCounter = () => {
    if (checkCaskStock()) {
      return (
        <div
          className="cask-stats-spacer"
          style={{
            top: `${Math.min((reserved / total) * 100, 95)}%`,
          }}
        >
          <div className="cask-stats-area">
            <div className="cask-stats-label">
              {/* TODO: Change icon */}
              <img
                alt="fill-depth"
                aria-hidden="true"
                className="cask-bottle-marker"
                src={require("../../assets/images/cask-bottle-marker-icon.svg")}
              />
              <strong>{total - reserved} left</strong>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Col
        xs={12}
        className={`cask-image-component ${onClick ? "clickable" : ""}`}
        onClick={() => (onClick ? onClick(cask) : null)}
      >
        <div className="cask-bottom-area">
          <div className="clipper">
            {/* <img
              alt="Cask"
              className="cask-bottom-image"
              src={getCaskBottomImage(cask)}
            /> */}

            {reservation != null ? (
              <div className="cask-text bottles-reserved">
                {reservation.bottles}
              </div>
            ) : (
              <div className="cask-text">{caskYear}</div>
            )}

            {cask.seller.signatureSeries === true ? (
              <>
                <div className="signature-stamp-wrapper">
                  <h5>
                    <span>signature</span> series
                  </h5>
                </div>
              </>
            ) : null}

            {hideCount ? null : fillIndicator()}
            {/* <img
              alt="Cask-Mask"
              className="cask-mask"
              src={require("../../assets/images/cask-end-overlay.png")}
            /> */}
          </div>
          {/* <img
            alt="Cask-Shadow"
            className="cask-shadow-mask"
            src={require("../../assets/images/cask-shadow-masked.png")}
            aria-hidden="true"
          /> */}
          {hideCount ? null : caskCounter()}
          {/* <img
            alt="Bottles"
            className="bottle-image"
            src={getCaskBottlesImage(cask)}
          /> */}
          {ownedCask && !disableOwnedCask ? (
            <div className="owned-cask-badge">
              <Tooltip lineOne={tooltipText} customIcon={<BiPurchaseTag />} />
            </div>
          ) : null}
          {!hideCount && cask.defaultVariant.price.maxDate ? (
            <div className="early-bird-banner">
              <h6>
                Early bird price until {cask.defaultVariant.price.maxDate}
              </h6>
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
    </>
  );
};

export default CaskImage;
