import { useEffect, useState } from "react"
import TagManager from "react-gtm-module"

const GTMTracking = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    if (!window.location.href.includes("localhost") &&
        !window.location.href.includes("staging")) {
      // PROD
      TagManager.initialize({
        gtmId: "GTM-M7BK7QP",
        auth: "YduM4lBlllZ5EMWEK4si3Q",
        preview: "env-1",
      });
    } else {
      // STAGING
      TagManager.initialize({
        gtmId: "GTM-M7BK7QP",
        auth: "lG_8r3kxEn9BsAwhGGnpOA",
        preview: "env-3",
      });
    }
    setInitialized(true);
  }, [])
}

export default GTMTracking
