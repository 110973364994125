import React, { CSSProperties, useState, useRef, useEffect } from "react";
import { Navbar, Col, Row, Button, Container, Dropdown } from "react-bootstrap";
import { NavLink, useLocation, useHistory, Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
// @ts-ignore
import useDimensions from "react-use-dimensions";
import { useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { IoMdArrowDropdown } from "react-icons/io";
import Socials from "./Socials";
import StoreOptions from "./StoreOptions";
import DropdownMenu from "../../../components/.v2/app/DropdownMenu";
import DiscoverDropdownMenu from "../../../components/.v2/app/DiscoverDropdownMenu";
import CheckoutIcon from "./CheckoutIcon";
import { RootState } from "../../../state/rootReducer";
import { COLLECTIONS } from "../../../graphql/Products";
import { SHOPS } from "../../../graphql/Shops";
import { ReactComponent as Logo } from "../../../assets/images/rebrand-logo.svg";
import styles from "./TopNav.module.scss";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const BurgerButton = ({
  isOpen,
  onClick,
  lightOrDark,
}: {
  isOpen: boolean;
  onClick: any;
  lightOrDark?: "light" | "dark";
}) => {
  return (
    <div
      id="nav-icon4"
      onClick={onClick}
      className={`${lightOrDark} ${isOpen ? "open" : ""}`}
    >
      <span />
      <span />
      <span />
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const SmallMenu = ({
  mobileMenu,
  setMobileMenu,
  setOpenShopDropdown,
  setOpenDiscoverDropdown,
  lightOrDark,
}: {
  mobileMenu: boolean;
  setMobileMenu: any;
  setOpenShopDropdown: any;
  setOpenDiscoverDropdown: any;
  lightOrDark?: "light" | "dark";
}) => {
  const history = useHistory();
  const onLogoClick = () => {
    if (mobileMenu) {
      setMobileMenu(false);
    }
    history.push("/");
  };
  return (
    <Container id="top" className={styles.smallMenuComponent}>
      <Col xs={3} sm={2} className={styles.burgerWrapper}>
        <BurgerButton
          isOpen={mobileMenu}
          onClick={() => {
            setMobileMenu(!mobileMenu);
            setOpenShopDropdown(false);
            setOpenDiscoverDropdown(false);
          }}
          lightOrDark={lightOrDark}
        />
      </Col>
      <Col xs={6} sm={7} className={styles.logoWrapper} onClick={onLogoClick}>
        <Logo className={`caskshare-logo ${lightOrDark}`} />
      </Col>
      <Col xs={3} className={styles.basketWrapper}>
        <CheckoutIcon
          mobile={true}
          theme={lightOrDark}
          setMobileMenu={setMobileMenu}
        />
      </Col>
    </Container>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MobileNav = ({
  mobileMenu,
  setMobileMenu,
  lightOrDark,
  openShopDropdown,
  setOpenShopDropdown,
  openDiscoverDropdown,
  setOpenDiscoverDropdown,
}: {
  mobileMenu: boolean;
  setMobileMenu: any;
  openShopDropdown?: boolean;
  setOpenShopDropdown?: any;
  openDiscoverDropdown?: boolean;
  setOpenDiscoverDropdown?: any;
  lightOrDark?: "light" | "dark";
}) => {
  const loggedInUser = useSelector((state: RootState) => state.user);
  return (
    <Navbar
      className={`${styles.mobileMenu} ${mobileMenu && styles.openMobileMenu} `}
      expand="lg"
      id="top"
    >
      <SmallMenu
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        setOpenShopDropdown={setOpenShopDropdown}
        setOpenDiscoverDropdown={setOpenDiscoverDropdown}
        lightOrDark={lightOrDark}
      />
      <div className={styles.mobileInnerWrapper}>
        <Col xs={12} md={9} className={styles.mobileMenuLinks}>
          <NavLink
            className={`${styles.menuLink} ${styles.shopLink}`}
            to="/caskshares"
            onClick={() => {
              setOpenShopDropdown(false);
              setOpenDiscoverDropdown(false);
              setMobileMenu(false);
            }}
          >
            Shop
          </NavLink>
          <NavLink
            className={styles.menuLink}
            to="/the-collective"
            onClick={() => {
              setOpenDiscoverDropdown(false);
              setOpenShopDropdown(false);
              setMobileMenu(false);
            }}
          >
            Join the Collective
          </NavLink>

          <NavLink
            className={styles.menuLink}
            to="/gifting"
            onClick={() => {
              setOpenShopDropdown(false);
              setOpenDiscoverDropdown(false);
              setMobileMenu(false);
            }}
          >
            Gift Ideas
          </NavLink>

          <NavLink
            className={styles.menuLink}
            to="/about-us"
            onClick={() => {
              setOpenShopDropdown(false);
              setOpenDiscoverDropdown(false);
              setMobileMenu(false);
            }}
          >
            About Us
          </NavLink>

          <NavLink
            className={styles.menuLink}
            to="/journal"
            target="_blank"
            onClick={() => {
              setOpenShopDropdown(false);
              setOpenDiscoverDropdown(false);
              setMobileMenu(false);
            }}
          >
            Journal
          </NavLink>
        </Col>
        <Col className={styles.userSection}>
          <div className={styles.storeWrap}>
            <StoreOptions
              setMobileMenu={setMobileMenu}
              mobileMenu={mobileMenu}
              lightOrDark={lightOrDark}
              showStoreOptions={true}
            />
          </div>
          <div className={styles.socialWrap}>
            <Socials variant="light" />
            {!mobileMenu ? null : (
              <div className={styles.phoneNumber}>
                <FaPhoneAlt /> <a href="tel:00441313812275">+44-131-381-2275</a>
              </div>
            )}
          </div>
        </Col>
      </div>
    </Navbar>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const TopNav = ({ lightOrDark }: { lightOrDark?: "light" | "dark" }) => {
  const location = useLocation();
  const [ref, dimensions] = useDimensions();
  const history = useHistory();
  const [openShopDropdown, setOpenShopDropdown] = useState(false);
  const [openDiscoverDropdown, setOpenDiscoverDropdown] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showShopDropdown, setShowShopDropdown] = useState(false);
  const [windowScrollY, setWindowScrollY] = useState(0);
  const loggedInUser = useSelector((state: RootState) => state.user);
  const { data: shopData } = useQuery(SHOPS);
  const shop = useSelector((state: RootState) => state.shop);
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      {mobileMenu ? (
        <MobileNav
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
          openShopDropdown={openShopDropdown}
          setOpenShopDropdown={setOpenShopDropdown}
          openDiscoverDropdown={openDiscoverDropdown}
          setOpenDiscoverDropdown={setOpenDiscoverDropdown}
          lightOrDark="dark"
        />
      ) : (
        <>
          <Navbar
            id="navigation"
            ref={ref}
            className={`${styles.navBar}`}
            expand="xl"
            onMouseLeave={() => {
              setOpenShopDropdown(false);
              setOpenDiscoverDropdown(false);
            }}
          >
            <Container className={styles.smallMenuArea}>
              <SmallMenu
                mobileMenu={mobileMenu}
                setMobileMenu={setMobileMenu}
                setOpenShopDropdown={setOpenShopDropdown}
                setOpenDiscoverDropdown={setOpenDiscoverDropdown}
                lightOrDark={lightOrDark}
              />
            </Container>
            <Container className={styles.mainMenuArea}>
              <Row className={styles.menuArea}>
                <Col xs={{ span: 5 }} className={styles.caskshareLogo}>
                  <div
                    onClick={() => {
                      history.push("/");
                      setOpenShopDropdown(false);
                      setOpenDiscoverDropdown(false);
                    }}
                  >
                    <Logo className={`caskshare-logo ${lightOrDark}`} />
                  </div>
                </Col>
                <Col className={styles.menuLinks}>
                  <NavLink
                    className={`${styles.menuLink} ${styles.shopLink}`}
                    style={{ fontWeight: 500 }}
                    to="/caskshares"
                    onClick={() => {
                      setOpenShopDropdown(false);
                      setOpenDiscoverDropdown(false);
                    }}
                  >
                    Shop
                  </NavLink>

                  <NavLink
                    className={styles.menuLink}
                    style={{ fontWeight: 500 }}
                    to="/the-collective"
                    onClick={() => {
                      setOpenShopDropdown(false);
                      setOpenDiscoverDropdown(false);
                    }}
                  >
                    Join the Collective
                  </NavLink>

                  <NavLink
                    className={styles.menuLink}
                    to="/gifting"
                    onClick={() => {
                      setOpenShopDropdown(false);
                      setOpenDiscoverDropdown(false);
                      setMobileMenu(false);
                    }}
                  >
                    Gift Ideas
                  </NavLink>

                  <NavLink
                    className={styles.menuLink}
                    to="/about-us"
                    onClick={() => {
                      setOpenShopDropdown(false);
                      setOpenDiscoverDropdown(false);
                    }}
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    className={styles.menuLink}
                    to="/journal"
                    target="_blank"
                    onClick={() => {
                      setOpenShopDropdown(false);
                      setOpenDiscoverDropdown(false);
                    }}
                  >
                    Journal
                  </NavLink>
                </Col>
                <Col className={styles.basketCol}>
                  <CheckoutIcon
                    theme={lightOrDark}
                    setOpenShopDropdown={setOpenShopDropdown}
                    setOpenDiscoverDropdown={setOpenDiscoverDropdown}
                  />
                </Col>
              </Row>
            </Container>
          </Navbar>
        </>
      )}
    </>
  );
};

export default TopNav;
