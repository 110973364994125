import React, { useEffect, useState, useRef } from "react";
import { ProgressBar, Row, Col } from "react-bootstrap";
import { Cask } from "../../../../graphql/Casks";
import ProgressProvider from "../../utils/ProgressProvider";
import useInViewport from "../../utils/useInViewport";
import styles from "./FlavourComp.module.scss";

const FLAVOUR_INIT = {
  complexity: 0,
  fruitiness: 0,
  smokiness: 0,
  spice: 0,
  sweetness: 0,
};

const FlavourComp = (caskData: any) => {
  const cask: Cask = caskData.caskData;
  const flavourSection: any = useRef<HTMLDivElement>();

  const flavourOnScreen: boolean = useInViewport<HTMLDivElement>(
    flavourSection,
    "0px",
  );

  return (
    <Row ref={flavourSection} className={styles.flavourCompWrap}>
      <Col xs={12} md={6} className={styles.content}>
        <p className={styles.title}>Flavour Profile</p>
        <p className={styles.notes}>{cask?.flavourNotes}</p>
      </Col>
      <Col xs={12} md={6} className={styles.flavourWrap}>
        <div className={styles.inner}>
          <p className={styles.title}>Smoky/Peaty</p>
          <div className={`${styles.progress} ${styles.smoky}`}>
            <ProgressProvider
              valueStart={0}
              valueEnd={
                flavourOnScreen ? Number(cask.flavourMap.smokiness * 10) : 0
              }
            >
              {(value: number) => <ProgressBar now={value} />}
            </ProgressProvider>
          </div>
        </div>

        <div className={styles.inner}>
          <p className={styles.title}>Floral</p>
          <div className={`${styles.progress} ${styles.floral}`}>
            <ProgressProvider
              valueStart={0}
              valueEnd={
                flavourOnScreen ? Number(cask.flavourMap.fruitiness * 10) : 0
              }
            >
              {(value: number) => <ProgressBar now={value} />}
            </ProgressProvider>
          </div>
        </div>

        <div className={styles.inner}>
          <p className={styles.title}>Spicy</p>
          <div className={`${styles.progress} ${styles.spicy}`}>
            <ProgressProvider
              valueStart={0}
              valueEnd={
                flavourOnScreen ? Number(cask.flavourMap.spice * 10) : 0
              }
            >
              {(value: number) => <ProgressBar now={value} />}
            </ProgressProvider>
          </div>
        </div>

        <div className={styles.inner}>
          <p className={styles.title}>Sweet/Fruity</p>
          <div className={`${styles.progress} ${styles.sweet}`}>
            <ProgressProvider
              valueStart={0}
              valueEnd={
                flavourOnScreen ? Number(cask.flavourMap.sweetness * 10) : 0
              }
            >
              {(value: number) => <ProgressBar now={value} />}
            </ProgressProvider>
          </div>
        </div>

        <div className={styles.inner}>
          <p className={styles.title}>Rich/Complex</p>
          <div className={`${styles.progress} ${styles.rich}`}>
            <ProgressProvider
              valueStart={0}
              valueEnd={
                flavourOnScreen ? Number(cask.flavourMap.complexity * 10) : 0
              }
            >
              {(value: number) => <ProgressBar now={value} />}
            </ProgressProvider>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default FlavourComp;
