import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./AddOnSlider.module.scss";
import BottleImage from "../../../caskshare-v3/components/casks/caskCard/BottleImage";
import { MemberAddOn } from "../../../graphql/MemberProduct";
import { formatCurrency } from "../../../utils/currency";
import { store } from "../../../state/store";
import { IMAGE_FALLBACKS } from "../../../config";

const AddOnSlider = ({
  onClick,
  addOns,
  itemPriceLabel,
}: {
  onClick: (() => void) | any;
  addOns: MemberAddOn[] | any;
  itemPriceLabel: string;
}) => {
  const history = useHistory();
  const sliderRef = useRef(null);
  const currency = store.getState().shop.currency;

  useEffect(() => {
    if (sliderRef.current && addOns) {
      // @ts-ignore
      sliderRef.current.slickGoTo(0);
    }
  }, [sliderRef, addOns]);

  if (!addOns) {
    return <></>;
  }

  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div className={`${styles.addOnSlider} my-collection-slider-control`}>
      {addOns && (
        <Slider ref={sliderRef} {...settings}>
          {addOns.map((addOn: MemberAddOn, i: number) =>
            addOn?.productType === "gift" ? (
              <div key={i} className={styles.addOnCard}>
                <div className={styles.imgWrap}>
                  <BottleImage
                    placeholderSrc={IMAGE_FALLBACKS.defaultPlaceholder}
                    src={addOn?.defaultVariant?.image}
                    hideOverlay={addOn?.nonBottleImage}
                  />
                </div>
                <div className={styles.contentWrap}>
                  <div className={styles.price}>
                    {itemPriceLabel}{" "}
                    {addOn?.defaultVariant?.price?.amount === 0
                      ? null
                      : formatCurrency(currency, 2).format(
                          addOn?.defaultVariant?.price?.amount,
                        )}
                  </div>
                  <div className={styles.title}>
                    <h5>{addOn?.defaultVariant?.displayName}</h5>
                  </div>
                  <div className={styles.description}>
                    <p>{addOn?.description}</p>
                  </div>
                  <div className={styles.cta}>
                    <Button
                      className={styles.btnPrimary}
                      onClick={() => onClick(addOn)}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div key={i} className={styles.addOnCard}>
                <div className={styles.imgWrap}>
                  <BottleImage
                    placeholderSrc={IMAGE_FALLBACKS.defaultPlaceholder}
                    src={addOn[addOn.type].image}
                    hideOverlay={addOn?.product?.nonBottleImage ?? false}
                  />
                </div>
                <div className={styles.contentWrap}>
                  <div className={styles.price}>
                    {itemPriceLabel}{" "}
                    {addOn.price === 0
                      ? null
                      : formatCurrency(currency, 2).format(addOn.price)}
                  </div>
                  <div className={styles.title}>
                    <h5>{addOn[addOn.type].title}</h5>
                  </div>
                  <div className={styles.description}>
                    <p>{addOn[addOn.type].description}</p>
                  </div>
                  <div className={styles.cta}>
                    <Button
                      className={styles.btnPrimary}
                      onClick={() => onClick(addOn)}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            ),
          )}
        </Slider>
      )}
    </div>
  );
};
export default AddOnSlider;
