import { gql } from "apollo-boost";

export interface AuthPayload {
  authenticatable: {
    email: string;
    firstName: string;
    collectiveActive: boolean;
  };
  credentials: {
    client: string;
    accessToken: string;
    tokenType: string;
  };
}

export const LOGIN = gql`
  mutation customerLogin($shop: String!, $email: String!, $password: String!, $redirectUrl: String) {
    customerLogin(shop: $shop, email: $email, password: $password, redirectUrl: $redirectUrl) {
      authenticatable {
        email
        firstName
        collectiveActive
      }
      credentials {
        client
        accessToken
        tokenType
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation customerLogout($shop: String!) {
    customerLogout(shop: $shop) {
      authenticatable {
        email
        firstName
        collectiveActive
      }
    }
  }
`;

export const REGISTER = gql`
  mutation customerRegister(
    $shop: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $affiliateClub: ID
    $redirectUrl: String
  ) {
    customerRegister(
      shop: $shop
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      affiliateClub: $affiliateClub
      redirectUrl: $redirectUrl
    ) {
      authenticatable {
        email
        firstName
        collectiveActive
      }
      credentials {
        client
        accessToken
        tokenType
      }
    }
  }
`;
