import React from 'react'
import { Link } from 'react-router-dom'

const Socials = ({ variant }: {variant?: 'dark' | 'light'}) => (
  <div
    className="socials-component"
    style={{
      color: variant === 'dark' ? 'black' : '#ECE6DD',
      overflow: 'hidden',
    }}
  >

    <Link to={{ pathname: "https://twitter.com/thecaskshare" }} target="_blank">
      <i style={{ width: '30px' }} className="fa fa-twitter fa-fw" aria-hidden="true" />
    </Link>
    <Link to={{ pathname: "https://instagram.com/caskshare" }} target="_blank">
      <i style={{ width: '30px' }} className="fa fa-instagram fa-fw" aria-hidden="true" />
    </Link>
    <Link to={{ pathname: "https://facebook.com/caskshare" }} target="_blank">
      <i style={{ width: '30px' }} className="fa fa-facebook fa-fw" aria-hidden="true" />
    </Link>

  </div>
)

export default Socials
