import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import {
  Col, Row, Form, Button, Spinner
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ACTIVATE_ACCOUNT } from '../graphql/User'
import { RootState } from '../state/rootReducer'
import { useSelector } from 'react-redux'

const ActivateAccountPage = () => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const shopId = useSelector((state: RootState) => state.shop.id)
  const [activateAccount, { loading }] = useMutation(ACTIVATE_ACCOUNT, {
    variables: {
      shop: shopId,
      password,
      passwordConfirmation,
      activationUrl: window.location.href
    }
  })

  const formSubmission = async (e: any) => {
    e.preventDefault()
    try {
      await activateAccount()
      history.replace('/login')
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Row>
      <Col
        style={{
          minHeight:      '70vh',
          display:        'flex',
          alignItems:      'center',
          justifyContent: 'center'
        }}
      >
        <Col md={6}>
          <h4>Activate your account</h4>
          <Form onSubmit={formSubmission}>
            <Form.Group>
              <Form.Label>New password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="New password"
                required
                minLength={6}
                maxLength={40}
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="Confirm password"
                required
                minLength={6}
                maxLength={40}
                value={passwordConfirmation}
                onChange={(e) => { setPasswordConfirmation(e.target.value) }}
              />
            </Form.Group>
            <Form.Group>
              <Button type="submit" disabled={loading || password !== passwordConfirmation} block>
                {loading
                  ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </>
                  )
                  : 'Create my password'}
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    </Row>
  )
}

export default ActivateAccountPage
