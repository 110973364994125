import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CaskImage from "../casks/CaskImage";
import BottleImage from "../../caskshare-v3/components/casks/caskCard/BottleImage";
import ProductImage from "../products/ProductImage";
import { formatCurrency } from "../../utils/currency";
import { RootState } from "../../state/rootReducer";
import AccountReferFriend from "../account/AccountReferFriend";
import { renderReferFriend } from "../account/renderReferFriend";

const elideStr = (str: string, len: number) =>
  str != null && str.length > len ? str.slice(0, len) + ".." : str;
const YouMightLikeCard = ({
  item,
  onClickCask,
  onClickProduct,
}: {
  item: any;
  onClickCask: (item: any) => void;
  onClickProduct: (item: any) => void;
}) => {
  const [price, setPrice] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);
  const [collectiveNote, setCollectiveNote] = useState("");
  const [referModalOpen, setReferModalOpen] = useState(false);

  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );
  const [bottleImg, setBottleImg] = useState(
    require("../../assets/home-page/.v3/db-lg.png"),
  );
  const [bottleImgPlc, setBottleImgPlc] = useState(
    require("../../assets/home-page/.v3/db-sm.png"),
  );

  useEffect(() => {
    if (item?.attachments) {
      const large = item.attachments.find(({ role }: any) => role === "large");
      large && setBottleImg(large?.url);
      const small = item.attachments.find(({ role }: any) => role === "small");
      small && setBottleImgPlc(small?.url);
    }
    return () => {
      setBottleImg(require("../../assets/home-page/.v3/db-lg.png"));
      setBottleImgPlc(require("../../assets/home-page/.v3/db-sm.png"));
    };
  }, [item?.attachments]);

  useEffect(() => {
    let percentageDiscount = item?.percentageDiscount;
    if (!collectiveActive) {
      setPrice(item.defaultVariant.price.amount);
      setCollectiveUser(false);
      setCollectiveNote("");
    } else if (loggedInUser && collectiveActive) {
      if (item.type === "cask") {
        setPrice(item.memberPrice);
        percentageDiscount !== "0" &&
          setCollectiveNote(
            `${Number(Number(percentageDiscount) * 100).toFixed(
              0,
            )}% Collective discount applied`,
          );
      } else {
        setPrice(item.defaultVariant.memberPrice);
        percentageDiscount !== "0" &&
          setCollectiveNote(
            `${Number(Number(percentageDiscount) * 100).toFixed(
              0,
            )}% Collective discount applied`,
          );
      }
      setCollectiveUser(true);
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("");
    };
  }, [loggedInUser, collectiveActive]);

  useEffect(() => {
    let bottleDiscount = item?.product?.percentageDiscount;
    if (item?.product && !collectiveActive) {
      setPrice(item?.product?.defaultVariant?.price?.amount);
      bottleDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(bottleDiscount) * 100).toFixed(
            0,
          )}% off for Collective members`,
        );
    } else if (item.product && collectiveActive && loggedInUser) {
      setPrice(item?.product?.defaultVariant?.memberPrice);
      bottleDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(bottleDiscount) * 100).toFixed(
            0,
          )}% Collective discount applied`,
        );
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("");
    };
  }, [loggedInUser, item?.product, collectiveActive]);

  // distillery link
  const distilleryLink = () => {
    if (item.distillery.slug && item.distillery.status === "published") {
      return (
        <p
          onClick={() => history.push(`/distillery/${item.distillery.slug}`)}
          className="cask-info-text dis-link"
        >
          {item.distillery.name} Distillery
        </p>
      );
    } else {
      return (
        <p className="cask-info-text">{item.distillery.name} Distillery</p>
      );
    }
  };

  return (
    <>
      <Col xs={12} className="you-might-like-card">
        <Row className="you-might-like-card-wrapper">
          <Col xs={12} className="card-img-wrap">
            {item.type === "cask" ? (
              // <CaskImage cask={item} onClick={() => onClickCask(item)} />
              <BottleImage
                onClick={() => onClickCask(item)}
                placeholderSrc={bottleImgPlc}
                src={bottleImg}
              />
            ) : (
              <ProductImage
                product={item}
                onClick={() => onClickProduct(item)}
              />
            )}
          </Col>
          <Col xs={12}>
            <Row>
              {item.type === "cask" ? (
                <h5 className="cask-title" onClick={() => onClickCask(item)}>
                  {elideStr(item.title, 50)}
                </h5>
              ) : (
                <h5 className="cask-title" onClick={() => onClickProduct(item)}>
                  {elideStr(item.title, 50)}
                </h5>
              )}
            </Row>

            {/* {item.distillery ||
            (item.vendor && (
              <Row>
                {item.type === "cask" ? (
                  distilleryLink()
                ) : (
                  <p className="cask-info-text">{item.vendor}</p>
                )}
              </Row>
            ))} */}

            <Row className="price-row">
              <div className="price-wrap">
                <small>Price per bottle</small>
                <p
                  className={
                    collectiveUser ? "collectivePrice" : "nonCollective"
                  }
                >
                  {formatCurrency(shop.currency, 2).format(price)}
                </p>
              </div>
              <div className="button-wrap">
                {item.type === "cask" ? (
                  <Button
                    block
                    onClick={() => onClickCask(item)}
                    variant="light"
                  >
                    Shop Now
                  </Button>
                ) : (
                  <Button
                    block
                    onClick={() => onClickProduct(item)}
                    variant="light"
                  >
                    Add to basket
                  </Button>
                )}
              </div>
            </Row>

            {collectiveNote ? (
              <Row className="banner-wrap">
                <Col
                  xs={12}
                  className={`banner ${
                    collectiveUser ? "collectivePrice" : "nonCollective"
                  }`}
                >
                  <p>{collectiveNote}</p>
                </Col>
              </Row>
            ) : collectiveActive ? (
              <Row className="banner-wrap">
                <Col
                  xs={12}
                  className={`banner ${
                    collectiveUser ? "collectivePrice" : "nonCollective"
                  }`}
                >
                  <a
                    onClick={(ev) => {
                      setReferModalOpen((prev: boolean) => !prev);
                    }}
                  >
                    Refer a Friend to The Collective
                  </a>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Col>
      {referModalOpen &&
        renderReferFriend({
          setModalOpen: setReferModalOpen,
          modalOpen: referModalOpen,
        })}
    </>
  );
};
export default YouMightLikeCard;
