import React from 'react'
import Error from '../components/app/Error'

const NotFoundPage= () => {
  return (
    <Error message="404" />
  )
}

export default NotFoundPage
