import React, { useState, useEffect } from "react";
import { Form, Col, Row, FormControl } from "react-bootstrap";
import InputRange from "react-input-range";

import { Range } from "rc-slider";
import "rc-slider/assets/index.css";

const ProductFilter = ({
  // priceFilter,
  // setPriceFilter,
  brands,
  countries,
  activeFilters,
  setActiveFilters,
  setCurrentPage,
  searchResults,
}: {
  // priceFilter: any;
  activeFilters: any;
  setActiveFilters: any;
  setCurrentPage: any;
  brands: Set<string>;
  countries: Set<string>;
  // setPriceFilter: any;
  searchResults?: any;
}) => {
  const [displayedCountries, setDisplayedCountries] = useState<any>([]);
  const [displayedBrands, setDisplayedBrands] = useState<any>([]);
  const [allLocations, setAllLocations] = useState<any>([]);
  const [allBrands, setAllBrands] = useState<any>([]);
  const [countLocations, setCountLocations] = useState<any>([]);
  const [countBrands, setCountBrands] = useState<any>([]);

  const [showAllLocations, setShowAllLocations] = useState(false);
  const [showAllBrands, setShowAllBrands] = useState(false);

  useEffect(() => {
    if (showAllBrands) {
      setDisplayedBrands(Array.from(brands));
    } else {
      setDisplayedBrands(Array.from(brands).slice(0, 3));
    }

    if (showAllLocations) {
      setDisplayedCountries(Array.from(new Set(allLocations)));
    } else {
      setDisplayedCountries(Array.from(new Set(allLocations)).slice(0, 3));
    }
  }, [showAllLocations, showAllBrands, allLocations, brands]);

  useEffect(() => {
    setAllLocations(
      searchResults.map((item: any) =>
        item.country.replace(/^\s+|\s+$/gm, "").toLowerCase(),
      ),
    );
    setAllBrands(searchResults.map((item: any) => item.seller.title));
  }, [searchResults]);

  // value pairs of each location
  useEffect(() => {
    setCountLocations(
      allLocations.reduce(
        (acc: number | any, val: any) => {
          acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
          return acc;
        },
        [0],
      ),
    );
  }, [allLocations]);

  // value pairs of each seller
  useEffect(() => {
    setCountBrands(
      allBrands.reduce((acc: number | any, val: any) => {
        acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
        return acc;
      }, []),
    );
  }, [allBrands]);

  const handleShowMoreLocations = () => {
    setShowAllLocations(!showAllLocations);
  };
  const handleShowMoreBrands = () => {
    setShowAllBrands(!showAllBrands);
  };

  const filterLocation = (value: string) => {
    if (activeFilters.country === value) {
      setActiveFilters({ ...activeFilters, country: "" });
    } else {
      setCurrentPage(1);
      setActiveFilters({ ...activeFilters, country: value });
    }
  };

  const filterBrand = (value: string) => {
    if (activeFilters.brand === value) {
      setActiveFilters({ ...activeFilters, brand: "" });
    } else {
      setCurrentPage(1);
      setActiveFilters({ ...activeFilters, brand: value });
    }
  };

  // console.log(searchResults);
  // console.log(displayedCountries);

  return (
    <>
      <p className="small-title">Price range</p>
      <Row style={{ paddingTop: "10px" }}>
        <Col>
          <Form.Group>
            <div className="cask-age-slider px-2">
              <Range
                min={0}
                max={400}
                value={[
                  activeFilters.priceFilter.min
                    ? activeFilters.priceFilter.min
                    : 0,
                  activeFilters.priceFilter.max
                    ? activeFilters.priceFilter.max
                    : 400,
                ]}
                onChange={(value) =>
                  setActiveFilters({
                    ...activeFilters,
                    priceFilter: { min: value[0], max: value[1] },
                  })
                }
                trackStyle={[
                  {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    height: "2px",
                  },
                ]}
                handleStyle={[
                  {
                    borderColor: "rgba(0, 0, 0, 0.4)",
                    marginTop: "-8px",
                    height: "18px",
                    width: "18px",
                  },
                  {
                    borderColor: "rgba(0, 0, 0, 0.4)",
                    marginTop: "-8px",
                    height: "18px",
                    width: "18px",
                  },
                ]}
                railStyle={{
                  height: "2px",
                }}
              />
            </div>
          </Form.Group>
          <Form.Row>
            <p style={{ alignSelf: "center" }}>£</p>
            <Form.Group as={Col}>
              <FormControl
                type="number"
                min={0}
                value={activeFilters.priceFilter.min}
                onChange={
                  (e: any) =>
                    setActiveFilters({
                      ...activeFilters,
                      priceFilter: { min: e.target.value },
                    })
                  // setActiveFilters({ ...activeFilters, priceFilter:{ min: value[0], max: value[1] }})
                }
              />
            </Form.Group>
            <p style={{ alignSelf: "center" }}>£</p>
            <Form.Group as={Col}>
              <FormControl
                type="number"
                max={400}
                value={activeFilters.priceFilter.max}
                onChange={(e: any) =>
                  setActiveFilters({
                    ...activeFilters,
                    priceFilter: { max: e.target.value },
                  })
                }
              />
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>

      <p className="small-title mt-4">Location</p>
      {displayedCountries.map((e: any, i: any) => (
        <p
          onClick={() => {
            filterLocation(e);
          }}
          className={`search-filter ${
            activeFilters.country === e ? "active" : ""
          }`}
          key={i}
        >
          {e}
          {e && countLocations[e] != undefined
            ? " " + "(" + countLocations[e] + ")"
            : null}
        </p>
      ))}
      <p className="load-more" onClick={handleShowMoreLocations}>
        {displayedCountries.length === 3 && Array.from(countries).length > 3
          ? `+ ${Array.from(countries).length - 3} more`
          : null}
      </p>
      <p className="small-title mt-5">Brand</p>
      {displayedBrands.map((e: string) => (
        <p
          onClick={() => {
            filterBrand(e);
          }}
          className={`search-filter ${
            activeFilters.brand === e &&
            activeFilters.brand !== null &&
            activeFilters.brand !== ""
              ? "active"
              : ""
          }`}
          key={e}
        >
          {e}
          {e && countBrands[e] != undefined
            ? " " + "(" + countBrands[e] + ")"
            : null}
        </p>
      ))}
      <p className="load-more" onClick={handleShowMoreBrands}>
        {displayedBrands.length === 3 && Array.from(brands).length > 3
          ? `+ ${Array.from(brands).length - 3} more`
          : null}
      </p>
    </>
  );
};

export default ProductFilter;
