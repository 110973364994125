import React, { useRef, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Distillery } from "../../graphql/Distillery";

const DistilleryInfoSlider = ({ distillery }: { distillery: Distillery }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    setCurrentSlide(0);
  }, []);
  const sliderRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);

  const distilleryDescriptionMarkup = (description?: string) => {
    return {
      __html: `
    ${
      description
        ? `<p class="preserve-new-lines">${description}</p>`
        : `<p style="display: none"></p>`
    }
    `,
    };
  };

  return (
    <Row className="distillery-info-slider-component">
      <Col xs={12} sm={5} className="indicator-area">
        <h3
          onClick={() => {
            setCurrentSlide(0);
          }}
          className={`indicator-item ${currentSlide === 0 ? "active" : ""}`}
        >
          <div
            className={`indicator-bar ${currentSlide === 0 ? "active" : ""}`}
          />
          Overview
        </h3>
        <h3
          onClick={() => {
            setCurrentSlide(1);
          }}
          className={`indicator-item ${currentSlide === 1 ? "active" : ""}`}
        >
          <div
            className={`indicator-bar ${currentSlide === 1 ? "active" : ""}`}
          />
          History
        </h3>
        <h3
          onClick={() => {
            setCurrentSlide(2);
          }}
          className={`indicator-item ${currentSlide === 2 ? "active" : ""}`}
        >
          <div
            className={`indicator-bar ${currentSlide === 2 ? "active" : ""}`}
          />
          Signature
        </h3>
      </Col>

      <Col xs={12} sm={7} className="slide-area">
        <Slider
          ref={sliderRef}
          adaptiveHeight={true}
          touchMove={false}
          draggable={false}
          swipe={false}
        >
          <div
            className="slide"
            dangerouslySetInnerHTML={distilleryDescriptionMarkup(
              distillery.overview,
            )}
          />
          <div
            className="slide"
            dangerouslySetInnerHTML={distilleryDescriptionMarkup(
              distillery.history,
            )}
          />
          <div
            className="slide"
            dangerouslySetInnerHTML={distilleryDescriptionMarkup(
              distillery.signature,
            )}
          />
          {/* <div className="slide">
                <p>
                  {distillery.overview}
                </p>
              </div>
              <div className="slide">
                <p>
                  {distillery.history}
                </p>
              </div>
              <div className="slide">
                <p>
                  {distillery.signature}
                </p>
              </div> */}
        </Slider>
      </Col>
    </Row>
  );
};

export default DistilleryInfoSlider;
