import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../../../../components/app/ScrollToTop";
import FlavourMapGraphic from "../../../../components/cask/FlavourMapGraphic";
import CaskCard from "../../../../components/casks/.v2/CaskCard";
import {
  RECOMMENDED_CASKS_FROM_FLAVOUR_MAP,
  Cask,
} from "../../../../graphql/Casks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/rootReducer";
import { useQuery } from "react-apollo";

import styles from "../QuizPage.module.scss";

function ResultsPage({
  setPage,
  flavourMap,
  setQuizAnswers,
  setUserEmail,
}: {
  setPage?: any;
  flavourMap?: any;
  setQuizAnswers?: any;
  setUserEmail?: any;
}) {
  const location = useLocation();
  const shopId = useSelector((state: RootState) => state.shop.id);
  // sample flavour url = /?complexity=6&fruitiness=9&smokiness=0&spice=3&sweetness=6
  const fruitiness = parseInt(
    new URLSearchParams(location.search).get("fruitiness") || "",
  );
  const smokiness = parseInt(
    new URLSearchParams(location.search).get("smokiness") || "",
  );
  const spice = parseInt(
    new URLSearchParams(location.search).get("spice") || "",
  );
  const sweetness = parseInt(
    new URLSearchParams(location.search).get("sweetness") || "",
  );
  const complexity = parseInt(
    new URLSearchParams(location.search).get("complexity") || "",
  );
  const history = useHistory();
  const [userFlavour, setUserFlavour] = useState("");
  const [casks, setCasks] = useState<any>([]);
  const [flavour, setFlavour] = useState<any>({
    flavourMap: {
      complexity: complexity,
      fruitiness: fruitiness,
      smokiness: smokiness,
      spice: spice,
      sweetness: sweetness,
    },
  });

  const { data: caskData, loading: loading } = useQuery(
    RECOMMENDED_CASKS_FROM_FLAVOUR_MAP,
    {
      variables: {
        shop: shopId,
        flavourMap: {
          complexity: complexity,
          fruitiness: fruitiness,
          smokiness: smokiness,
          spice: spice,
          sweetness: sweetness,
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (flavourMap?.complexity && flavourMap?.fruitiness && flavourMap?.spice) {
      setFlavour({
        flavourMap: flavourMap,
      });
    }
    return () => setFlavour({});
  }, [flavourMap]);

  useEffect(() => {
    if (flavour?.flavourMap?.complexity) {
      setUserFlavour(
        Object.keys(flavour.flavourMap).reduce((a: any, b: any) =>
          flavour.flavourMap[a] > flavour.flavourMap[b] ? a : b,
        ),
      );
    }
    return () => {
      setUserFlavour("");
    };
  }, [flavour?.flavourMap]);

  const handleStartAgain = () => {
    setUserEmail("");
    setQuizAnswers({ id: "1", questions: [{ id: "", answer: { id: "" } }] });
    location.search ? history.push("/find-your-flavour") : setPage(0);
  };

  const flav = (e: any) => {
    switch (e) {
      case "complexity":
        return "rich and complex";
        break;
      case "fruitiness":
        return "fruity";
        break;
      case "smokiness":
        return "smokey";
        break;
      case "spice":
        return "spicy";
        break;
      case "sweetness":
        return "sweet";
        break;
    }
  };
  const flav_conclusion = (e: any) => {
    switch (e) {
      case "complexity":
        return "to make the most of those complex flavour notes";
        break;
      case "fruitiness":
        return "to emphasise that punch of fruity flavour";
        break;
      case "smokiness":
        return "to make the most of the peated aroma";
        break;
      case "spice":
        return "to emphasise the sultry spice";
        break;
      case "sweetness":
        return "to bring out the syrupy sweetness";
        break;
    }
  };

  const caskDetails = async (caskToAdd?: any) => {
    const caskId = caskToAdd.defaultVariant.slug
      ? caskToAdd.defaultVariant.slug
      : caskToAdd.defaultVariant.id.split("/")[4];
    history.push(`/caskshares/${caskId}`);
    // history.push(`/caskshares/${caskId}`, { ...filter });
  };

  return (
    <>
      <ScrollToTop />
      <div className={styles.resultsPage}>
        <div className={styles.flavourMapWrap}>
          <div className={styles.content}>
            <h4>
              Your flavour map is <span>{flav(userFlavour)}.</span>{" "}
            </h4>
            {!loading && caskData.recommendedCasks.length ? (
              <>
                <p>
                  You like <span>{flav(userFlavour)}</span> notes. We'd
                  recommend an{" "}
                  <span>{caskData.recommendedCasks[0].barrelType}</span>,{" "}
                  {flav_conclusion(userFlavour)}.
                </p>
                <p>Here are our top three recommendations for you:</p>{" "}
              </>
            ) : null}
          </div>
          <div className={styles.flavourMap}>
            <div className={styles.flavour}>
              {!loading && caskData.recommendedCasks.length ? (
                <FlavourMapGraphic cask={flavour} />
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.caskSelection}>
          {!loading && caskData.recommendedCasks.length ? (
            <>
              {caskData.recommendedCasks.slice(0, 3).map((cask: Cask) => (
                <div className={styles.caskCardWrap}>
                  <CaskCard cask={cask} onClick={caskDetails} />
                </div>
              ))}
            </>
          ) : null}
        </div>
        <div className={styles.startAgain}>
          <button
            className={styles.btnPrimary}
            disabled={loading}
            onClick={() => handleStartAgain()}
          >
            Start Again
          </button>
        </div>
      </div>
    </>
  );
}

export default ResultsPage;
