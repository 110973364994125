import React from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Cask } from "../../graphql/Casks";
import { Product } from "../../graphql/Products";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import YouMightLikeCard from "../checkout/YouMightLikeCard";
import ComponentLoader from "../app/ComponentLoader";

const YouMightLikeSlider = ({
  items,
  loading,
  show,
}: {
  items: any;
  loading: boolean;
  show?: number;
}) => {
  const history = useHistory();
  const onReserveChoiceClick = (c: Cask) => {
    const newCaskId = c?.defaultVariant?.slug;
    history.push(`/caskshares/${newCaskId}`);
  };

  const productDetails = async (p: Product) => {
    const productId = p.slug;
    history.push(`/products/${productId}`);
  };

  if (!items.length || loading) {
    return <ComponentLoader minHeight={"10vh"} />;
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 0,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: false,
        },
      },
    ],
  };
  return (
    <Col className="mt-4">
      <Slider {...settings}>
        {items
          .filter((item: Cask|Product) =>
            (item.type == "product" &&
             item.defaultVariant.memberPrice > 1 &&
             item.defaultVariant?.price?.amount > 1) ||
            (item.type == "cask")
          )
          .slice(0, 4)
          .map((item: Cask|Product) => (
            <YouMightLikeCard
              key={item.defaultVariant.id}
              item={item}
              onClickCask={onReserveChoiceClick}
              onClickProduct={productDetails}
            />
        ))}
      </Slider>
    </Col>
  );
};
export default YouMightLikeSlider;
