import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import cn from "classnames";
import { hotjar } from "react-hotjar";
import { gsap } from "gsap";

import { getMetaText, getCopyText } from "../../../AppText";

import { CASKS, Cask } from "../../../graphql/Casks";
import { Shop, SHOPS, howItWorksSection } from "../../../graphql/Shops";
import { GET_CUSTOMER } from "../../../graphql/User";
import { RootState } from "../../../state/rootReducer";
import ScrollToSection from "../../../components/app/ScrollToSection";

import styles from "./HomePage.module.scss";

import HeroCarousel from "./HeroCarousel";
import { ReactComponent as Marque } from "../../../assets/images/Marque.svg";
import useInViewport from "../../components/utils/useInViewport";
import FeaturedBottlesComp from "../../components/app/FeaturedBottlesComp";
import LoadingLogo from "../../../components/app/LoadingLogo";

const INIT_HOW_IT_WORKS: howItWorksSection[] = [
  {
    id: "1",
    buttonText: "Reserve online Today",
    buttonUrl: "caskshares",
    description:
      "It starts here.\n\nBrowse our online range of exclusive whisky casks, and reserve a share in a cask to secure your bottle of something truly exciting.\n\nSo now what?",
    navButtonText: "1. Reserve",
    nextStepId: "2",
    nextStepText: "Step 2. Let the whisky mature",
    title: "1. Reserve",
    attachments: [
      {
        role: "top",
        title: "women enjoy drinking whisky",
        url: `${require("../../../assets/home-page/.v3/Women drinking whisky at a bar-0833 (credit OurWhisky Foundation_Jo Hanley).jpg")}`,
      },
      {
        role: "bottom",
        title: "caskshare bottles",
        url: `${require("../../../assets/home-page/.v2/hero-carousel/shipping-now.jpg")}`,
      },
    ],
  },
  {
    id: "2",
    buttonText: "Explore the Collective",
    buttonUrl: "the-collective",
    description:
      "Let the cask mature and enjoy the benefits of our Collective with tastings, meet-the-maker events and exclusive content whilst you wait.\n\nAfter the whisky has fully matured, you'll then be on to",
    navButtonText: "2. Mature",
    nextStepId: "3",
    nextStepText: "Step 3. Time to enjoy your whisky",
    title: "2. Mature",
    attachments: [
      {
        role: "bottom",
        title: "caskshare bruichladdich bottle",
        url: `${require("../../../assets/home-page/.v3/homeBottle.png")}`,
      },
      {
        role: "top",
        title: "a man looking after casks of whisky",
        url: `${require("../../../assets/home-page/.v3/man looking after casks of whisky.jpg")}`,
      },
    ],
  },
  {
    id: "3",
    buttonText: "Shop for a bottle",
    buttonUrl: "caskshares",
    description:
      "The wait is over. The cask is full matured and your bottle of unique, single cask whisky will be delivered to you.\n\nShare your bottle, your passion and your stories with friends and enjoy a dram of something special together.",
    navButtonText: "3. Enjoy",
    nextStepId: "",
    nextStepText: "",
    title: "3. Enjoy",
    attachments: [
      {
        role: "top",
        title:
          "Female whisky drinker woman at a bar-0924 (credit OurWhisky Foundation_Jo Hanley)",
        url: `${require("../../../assets/home-page/.v3/Female whisky drinker woman at a bar-0924 (credit OurWhisky Foundation_Jo Hanley).jpg")}`,
      },
      {
        role: "bottom",
        title: "caskshare agitator whisky",
        url: `${require("../../../assets/home-page/.v3/caskshare agitator whisky.jpg")}`,
      },
    ],
  },
];

const HomePage3 = () => {
  const history = useHistory();
  const { t } = useTranslation();
  hotjar.initialize(2882523, 6);
  const shop = useSelector((state: RootState) => state.shop);
  const loggedInUser = useSelector((state: RootState) => state.user);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  const homePage = useRef(null);
  const stepOneSection: any = useRef<HTMLDivElement>();
  const stepTwoSection: any = useRef<HTMLDivElement>();
  const stepThreeSection: any = useRef<HTMLDivElement>();

  const [caskData, setCaskData] = useState<Cask[]>([]);
  const [scroll, setScroll] = useState("");
  const [user, setUser] = useState<any>({});
  const [width, setWidth] = useState(window.innerWidth);
  const [xlBreakpoint] = useState(2200);
  const [tabletBreakpoint] = useState(821);
  const [tabletText] = useState(767);
  const [mobileBreakpoint] = useState(576);

  const [currentShopHowItWorks, setCurrentShopHowItWorks] =
    useState<howItWorksSection[]>(INIT_HOW_IT_WORKS);

  const [casksFilter, setCasksFilter] = useState<any>({
    locations: [],
    distilleries: [],
    shippingTimeframe: [],
    statuses: ["published", "comingsoon", "bottled"],
    spiritStyles: [],
    flavours: [],
    priceBrackets: [],
    bottlingAgeBrackets: [],
    yearsToBottlingBrackets: [],
    tag: "",
  });

  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      shop: shop.id,
    },
    fetchPolicy: "network-only",
  });

  const { data: casks, loading: casksLoading } = useQuery(CASKS, {
    variables: {
      shop: shop.id,
      filters: casksFilter,
    },
    skip: !shop.id,
  });

  const { data: shopData, loading: shopLoading } = useQuery(SHOPS, {
    variables: {},
    fetchPolicy: "network-only",
  });

  const stepOneOnScreen: boolean = useInViewport<HTMLDivElement>(
    stepOneSection,
    "0px",
  );
  const stepTwoOnScreen: boolean = useInViewport<HTMLDivElement>(
    stepTwoSection,
    "150px",
  );
  const stepThreeOnScreen: boolean = useInViewport<HTMLDivElement>(
    stepThreeSection,
    "150px",
  );

  useEffect(() => {
    if (!shopData?.shops) {
      return;
    } else {
      const currentShop: Shop = shopData.shops.find(
        (shops: Shop) => shops.currency === shop.currency,
      );

      if (currentShop && currentShop.howItWorksSections) {
        setCurrentShopHowItWorks(currentShop.howItWorksSections);
      } else {
        return;
      }
    }
    return () => {
      setCurrentShopHowItWorks(INIT_HOW_IT_WORKS);
    };
  }, [shopData?.shops, shop?.currency]);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    gsap.to(homePage.current, { visibility: "visible", opacity: "1" });
  });

  useEffect(() => {
    const element = stepOneSection.current;
    gsap.fromTo(
      element?.querySelector("div[id='reserveImgTop']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.5,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    );
    gsap.fromTo(
      element?.querySelector("div[id='reserveImgBottom']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.75,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.5,
      },
    );
    gsap.fromTo(
      element?.querySelector("div[id='reserve']"),
      {
        opacity: 0.9,
        scale: width > tabletText ? 0.9 : 1,
        duration: 0.5,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.5,
      },
    );
  }, [stepOneOnScreen, stepOneSection, width]);

  useEffect(() => {
    const element = stepTwoSection.current;
    gsap.fromTo(
      element?.querySelector("div[id='matureImgLeft']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.75,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.75,
      },
    );
    gsap.fromTo(
      element?.querySelector("div[id='matureImgRight']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.75,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.75,
      },
    );
    gsap.fromTo(
      element?.querySelector("div[id='mature']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.75,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.75,
      },
    );
  }, [stepTwoOnScreen, stepTwoSection, width]);

  useEffect(() => {
    const element = stepThreeSection.current;
    gsap.fromTo(
      element?.querySelector("div[id='enjoyImgLeft']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.1,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.75,
      },
    );
    gsap.fromTo(
      element?.querySelector("div[id='enjoyImgRight']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.75,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.75,
      },
    );
    gsap.fromTo(
      element?.querySelector("div[id='enjoy']"),
      {
        scale: width > tabletText ? 0.9 : 1,
        opacity: 0.9,
        duration: 0.75,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.75,
      },
    );
  }, [stepThreeOnScreen, stepThreeSection, width]);

  useEffect(() => {
    if (data && data?.customer) {
      setUser(data.customer);
    }
    return () => {
      setUser({});
    };
  }, [data?.customer, loggedInUser?.email]);

  useEffect(() => {
    let featuredCasks;
    let caskshares;
    if (casks?.casks) {
      featuredCasks = casks.casks.filter((cask: Cask) => cask.featuredOrdinal);
      caskshares = casks.casks.filter(
        (cask: Cask) =>
          ["published", "bottled"].includes(cask.status) &&
          !cask.featuredOrdinal,
      );
      const featuredCaskshares =
        featuredCasks.length <= 5
          ? featuredCasks.concat(caskshares)
          : featuredCasks;
      setCaskData(
        featuredCaskshares
          .sort((a: any, b: any) => {
            if (a.featuredOrdinal === b.featuredOrdinal) {
              return 0;
            }
            if (a.featuredOrdinal === null) {
              return 1;
            }
            if (b.featuredOrdinal === null) {
              return -1;
            }
            return a.featuredOrdinal < b.featuredOrdinal ? -1 : 1;
          })
          .slice(0, 6),
      );
    }
    return () => {
      setCaskData([]);
    };
  }, [casks?.casks]);

  const getImgTop = (attachments: any) => {
    let images = attachments?.filter((a: any) => a.role === "top");
    let image = images?.length
      ? images[0]
      : {
          url: require("../../../assets/home-page/.v2/hero-carousel/shipping-now.jpg"),
          title: "tba...",
        };
    return image;
  };

  const getImgBottom = (attachments: any) => {
    let images = attachments?.filter((a: any) => a.role === "bottom");
    let image = images?.length
      ? images[0]
      : {
          url: require("../../../assets/home-page/.v2/hero-carousel/shipping-now.jpg"),
          title: "tba...",
        };
    return image;
  };

  function scrollToSection(id: string) {
    const el = document.getElementById(id);
    el?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }

  const sectionDescriptionMarkup = (description?: string) => {
    return {
      __html: `
    ${
      description
        ? `<p class="preserve-new-lines">${description}</p>`
        : `<p style="display: none"></p>`
    }
    `,
    };
  };

  if (loading || shopLoading) {
    return <LoadingLogo minHeight="85vh" />;
  }

  return (
    <>
      <ScrollToSection scroll={scroll} setScroll={setScroll} />
      <Helmet>
        <title>{getMetaText("HOME_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText("HOME_PAGE", "keywords")} />
        <meta
          name="description"
          content={getMetaText("HOME_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("HOME_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={getMetaText("HOME_PAGE", "title")} />
        <meta
          property="og:description"
          content={getMetaText("HOME_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("HOME_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("HOME_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      <Container fluid className={styles.homePageWrap} ref={homePage}>
        <img
          className={styles.iconsOfWhisky}
          src={require("../../../assets/images/IoW25-Scotland-IndependentBottler.svg")}
        />
        <Row className={styles.heroSection}>
          <HeroCarousel shopData={shopData?.shops} />
          <Container className={styles.contentSection}>
            <Row className={styles.sectionNav}>
              <Col className={styles.colItem}>
                <Button
                  onClick={() => {
                    scrollToSection(currentShopHowItWorks[0]?.id);
                  }}
                  className={`${styles.btnBeigeOutline} ${
                    stepOneOnScreen && styles.active
                  }`}
                >
                  {width > tabletText
                    ? `${currentShopHowItWorks[0].navButtonText}`
                    : `Our Ethos - ${currentShopHowItWorks[0].navButtonText}`}
                </Button>
              </Col>
              <Col className={styles.colItem}>
                <Button
                  onClick={() => {
                    scrollToSection(currentShopHowItWorks[1]?.id);
                  }}
                  className={`${styles.btnBeigeOutline} ${
                    stepTwoOnScreen && styles.active
                  }`}
                >
                  {currentShopHowItWorks[1].navButtonText}
                </Button>
              </Col>
              <Col className={styles.colItem}>
                <Button
                  onClick={() => {
                    scrollToSection(currentShopHowItWorks[2]?.id);
                  }}
                  className={`${styles.btnBeigeOutline} ${
                    stepThreeOnScreen && styles.active
                  }`}
                >
                  {currentShopHowItWorks[2].navButtonText}
                </Button>
              </Col>
            </Row>
            <Row ref={stepOneSection} className={styles.stepOneSection}>
              <div className={styles.inner}>
                <div
                  id={currentShopHowItWorks[0]?.id}
                  className={styles.content}
                >
                  <div className={styles.dots}>
                    <Marque
                      className={cn(
                        stepOneOnScreen && currentShopHowItWorks[0]?.id,
                      )}
                    />
                  </div>
                  <div className={styles.howItWorks}>
                    <p>Our Ethos</p>
                  </div>
                  <div className={styles.innerContent}>
                    <h3>{currentShopHowItWorks[0]?.title}</h3>
                    <div
                      className={styles.descriptionWrap}
                      dangerouslySetInnerHTML={sectionDescriptionMarkup(
                        currentShopHowItWorks[0]?.description,
                      )}
                    />
                    <p style={{ marginTop: "-1rem" }}>
                      <a
                        onClick={() => {
                          scrollToSection(currentShopHowItWorks[0].nextStepId);
                        }}
                      >
                        {currentShopHowItWorks[0]?.nextStepText}
                      </a>{" "}
                    </p>
                    <Button
                      onClick={() =>
                        history.push(currentShopHowItWorks[0]?.buttonUrl)
                      }
                      className={styles.btnBeigeOutline}
                    >
                      {currentShopHowItWorks[0]?.buttonText}
                    </Button>
                  </div>
                </div>
                <div id="reserveImgTop" className={styles.topImg}>
                  <img
                    src={getImgTop(currentShopHowItWorks[0]?.attachments)?.url}
                    alt={
                      getImgTop(currentShopHowItWorks[0]?.attachments)?.title
                    }
                  />
                </div>
                <div id="reserveImgBottom" className={styles.bottomImg}>
                  <img
                    src={
                      getImgBottom(currentShopHowItWorks[0]?.attachments)?.url
                    }
                    alt={
                      getImgBottom(currentShopHowItWorks[0]?.attachments)?.title
                    }
                  />
                </div>
              </div>
            </Row>

            <Row className={styles.spacer}></Row>

            <Row ref={stepTwoSection} className={styles.stepTwoSection}>
              <div className={styles.inner}>
                <div
                  id={currentShopHowItWorks[1]?.id}
                  className={styles.content}
                >
                  <div className={styles.dots}>
                    <Marque
                      className={cn(
                        stepTwoOnScreen && currentShopHowItWorks[1]?.id,
                      )}
                    />
                  </div>
                  <div className={styles.howItWorks}>
                    <p>Our Ethos</p>
                  </div>
                  <div className={styles.innerContent}>
                    <h3>{currentShopHowItWorks[1]?.title}</h3>
                    <div
                      className={styles.descriptionWrap}
                      dangerouslySetInnerHTML={sectionDescriptionMarkup(
                        currentShopHowItWorks[1]?.description,
                      )}
                    />
                    <p style={{ marginTop: "-1rem" }}>
                      <a
                        onClick={() => {
                          scrollToSection(currentShopHowItWorks[1].nextStepId);
                        }}
                      >
                        {currentShopHowItWorks[1]?.nextStepText}
                      </a>{" "}
                    </p>
                    <Button
                      onClick={() =>
                        history.push(currentShopHowItWorks[1]?.buttonUrl)
                      }
                      className={styles.btnBeigeOutline}
                    >
                      {currentShopHowItWorks[1]?.buttonText}
                    </Button>
                  </div>
                </div>
              </div>
              <div id="matureImgLeft" className={styles.leftImg}>
                <img
                  src={getImgTop(currentShopHowItWorks[1]?.attachments)?.url}
                  alt={getImgTop(currentShopHowItWorks[1]?.attachments)?.title}
                />
              </div>
              <div id="matureImgRight" className={styles.rightImg}>
                <img
                  src={getImgBottom(currentShopHowItWorks[1]?.attachments)?.url}
                  alt={
                    getImgBottom(currentShopHowItWorks[1]?.attachments)?.title
                  }
                />
              </div>
            </Row>

            <Row className={styles.spacer}></Row>
          </Container>
        </Row>
        <div className={styles.enjoyBG}>
          <Container className={styles.contentSection}>
            <Row ref={stepThreeSection} className={styles.stepThreeSection}>
              <div className={styles.inner}>
                <div
                  id={currentShopHowItWorks[2]?.id}
                  className={styles.content}
                >
                  <div className={styles.dots}>
                    <Marque
                      className={cn(
                        stepThreeOnScreen && currentShopHowItWorks[2]?.id,
                      )}
                    />
                  </div>
                  <div className={styles.howItWorks}>
                    <p>Our Ethos</p>
                  </div>
                  <div className={styles.innerContent}>
                    <h3>{currentShopHowItWorks[2]?.title}</h3>
                    <div
                      className={styles.descriptionWrap}
                      dangerouslySetInnerHTML={sectionDescriptionMarkup(
                        currentShopHowItWorks[2]?.description,
                      )}
                    />
                    <Button
                      onClick={() =>
                        history.push(currentShopHowItWorks[2]?.buttonUrl)
                      }
                      className={styles.btnBeigeOutline}
                    >
                      {currentShopHowItWorks[2]?.buttonText}
                    </Button>
                  </div>
                </div>
              </div>
              <div id="enjoyImgLeft" className={styles.leftImg}>
                <img
                  src={getImgTop(currentShopHowItWorks[2]?.attachments)?.url}
                  alt={getImgTop(currentShopHowItWorks[2]?.attachments)?.title}
                />
              </div>
              <div id="enjoyImgRight" className={styles.rightImg}>
                <img
                  src={getImgBottom(currentShopHowItWorks[2]?.attachments)?.url}
                  alt={
                    getImgBottom(currentShopHowItWorks[2]?.attachments)?.title
                  }
                />
              </div>
            </Row>
          </Container>
        </div>
        <div className={styles.featuredBG}>
          <FeaturedBottlesComp
            caskData={caskData}
            user={user}
            casksLoading={casksLoading}
          />
        </div>
        <div className={styles.pressBG}>
          <Container className={styles.contentSection}>
            <Row className={styles.pressSection}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/home-page/.v3/man enjoying single cask whisky.jpg")}
                  alt="man enjoying single cask whisky"
                />
              </div>
              <div className={styles.head}>
                <h3>
                  What is said <br /> <span>in the press</span>
                </h3>
                <Button
                  onClick={() => history.push("/in-the-press")}
                  className={styles.btnBlackOutline}
                >
                  See articles
                </Button>
              </div>
              <div className={styles.content}>
                <p>
                  Caskshare was founded in 2019 as a “cask to glass” service.
                  In addition to offering whisky from newer distilleries, it
                  acquires single casks from more established operations which
                  it bottles and sells on its website, giving you the opportunity
                  to try something that is quite literally a unique offering.
                  - Robb Report
                </p>
              </div>
            </Row>
          </Container>
        </div>

        {width > tabletBreakpoint ? (
          <div className={styles.collectiveBG}>
            <Container className={styles.contentSection}>
              <Row className={styles.collectiveSection}>
                <div className={styles.content}>
                  <div className={styles.howItWorks}>
                    <p>Exclusive Whisky Club</p>
                  </div>
                  <div className={styles.innerContent}>
                    <h3>
                      Join the collective <br /> <span>For just £50</span>
                    </h3>
                    <p>
                      The Caskshare Collective is the only whisky membership
                      you’ll ever need. From exclusive discounts to expertly
                      guided meet the maker events – our club gives you access
                      to exciting new distilleries, insightful content and
                      unique whisky before it’s bottled.
                    </p>
                    <Button
                      onClick={() => history.push("/the-collective")}
                      className={styles.btnLightCoralOutline}
                    >
                      Join today
                    </Button>
                  </div>
                  <div className={styles.benefits}>
                    <div className={styles.howItWorks}>
                      <p>Club Benefits</p>
                    </div>
                    <div className={styles.benefitsWrap}>
                      <p>Early access to new releases</p>
                      <p>Free shipping on all purchases</p>
                      <p>Discounts at Caskshare.com</p>
                      <p>Invites to exclusive events</p>
                      <p>Caskshare portfolio access</p>
                      <p>Online event playback</p>
                    </div>
                  </div>
                  <div className={styles.imgWrap}>
                    <img
                      src={require("../../../assets/home-page/.v3/Whisky glass woman-4252 (credit OurWhisky Foundation_Jo Hanley).jpg")}
                      alt="Whisky glass woman-4252 (credit OurWhisky Foundation_Jo Hanley)"
                    />
                  </div>
                  <div className={styles.joinWrap}>
                    <div className={styles.howItWorks}>
                      <p>Exclusive Whisky Club</p>
                    </div>
                    <Button
                      onClick={() => history.push("/the-collective")}
                      className={styles.btnLightCoralOutline}
                    >
                      Join today
                    </Button>
                  </div>
                </div>
                <div className={styles.leftImg}>
                  <img
                    src={require("../../../assets/home-page/.v3/happy caskshare collective member.jpg")}
                    alt="happy caskshare collective member"
                  />
                </div>
              </Row>
            </Container>
          </div>
        ) : (
          <>
            <div className={styles.collectiveBG}>
              <Container className={styles.contentSection}>
                <Row className={styles.collectiveBanner}>
                  <div className={styles.content}>
                    <div className={styles.howItWorks}>
                      <p>Exclusive Whisky Club</p>
                    </div>
                    <div className={styles.innerContent}>
                      <h3>
                        Join the collective <br /> <span>For just £50</span>
                      </h3>
                      <Button
                        onClick={() => history.push("/the-collective")}
                        className={styles.btnLightCoralOutline}
                      >
                        Join today
                      </Button>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <div className={styles.collectiveContentBG}>
              <Container className={styles.contentSection}>
                <Row className={styles.collectiveContent}>
                  <div className={styles.content}>
                    <p>
                      The Caskshare Collective is the only whisky membership
                      you’ll ever need. From exclusive discounts to expertly
                      guided meet the maker events – our club gives you access
                      to exciting new distilleries, insightful content and
                      unique whisky before it’s bottled.
                    </p>
                    <div className={styles.benefits}>
                      <div className={styles.howItWorks}>
                        <p>Club Benefits</p>
                      </div>
                      <div className={styles.benefitsWrap}>
                        <p>Early access to new releases</p>
                        <p>Free shipping on all purchases</p>
                        <p>Discounts at Caskshare.com</p>
                        <p>Invites to exclusive events</p>
                        <p>Caskshare portfolio access</p>
                        <p>Online event playback</p>
                      </div>
                      <Button
                        onClick={() => history.push("/the-collective")}
                        className={styles.btnLightCoralOutline}
                      >
                        Join today
                      </Button>
                    </div>
                    <div className={styles.imgWrap}>
                      <img
                        src={require("../../../assets/home-page/.v3/happy caskshare collective member.jpg")}
                        alt="happy caskshare collective member"
                      />
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default HomePage3;
