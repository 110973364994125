import React, { useEffect } from "react";
import { store } from "../state/store";

const KlaviyoIntegration = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.async = true;
    script.type = "text/javascript";
    script.src =
      "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XpjHQ4";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (store.getState().user?.email) {
      // @ts-ignore
      window._klOnsite = window._klOnsite || [];
      // @ts-ignore
      window._klOnsite.push(["identify", {
        "$email": store.getState().user.email
      }]);
    }
  }, [store.getState().user?.email]);
};

export default KlaviyoIntegration;
