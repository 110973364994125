import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// import TopNav from "./TopNav";
import TopNav from "../../../caskshare-v3/components/app/TopNav";
import StoreOptions from "./StoreOptions";
import styles from "./TopNav.module.scss";

const PAGES_WITHOUT_NAV = [
  // new RegExp(/^\/$/),
  // new RegExp(/\/caskshares(?:\/selection\/.*)?$/),
  // new RegExp(/\/the-collective(.*)/),
  // new RegExp(/\/distillery\//),
  new RegExp(/\/register$/),
  new RegExp(/\/login$/),
];

const TopNavContainer = ({}: {}) => {
  const location = useLocation();
  const [showStoreOptions, setShowStoreOptions] = useState(true);

  useEffect(() => {
    let currentScroll = 0;
    let lastScroll = 0;
    const onScroll = () => {
      currentScroll = window.pageYOffset;
      if (currentScroll > 100) {
        setShowStoreOptions(false);
      } else if (currentScroll === lastScroll) {
        setShowStoreOptions(true);
      }
      // lastScroll = currentScroll;
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [window]);

  let hideNavbar = false;

  const pageHasNotHero =
    PAGES_WITHOUT_NAV.filter((r) => r.exec(location.pathname)).length > 0;
  if (pageHasNotHero) {
    hideNavbar = true;
  }

  return (
    <div
      id="topNavContainer"
      className={`${hideNavbar && styles.hideNavbar} ${
        showStoreOptions && styles.showStoreOptions
      } ${styles.topNavContainer}`}
    >
      <div className={styles.storeCompWrap}>
        <StoreOptions showStoreOptions={showStoreOptions} />
      </div>
      <TopNav lightOrDark="dark" />
    </div>
  );
};

export default TopNavContainer;
