import React, { ReactNode, ReactElement } from "react";
import styles from "./SelectWrapper.module.scss";

type SelectWrapperProps = {
  imageComp?: ReactNode;
  title?: string;
  children?: ReactNode;
  show?: boolean;
};

export function SelectWrapper({
  imageComp,
  title,
  children,
  show,
}: SelectWrapperProps) {
  return (
    <div className={styles.selectWrap}>
      {imageComp && (
        <div style={{ display: `${!show && "none"}` }} className={styles.img}>
          {imageComp}
        </div>
      )}
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
