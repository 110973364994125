import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { RootState } from "../state/rootReducer";
import { getMetaText } from "../AppText";
import { useSelector } from "react-redux";

import LoadingLogo from "../components/app/LoadingLogo";
import styles from "./EmbeddedCheckoutPage.module.scss";
import BuyButtonComponent from "../components/stripe/BuyButtonComponent";

interface BuyButton {
  buttonId: string;
  strapline: string;
  subtitle: string,
  message: string;
  heroImage: string;
}

const EmbeddedCheckoutPage = () => {
  // @ts-ignore
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const [tabletBreakpoint] = useState(821);
  const { campaign } = useParams() as { campaign: string };
  const shop = useSelector((state: RootState) => state.shop);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const BUY_BUTTONS: { [key: string]: BuyButton } = {
    "tasting-pack-offer": {
      buttonId: "buy_btn_1Ox6hOG60ZFPjGzdrdjUjVue", // PROD
      // buttonId: "buy_btn_1Ox9wYG60ZFPjGzdS2AERXW8", // TEST
      strapline: "TASTING PACK & <br/>1 YEAR MEMBERSHIP<br/><span>for only £350</span>",
      subtitle: "2024 Icons of Whisky award",
      message: `To celebrate our 2024 Icons of Whisky award, we're offering a chance to try 5 of our best-selling
                whiskies for £35 plus 1-year membership to our community of passionate whisky fans. Our 5x3cl tasting
                pack contains:
                <ul class="mt-2" style="list-style-type:none;">
                  <li>Collective Signature Welcome Bottle </li>
                  <li>Agitator Four Grain White Port Wine Cask</li>
                  <li>Stauning Ex-Fiji Rum Cask</li>
                  <li>Agitator Chestnut Cask</li>
                  <li>Dailuaine 14-Year-Old Ex-Bourbon Cask</li>
                </ul>
                `,
      heroImage: require("../assets/home-page/.v3/testing-pack-offer-bg.jpg"),

    },
    "auchentoshan-33yo": {
      buttonId: "buy_btn_1PU4wPG60ZFPjGzdFeyoeKXw", // PROD
      // buttonId: "", // TEST
      strapline: "AUCHENTOSHAN 33YO & <br/>1 YEAR MEMBERSHIP for £690",
      subtitle: "COLLECTIVE SERIES AUCHENTOSHAN 33YO",
      message: `<p class="mb-2">Your chance to own a bottle of extremely rare our COLLECTIVE SERIES Auchentoshan
                33yo bottled by Caskshare.</p>
                <p class="mb-2">
                Caramel, toffee, and vanilla lead the way. Behind this sits a light, floral body with notes of
                smouldering heather and freshly cut grass. A long cake like finish – cherry bakewell and bread
                pudding. The perfect dram to savour over a warm summers night.
                </p>
                <p class="mb-2">This bottle includes 1-year membership to The Caskshare Collective, with full
                membership benefits, all for the discounted price of £690.</p>
                `,
      heroImage: require("../assets/home-page/.v3/auchentoshan-bg.jpg"),
    },
    "glenrothes-31yo": {
      buttonId: "buy_btn_1PVcNUG60ZFPjGzdScd6gcRK", // PROD
      // buttonId: "", // TEST
      strapline: "GLENROTHES 31YO & <br/>1 YEAR MEMBERSHIP for £715",
      subtitle: "COLLECTIVE SERIES GLENROTHES 31YO",
      message: `<p class="mb-2">Your chance to own a bottle of extremely rare our COLLECTIVE SERIES Glenrothes
                31yo bottled by Caskshare.</p>
                <p class="mb-2">
                On the nose, there’s notes of gingersnap with juicy raisins and a hint of sweet pear. Classic
                sherry flavours pour in on the first sip – with a sweet blend of plums, sultanas and dried cherries.
                Burnt citrus creeps through the dominating sherry profile, adding a fresh twist to this complex dram.
                The finish is filled with caramel and a splash of vanilla, like taking a bite of an artisanal chocolate.
                </p>
                <p class="mb-2">This bottle includes 1-year membership to The Caskshare Collective, with full
                membership benefits, all for the discounted price of £715.</p>
                `,
      heroImage: require("../assets/home-page/.v3/glenrothes-bg.jpg"),
    },
    "highland-park-35yo": {
      buttonId: "buy_btn_1PVcS8G60ZFPjGzddFJW9V5z", // PROD
      // buttonId: "", // TEST
      strapline: "HIGHLAND PARK 35YO & <br/>1 YEAR MEMBERSHIP for £1,500",
      subtitle: "COLLECTIVE SERIES HIGHLAND PARK 35YO",
      message: `<p class="mb-2">Your chance to own a bottle of extremely rare our COLLECTIVE SERIES Highland Park
                35yo bottled by Caskshare.</p>
                <p class="mb-2">
                Soft citrus and sweet stone fruits are first on the nose – with subtle aged oak weaving through.
                Hints of ginger, peat smoke, and burning heather come through on your first taste, with the simmering
                smell of cherry jam on the stove prevailing. With just a splash of seaspray, this whisky has a long
                and fruity finish that makes you long for a scoop of something refreshing by the Scapa shoreline.
                </p>
                <p class="mb-2">This bottle includes 1-year membership to The Caskshare Collective, with full
                membership benefits, all for the discounted price of £1,500.</p>
                `,
      heroImage: require("../assets/home-page/.v3/highland-park-bg.jpg"),
    },
    "port-charlotte-21yo": {
      buttonId: "buy_btn_1PVceyG60ZFPjGzdxcyqvdMk", // PROD
      // buttonId: "", // TEST
      strapline: "PORT CHARLOTTE 21YO & <br/>1 YEAR MEMBERSHIP for £500",
      subtitle: "COLLECTIVE SERIES PORT CHARLOTTE 21YO",
      message: `<p class="mb-2">Your chance to own a bottle of extremely rare our COLLECTIVE SERIES Port Charlotte
                21yo bottled by Caskshare.</p>
                <p class="mb-2">
                With notes of mango and sweet tropical fruits comes a soft and warm waft of vanilla and malt biscuits.
                The syrupy softness of the barley sugar contrasts with the fire and spice imbued by its transition
                through three unique casks. The undercurrent of caramel mingles with a delicate maritime smoke in this
                smooth and illustrious dram.
                </p>
                <p class="mb-2">This bottle includes 1-year membership to The Caskshare Collective, with full
                membership benefits, all for the discounted price of £500.</p>
                `,
      heroImage: require("../assets/home-page/.v3/port-charlotte-bg.jpg"),
    },
    "springbank-30yo": {
      buttonId: "buy_btn_1PVcWFG60ZFPjGzdC67saD6c", // PROD
      // buttonId: "", // TEST
      strapline: "SPRINGBANK 30YO & <br/>1 YEAR MEMBERSHIP for £1,950",
      subtitle: "COLLECTIVE SERIES SPRINGBANK 30YO",
      message: `<p class="mb-2">Your chance to own a bottle of extremely rare our COLLECTIVE SERIES Springbank
                30yo bottled by Caskshare.</p>
                <p class="mb-2">
                On the nose, there’s a fresh, crisp scent of apple and pine with a slightly sweet sherbet lingering.
                This whisky is sharp and tangy – a funky blend of orchard fruits and lime pickle relish that almost
                fizzes on the palate. With a lingering finish, this 30 year old is packed with nostalgia as an
                undercurrent of school gymnasiums, fresh rubber plimsolls and tuck shop sweetness remain.
                </p>
                <p class="mb-2">This bottle includes 1-year membership to The Caskshare Collective, with full
                membership benefits, all for the discounted price of £1,950.</p>
                `,
      heroImage: require("../assets/home-page/.v3/springbank-bg.jpg"),
    },
  };

  if (!campaign || !shop || !shop.stripePublishableKey) {
    return <LoadingLogo />;
  }

  return (
    <>
      <Helmet>
        <title>{getMetaText("WELCOME_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("WELCOME_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("WELCOME_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("WELCOME_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("WELCOME_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("WELCOME_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
      </Helmet>

      <Container fluid className={styles.embeddedCheckoutPageWrap}>
        {width > tabletBreakpoint ? (
          <div className={styles.collectiveBG} style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(${BUY_BUTTONS[campaign]?.heroImage})`
            }}>
            <Container className={styles.contentSection}>
              <Row className={styles.collectiveSection}>
                <div className={styles.content}>
                  <div className={styles.innerContent}>
                    <h3
                      dangerouslySetInnerHTML={{ __html: BUY_BUTTONS[campaign]['strapline'] }}
                    >
                    </h3>
                    <div className={styles.howItWorks}>
                      <p
                      dangerouslySetInnerHTML={{ __html: BUY_BUTTONS[campaign]['subtitle'] }}
                      ></p>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{ __html: BUY_BUTTONS[campaign]['message'] }}
                    ></p>
                  </div>
                  <div className={styles.innerContent}>
                    <div className={styles.howItWorks}>
                      <p>The Caskshare Collective</p>
                    </div>
                    <p>
                      Your subscription will automatically renew in one year but
                      rest assured we will send you a renewal reminder when your
                      subscription is about to renew so you can choose to cancel
                      if you wish.
                    </p>
                    <BuyButtonComponent
                      buyButtonId={BUY_BUTTONS[campaign]?.buttonId}
                      publishableKey={shop.stripePublishableKey}
                    />
                  </div>
                  <div className={styles.spacer1}></div>
                  <div className={styles.imgWrap}>
                    <img
                      src={require("../assets/campaign-pages/rare-whiskies-1.jpg")}
                      alt="Caskshare campaign testing pack caskshare collective whisky membership)"
                    />
                  </div>
                  <div className={styles.joinWrap}>
                    {/* <div className={styles.howItWorks}>
                      <p>Exclusive Whisky Club</p>
                    </div> */}
                    <BuyButtonComponent
                      buyButtonId={BUY_BUTTONS[campaign]?.buttonId}
                      publishableKey={shop.stripePublishableKey}
                    />
                  </div>
                </div>
                <div className={styles.leftImg}>
                  <img
                    src={require("../assets/collective-page/cardEvents.jpg")}
                    alt="happy caskshare collective members"
                  />
                </div>
              </Row>
            </Container>
          </div>
        ) : (
          <>
            <div className={styles.collectiveBG} style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(${BUY_BUTTONS[campaign]?.heroImage})`
            }}>
              <Container className={styles.contentSection}>
                <Row className={styles.collectiveBanner}>
                  <div className={styles.content}>
                    <div className={styles.howItWorks}>
                      <p
                        dangerouslySetInnerHTML={{ __html: BUY_BUTTONS[campaign]['subtitle'] }}
                      ></p>
                    </div>
                    <div className={styles.innerContent}>
                      <h3
                        dangerouslySetInnerHTML={{ __html: BUY_BUTTONS[campaign]['strapline'] }}
                      >
                      </h3>
                      <BuyButtonComponent
                        buyButtonId={BUY_BUTTONS[campaign]?.buttonId}
                        publishableKey={shop.stripePublishableKey}
                      />
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <div className={styles.collectiveContentBG}>
              <Container className={styles.contentSection}>
                <Row className={styles.collectiveContent}>
                  <div className={styles.content}>
                    <p
                      dangerouslySetInnerHTML={{ __html: BUY_BUTTONS[campaign]['message'] }}
                    >
                    </p>
                    <div className={styles.innerContent}>
                      <div className={styles.howItWorks}>
                        <p>The Caskshare Collective</p>
                      </div>
                      <p>
                        Your subscription will automatically renew in one year
                        but rest assured we will send you a renewal reminder
                        when your subscription is about to renew so you can
                        choose to cancel if you wish.
                      </p>
                      <BuyButtonComponent
                        buyButtonId={BUY_BUTTONS[campaign]?.buttonId}
                        publishableKey={shop.stripePublishableKey}
                      />
                    </div>
                    <div className={styles.imgWrap}>
                      <img
                        src={require("../assets/campaign-pages/rare-whiskies-1.jpg")}
                        alt="Caskshare campaign testing pack caskshare collective whisky membership)"
                      />
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
          </>
        )}

        <div className={styles.blackBG}>
          <Container className={styles.contentSection}>
            <Row className={styles.benefitsSection}>
              <div className={styles.content}>
                <div className={styles.innerContent}>
                  <h3>
                    WE'LL GIVE YOU ALL OF THIS
                    <br /> FOR THE NEXT
                    <span> 12 MONTHS</span>
                  </h3>
                  <div className={styles.benefits}>
                    <p>Free shipping on all products</p>
                    <p>Exclusive Member discounts</p>
                    <p>Monthly Tasting Events</p>
                    <p>Build your maturing cask collection</p>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>

        <div className={styles.blackBG}>
          <Container className={styles.contentSection}>
            <Row className={styles.reviewSection}>
              <div className={styles.content}>
                <div className={styles.innerContent}>
                  <div className={styles.reviewCard}>
                    <div className={styles.stars}>
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                    </div>
                    <p className={styles.review}>
                      Excellent communication and process! My whiskey arrived in
                      careful, safe packaging, and looking forward to tasting at
                      some special event. I'll order more from this team.
                    </p>
                  </div>
                  <div className={styles.reviewCard}>
                    <div className={styles.stars}>
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                    </div>
                    <p className={styles.review}>
                      Caskshare offered seamless setup and swift delivery of my
                      whisky trio. The packaging was secure, ensuring safe
                      arrival. Excited for future tastings in Edinburgh!
                    </p>
                  </div>
                  <div className={styles.reviewCard}>
                    <div className={styles.stars}>
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                      <i className="fa fa-solid fa-star" />
                    </div>
                    <p className={styles.review}>
                      Caskshare is an outstanding company! My tasting pack
                      arrived safely packaged, reflecting the care of the team.
                      Eagerly anticipating the next tasting experience.
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>

        {/* <div className={styles.embeddedCheckoutOuterWrap}>
          <Container className={styles.embeddedCheckoutInnerWrap}>
            <Row className={styles.embeddedCheckoutRow}>
              <Col xs={12} className={styles.embeddedCheckoutCol}>
                <h2 className="px-3 px-md-5">
                  {BUY_BUTTONS[campaign]?.strapline}
                </h2>
                {BUY_BUTTONS[campaign] ? (
                  <p className="px-3 px-md-5"
                    dangerouslySetInnerHTML={{ __html: BUY_BUTTONS[campaign].message }}
                  />
                  ) : null }
                <BuyButtonComponent
                  buyButtonId={BUY_BUTTONS[campaign]?.buttonId}
                  publishableKey={shop.stripePublishableKey}
                />
              </Col>
            </Row>
            <Row className={styles.benefitsTitlesRow}>
              <Col xs={12}>
                <h3>
                  WE'LL GIVE YOU ALL OF THIS FOR THE NEXT 12 MONTHS:
                </h3>
              </Col>
            </Row>
            <Row className={styles.benefitsRow}>
              <Col xs={6} md={3}>
                <ul className="fa-ul">
                  <li><i className="fa fa-solid fa-play" />Free shipping on all products</li>
                </ul>
              </Col>
              <Col xs={6} md={3}>
                <ul className="fa-ul">
                  <li><i className="fa fa-solid fa-play" />Exclusive Member discounts</li>
                </ul>
              </Col>
              <Col xs={6} md={3}>
                <ul className="fa-ul">
                  <li><i className="fa fa-solid fa-play" />Monthly Tasting Events</li>
                </ul>
              </Col>
              <Col xs={6} md={3}>
                <ul className="fa-ul">
                  <li><i className="fa fa-solid fa-play" />Build your maturing cask collection</li>
                </ul>
              </Col>
            </Row>
            <Row className={styles.reviewRow}>
              <Col xs={4}>
                <i className="fa fa-solid fa-star"/>
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <p>
                  Excellent communication and process! My whiskey arrived in careful, safe packaging,
                  and looking forward to tasting at some special event. I'll order more from this team.
                </p>
              </Col>
              <Col xs={4}>
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <p>
                  Caskshare gave excellent service in, firstly, setting up my membership and then, secondly,
                  when I made my first order of three bottles - the whisky arrived quickly and was superbly
                  packaged to avoid any breakages. I look forward to going to a tasting in Edinburgh.
                </p>
              </Col>
              <Col xs={4}>
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <i className="fa fa-solid fa-star" />
                <p>
                  This company is the best. Highest recommendation!
                </p>
              </Col>
            </Row>
          </Container>
        </div> */}
      </Container>
    </>
  );
};
export default EmbeddedCheckoutPage;
