import React from "react";
import ReactDOM from "react-dom";
import AccountReferFriend from "./AccountReferFriend";

export const renderReferFriend = ({
  setModalOpen,
  modalOpen,
}: {
  setModalOpen?: any;
  modalOpen: boolean;
}) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  function handleClose() {
    setModalOpen(false);
    container.remove();
  }

  return ReactDOM.createPortal(
    <AccountReferFriend setModalOpen={handleClose} modalOpen={modalOpen} />,
    container,
  );
};
