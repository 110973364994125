import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import Color, { useColor } from "color-thief-react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImPlay3 } from "react-icons/im";
import { useQuery } from "react-apollo";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import { gsap } from "gsap";

import { RootState } from "../../state/rootReducer";
import ScrollToSection from "../../components/app/ScrollToSection";
import styles from "./MeetMaker.module.scss";

const MeetMaker = () => {
  //@ts-ignore
  const { makerId } = useParams();
  const history = useHistory();
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  const hero = useRef(null);
  const [player, setPlayer] = useState(false);
  const [url] = useState("https://youtu.be/JRKfikYOJPM");

  useEffect(() => {
    gsap.to(hero.current, { visibility: "visible", opacity: "1" });
  });

  useEffect(() => {
    if (!collectiveActive) {
      history.replace("/the-collective");
    }
  }, [collectiveActive]);

  return (
    <Container ref={hero} fluid className={styles.outerPageWrap}>
      <Container className={styles.innerPageWrap}>
        <Row className={styles.videoRow}>
          <Col xs={12} md={6} className={styles.videoCol}>
            <div className={styles.videoWrapper}>
              <img
                src={require("../../assets/home-page/annandale-dist.jpg")}
                alt="Annandale Distillery photograph"
              />
              {url ? (
                <>
                  <ImPlay3 onClick={() => setPlayer(true)} />
                  <div className={styles.background}></div>
                </>
              ) : null}
            </div>
          </Col>
          <Col xs={12} md={6} className={styles.contentCol}>
            <h3>Meet The Maker - Annandale Distillery</h3>
            <p>
              Six stunning 5cl bottles of premium single cask spirit from
              Annandale Distillery. Peated Oloroso, ex-red wine & ex-bourbon and
              unpeated Oloroso, ex-red wine & ex-bourbon.
            </p>
            <p>
              Meet Ben Callow. Ben has taken tastings all across the country
              while filling multiple roles at the Annandale Distillery in his
              four years if working there. Ben will be walking us through these
              6 cracking samples along with the story of the Annandale
              Distillery.
            </p>
            <div className={styles.button}>
              <Button
                as={Link}
                // to={`/products/${raffleId}`}
                to={"/caskshares/selection/annandale"}
                className={styles.btnPrimary}
              >
                View Annandale caskshares
              </Button>
            </div>
          </Col>
        </Row>
        <Row className={styles.supportRow}>
          <Col xs={12} md={6} className={styles.supportItem}>
            <h4>Don't have your tasting pack yet?</h4>
            <p>
              Grab your{" "}
              <span
                onClick={() =>
                  history.push("/products/annandale-tasting-pack-1")
                }
              >
                Annandale tasting pack
              </span>{" "}
              and catch up on our Meet the Makers event from the comfort of your
              own home.
            </p>
          </Col>
          <Col xs={12} md={6} className={styles.supportItem}>
            <div
              onClick={() => history.push("/distillery/annandale-distillery")}
              className={styles.imgWrapper}
            >
              <img
                src={require("../../assets/home-page/annandale-casks.jpg")}
                alt="Annandale Distillery employee rolling casks"
              />
              <h4>
                Annandale <br /> Distillery
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
      {player && (
        <div
          className={styles.playerPopup}
          onClick={() => {
            setPlayer(false);
          }}
        >
          <div className={styles.playerWrap}>
            <span
              onClick={() => {
                setPlayer(false);
              }}
            >
              <i className="fa fa-times"></i>
            </span>
            <ReactPlayer
              url={url}
              controls={true}
              width="100%"
              height="100%"
              playIcon={<ImPlay3 />}
            />
          </div>
        </div>
      )}
    </Container>
  );
};

export default MeetMaker;
