import React, { useState, useEffect } from "react";
import { Col, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CasksharesSorter = ({
  searchSort,
  setSearchSort,
}: {
  searchSort: any;
  setSearchSort: (arg0: any) => void;
}) =>
  // ^-- { term: '', order: '' }
  {
    const { t } = useTranslation();

    const allTerms = ["amount", "timeToBottling", "bottlingAge"];
    const allOrders = ["asc", "desc"];

    const makeSortDisplay = (term: string, order?: any) => {
      if (term === "featured") {
        return `${t(term)}`;
      } else {
        return `${t(term)}: ${t(order)}`;
      }
    };
    return (
      <Col
        style={{
          whiteSpace: "nowrap",
          padding: "0",
        }}
      >
        <span>Sort</span>
        <Dropdown as="span">
          <Dropdown.Toggle
            style={{
              textTransform: "uppercase",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
            variant="secondary"
            id="dropdown-basic"
          >
            {makeSortDisplay(searchSort.term, searchSort.order)}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              className="sort-dropdown"
              onSelect={() => setSearchSort({ term: "featured" })}
              active={"featured" === searchSort.term}
            >
              {makeSortDisplay("featured")}
            </Dropdown.Item>
            {allTerms.map((term: string) => {
              return allOrders.map((order: string) => {
                return (
                  <Dropdown.Item
                    key={`${term}-${order}`}
                    onSelect={() => setSearchSort({ term, order })}
                    active={
                      term === searchSort.term && order === searchSort.order
                    }
                  >
                    {makeSortDisplay(term, order)}
                  </Dropdown.Item>
                );
              });
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    );
  };

export default CasksharesSorter;
