import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AiFillStar } from "react-icons/ai";

import CheckoutGiftedLineItem from "./.v2/CheckoutGiftedLineItem";
import CaskShareBasketItem from "./CaskShareBasketItem";
import { RootState } from "../../state/rootReducer";
import { LineItem } from "../../graphql/LineItem";
import CheckoutTotals from "./CheckoutTotals";
import styles from "./Checkout.module.scss";
import { FaTimesCircle } from "react-icons/fa";

const CheckoutBasket = ({
  canEditQuantities,
  hideTotals,
  isOversold,
  giftingData,
  setGiftingData,
  promoValidationLoading,
}: {
  canEditQuantities: boolean;
  promoValidationLoading?: boolean;
  hideTotals?: boolean;
  isOversold?: boolean;
  giftingData?: any;
  setGiftingData?: any;
}) => {
  const checkout = useSelector((state: RootState) => state.checkout);
  const isMember = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );
  const [caskshares, setCaskshares] = useState<LineItem[]>([]);
  const [readyBottled, setReadyBottled] = useState<LineItem[]>([]);
  const [bundles, setBundles] = useState<LineItem[]>([]);
  const [gifts, setGifts] = useState<LineItem[]>([]);
  const [shippingFees, setShippingFees] = useState<LineItem[]>([]);
  const [giftBundle, setGiftBundle] = useState<LineItem[]>([]);
  const [colAndVou, setColAndVou] = useState<number>(0);
  const [giftedLineItem, setGiftedLineItem] = useState(false);
  const [giftLimit, setGiftLimit] = useState(false);
  const [lineItemId, setLineItemId] = useState("");
  const [hideBundles, setHideBundles] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const caskshares = checkout?.lineItems?.filter(
      (product: LineItem) =>
        product.type === "cask" &&
        !product.bundleId &&
        product.productType !== "gift",
    );
    setCaskshares(caskshares);
    const readyBottled = checkout?.lineItems?.filter(
      (product: LineItem) =>
        product.type === "product" &&
        !product.bundleId &&
        product.productType !== "gift" &&
        product.productType !== "bottled_caskshare",
    );
    setReadyBottled(readyBottled);
    const bundles = checkout?.lineItems?.filter(
      (product: LineItem) => product.bundleId,
    );
    setBundles(bundles);
    const gifts = checkout?.lineItems?.filter(
      (product: LineItem) => product.productType === "gift",
    );
    setGifts(gifts);
    const shippingFees = checkout?.lineItems?.filter(
      (product: LineItem) => product.productType === "bottled_caskshare",
    );
    setShippingFees(shippingFees);
    let colAndVou = checkout?.lineItems
      ?.map(({ title, isABundle }) => {
        return (
          (title.toLowerCase().includes("collective") && !isABundle) ||
          (title.toLowerCase().includes("voucher") && !isABundle)
        );
      })
      .filter((value) => value === true).length;
    setColAndVou(colAndVou);
  }, [checkout?.lineItems]);

  useEffect(() => {
    if (gifts?.length) {
      setGiftLimit(true);
    }
  }, [gifts?.length]);

  useEffect(() => {
    const abortController = new AbortController();
    setError("");

    if (checkout?.promoCode?.code && checkout?.lineItems?.length) {
      let count = 0;
      for (let i = 0; i < checkout.lineItems.length; i++) {
        const lineItem = checkout.lineItems[i];
        if (checkout.promoCode.code !== lineItem.promoCode) {
          count++;
        }
        count === checkout.lineItems.length &&
          setError(
            `Sorry, this discount code does not apply to any item/s in your basket.`,
          );
      }
    }

    return () => {
      abortController.abort();
    };
  }, [checkout.promoCode, checkout?.lineItems]);

  useEffect(() => {
    const abortController = new AbortController();
    if (bundles?.length && gifts?.length) {
      if (haveCommonIds(bundles, gifts)) {
        const filteredBundles = [...filterObjectsByIds(gifts, bundles)];

        setGifts(filteredBundles);
        setHideBundles(true);
      }
    }

    return () => {
      abortController.abort();
    };
  }, [bundles, gifts]);

  const bundleChecker = (item: any) =>
    (item.bundleId === null && item.isABundle === false) ||
    (item.bundleId && item.isABundle === true);

  const promoCodeChecker = (item: any) =>
    checkout.promoCode &&
    !promoValidationLoading &&
    checkout.promoCode.code === item.promoCode &&
    item.publicTotalPrice !== item.priceTotal;

  const elideStr = (str: string, len: number) =>
    str != null && str.length > len ? str.slice(0, len) + ".." : str;

  const haveCommonIds = (array1: any, array2: any) => {
    return array1.some((item1: any) =>
      array2.some((item2: any) => item1.inventoryId === item2.inventoryId),
    );
  };

  function filterObjectsByIds(array1: any, array2: any) {
    return array1.filter(
      (obj1: any) => !array2.some((obj2: any) => obj2.id === obj1.id),
    );
  }

  const isVoucherOrCollective = (item: LineItem) => {
    if (item.title.toLowerCase().includes("collective")) {
      return "Please note, you can only gift one collective membership per transaction.";
    } else {
      return "Please note, you can only gift one voucher per transaction.";
    }
  };

  if (
    !caskshares.length &&
    !readyBottled.length &&
    !shippingFees.length &&
    !bundles.length &&
    !gifts.length
  ) {
    return <h5>Your basket is empty</h5>;
  }

  return (
    <>
      {error && !promoValidationLoading && (
        <div className={styles.undiscountedLineItems}>{error}</div>
      )}
      {caskshares.length ? (
        <>
          <h5 style={{ marginBottom: "1rem" }}>
            Maturing
            <span className="number-of-caskshares">
              {` (${caskshares.reduce(
                (acc: number, item: LineItem) => (acc += item.quantity),
                0,
              )})`}
            </span>
          </h5>
          <ul className="list-unstyled caskshare-basket-items-list">
            {caskshares.map((item: LineItem, i) => (
              <div
                style={{
                  marginBottom:
                    !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                }}
                key={i}
              >
                <CaskShareBasketItem
                  item={item}
                  canEditQuantities={canEditQuantities}
                  checkoutStockIssues={checkout.stockIssues}
                  setGiftedLineItem={setGiftedLineItem}
                  giftedLineItem={giftedLineItem}
                  setLineItemId={setLineItemId}
                  lineItemId={lineItemId}
                  colAndVou={colAndVou}
                />
                {item?.cask?.percentageDiscount !== "0" &&
                bundleChecker(item) &&
                !promoCodeChecker(item) ? (
                  <div className="collective-upsell-row">
                    {isMember ? (
                      <div className="member-banner">
                        <AiFillStar />
                        <p>
                          {Number(item?.cask?.percentageDiscount) * 100}%
                          Collective discount applied
                        </p>
                      </div>
                    ) : (
                      <div className="nonMember-banner">
                        <AiFillStar />

                        <p>
                          {Number(item?.cask?.percentageDiscount) * 100}% off
                          for Collective members
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
                {giftedLineItem &&
                lineItemId === item?.id &&
                bundleChecker(item) ? (
                  <CheckoutGiftedLineItem
                    giftedLineItem={giftedLineItem}
                    giftingData={giftingData}
                    setGiftingData={setGiftingData}
                    setGiftedLineItem={setGiftedLineItem}
                    item={item}
                  />
                ) : null}
              </div>
            ))}
          </ul>
        </>
      ) : null}
      {readyBottled.length ? (
        <>
          <h5 style={{ paddingTop: "20px" }}>
            Shipping Now
            <span className="number-of-caskshares">
              {` (${readyBottled
                .filter((a: LineItem) => bundleChecker(a))
                .reduce(
                  (acc: number, item: LineItem) => (acc += item.quantity),
                  0,
                )})`}
            </span>
          </h5>
          <ul className="list-unstyled caskshare-basket-items-list">
            {readyBottled.map((item: LineItem, i) => (
              <div
                style={{
                  marginBottom:
                    !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                  marginTop:
                    !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                }}
                key={i}
              >
                <CaskShareBasketItem
                  item={item}
                  canEditQuantities={canEditQuantities}
                  checkoutStockIssues={checkout.stockIssues}
                  setGiftedLineItem={setGiftedLineItem}
                  giftedLineItem={giftedLineItem}
                  setLineItemId={setLineItemId}
                  lineItemId={lineItemId}
                  isOversold={isOversold}
                  colAndVou={colAndVou}
                />
                {item?.product?.percentageDiscount !== "0" &&
                bundleChecker(item) &&
                !item.isABundle &&
                !promoCodeChecker(item) ? (
                  <div className="collective-upsell-row">
                    {isMember ? (
                      <div className="member-banner">
                        <AiFillStar />
                        <p>
                          {Number(item?.product?.percentageDiscount) * 100}%
                          Collective discount applied
                        </p>
                      </div>
                    ) : (
                      <div className="nonMember-banner">
                        <AiFillStar />
                        <p>
                          {Number(item?.product?.percentageDiscount) * 100}% off
                          for Collective members
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
                {giftedLineItem &&
                lineItemId === item.id &&
                bundleChecker(item) ? (
                  <CheckoutGiftedLineItem
                    giftedLineItem={giftedLineItem}
                    giftingData={giftingData}
                    setGiftingData={setGiftingData}
                    setGiftedLineItem={setGiftedLineItem}
                    item={item}
                  />
                ) : null}
              </div>
            ))}
          </ul>
        </>
      ) : null}
      {bundles.length ? (
        <>
          <h5 style={{ paddingTop: "20px" }}>
            Bundles
            <span className="number-of-caskshares">
              {` (${bundles
                .filter((a: LineItem) => bundleChecker(a))
                .reduce(
                  (acc: number, item: LineItem) => (acc += item.quantity),
                  0,
                )})`}
            </span>
          </h5>
          <ul className="list-unstyled caskshare-basket-items-list">
            {bundles.map((item: LineItem, i) => (
              <div
                style={{
                  marginBottom:
                    !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                  marginTop:
                    !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                }}
                key={i}
              >
                {giftLimit && canEditQuantities && !item.bundleChild ? (
                  <div className="oneGiftPerCheckout">
                    <span onClick={() => setGiftLimit(false)}>
                      {<FaTimesCircle />}
                    </span>
                    <p>{isVoucherOrCollective(item)}</p>
                  </div>
                ) : null}
                <CaskShareBasketItem
                  item={item}
                  canEditQuantities={canEditQuantities}
                  checkoutStockIssues={checkout.stockIssues}
                  setGiftedLineItem={setGiftedLineItem}
                  giftedLineItem={giftedLineItem}
                  setLineItemId={setLineItemId}
                  lineItemId={lineItemId}
                  isOversold={isOversold}
                  colAndVou={colAndVou}
                />
                {giftedLineItem &&
                lineItemId === item.id &&
                bundleChecker(item) ? (
                  <CheckoutGiftedLineItem
                    giftedLineItem={giftedLineItem}
                    giftingData={giftingData}
                    setGiftingData={setGiftingData}
                    setGiftedLineItem={setGiftedLineItem}
                    item={item}
                  />
                ) : null}
              </div>
            ))}
          </ul>
        </>
      ) : null}
      {gifts.length || giftBundle.length ? (
        <>
          <h5 style={{ paddingTop: "20px" }}>
            Gifts
            <span className="number-of-caskshares">{`(${
              !giftBundle.length ? gifts.length : giftBundle.length
            })`}</span>
          </h5>
          <ul className="list-unstyled caskshare-basket-items-list">
            {(!giftBundle.length ? gifts : giftBundle)?.map(
              (item: LineItem, i) => (
                <div
                  style={{
                    marginBottom:
                      !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                    marginTop:
                      !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                  }}
                  key={i}
                >
                  {giftLimit && canEditQuantities ? (
                    <div className="oneGiftPerCheckout">
                      <span onClick={() => setGiftLimit(false)}>
                        {<FaTimesCircle />}
                      </span>
                      <p>{isVoucherOrCollective(item)}</p>
                    </div>
                  ) : null}
                  <CaskShareBasketItem
                    item={item}
                    canEditQuantities={canEditQuantities}
                    checkoutStockIssues={checkout.stockIssues}
                    setGiftedLineItem={setGiftedLineItem}
                    giftedLineItem={giftedLineItem}
                    setLineItemId={setLineItemId}
                    lineItemId={lineItemId}
                    isOversold={isOversold}
                    colAndVou={colAndVou}
                  />
                </div>
              ),
            )}
          </ul>
        </>
      ) : null}
      {shippingFees.length ? (
        <>
          <h5 style={{ paddingTop: "20px" }}>
            Shipping
            <span className="number-of-caskshares">{` (${shippingFees.length})`}</span>
          </h5>
          <ul className="list-unstyled caskshare-basket-items-list">
            {shippingFees.map((item: LineItem, i) => (
              <div
                style={{
                  marginBottom:
                    !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                  marginTop:
                    !item.isABundle && !item.bundleChild ? ".6rem" : "0rem",
                }}
                key={i}
              >
                <CaskShareBasketItem
                  item={item}
                  canEditQuantities={canEditQuantities}
                  checkoutStockIssues={checkout.stockIssues}
                  isOversold={isOversold}
                />
              </div>
            ))}
          </ul>
        </>
      ) : null}
      {/* <>
        <h5 style={{ paddingTop: "20px" }}>Promo code</h5>
        <Form.Control
          size="lg"
          type="text"
          placeholder={"Promo code"}
          onBlur={(e: any) => {
            if (e.target.value) {
              dispatch(addPromoCodeToCheckout(e.target.value));
            } else {
              dispatch(removePromoCodeFromCheckout());
            }
          }}
        />
      </> */}
      {hideTotals ? null : <CheckoutTotals checkoutData={checkout} />}
      {isOversold ? (
        <p
          style={{
            backgroundColor: "#e76852",
            padding: "10px",
            color: "#fff",
          }}
        >
          Oops, looks like one or more item/s is oversold. Please, go back to
          your cart and adjust quantities.
        </p>
      ) : null}
    </>
  );
};

export default CheckoutBasket;
