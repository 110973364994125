import { configureStore, Action } from "@reduxjs/toolkit";
import thunk, { ThunkAction } from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer, { RootState } from "./rootReducer";

const persistConfig = {
  key: "root",
  storage,
  timeout: null || undefined,
  // blacklist: ["checkout"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // @ts-ignore
  middleware: [thunk, logger],
});

export const persistor = persistStore(store);
// persistor.purge();

if ((module as any).hot) {
  // @ts-ignore
  module.hot.accept(() => {
    const nextRootReducer = require("./rootReducer").default;
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
