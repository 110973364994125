import { store } from "../state/store";

export const feature = (featureName: any) => {
  const features = store.getState().shop.features;
  const featureIndex = features.map((a: any) => a.feature).indexOf(featureName);

  if (featureIndex >= 0) {
    return features[featureIndex].active;
  } else {
    return null;
  }
};
