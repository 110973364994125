import React from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router";
import { SelectWrapper } from "./SelectWrapper";
import styles from "./SelectWrapper.module.scss";
import { IMAGE_FALLBACKS } from "../../../../config";
import ProgressiveImg from "../../../.v2/general/ProgressiveImg";
import { Events } from "../../../../graphql/User";

type StateData = {
  image: string;
  seats: number;
  tastingPacks: number;
  event?: Events;
  showConfirmation: boolean;
};

type SelectProps = StateData & {
  updateState: (state: Partial<StateData>) => void;
};

export function SelectTastingPack({
  tastingPacks,
  updateState,
  seats,
  event,
  image,
  showConfirmation,
}: SelectProps) {
  return (
    <SelectWrapper
      title={
        !showConfirmation
          ? `Add ${event?.product?.title}`
          : showConfirmation && seats === 0
          ? "You are all set!"
          : "Your seat has been booked!"
      }
      imageComp={
        !showConfirmation && (
          <ProgressiveImg
            src={image}
            placeholderSrc={IMAGE_FALLBACKS.glenrothesPlaceholder}
            props={{ alt: "product" }}
          />
        )
      }
      show={!showConfirmation && true}
    >
      {!showConfirmation ? (
        <>
          {event?.product?.description && <p>{event?.product?.description}</p>}
          <div className={styles.qtyPicker}>
            <button
              type="button"
              className={""}
              onClick={() =>
                tastingPacks > 0 &&
                updateState({ tastingPacks: tastingPacks - 1 })
              }
              disabled={tastingPacks === 0}
            >
              -
            </button>
            <p>{tastingPacks}</p>
            <button
              type="button"
              className={""}
              onClick={() => updateState({ tastingPacks: tastingPacks + 1 })}
            >
              +
            </button>
          </div>
        </>
      ) : (
        <>
          {tastingPacks > 0 && (
            <p style={{ border: "none" }}>
              Go to your basket to complete this purchase.
            </p>
          )}
        </>
      )}
    </SelectWrapper>
  );
}
