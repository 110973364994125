import React from 'react'
import { Container } from 'react-bootstrap'

// interface Details {
//   message: string
// }

const Error = (
  { message }:
  { message:string }
  ) => {
  return (
    <Container
      fluid
      className="d-flex flex-column h-100 flex-grow-1"
      style={{
        background: `url(${require('../../assets/images/error.jpg')}) center center/cover no-repeat`
      }}>
        <div className="text-light text-center my-auto">
          <h1>
            { message }
          </h1>
          <p>
            Whoops. This is embarrassing.
          </p>
        </div>
    </Container>
  )
}

export default Error
