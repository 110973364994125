import { gql } from 'apollo-boost'

export type Term = {
  id: string
  title: string
  body: string
}

export interface Terms {
  terms: Term[]
}

export const GET_TERMS = gql`
  query terms($shop: ID!, $role: String!) {
    terms(shop: $shop, role: $role) {
      id
      title
      body
    }
  }
`
