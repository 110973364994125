import React, { useState, useEffect } from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import pluralize from "pluralize";
import { useQuery } from "react-apollo";
import { Product } from "../../../graphql/Products";
import { formatCurrency } from "../../../utils/currency";
import { RootState } from "../../../state/rootReducer";
import { IMAGE_FALLBACKS } from "../../../config";
import ProgressiveImg from "../../.v2/general/ProgressiveImg";
import styles from "./ProductCard.module.scss";

const elideStr = (str: string, len: number) =>
  str != null && str.length > len ? str.slice(0, len) + "..." : str;
const ProductItem = ({
  bottle,
  onClick,
}: {
  bottle: Product;
  onClick: (bottle: Product) => void;
}) => {
  const [price, setPrice] = useState(0);
  const [collectiveUser, setCollectiveUser] = useState(false);
  const [collectiveNote, setCollectiveNote] = useState("");
  const shop = useSelector((state: RootState) => state.shop);
  const loggedInUser = useSelector((state: RootState) => state.user.email);
  const collectiveActive = useSelector(
    (state: RootState) => state.user.collectiveActive,
  );

  useEffect(() => {
    let percentageDiscount = bottle?.percentageDiscount;
    if (!collectiveActive) {
      setPrice(bottle.defaultVariant.price.amount);
      setCollectiveUser(false);
      percentageDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(percentageDiscount) * 100).toFixed(
            0,
          )}% off for Collective members`,
        );
    } else if (loggedInUser && collectiveActive) {
      setPrice(bottle.defaultVariant.memberPrice);
      setCollectiveUser(true);
      percentageDiscount !== "0" &&
        setCollectiveNote(
          `${Number(Number(percentageDiscount) * 100).toFixed(
            0,
          )}% Collective discount applied`,
        );
    }
    return () => {
      setCollectiveUser(false);
      setCollectiveNote("");
    };
  }, [loggedInUser, collectiveActive]);

  return (
    <div className={styles.productCardComponent}>
      <Row className={styles.imageWrapper}>
        <ProgressiveImg
          src={bottle?.image}
          placeholderSrc={IMAGE_FALLBACKS.defaultBottle}
          props={{ alt: "bottle" }}
          onClick={() => onClick(bottle)}
        />
      </Row>
      <Row className={styles.contentWrapper}>
        <Col className={styles.bottleTitle}>
          <h5 onClick={() => onClick(bottle)}>{elideStr(bottle.title, 34)}</h5>
        </Col>
        <Col className={styles.volume}>
          {bottle.volume !== "0.0" && <p>{bottle.volume}cl</p>}
        </Col>
        <Col className={styles.priceWrap}>
          <p
            className={
              collectiveUser ? styles.collectivePrice : styles.nonCollective
            }
          >
            {formatCurrency(shop.currency, 2).format(price)}
          </p>
          <Button
            onClick={() => onClick(bottle)}
            disabled={bottle.quantity <= 0}
            className={styles.btnLight}
          >
            {bottle.quantity >= 1 ? "Buy" : "Sold out"}
          </Button>
        </Col>
        <Col className={styles.collectiveNote}>
          <div className={styles.innerWrap}>
            <p
              className={
                collectiveUser ? styles.collectivePrice : styles.nonCollective
              }
            >
              {collectiveNote ? (
                collectiveNote
              ) : !collectiveNote && collectiveActive ? (
                <Link to="/my-account/refer-a-friend">
                  Refer a Friend to The Collective
                </Link>
              ) : (
                <Link to="/the-collective">Join the Collective</Link>
              )}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductItem;
