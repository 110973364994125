import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Shop, CarouselSlide } from "../../../graphql/Shops";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./HomePage.module.scss";
import { RootState } from "../../../state/rootReducer";
import { Attachment } from "../../../graphql/Distillery";
import LoadingLogo from "../../../components/app/LoadingLogo";

const HeroCarousel = ({ shopData }: { shopData: Shop[] }) => {
  const HERO_SLIDES_INIT: CarouselSlide = {
    strapline1: "Welcome to",
    strapline2: "Caskshare",
    buttonText: "Shop Now",
    buttonUrl: "/caskshares",
    page: "home",
    ordinal: 1,
    attachments: [
      {
        role: "hero",
        title: "caskshare agitator whisky",
        url: `${require("../../../assets/home-page/.v3/caskshare agitator whisky.jpg")}`,
      },
    ],
  };

  const sliderRef = useRef(null);
  const shop = useSelector((state: RootState) => state.shop);

  const [carouselSlides, setCarouselSlides] = useState<CarouselSlide[]>([]);

  useEffect(() => {
    const abortController = new AbortController();

    if (shopData && !abortController.signal.aborted) {
      const filteredResults = shopData.find(
        (shops: Shop) => shops.currency === shop.currency,
      );

      if (filteredResults?.heroItems?.length) {
        setCarouselSlides(
          filteredResults.heroItems.filter(
            (item: CarouselSlide) => item.page === "home",
          ),
        );
      } else {
        setCarouselSlides([HERO_SLIDES_INIT]);
      }
    } else {
      setCarouselSlides([HERO_SLIDES_INIT]);
    }
    return () => abortController.abort();
  }, [shopData, shop]);

  useEffect(() => {
    if (sliderRef.current && carouselSlides) {
      // @ts-ignore
      sliderRef.current.slickGoTo(0);
    }
  }, [sliderRef, carouselSlides]);

  const getHeroImg = (attachments: Attachment[]) => {
    let images = attachments?.filter((att: Attachment) => att.role === "hero");
    let image = images?.length
      ? images[0]
      : {
          role: "hero",
          title: "caskshare agitator whisky",
          url: `${require("../../../assets/home-page/.v3/caskshare agitator whisky.jpg")}`,
        };
    return image;
  };

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 0,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 10000,
    fade: true,
    cssEase: "linear",
  };

  return (
    <div
      className={`${styles.heroCarouselWrapper} hero-carousel-slider-control`}
    >
      <Slider ref={sliderRef} {...settings}>
        {carouselSlides.map((slide: CarouselSlide) => (
          <div key={slide.ordinal} className={styles.slideWrap}>
            <div className={styles.innerWrap}>
              <div className={styles.imgWrap}>
                <img
                  src={getHeroImg(slide.attachments)?.url}
                  alt={getHeroImg(slide.attachments)?.title}
                />
              </div>
              <Container className={styles.content}>
                <div className={styles.head}>
                  <h2>{slide.strapline1}</h2>
                  <h2>
                    <span>{slide.strapline2}</span>
                  </h2>
                </div>
                <div className={styles.buttonWrap}>
                  <Button
                    as={Link}
                    to={slide.buttonUrl}
                    className={styles.newButton}
                  >
                    {slide.buttonText}
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default HeroCarousel;
