import React, { useState } from "react";
import styles from "./ArticleMobile.module.scss";
import arrowGoTo from "../../assets/images/GoToArrow.png";
import plusSign from "../../assets/images/plus.png";

interface MonthYear {
  month: number;
  year: number;
}

// Number to month
function getMonthName(monthVar: number) {
  const d = new Date();
  d.setMonth(monthVar - 1);
  const monthName = d.toLocaleString("default", { month: "long" });
  return monthName;
}

const ArticleMobile = ({
  monthYear,
  filter,
  menuItem,
  data,
}: {
  monthYear: MonthYear[];
  filter: any;
  menuItem: any;
  data: any;
}) => {
  // get all months
  const allMonths = [
    ...data
      .map((item: any) => new Date(item.publishedDate).getMonth() + 1)
      .sort((a: any, b: any) => a - b),
  ];

  // value pairs of each month - year
  const count = data.reduce((acc: any, item: any) => {
    const date = new Date(item.publishedDate);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const key = `${month}-${year}`;
    acc[key] = acc[key] === undefined ? 1 : (acc[key] += 1);
    return acc;
  }, {});

  const [isActive, setActive] = useState<MonthYear>({ month: -1, year: -1 });

  const handleActive = (month: number, year: number) => {
    setActive({ month, year });

    let height = document.getElementById("articleElement");
    height?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      {monthYear.map(({ month, year }: MonthYear, i: number) => {
        return (
          <div
            id={isActive.month === month && isActive.year === year ? "articleElement" : ""}
            className={`${styles.dateWrapper} ${
              isActive.month === month && isActive.year === year ? styles.active : ""
            }`}
            key={i}
          >
            <div
              className={styles.inner}
              onClick={() => {
                filter(month, year);
                handleActive(month, year);
              }}
            >
              <p>{month === -1 ? "All" : getMonthName(month) + ` ${year}`}</p>
              <small>
                {month === -1
                  ? "(" + allMonths.length + ")"
                  : "(" + count[`${month}-${year}`] + ")"}
              </small>
              <img className={styles.plus} src={plusSign} alt="plus-sign" />
            </div>
            <div className={styles.articleOuter}>
              {menuItem.map((item: any) => {
                return (
                  <div className={styles.articleWrapper} key={item.id}>
                    <h4>{item.title}</h4>
                    <p>{item.body}</p>
                    <a href={item.url} target="_blank">
                      <img src={arrowGoTo} alt="arrow" />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ArticleMobile;
