import React, { useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { useSelector } from "react-redux";

import { RootState } from "../../../state/rootReducer";
import ScrollToSection from "../../../components/app/ScrollToSection";
import CaskThumbnail from "../../../components/casks/CaskThumbnail";
import GeneralSlider from "../../../components/general-slider/GeneralSlider";
import GeneralSliderMobile from "../../../components/general-slider/GeneralSliderMobile";

import styles from "./BallotFallback.module.scss";

function BallotFallback() {
  const shop = useSelector((state: RootState) => state.shop);
  const [scroll, setScroll] = useState("top");
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 992;

  const handleOpenNewsLetter = () => {
    // @ts-ignore
    window._klOnsite = window._klOnsite || [];
    // @ts-ignore
    window._klOnsite.push(["openForm", "V9876V"]);
    window.addEventListener("klaviyoForms", function (e: any) {
      if (e.detail.type == "open") {
        // @ts-ignore
        _learnq.push([
          "identify",
          {
            Market: shop.market,
          },
        ]);
      }
    });
  };

  const ballotData = [
    {
      id: 0,
      title: "terms",
      body: "To enter, simply complete the entry form. The form should be completed only once. You will receive an email confirming your entry.\n\nDuplicate applications will not be accepted. Anyone found making multiple applications for any single bottle will be disqualified.",
      buttonUrl: "/terms/ballot",
      buttonName: "Ballot Terms & Conditions",
    },
    {
      id: 1,
      title: "priority",
      body: "Priority access will be given to Collective Members. For international shipping options please contact us directly (pickup in Edinburgh, Scotland also available).",
    },
  ];
  return (
    <>
      <ScrollToSection scroll={scroll} setScroll={setScroll} />
      <Container fluid className={styles.privateSalePageWrapper}>
        {/* HERO SECTION */}
        <Container className={styles.heroContainer}>
          <Row className={styles.heroRow}>
            <Col
              xs={12}
              sm={6}
              className={`${styles.fallbackImgCol} ${"ballot-img"}`}
            >
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/ballot-page/csEmpty.png")}
                  alt="cask img"
                />
                <img
                  className={styles.caskShadow}
                  src={require("../../../assets/images/cask-shadow-masked.png")}
                  alt="cask-shadow"
                />
                <h4>
                  Ballots <br /> Coming Soon
                </h4>
              </div>
            </Col>
            <Col
              xs={12}
              sm={6}
              className={`${styles.contentCol} ${styles.contentFallback}`}
            >
              <div className={styles.content}>
                <h3 className={styles.title}>
                  Caskshare<span> Ballots</span>
                </h3>
                <p className={styles.paragraphFall}>
                  Our ballots are for the rare, hard-to-find casks that we've
                  had high demand for.{" "}
                </p>
                <p className={styles.paragraphFall}>
                  By entering, you could secure your bottle of a unique single
                  cask for collecting or drinking.
                </p>

                <a onClick={() => setScroll("EnterBallot")}>
                  Discover
                  <i
                    style={{ width: "30px" }}
                    className="fa fa-long-arrow-down fa-fw"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <Row id="EnterBallot" className={styles.enterBallotWrap}>
          <Col xs={12} md={6} className={styles.imgWrap}>
            <div className={styles.top}>
              <img
                src={require("../../../assets/ballot-page/csCasks.jpg")}
                alt="caskshare marketing image"
              />
            </div>
            <div className={styles.bottom}>
              <img
                src={require("../../../assets/ballot-page/csBottle.jpg")}
                alt="caskshare marketing image"
              />
            </div>
          </Col>
          <Col xs={12} md={6} className={styles.contentWrap}>
            <div className={styles.inner}>
              <h3>
                Entering <br /> The Ballot
              </h3>
              <p className={`preserve-new-lines ${styles.fallbackP}`}>
                We've had a phenomenal year when it comes to truly rare single
                casks and our ballots.{"\n\n"}Our two 30 year old single casks,
                one distilled at Springbank, the other at Macallan distillery,
                have been incredibly popular and we can't wait to reveal our
                next ballot in 2023.{"\n\n"}By entering, you're joining a list
                of interested buyers and you could be selected to purchase one
                bottle from a very limited cask.{"\n\n"}We'll pre-authorise your
                card so that we can take payment if you're successful. No need
                to worry about money leaving your account if you're not
                selected, nothing is taken until your name is chosen.{"\n\n"}
                Keep an eye on this page for details of our next release or join
                our newsletter to get early bird access to our next ballot.
              </p>
              <div className={styles.buttonWrap}>
                <Button
                  name="Newsletter"
                  onClick={handleOpenNewsLetter}
                  className={styles.btnPrimary}
                >
                  Join Our Newsletter
                </Button>
                <Button
                  className={styles.btnLight}
                  onClick={() => setScroll("BallotInfo")}
                >
                  Learn more{" "}
                  <i
                    style={{ width: "30px" }}
                    className="fa fa-long-arrow-down fa-fw"
                    aria-hidden="true"
                  />
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Container className={styles.ballotInfoCon}>
          <Row id="BallotInfo" className={styles.ballotInfo}>
            <Col xs={12} md={7} className={styles.contentCol}>
              {width < breakpoint ? (
                <div className={styles.content}>
                  <h3 className={styles.title}>
                    Caskshare<span> Ballots</span>
                  </h3>

                  <div className={`${styles.slider} ${styles.sliderFallback}`}>
                    <GeneralSliderMobile
                      data={ballotData}
                      collectiveClass={true}
                      activeIndex={0}
                      title={false}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.content}>
                  <h3 className={styles.title}>
                    Caskshare<span> Ballots</span>
                  </h3>
                  <div className={styles.slider}>
                    <GeneralSlider
                      data={ballotData}
                      collectiveClass={true}
                      activeIndex={0}
                    />
                  </div>
                </div>
              )}
            </Col>
            <Col xs={12} md={5} className={styles.imgCol}>
              <img
                src={require("../../../assets/ballot-page/csRiver.jpg")}
                alt="distillery image"
              />
            </Col>
          </Row>
        </Container>

        {/* -------------------Footer Block-------------------- */}
        <Row className={styles.startCollection}>
          <Col xs={12} className={styles.inner}>
            <span />
            <h2>Need help?</h2>
            <h5>get in touch...</h5>
            <div className={styles.contactDetails}>
              <FaEnvelope />{" "}
              <a href="mailto:contact@caskshare.com">contact@caskshare.com</a>
            </div>
            <div className={styles.contactDetails}>
              <FaPhoneAlt /> <a href="tel:00441313812275">+44-131-381-2275</a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BallotFallback;
