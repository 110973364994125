import React, { useState, useEffect, useRef } from "react";
import { BiPurchaseTag } from "react-icons/bi";
import { Col } from "react-bootstrap";
import { IMAGE_FALLBACKS } from "../../../config";
import { Cask } from "../../../graphql/Casks";
import Tooltip from "../../app/Tooltip";
import CurvedTexts from "./CurvedTexts";
// import CurvedText from "./CurvedText";

const CaskImage = ({
  cask,
  hideCount = false,
  newReserved = -1,
  reservation = null,
  ownedCask,
  disableOwnedCask,
  numberOfOwnedCasks,
  onClick,
}: {
  cask: Cask;
  hideCount?: boolean;
  newReserved?: number;
  reservation?: any;
  ownedCask?: boolean;
  disableOwnedCask?: boolean;
  numberOfOwnedCasks?: number;
  onClick?: (cask: Cask) => void;
}) => {
  // const [distilleryName, setDistilleryName] = useState("");
  const [isBadgeDisplayed, setIsBadgeDisplayed] = useState(false);
  const [isBadge, setIsBadge] = useState("");
  const [curvedText, setCurvedText] = useState({
    fontSize: "",
    scale: "",
    top: "",
    bottom: "",
    objSize: 80,
  });
  const caskImage = useRef<any>({});

  useEffect(() => {
    if (cask) {
      let disBadge;
      if (cask.seller.signatureSeries) {
        disBadge = require("../../../assets/home-page/.v2/signatureSeries.png");
      } else if (cask.featured) {
        disBadge = require("../../../assets/home-page/.v2/expertPick.png");
      } else if (cask.collectiveOnly) {
        disBadge = require("../../../assets/home-page/.v2/collectiveExclusive.png");
      } else {
        disBadge = "";
      }
      if (cask.shippingTimeframe === "shipping_now") {
        disBadge = require("../../../assets/home-page/.v2/shippingNow.png");
      } else if (cask.status === "comingsoon") {
        disBadge = require("../../../assets/home-page/.v2/comingSoon.png");
      }
      setIsBadge(disBadge);
    }
    return () => {
      setIsBadge("");
    };
  }, [cask, isBadgeDisplayed]);

  useEffect(() => {
    let caskWidth = caskImage.current.offsetWidth;
    // console.log(caskWidth);
    if (caskWidth >= 500) {
      setIsBadgeDisplayed(true);
      console.log("object");
      setCurvedText({
        fontSize: "1.5rem",
        scale: "scale(2.4)",
        top: "7rem",
        bottom: "2rem",
        objSize: 40,
      });
    } else if (caskWidth >= 400) {
      setIsBadgeDisplayed(true);
      setCurvedText({
        fontSize: "1.5rem",
        scale: "scale(2)",
        top: "5rem",
        bottom: "2rem",
        objSize: 100,
      });
    } else if (caskWidth >= 260) {
      setIsBadgeDisplayed(true);
      setCurvedText({
        fontSize: "1.3rem",
        scale: "scale(1.3)",
        top: "2rem",
        bottom: "3.6rem",
        objSize: 100,
      });
    } else if (caskWidth >= 220) {
      setIsBadgeDisplayed(true);
      setCurvedText({
        fontSize: "1.3rem",
        scale: "scale(1.2)",
        top: "1rem",
        bottom: "2rem",
        objSize: 80,
      });
    } else if (caskWidth >= 180) {
      setIsBadgeDisplayed(true);
      setCurvedText({
        fontSize: "1.2rem",
        scale: "scale(1)",
        top: ".5rem",
        bottom: "1rem",
        objSize: 80,
      });
    } else if (caskWidth < 160) {
      setIsBadgeDisplayed(false);
      setCurvedText({
        ...curvedText,
        fontSize: "0px",
      });
    } else {
      setIsBadgeDisplayed(true);
      setCurvedText({
        fontSize: "1rem",
        scale: "scale(.9)",
        top: "-.4rem",
        bottom: ".5rem",
        objSize: 100,
      });
    }
  }, [cask, caskImage, caskImage.current.offsetWidth]);

  const caskYear = cask.fillingDate
    ? new Date(cask.fillingDate).getFullYear()
    : null;
  const reserved =
    newReserved >= 0
      ? newReserved
      : cask?.product
      ? cask.totalBottles - cask?.product?.quantity
      : cask.totalReservedBottles;

  let total: any;
  total = cask.totalBottles; // real

  const getCaskBottomImage = (cask: Cask) => {
    let images = cask.distillery.attachments.filter(
      (a) => a.role === "cask-bottom",
    );
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.caskImageCaskBottom;
    return image;
  };
  const getCaskBottlesImage = (cask: Cask) => {
    let caskImages = cask.seller.attachments?.filter(
      (a) => a.role === "cask-bottles",
    );
    let caskImage = caskImages?.length
      ? caskImages[0].url
      : IMAGE_FALLBACKS.caskImageCaskBottles;
    return caskImage;
  };

  let tooltipText = `<p>You have a ${numberOfOwnedCasks} bottle allocation from this cask.</p>`;

  const checkCaskStock = () => {
    if (
      cask.totalReservedBottles >= cask.totalBottles * 0.5 &&
      cask.status !== "comingsoon"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const fillIndicator = () => {
    if (checkCaskStock()) {
      return (
        <>
          <div
            className="fill-spacer"
            style={{
              top: `calc(${Math.min((reserved / total) * 100, 95)}% + 6px)`,
            }}
          >
            &nbsp;
          </div>
          <div
            className="fill-marker"
            style={{
              top: `${Math.min((reserved / total) * 100, 95)}%`,
            }}
          >
            <div className="dashed-line"></div>
          </div>
        </>
      );
    } else if (cask.distillery.physicalAddress !== null) {
      return (
        <div
          className="distillery-stamp-bottom"
          style={{ bottom: curvedText.bottom }}
        >
          <CurvedTexts
            text="Distillery"
            fontSize={curvedText.fontSize}
            scale={curvedText.scale}
            spacing={25}
            offset={70}
            reverse={true}
          />
        </div>
      );
    }
  };

  const caskCounter = () => {
    if (checkCaskStock()) {
      return (
        <div
          className="cask-stats-spacer"
          style={{
            top: `${Math.min((reserved / total) * 100, 95)}%`,
          }}
        >
          <div className="cask-stats-area">
            <div className="cask-stats-label">
              {/* TODO: Change icon */}
              <img
                alt="fill-depth"
                aria-hidden="true"
                className="cask-bottle-marker"
                src={require("../../../assets/images/cask-bottle-marker-icon.svg")}
              />
              <strong>{total - reserved} left</strong>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Col
        xs={12}
        className={`cask-image-component ${onClick ? "clickable" : ""}`}
        onClick={() => (onClick ? onClick(cask) : null)}
      >
        <div className="cask-bottom-area">
          {cask?.displayBadge && isBadgeDisplayed && isBadge !== "" ? (
            <div className="badge-wrapper">
              <img src={isBadge} alt="Caskshare badge image" />
            </div>
          ) : null}

          <div className="clipper">
            <img
              alt="Cask"
              className="cask-bottom-image"
              src={getCaskBottomImage(cask)}
              ref={caskImage}
            />

            {reservation != null ? (
              <div className="cask-text bottles-reserved">
                {reservation.bottles}
              </div>
            ) : caskImage?.current?.offsetWidth > 180 ? (
              <div className="cask-text">{caskYear}</div>
            ) : null}

            {/* {cask.distillery.name && cask.seller.signatureSeries === true ? (
              <>
                <div className="signature-stamp-wrapper">
                  <h5>
                    <span>signature</span> series
                  </h5>
                </div>
              </>
            ) : null} */}

            {cask.distillery.name ? (
              <>
                <div
                  className="distillery-stamp-wrapper"
                  style={{ top: curvedText.top }}
                >
                  <CurvedTexts
                    text={cask.distillery.name}
                    objectSize={80 || curvedText.objSize}
                    fontSize={curvedText.fontSize}
                    scale={curvedText.scale}
                    reverse={false}
                  />
                </div>
              </>
            ) : null}

            {hideCount ? null : fillIndicator()}
            <img
              alt="Cask-Mask"
              className="cask-mask"
              src={require("../../../assets/images/cask-end-overlay.png")}
            />
          </div>
          <img
            alt="Cask-Shadow"
            className="cask-shadow-mask"
            src={require("../../../assets/images/cask-shadow-masked.png")}
            aria-hidden="true"
          />
          {hideCount ? null : caskCounter()}
          <img
            alt="Bottles"
            className="bottle-image"
            src={getCaskBottlesImage(cask)}
          />
          {ownedCask && !disableOwnedCask ? (
            <div className="owned-cask-badge">
              <Tooltip lineOne={tooltipText} customIcon={<BiPurchaseTag />} />
            </div>
          ) : null}
          {/* {!hideCount && cask.defaultVariant.price.maxDate ? (
            <div className="early-bird-banner">
              <h6>
                Early bird price until {cask.defaultVariant.price.maxDate}
              </h6>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </Col>
    </>
  );
};

export default CaskImage;
