import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Image, Form, Col, Row, Button } from "react-bootstrap";
import { feature } from "../../../utils/featureFlag";

import {
  increaseCheckoutQuantity,
  reduceCheckoutQuantity,
} from "../../../state/slices/checkoutSlice";
import { RootState } from "../../../state/rootReducer";
import { LineItem } from "../../../graphql/LineItem";

const CheckoutGiftedLineItem = ({
  giftedLineItem,
  giftingData,
  setGiftingData,
  setGiftedLineItem,
  item,
}: {
  giftedLineItem?: any;
  giftingData?: any;
  setGiftingData?: any;
  setGiftedLineItem?: any;
  item: LineItem;
}) => {
  const dispatch = useDispatch();
  const lineItems = useSelector((state: RootState) => state.checkout.lineItems);

  const handleAddGiftData = (product: any) => {
    if (giftingData.giftDate && giftingData.recipientEmail) {
      dispatch(
        increaseCheckoutQuantity({ product: product, giftData: giftingData }),
      );
      setGiftedLineItem(false);
    }
  };
  const handleRemoveGiftData = (product: any) => {
    setGiftedLineItem(false);
    if (giftingData.giftDate && giftingData.recipientEmail) {
      dispatch(reduceCheckoutQuantity(product, giftingData));
    }
  };

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  return (
    <Form
      className={`${
        giftedLineItem ? "gifting-lineItem-row-active" : null
      } gifting-lineItem-row`}
    >
      <Form.Group className="first-col">
        <Form.Control
          required
          type="text"
          placeholder="Recipient's name (required)"
          onChange={(e) =>
            setGiftingData({
              ...giftingData,
              recipientName: e.target.value,
            })
          }
        />
        <Form.Control
          required
          type="text"
          placeholder="Recipient's email (required)"
          onChange={(e) =>
            setGiftingData({
              ...giftingData,
              recipientEmail: e.target.value,
            })
          }
        />
        {feature("gift_card") === true && (
          <div className="gift-card">
            <Form.Check
              type="checkbox"
              label="Gift Card?"
              id="gift-card"
              className={""}
              checked={giftingData.giftCard}
              onChange={() => {
                setGiftingData({
                  ...giftingData,
                  giftCard: !giftingData.giftCard,
                });
              }}
            />
          </div>
        )}
      </Form.Group>
      <Form.Group className="second-col">
        <Form.Control
          placeholder="Add a message (optional)"
          rows={2}
          as="textarea"
          onChange={(e) =>
            setGiftingData({
              ...giftingData,
              giftNote: e.target.value,
            })
          }
        />
      </Form.Group>
      <Form.Group className="button-col">
        <Button
          className="btn-secondary"
          onClick={() => {
            handleRemoveGiftData(
              item.type === "cask" ? item.cask : item.product,
            );
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary"
          disabled={
            !giftingData.recipientName ||
            !validateEmail(giftingData.recipientEmail)
              ? true
              : false
          }
          onClick={() => {
            handleAddGiftData(item.type === "cask" ? item.cask : item.product);
          }}
        >
          Save
        </Button>
      </Form.Group>
    </Form>
  );
};

export default CheckoutGiftedLineItem;
