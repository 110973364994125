import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Account.module.scss";

const CollectiveDepositSuccess = ({}: {}) => {
  return (
    <div className={styles.successWrap}>
      <h4>Your Monthly Deposit Amount has been setup successfully!</h4>
      <p>
        Thank you for setting up! Click the button below to view your collective
        account page.
      </p>
      <Button as={Link} to="/my-account/collective">
        BarrelBank
      </Button>
    </div>
  );
};

export default CollectiveDepositSuccess;
