import React from "react";
import { Spinner } from "react-bootstrap";

const ComponentLoader = ({ minHeight }: { minHeight?: string }) => (
  <div
    style={{
      minHeight: minHeight || "70vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <img
      style={{ maxHeight: "80px" }}
      src={require("../../assets/account-icons/loader.gif")}
      alt="loading gif"
    />
  </div>
);

export default ComponentLoader;
