import React, { useState, useMemo, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import pluralize from "pluralize";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Events } from "../../../../graphql/User";
import styles from "../InsideCollective.module.scss";
import { CustomerEvents } from "../InsideCollective";
import { IMAGE_FALLBACKS } from "../../../../config";
import { Attachment } from "../../../../graphql/Distillery";
import GeneralModal from "../../../.v2/general/GeneralModal";
import ProgressiveImg from "../../../.v2/general/ProgressiveImg";

const CollectiveItemsSlider = ({
  events,
  sort,
  activeEvent,
  customerEvents,
  setHideCover,
  setActiveEvent,
}: {
  events: Events[];
  activeEvent: Events;
  sort?: string;
  customerEvents: CustomerEvents[];
  setHideCover: (status: boolean) => void;
  setActiveEvent: (event: Events) => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Events>();
  const [filteredEvents, setFilteredEvents] = useState<Events[]>();
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current && filteredEvents) {
      // @ts-ignore
      sliderRef.current.slickGoTo(0);
    }
  }, [sliderRef, filteredEvents]);

  useEffect(() => {
    if (sort === "upcoming-events") {
      const today = new Date();
      setFilteredEvents(
        events.filter((event: Events) => new Date(event.date) > today)
      );
    } else {
      setFilteredEvents(events);
    }
  }, [sort, events]);

  function isBooked(eventSlug: string) {
    let arr = customerEvents.map((a: CustomerEvents) => a.slug);
    return arr.indexOf(eventSlug) !== -1 ? true : false;
  }

  function isAllSeatsBooked(event: Events) {
    return event.availableSeats < 1
  }

  function isInThePast(eventDate: string) {
    const dateToday = new Date().toISOString();
    return eventDate > dateToday ? false : true;
  }

  const formatDate = (d: Date) =>
    new Date(d).toLocaleString("default", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatTime = (d: Date) => {
    let minutes = new Date(d).getMinutes();
    const time = new Date(d).toLocaleTimeString([], {
      hour12: true,
      hour: "numeric",
      minute: minutes >= 1 ? "2-digit" : undefined,
      timeZoneName: timeZone !== "Europe/London" ? "short" : undefined,
      timeZone: timeZone,
    });
    return time.replace(" ", "");
  };

  const getTastingPackDate = (date: Date) => {
    let today = new Date();
    let orderDate = new Date(date);
    let difference = orderDate.getTime() - today.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (TotalDays >= 1 && TotalDays <= 14)
      return `${pluralize("day", TotalDays, true)} left to order the pack`;
    else return null;
  };

  const getLargeImg = (attachments: Attachment[]) => {
    let images = attachments.filter((a: Attachment) => a.role === "large");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.defaultPlaceholder;
    return image;
  };

  const getSmallImg = (attachments: Attachment[]) => {
    let images = attachments.filter((a: Attachment) => a.role === "small");
    let image = images.length
      ? images[0].url
      : IMAGE_FALLBACKS.defaultPlaceholder;
    return image;
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.upcomingVids}>
        <div className={`${styles.cardWrapper} collective-slider-control`}>
          {filteredEvents && (
            <Slider ref={sliderRef} {...settings}>
              {filteredEvents
                .sort((a: Events, b: Events) => {
                  if (a.featured === true) return -1;
                  else if (b.featured === true) return 1;
                  else if (a.date < b.date) return -1;
                  else if (b.date < a.date) return 1;
                  else return 0;
                })
                .map((event: Events) => (
                  <div
                    key={event.slug}
                    style={{ border: "1px solid white" }}
                    className={`${styles.card} ${
                      activeEvent?.slug === event.slug && sort === "all-events"
                        ? styles.activeCard
                        : null
                    } ${sort === "upcoming-events" && styles.activeCards}`}
                  >
                    <div className={styles.vidWrap}>
                      <ProgressiveImg
                        onClick={() => {
                          setActiveEvent(event);
                          setHideCover(false);
                        }}
                        src={getLargeImg(event.attachments)}
                        placeholderSrc={getSmallImg(event.attachments)}
                        props={{ alt: "event-image" }}
                      />
                      {getTastingPackDate(event.orderDeadline) && (
                        <div className={styles.tastingPackBanner}>
                          {getTastingPackDate(event.orderDeadline)}
                        </div>
                      )}
                    </div>
                    <div className={styles.content}>
                      <p>
                        {!isInThePast(new Date(event.date).toISOString()) ? (
                          `${formatDate(event.date)} — ${formatTime(event.date)}`
                        ) :
                          <br/>
                        }
                      </p>
                      <h5
                        onClick={() => {
                          setActiveEvent(event);
                          setHideCover(false);
                        }}
                      >
                        {event.title}
                      </h5>
                      {event.product ? (
                        <Button
                          onClick={() => {
                            setModalOpen((prev) => !prev);
                            setSelectedEvent(event);
                          }}
                          className={styles.btnPrimary}
                        >
                          {isBooked(event.slug) &&
                          !isAllSeatsBooked(event) &&
                          event.virtual &&
                          !isInThePast(new Date(event.date).toISOString())
                            ? "Book more seats"
                            : isBooked(event.slug) && isAllSeatsBooked(event)
                            ? "Add tasting pack"
                            : isBooked(event.slug) && !event.virtual
                            ? "Add tasting pack"
                            : isInThePast(new Date(event.date).toISOString())
                            ? "Add tasting pack"
                            : "Book your seat"}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ))}
            </Slider>
          )}
        </div>
      </div>
      {modalOpen && (
        <GeneralModal
          modalOpen={modalOpen}
          customerEvents={customerEvents}
          setModalOpen={setModalOpen}
          item={selectedEvent!}
        />
      )}
    </>
  );
};
export default CollectiveItemsSlider;
