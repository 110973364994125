import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Account.module.scss";

const CollectiveCardUpdateSuccess = ({}: {}) => {
  return (
    <div className={styles.successWrap}>
      <h4>Your Payment Method was successfully updated!</h4>
      <p>Thank you! Click the button below to view your account page.</p>
      <Button as={Link} to="/my-account/collective">
        BarrelBank
      </Button>
    </div>
  );
};

export default CollectiveCardUpdateSuccess;
