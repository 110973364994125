import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-apollo";
import { RootState } from "../state/rootReducer";
import { Helmet } from "react-helmet";
import { addPromoCodeToCheckout } from "../state/slices/checkoutSlice";
import { GET_PROMO_CODE } from "../graphql/Checkout";

import { getMetaText, getCopyText } from "../AppText";
import { CASKS } from "../graphql/Casks";
import { PRODUCTS, Product } from "../graphql/Products";
import { resetCheckout } from "../state/slices/checkoutSlice";

import { LineItem } from "../graphql/LineItem";

import CheckoutBasket from "../components/checkout/CheckoutBasket";
import CheckoutLogin from "../components/checkout/CheckoutLogin";
import YouMightLikeSlider from "../components/checkout/YouMightLikeSlider";
import moment from "moment";
import LoadingLogo from "../components/app/LoadingLogo";
import { BiInfoCircle } from "react-icons/bi";
import DeliveryInfo from "../components/app/DeliveryInfo";

const BasketPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const checkout = useSelector((state: RootState) => state.checkout);

  if (checkout.status === "completed") {
    dispatch(resetCheckout());
  }

  // We must have a recent checkout data structure to proceed
  // if (!checkout.redeemedFunds) {
  if (!checkout.shippingLine) {
    dispatch(resetCheckout());
  }

  const dateForPicker = (date: any) => {
    return moment(new Date(date)).format("YYYY-MM-DD");
  };

  const [caskshares, setCaskshares] = useState(checkout.lineItems);
  const [readyBottled, setReadyBottled] = useState(checkout.lineItems);
  const [isOversold, setIsOversold] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [giftingData, setGiftingData] = useState<any>({
    giftCard: false,
    recipientEmail: "",
    recipientName: "",
    giftDate: dateForPicker(Date.now()),
    giftNote: "",
  });

  // Promo code logic start
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [promoFormVisible, setPromoFormVisible] = useState(false);
  const [promoValidationLoading, setPromoValidatedLoading] = useState(false);
  const [skipQuery, setSkipQuery] = useState(true);

  const {
    data,
    loading: promoLoading,
    refetch,
  } = useQuery(GET_PROMO_CODE, {
    fetchPolicy: "network-only",
    skip: skipQuery,
    variables: {
      shop: shop.id,
      code: promoCode,
    },
  });

  const handlePromoCode = async (e: any) => {
    e.preventDefault();
    await setSkipQuery(false);
    setPromoError("");
    setPromoValidatedLoading(true);

    if (checkout.promoCode.code) {
      setPromoError("Discount code has already been applied");
      setPromoValidatedLoading(false);
      return;
    }

    const promoCodeObj = (await refetch())?.data?.promoCode;

    if (promoCodeObj?.valid && !promoLoading) {
      await dispatch(addPromoCodeToCheckout(promoCodeObj));
      setPromoCodeApplied(true);
      setTimeout(() => {
        setPromoCodeApplied(false);
      }, 5000);
    } else {
      setPromoError("Sorry, discount code is not valid.");
    }
    setPromoValidatedLoading(false);
    setSkipQuery(true);
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (checkout?.promoCode?.code) {
      setPromoCode(checkout?.promoCode.code);
      setPromoFormVisible(true);
    }

    return () => {
      abortController.abort();
    };
  }, []);
  // Promo code logic end

  useEffect(() => {
    if (
      checkout?.stockIssues?.length &&
      checkout?.stockIssues.map(
        (stockIssue: any) =>
          stockIssue.id ===
          readyBottled.map(
            (bottle: LineItem) => bottle.product.defaultVariant.id,
          ),
      )
    ) {
      setIsOversold(true);
    } else {
      setIsOversold(false);
    }
  }, [checkout.stockIssues, readyBottled]);

  useEffect(() => {
    const caskshares = checkout.lineItems.filter(
      (product: LineItem) => product.type === "cask",
    );
    setCaskshares(caskshares);
    const readyBottled = checkout.lineItems.filter(
      (product: LineItem) => product.type === "product",
    );
    setReadyBottled(readyBottled);
  }, [checkout.lineItems]);

  // const { data: userData, loading: userLoading } = useQuery(GET_CUSTOMER, {});

  const { data: caskData, loading: casksLoading } = useQuery(CASKS, {
    variables: {
      shop: shop.id,
    },
  });
  const { data: bottleData, loading: bottleLoading } = useQuery(PRODUCTS, {
    variables: {
      shop: shop.id,
    },
  });

  if (casksLoading || bottleLoading) {
    return <LoadingLogo />;
  }

  if (checkout.status === "completed") {
    dispatch(resetCheckout());
  }

  const textSubstitutions = {
    "Customer-name": "Customer",
  };

  let title65 = <h4>Proposition 65 WARINING</h4>;
  let message65 = (
    <p>
      Warning: DRINKING DISTILLED SPIRITS, BEER, COOLERS, WINE AND OTHER
      ALCOHOLIC BEVERAGES MAY INCREASE CANCER RISK, AND, DURING PREGNANCY, CAN
      CAUSE BIRTH DEFECTS. FOR MORE INFORMATION GO TO{" "}
      <a href="WWW.P65WARNINGS.CA.GOV/ALCOHOL">
        WWW.P65WARNINGS.CA.GOV/ALCOHOL
      </a>
    </p>
  );

  return (
    <>
      <Container>
        <Helmet>
          <title>
            {getMetaText("BASKET_PAGE", "title", textSubstitutions)}
          </title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="keywords"
            content={getMetaText("BASKET_PAGE", "keywords", textSubstitutions)}
          />
          <meta
            name="description"
            content={getMetaText(
              "BASKET_PAGE",
              "description",
              textSubstitutions,
            )}
          />
          <meta
            name="author"
            content={getMetaText("BASKET_PAGE", "author", textSubstitutions)}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content={getMetaText("BASKET_PAGE", "title", textSubstitutions)}
          />
          <meta
            property="og:description"
            content={getMetaText(
              "BASKET_PAGE",
              "description",
              textSubstitutions,
            )}
          />
          <meta
            property="og:image"
            content={
              window.location.origin + require("../assets/home-page/splash.jpg")
            }
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@thecaskshare" />
          <meta
            name="twitter:title"
            content={getMetaText("BASKET_PAGE", "title")}
          />
          <meta
            name="twitter:description"
            content={getMetaText("BASKET_PAGE", "description")}
          />
          <meta
            name="twitter:image"
            content={
              window.location.origin + require("../assets/home-page/splash.jpg")
            }
          />
        </Helmet>

        <Row>
          <Col xs={9} md={4}>
            <a onClick={() => history.goBack()}>
              <h6 style={{ paddingBottom: "20px" }}>
                <i
                  style={{ width: "30px" }}
                  className="fa fa-long-arrow-left fa-fw mt-4"
                  aria-hidden="true"
                />
                Continue shopping
              </h6>
            </a>
          </Col>
        </Row>
        <Row style={{ minHeight: "400px" }}>
          <Col md={8} className="checkout-basket-area">
            <CheckoutBasket
              giftingData={giftingData}
              setGiftingData={setGiftingData}
              promoValidationLoading={promoValidationLoading}
              canEditQuantities
              hideTotals
            />
          </Col>
          <Col
            md={4}
            style={{ marginTop: "25px" }}
            className="checkout-login-area"
          >
            <CheckoutLogin
              isOversold={isOversold}
              promoFormVisible={promoFormVisible}
              handlePromoCode={handlePromoCode}
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              setPromoFormVisible={setPromoFormVisible}
              promoError={promoError}
              setPromoError={setPromoError}
              promoLoading={promoLoading}
              promoValidationLoading={promoValidationLoading}
              promoCodeApplied={promoCodeApplied}
            />
            {shop.market === "US" && (
              <div className="us-shipping-warning">
                <p>
                  <i>
                    California Residents:{" "}
                    <span
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      Click here
                    </span>{" "}
                    for Proposition 65 WARNING
                  </i>
                </p>
                <p  className="small pt-2">
                  All alcohol orders are processed and fulfilled by
                  licensed third-party retailers. You are not buying
                  from Caskshare.com, but from licensed retailers who
                  receive all orders.
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Container className="you-might-like-slider-mobile">
        {caskshares?.length ? (
          <Row className="you-might-like-area">
            <Col xs={12}>
              <h3>more casks you might like</h3>
            </Col>
            <YouMightLikeSlider
              items={caskData?.casks}
              loading={casksLoading}
            />
          </Row>
        ) : null}
        {readyBottled?.length && !caskshares?.length ? (
          <Row className="you-might-like-area">
            <Col xs={12}>
              <h3>more whisky you might like</h3>
            </Col>
            <YouMightLikeSlider
              items={
                caskData?.casks.filter(
                  (a: any) => a.shippingTimeframe === "shipping_now",
                ) || caskData?.casks
              }
              loading={bottleLoading}
            />
          </Row>
        ) : null}
        {modalOpen && (
          <DeliveryInfo
            setDeliveryInfo={setModalOpen}
            title={title65}
            message={message65}
            shop={shop}
          />
        )}
      </Container>
    </>
  );
};
export default BasketPage;
