import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Cask } from "../../../../graphql/Casks";
import { Product } from "../../../../graphql/Products";
import styles from "./MyCollectionSlider.module.scss";
import BottleImage from "../../../../caskshare-v3/components/casks/caskCard/BottleImage";

const MyCollectionSlider = ({
  customerCollection,
  customer,
  bottles,
}: {
  customerCollection?: Cask[];
  customer?: any;
  bottles?: Product[];
}) => {
  const history = useHistory();
  const sliderRef = useRef(null);

  const getLargeImg = (cask: Cask) => {
    let images = cask?.attachments?.find(({ role }: any) => role === "large");
    let image = images
      ? images.url
      : require("../../../../assets/home-page/.v3/db-lg.png");
    return image;
  };
  const getSmallImg = (cask: Cask) => {
    let images = cask?.attachments?.find(({ role }: any) => role === "small");
    let image = images
      ? images.url
      : require("../../../../assets/home-page/.v3/db-sm.png");
    return image;
  };

  useEffect(() => {
    if (sliderRef.current && customerCollection) {
      // @ts-ignore
      sliderRef.current.slickGoTo(0);
    }
  }, [sliderRef, customerCollection]);

  const elideStr = (str: string, len: number) =>
    str != null && str.length > len ? str.slice(0, len) + "..." : str;

  const distilleryLink = (cask: Cask) => {
    if (
      cask.distillery.slug &&
      cask.distillery.status === "published" &&
      cask.distillery.physicalAddress !== null
    ) {
      return (
        <p
          onClick={() => history.push(`/distillery/${cask.distillery.slug}`)}
          className="cask-info-text dis-link"
        >
          {cask.distillery.name} Distillery
        </p>
      );
    } else if (cask.distillery.physicalAddress !== null) {
      return (
        <p className="cask-info-text">{cask.distillery.name} Distillery</p>
      );
    } else {
      return <p className="cask-info-text">{cask.distillery.name}</p>;
    }
  };

  if (!customerCollection) {
    return <></>;
  }

  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div
      className={`${styles.myCollectionSlider} my-collection-slider-control`}
    >
      {customerCollection && (
        <Slider ref={sliderRef} {...settings}>
          {customerCollection.map((reservation: any) => (
            <div
              key={reservation.cask.code}
              className={styles.myCollectionCard}
            >
              <div className={styles.imgWrap}>
                <BottleImage
                  placeholderSrc={getLargeImg(reservation.cask)}
                  src={getSmallImg(reservation.cask)}
                />
                {/* <CaskImage
                  cask={reservation.cask}
                  reservation={reservation}
                  hideCount
                /> */}
              </div>
              <div className={styles.contentWrap}>
                <div className={styles.distillery}>
                  {distilleryLink(reservation.cask)}
                </div>
                <div className={styles.title}>
                  <h5>{elideStr(reservation.cask.title, 46)}</h5>
                </div>
                <div className={styles.cta}>
                  <Button
                    className={styles.btnPrimary}
                    as={Link}
                    to="/my-account"
                  >
                    {reservation?.shippingPaid
                      ? "Shipped"
                      : "Check for updates"}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};
export default MyCollectionSlider;
