import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import CookieConsent from "react-cookie-consent";
import { getCopyText } from "../../AppText";
import ReactPixel from "react-facebook-pixel";
import { Link } from "react-router-dom";

const ACCEPT_BUTTON_STYLE = {
  // color: 'white',
  // borderColor: 'white',
  // borderWidth: '1px',
  // borderStyle: 'solid',
  // backgroundColor: 'transparent',
  // fontFamily: 'Bebas Neue',
  // fontSize: '1.25rem'
};

const CookieConsentStrip = () => {
  return (
    <CookieConsent
      disableStyles={true}
      contentClasses="content-area"
      buttonText="Accept and close"
      buttonClasses="accept-button"
      buttonWrapperClasses="accept-button-area"
      disableButtonStyles={true}
      cookieName="caskshare-cookie-consent"
      cookieValue="consented"
      expires={150}
      overlay={false}
      debug={false}
      enableDeclineButton={false}
      onAccept={ReactPixel.grantConsent}
    >
      <Container
        style={{
          paddingTop: "0",
          paddingBottom: "0",
        }}
      >
        <Row className="">
          <Col xs={12} md={2} className="cookie-consent-title">
            Cookie Notice
          </Col>
          <Col xs={12} md={7} lg={8} className="cookie-consent-text">
            {getCopyText("HOME_PAGE", "cookie-consent-explantory-link")}
            <Link to="/terms/privacy-policy">More about our cookies.</Link>
          </Col>
        </Row>
      </Container>
    </CookieConsent>
  );
};

export default CookieConsentStrip;
