import { gql } from "apollo-boost";
import { string } from "prop-types";
import { Attachment } from "./Distillery";
import { Product } from "./Products";

type Variant = {
  availableForSale: boolean;
  nonBottleImage: boolean;
  id: string;
  image: string;
  price: {
    amount: number;
    currencyCode: string;
    maxDate: string;
    maxBottles: number;
  };
  compareAtPrice: {
    amount: number;
    currencyCode: string;
  };
};

export type Artist = {
  name: string;
  description: string;
  linkUrl: string;
  linkText: string;
  attachments: Attachment[];
};

const VARIANT_INIT: Variant = {
  availableForSale: false,
  nonBottleImage: false,
  id: "string",
  image: "string",
  price: {
    amount: 0,
    currencyCode: "string",
    maxDate: "string",
    maxBottles: 0,
  },
  compareAtPrice: {
    amount: 0,
    currencyCode: "string",
  },
};
export interface Cask {
  // simple fields
  attachments: Attachment[];
  artist: Artist;
  barrelType: string;
  barrelVariant: string;
  ballotActive: boolean;
  ballotSlug: string;
  bondQuarter: number;
  bondYear: number;
  bottlingAge: number;
  bottlingMonth: number;
  bottlingYear: number;
  collectiveOnly: boolean;
  displayBadge: boolean;
  featured: boolean;
  featuredOrdinal: number;
  formLink: string;
  shippingTimeframe: string;
  percentageDiscount: string;
  fixedDiscount: string;
  description: string;
  release: string;
  status: string;
  fillingDate: string;
  flavourNotes: string;
  shortDescription: string;
  storedAt: string;
  title: string;
  totalBottles: number;
  totalReservedBottles: number;
  type: "cask";
  wholesalePrice: number;
  yearsToBottling: number;
  tags: string[];
  memberPrice: number;
  bottledCaskshare: {
    slug: string;
    id: string;
    defaultVariant: {
      id: string;
    };
    type: string;
  };
  earlyBottlePrice: {
    amount: number;
    currencyCode: string;
  };
  midBottlePrice: {
    amount: number;
    currencyCode: string;
  };
  lateBottlePrice: {
    amount: number;
    currencyCode: string;
  };
  priceTier: string;
  product: Product;
  // compound fields
  defaultVariant: {
    id: string;
    slug: string;
    price: {
      amount: number;
      currencyCode: string;
      maxDate: string;
      maxBottles: number;
    };
  };
  distillery: {
    overview: string;
    name: string;
    intro: string;
    physicalAddress: {
      country: string;
      countryCode: string;
    };
    slug: string;
    status: string;
    attachments: Attachment[];
  };
  image: string;
  variant: { id: string };
  variants: Variant[];
  flavourMap: {
    complexity: number;
    fruitiness: number;
    smokiness: number;
    spice: number;
    sweetness: number;
  };
  code: string;
  isCaskStrength: boolean;
  bottlingStrength: String;
  spiritStyle: string;
  productionMethod: string;
  seller: {
    title: string;
    signatureSeries: boolean;
    slug: string;
    curatorName: string;
    attachments: Attachment[];
  };
}

export const CASK_INIT: Cask = {
  // simple fields
  attachments: [
    {
      role: "",
      title: "",
      url: "",
    },
  ],
  artist: {
    name: "",
    description: "",
    linkUrl: "",
    linkText: "",
    attachments: [
      {
        role: "",
        title: "",
        url: "",
      },
    ],
  },
  barrelType: "",
  barrelVariant: "",
  ballotActive: false,
  ballotSlug: "",
  bondQuarter: 0,
  bondYear: 0,
  bottlingAge: 0,
  bottlingMonth: 0,
  bottlingYear: 0,
  collectiveOnly: false,
  displayBadge: false,
  featured: false,
  featuredOrdinal: 0,
  formLink: "",
  shippingTimeframe: "",
  percentageDiscount: "",
  fixedDiscount: "",
  description: "",
  release: "",
  status: "",
  fillingDate: "",
  flavourNotes: "",
  shortDescription: "",
  storedAt: "",
  title: "",
  totalBottles: 0,
  totalReservedBottles: 0,
  type: "cask",
  wholesalePrice: 0,
  yearsToBottling: 0,
  tags: [""],
  memberPrice: 0,
  bottledCaskshare: {
    slug: "",
    id: "",
    defaultVariant: {
      id: "",
    },
    type: "",
  },
  earlyBottlePrice: {
    amount: 0,
    currencyCode: "",
  },
  midBottlePrice: {
    amount: 0,
    currencyCode: "",
  },
  lateBottlePrice: {
    amount: 0,
    currencyCode: "",
  },
  priceTier: "",
  product: <any>{},
  // compound fields
  defaultVariant: {
    id: "",
    slug: "",
    price: {
      amount: 0,
      currencyCode: "",
      maxDate: "",
      maxBottles: 0,
    },
  },
  distillery: {
    overview: "",
    name: "",
    intro: "",
    physicalAddress: {
      country: "",
      countryCode: "",
    },
    slug: "",
    status: "",
    attachments: [
      {
        role: "",
        title: "",
        url: "",
      },
    ],
  },
  image: "",
  variant: {
    id: "",
  },
  variants: [VARIANT_INIT],
  flavourMap: {
    complexity: 0,
    fruitiness: 0,
    smokiness: 0,
    spice: 0,
    sweetness: 0,
  },
  code: "",
  isCaskStrength: false,
  bottlingStrength: "",
  spiritStyle: "",
  productionMethod: "",
  seller: {
    title: "",
    signatureSeries: false,
    slug: "",
    curatorName: "",
    attachments: [
      {
        role: "",
        title: "",
        url: "",
      },
    ],
  },
};

export const CASK_FRAGMENT = gql`
  fragment CaskFragment on Cask {
    barrelType
    barrelVariant
    bondQuarter
    bondYear
    bottlingAge
    bottlingMonth
    bottlingYear
    collectiveOnly
    displayBadge
    fixedDiscount
    percentageDiscount
    description
    release
    fillingDate
    flavourNotes
    featured
    featuredOrdinal
    formLink
    shippingTimeframe
    shortDescription
    storedAt
    title
    totalBottles
    totalReservedBottles
    type
    wholesalePrice
    yearsToBottling
    status
    tags
    memberPrice
    ballotActive
    ballotSlug
    bottledCaskshare {
      slug
      id
      defaultVariant {
        id
      }
      type
    }
    earlyBottlePrice {
      amount
      currencyCode
    }
    attachments {
      role
      title
      url
    }
    artist {
      name
      description
      linkUrl
      linkText
      attachments {
        role
        title
        url
      }
    }
    midBottlePrice {
      amount
      currencyCode
    }
    lateBottlePrice {
      amount
      currencyCode
    }
    priceTier
    product {
      abv
      title
      description
      descriptionHtml
      createdAt
      percentageDiscount
      fixedDiscount
      id
      slug
      country
      type
      quantity
      defaultVariant {
        id
        sku
        memberPrice
        price {
          amount
          currencyCode
        }
        displayName
        image
      }
      volume
      vendor
      seller {
        title
      }
      image
    }
    defaultVariant {
      id
      slug
      displayName
      price {
        amount
        currencyCode
        maxDate
        maxBottles
      }
      nonBottleImage
      image
    }
    distillery {
      overview
      name
      intro
      physicalAddress {
        country
        countryCode
      }
      slug
      status
      attachments {
        role
        title
        url
      }
    }
    image
    variants {
      availableForSale
      nonBottleImage
      id
      slug
      image
      price {
        amount
        currencyCode
        maxDate
        maxBottles
      }
      compareAtPrice {
        amount
        currencyCode
      }
    }
    flavourMap {
      complexity
      fruitiness
      smokiness
      spice
      sweetness
    }
    code
    bottlingStrength
    isCaskStrength
    spiritStyle
    productionMethod
    seller {
      title
      signatureSeries
      slug
      curatorName
      attachments {
        role
        title
        url
      }
    }
  }
`;

export const CASK = gql`
  query Cask($shop: ID!, $id: ID, $slug: String) {
    cask(shop: $shop, id: $id, slug: $slug) {
      ...CaskFragment
    }
  }
  ${CASK_FRAGMENT}
`;

export const RECOMMENDED_CASKS_FROM_FLAVOUR_MAP = gql`
  query recommendedCasks($shop: ID!, $flavourMap: FlavourMapAttributes!) {
    recommendedCasks(shop: $shop, flavourMap: $flavourMap) {
      ...CaskFragment
    }
  }
  ${CASK_FRAGMENT}
`;

export const RECOMMENDED_CASKS = gql`
  query similarCasks($shop: ID!, $cask: ID, $slug: String, $first: Int) {
    similarCasks(shop: $shop, cask: $cask, slug: $slug, first: $first) {
      ...CaskFragment
    }
  }
  ${CASK_FRAGMENT}
`;

export const CASK_SUMMARY = gql`
  query caskSummary($shop: ID!) {
    caskSummary(shop: $shop) {
      countries {
        name
        totalCasks
      }
      distilleries {
        name
        totalCasks
        totalProducts
        isAvailable
      }
      statuses {
        name
        totalCasks
      }
      bottlingAges {
        name
        totalCasks
      }
      yearsToBottlings {
        name
        totalCasks
      }
      priceBrackets {
        min
        max
      }
      spiritStyles {
        name
        totalCasks
      }
      shippingTimeframe {
        name
        totalCasks
      }
      flavours
    }
  }
`;

export const CASKS = gql`
  query Casks($shop: ID!, $filters: CaskFilterAttributes) {
    casks(shop: $shop, filters: $filters) {
      ...CaskFragment
    }
  }
  ${CASK_FRAGMENT}
`;

export const DISTILLERY_CASKS = gql`
  query Casks($shop: ID!, $distilleryId: ID) {
    casks(shop: $shop, distilleryId: $distilleryId) {
      ...CaskFragment
    }
  }
  ${CASK_FRAGMENT}
`;

export const SELLER_CASKS = gql`
  query Casks($shop: ID!, $sellerId: ID) {
    casks(shop: $shop, sellerId: $sellerId) {
      ...CaskFragment
    }
  }
  ${CASK_FRAGMENT}
`;
