import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

import styles from "./SignUpForNew.module.scss";
import SignUpForNewForm from "../SignUpForNewForm";

import windowImg from "../../../assets/sign-up-for-new/look-out-window.png";
import phoneImg from "../../../assets/sign-up-for-new/using-phone.png";

const SignUpForNew = () => {
  return (
    <Row className={styles.signUpForNewComponent} as="section">
      <Col className={styles.imageContainer}>
        <div className={styles.lookOutWindowDivImage}>
          <img src={windowImg} alt="Caskshare whisky looking out the window" />
        </div>
        <div className={styles.usingPhoneDivImage}>
          <img src={phoneImg} alt="Cashshare website on phone" />
        </div>
      </Col>

      <Col className={styles.signUpForNewArea}>
        <h3>
          Sign up for new product arrivals and special <br /> offers
        </h3>
        <SignUpForNewForm />
      </Col>

      <Col className={styles.howDoesItWorkArea}>
        <div className={styles.imgArea}>
          <div className={styles.linkArea}>
            <h4 className={styles.dMdBlock}>
              Unique taste.
              <br />
              Unique experience.
            </h4>

            <Button
              smooth
              // to={{ pathname: "/#how-does-caskshare-work-link-dest" }}
              to={{ pathname: "/" }}
              target="_blank"
              as={HashLink}
              variant="primary"
              className={styles.signUpBtn}
            >
              How does it work?
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SignUpForNew;
