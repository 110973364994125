import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/currency";
import { RootState } from "../../../state/rootReducer";
import styles from "./AddProductToBasket.module.scss";
import { Product } from "../../../graphql/Products";
import { Button } from "react-bootstrap";
import { increaseCheckoutQuantity } from "../../../state/slices/checkoutSlice";
import { useHistory } from "react-router-dom";

const AddProductToBasket = ({
  product,
  originalPrice
}: {
  product: Product,
  originalPrice?: number
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (product?.defaultVariant) {
      setPrice(product.defaultVariant.price.amount);
    }
  }, [product]);

  if (!shop?.currency || price <= 0) {
    return null;
  }

  const addProductToBasket = () => {
    dispatch(increaseCheckoutQuantity({ product: product }));
    history.push("/basket");
  };

  return (
    <div className={styles.priceWrap}>
      <div className={styles.innerWrap}>
        <h3 className={styles.price}>
          {price &&
            formatCurrency(shop.currency, price > 999 ? 0 : 2).format(price)}
        </h3>
        {originalPrice && originalPrice > price && (
          <p className={styles.originalPrice}>
            Was{" "}
            {formatCurrency(shop.currency, originalPrice > 999 ? 0 : 2).format(
              originalPrice
            )}
          </p>
        )}
        {!product.nonBottleImage && <small>/bottle</small>}
      </div>
      <div className={styles.shippingInfo}>
        <p>
          <span>Shipping now</span>
        </p>
      </div>

      <div className={styles.buttonRow}>
        <div className={styles.buttonRow}>
          <Button
            className={styles.btnCoralOutline}
            onClick={addProductToBasket}
            disabled={product.quantity <= 0}
          >
            {product.quantity >= 1 ? "Add to basket" : "Sold Out"}
          </Button>
        </div>
      </div>
      {shop.market === "GB" ? (
        <div className={styles.infoRow}>
          <p className={styles.priceNotice}>
            <small>Buying outside UK? VAT removed at checkout.</small>
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default AddProductToBasket;
