import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Spinner, Toast } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "react-apollo";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { getMetaText, getCopyText } from "../AppText";
import { CONTACT_US } from "../graphql/User";
import { setUser } from "../state/slices/userSlice";

// answers for "How did you hear about Caskshare?" dropdown
// [ [ key, display text ], [ ...
const HOW_HEAR_OPTIONS = [
  ["web-search", "Web search"],
  ["web-publication", "Web publication"],
  ["print-publication", "Print publication"],
  ["word-of-mouth", "Word of mouth"],
  ["other", "Other"],
];

const ContactPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [howHear, setHowHear] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState("");
  const [customerContactUs] = useMutation(CONTACT_US);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
  }, [email, message]);

  const onContactUs = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      // const { data, errors } = await customerContactUs({
      //   variables: {
      //     email,
      //     message,
      //     howHear,
      //   }
      // })
      // if (errors) {
      //   setError(errors[0].message)
      //   setLoading(false)
      //   return
      // }
      setShowToast(true);
    } catch (registerError) {
      setError(registerError.message);
    }
    setLoading(false);
  };
  return (
    <Col xs={12}>
      <Helmet>
        <title>{getMetaText("CONTACT_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("CONTACT_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("CONTACT_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("CONTACT_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("CONTACT_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("CONTACT_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("CONTACT_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("CONTACT_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin + require("../assets/home-page/splash.jpg")
          }
        />
      </Helmet>

      <Row>
        <Col
          style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-md-6">
            <h4>Contact Us</h4>
            <Form onSubmit={onContactUs}>
              <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Email address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Your message</Form.Label>
                <Form.Control
                  size="lg"
                  as="textarea"
                  rows={6}
                  placeholder="Your message"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>How did you hear about Caskshare?</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  size="lg"
                  onChange={(e) => setHowHear(e.target.value)}
                >
                  <option key="none" id="none"></option>
                  {HOW_HEAR_OPTIONS.map(([key, text]) => (
                    <option key={key} id={key}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <span style={{ color: "red", fontWeight: "bold" }}>
                {error || null}
              </span>
              <Form.Group>
                <Button type="submit" disabled={loading || !!error} block>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </>
                  ) : (
                    "Send"
                  )}
                </Button>
              </Form.Group>
            </Form>
            <Toast
              delay={3000}
              autohide
              animation
              onClose={() => setShowToast(false)}
              style={{ marginTop: "30px" }}
              show={showToast}
            >
              <Toast.Header closeButton={false}>
                Your message has been sent - thankyou!
              </Toast.Header>
            </Toast>
          </div>
        </Col>
      </Row>
    </Col>
  );
};
export default ContactPage;
