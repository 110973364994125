import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { CASK, Cask } from "../graphql/Casks";
import { RootState } from "../state/rootReducer";
import CaskThumbnail from "../components/casks/CaskThumbnail";

const CaskThumbnailPage = () => {
  // @ts-ignore
  const { caskId } = useParams();
  const shopId = useSelector((state: RootState) => state.shop.id);

  const caskVariables = (() => {
    if (caskId.indexOf("-")) {
      return { shop: shopId, slug: caskId };
    } else {
      return { shop: shopId, id: `gid://shopify/ProductVariant/${caskId}` };
    }
  })();

  const { data, loading } = useQuery(CASK, {
    variables: caskVariables,
  });

  if (!data || loading) {
    return <></>;
  }
  const cask: Cask = data.cask;

  return (
    <div id="cask-thumbnail" className="thumbnail-wrapper">
      <CaskThumbnail cask={cask} />
    </div>
  );
};

export default CaskThumbnailPage;
