import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BALLOT, BALLOT_CHECKOUT } from "../../../graphql/Ballot";
import { useQuery, useMutation } from "react-apollo";
import { getMetaText, getSpiritStyle } from "../../../AppText";
import { RootState } from "../../../state/rootReducer";
import styles from "./BallotFallback.module.scss";
import BottleImage from "../../../caskshare-v3/components/casks/caskCard/BottleImage";
import FlavourComp from "../../../caskshare-v3/components/casks/flavourComponent/FlavourComp";
import { formatCurrency } from "../../../utils/currency";
import { IMAGE_FALLBACKS } from "../../../config";

const BallotSuccess = ({}: {}) => {
  // @ts-ignore
  const { slug } = useParams();
  const history = useHistory();
  const shop = useSelector((state: RootState) => state.shop);
  const [ballotItem, setBallotItem] = useState<any>({});
  const [showReadMore, setShowReadMore] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [readFullDesc, setReadFullDesc] = useState(false);
  const descriptionRef: any = useRef<HTMLDivElement>();

  const { data, refetch, loading } = useQuery(BALLOT, {
    variables: {
      shop: shop.id,
      slug: slug,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.ballot) {
      setBallotItem(data.ballot);
    }
  }, [data, data?.ballot]);

  useEffect(() => {
    if (descriptionRef?.current) {
      if (
        // @ts-ignore
        descriptionRef?.current.offsetHeight <
        descriptionRef?.current.scrollHeight
      ) {
        setShowReadMore(true);
      } else {
        setShowReadMore(false);
      }
    }
    return () => {
      setShowReadMore(false);
      setShadow(false);
    };
  }, [descriptionRef?.current]);

  const formatDate = (d: Date) =>
    d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();

  const getLargeBottle = (attachments: any) => {
    let images = attachments?.filter((a: any) => a.role === "large");
    let image = images?.length
      ? images[0]?.url
      : require("../../../assets/home-page/.v3/db-lg.png");
    return image;
  };
  const getSmallBottle = (attachments: any) => {
    let images = attachments?.filter((a: any) => a.role === "small");
    let image = images?.length
      ? images[0]?.url
      : require("../../../assets/home-page/.v3/db-sm.png");
    return image;
  };
  const shippingDate = () => {
    let shippingDate;
    if (ballotItem?.cask?.bottlingYear && ballotItem?.cask?.bottlingMonth) {
      shippingDate = new Date(ballotItem.cask.bottlingYear, ballotItem.cask.bottlingMonth + 1 + shop.shippingMonthIncrement, 0);
    } else if (ballotItem.shippingAt) {
      let shippingAt = new Date(ballotItem.shippingAt);
      shippingDate = new Date(shippingAt.getFullYear(), shippingAt.getMonth() + 1, 0);
    }
    let today = new Date();

    if (Number(shippingDate) >= Number(today)) {
      return (
        <p className={styles.info}>
          {shippingDate ? formatDate(shippingDate): ""}
        </p>
      );
    } else if (Number(shippingDate) < Number(today)) {
      console.log(Number(shippingDate) < Number(today));
      return (
        <p>
          <span>Shipping now</span>
        </p>
      );
    } else {
      return "";
    }
  };

  let bottledDate;
  if (ballotItem?.cask?.bottlingMonth && ballotItem?.cask?.bottlingYear) {
    bottledDate = formatDate(
      new Date(
        ballotItem.cask.bottlingYear,
        ballotItem.cask.bottlingMonth - 1,
        1,
      ),
    );
  } else if (ballotItem.product?.title) {
    bottledDate = "Bottled";
  } else {
    bottledDate = "TBA...";
  }

  const ballotTitleMarkup = (title?: string) => {
    return {
      __html: `
    ${title ? `<h3 style="">${title}</h3>` : `<h3 style="display: none"></h3>`}
    `,
    };
  };

  const handleOpenNewsLetter = () => {
    // @ts-ignore
    window._klOnsite = window._klOnsite || [];
    // @ts-ignore
    window._klOnsite.push(["openForm", "VTZnPC"]);
    window.addEventListener("klaviyoForms", function (e: any) {
      if (e.detail.type == "open") {
        // @ts-ignore
        _learnq.push([
          "identify",
          {
            Market: shop.market,
          },
        ]);
      }
    });
  };

  if (!Object.keys(ballotItem).length) {
    return (
      <div className={styles.successWrap}>
        <h4>Success!</h4>
        <p>
          Thank you for your submission! Click the button below to view all
          caskshares.
        </p>
        <Button className={styles.btnPrimary} as={Link} to="/caskshares">
          Shop Caskshares
        </Button>
      </div>
    );
  }

  return (
    <Container>
      <div className={styles.successWrap}>
        <div className={styles.heading}>
          <h4>Submission Confirmed!</h4>
          <p>
            Here's what's in store for you.
            You'll be notified via email if you entry is successful at the conclusion
            of the ballot.
          </p>
          <span />
        </div>
        <div className={`${styles.sectionWrap} ${""}`}>
          <Row className={`${styles.caskDetailRow} ${styles.whiteBG}`}>
            <Col xs={12} md={6} className={styles.imgWrap}>
              <BottleImage
                src={
                  ballotItem?.product?.image ?
                    ballotItem?.product?.image
                  : getLargeBottle(ballotItem?.cask?.attachments)
                }
                placeholderSrc={
                  ballotItem?.product?.image ?
                    IMAGE_FALLBACKS.defaultBottle
                  : getSmallBottle(ballotItem?.cask?.attachments)
                }
                props={{ alt: ballotItem?.cask?.title ?? ballotItem?.product?.title }}
              />
            </Col>
            <Col xs={12} md={6} className={styles.contentWrap}>
              <div className={styles.titleWrap}>
                <div className={styles.distillery}>
                  <p>{ballotItem.subtitle}</p>
                </div>
                <div
                  className={styles.title}
                  dangerouslySetInnerHTML={ballotTitleMarkup(ballotItem?.title)}
                />
                <div className={styles.descriptionWrap}>
                  <p
                    ref={descriptionRef}
                    className={`${readFullDesc && styles.fullDescription} ${
                      styles.description
                    }`}
                  >
                    {ballotItem?.cask?.description || ""}
                  </p>
                  {!readFullDesc && showReadMore ? (
                    <p
                      className={styles.more}
                      onClick={() => setReadFullDesc((prev) => !prev)}
                    >
                      read more
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.detailWrap}>
                <div className={styles.caskInfoWrap}>
                  <div className={styles.inner}>
                    <p>Bottling strength</p>
                    {ballotItem?.cask?.isCaskStrength ? (
                      <p className={styles.info}>Cask strength</p>
                    ) : ballotItem?.cask?.bottlingStrength ? (
                      <p className={styles.info}>
                        {ballotItem?.cask?.bottlingStrength}%
                      </p>
                    ) : (
                      <p className={styles.info}>Cask Strength</p>
                    )}
                  </div>
                  <div className={styles.inner}>
                    <p>Sold by</p>
                    <p className={styles.info}>
                      {ballotItem?.cask?.seller?.title ?? ballotItem?.product?.seller?.title}
                    </p>
                  </div>
                  <div className={styles.inner}>
                    <p>Bottling Date</p>
                    <p className={styles.info}>
                      {bottledDate ? bottledDate : "TBA.."}
                    </p>
                  </div>
                  <div className={styles.inner}>
                    <p>Spirit style</p>
                    <p className={styles.info}>
                      {ballotItem?.cask?.spiritStyle &&
                      ballotItem?.cask?.spiritStyle !== "plain"
                        ? getSpiritStyle(ballotItem?.cask?.spiritStyle, "text")
                        : "Multiple"}
                    </p>
                  </div>
                  <div className={styles.inner}>
                    <p>Est. yield</p>
                    <p className={styles.info}>
                      {ballotItem?.cask?.totalBottles
                        ? `${ballotItem?.cask?.totalBottles} Bottles`
                        : ballotItem?.quantity}
                    </p>
                  </div>
                  <div className={styles.inner}>
                    <p>
                      {ballotItem?.cask ?
                        `Est. shipping:${" "}`
                        :
                        `Shipping:${" "}`
                      }
                    </p>
                    <p className={styles.info}>{shippingDate()}</p>
                  </div>
                </div>
              </div>
              <div className={styles.ctaWrap}>
                <Button
                  type="button"
                  className={styles.btnCoralOutline}
                  onClick={handleOpenNewsLetter}
                >
                  Join Our Newsletter
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default BallotSuccess;
