import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import styles from "./EmailOpt.module.scss";
import { ReactComponent as Logo } from "../../assets/images/rebrand-logo.svg";

type EmailOptParams = {
  action: string;
  campaign: string;
};

const EmailOpt = () => {
  const history = useHistory();
  const { action, campaign } = useParams<EmailOptParams>();

  const campaignAction = (campaign: string) => {
    switch (campaign) {
      case "fathers-day":
        return "Fathers' Day";
      default:
        return "";
    }
  };

  const emailAction = (action: string) => {
    switch (action) {
      case "opt-in":
        return "opting in";
      case "opt-out":
        return "opting out";
      default:
        return "";
    }
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        {/* <div className={styles.headline}>
          <h4>Thank you!</h4>
        </div> */}
        {!action || !campaign ? (
          <>
            <div className={styles.paragraph}>
              <p>
                Oops, looks like something gone wrong. Please hit the button
                below and we will redirect you to our website
              </p>
            </div>
            <div className={styles.buttonsWrap}>
              <Button
                value="true"
                onClick={() => history.push("/")}
                className={styles.btnCoralOutline}
              >
                Visit Caskshare.com
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.paragraph}>
              <p>
                Thanks for {emailAction(action)} of {campaignAction(campaign)}{" "}
                comms - you'll still receive updates from us on everything else.
              </p>
            </div>
            <div className={styles.buttonsWrap}>
              <Button
                value="true"
                onClick={() => history.push("/")}
                className={styles.btnCoralOutline}
              >
                Visit Caskshare.com
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailOpt;
